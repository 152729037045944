import React from 'react'
import logo from '../../img/logo.svg'
import './Login.scss';
import Cookies from 'js-cookie'
import config from '../../config/config.json'

const ModuleNavbar = () =>{
  return (
    <div className="loginNavbar">
        <div className="logo"><img src={ config["logo"].includes(Cookies.get("master")) ? `/logo/${Cookies.get("master")}.svg` : logo} alt="logo"/></div>
        {/* <div className="navbar-list">
          <p>Language</p>
          <p>Logout</p>
        </div> */}
    </div>
  )
}

export default ModuleNavbar