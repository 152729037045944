import React, { useContext, useEffect, useRef } from 'react'
import style from './style/PartlevelResult.module.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { AppContext } from './context'
import lang from './language/PartLevelResult.json'
import GoBack from '../img/left-arrow.png'
import Cookies from 'js-cookie'

let imageCounter = 0

const RejectedPartLevelResult = () => {

    const { swipe, setSwipe, photowiseDetails, selectedImage, setSelectedImage, language} = useContext(AppContext)

    const navigate = useNavigate()
    const imageRef = useRef()
    const { id } = useParams()

    const setImageCounter = (direction) => {
        if(direction === "right"){
            if(imageCounter >= photowiseDetails["rejected_img_urls"].length - 1){
                imageCounter = 0
                setSelectedImage(photowiseDetails["rejected_img_urls"][imageCounter])
                scrollSmoothHandler(-12000)
                return
            }
            imageCounter ++
            setSelectedImage(photowiseDetails["rejected_img_urls"][imageCounter])
            scrollSmoothHandler(103)
        }else{
            if(imageCounter <= 0){
                imageCounter = photowiseDetails["rejected_img_urls"].length - 1
                setSelectedImage(photowiseDetails["rejected_img_urls"][imageCounter])
                scrollSmoothHandler(12000)
                return
            }
            imageCounter --
            setSelectedImage(photowiseDetails["rejected_img_urls"][imageCounter])
            scrollSmoothHandler(-103)
        }
    }

    const scrollSmoothHandler = (value) => {
        imageRef.current.scrollLeft += value
    }

    useEffect(()=>{
        imageCounter = 0
        setSelectedImage(photowiseDetails["rejected_img_urls"][imageCounter])
    },[setSelectedImage, photowiseDetails])

    return (
    <div className={style.carlevel}>
        <div className={style.ModuleID} onClick={()=>{
                if(Cookies.get("isMahindra")) return navigate('/vehicle-listing')
                navigate('/pre-inspection')
            }}>
            <img src={GoBack}></img>
            <span className={style.preSpan}>{Cookies.get("isMahindra") ? "Vehicle Listing Page" : lang["Pre-Inspection"][language]}</span>
        </div>

        <div className={style.switchBtnContainer}>
            <div>
                <button style={{backgroundColor: swipe === "carLevelResult" ? "#157AFF" : null }} onClick={() => setSwipe("carLevelResult")} className={style.switchBtn}>{lang["Car level result"][language]}</button>
                <button style={{backgroundColor: swipe === "partlevelResult" ? "#157AFF" : null }} onClick={() => setSwipe("partlevelResult")} className={style.switchBtn}>{lang["Part level result"][language]}</button>
            </div>
        </div>
        <div className={style.resultSvg}>
            <div className={style.profileUpdate}>
                <div className={style.profileUpdateContent}>
                    <p className={style.profile}>NA</p>
                </div>
            </div>
            <div className={style.carSvg}>
                <div className={style.carSvgContainer}>
                    <div className={style.carImg}>
                        <img className={style.img} src={selectedImage} alt="maincar" />
                    </div>
                </div>
            </div>
            <div className={style.footer}>
                <div className={style.degreeUpdater}>
                    <p className={style.degree}>
                        {`Q-Score: NA, Angle: NA`}
                    </p>
                </div>
            </div>
            <div className={style.imageReelContainer}>
                <i className="fa-solid fa-angle-left" onClick={()=>setImageCounter("left")}></i>
                <div className={style.imageReel} ref={imageRef}>
                    {photowiseDetails["rejected_img_urls"].map((item,index)=>{
                        return <div key={index} onClick={()=>{
                            setSelectedImage(photowiseDetails["rejected_img_urls"][index])
                            imageCounter = index
                            }}>
                            <img src={item} style={{border: item === selectedImage ? "3px solid #157AFF" : null}} alt="img"/>
                        </div>
                    })}
                </div>
                <i className="fa-solid fa-angle-right" onClick={()=>setImageCounter("right")}></i>
            </div>
        </div>
    </div>
  )
}

export default RejectedPartLevelResult