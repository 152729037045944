import React, { useState, useEffect, useContext } from 'react'
import profileImg from "../img/profile.png"
import filterimg from "../img/filter.png"
import car from "../img/carFleet.png"
import location from "../img/location.png"
import logo from "../img/logoBlack.png"
import downArrow from '../img/downArrowFleet.png'
import styles from './style/FleetInspection.module.scss'
import loadingImg from '../img/loading.gif'
import { makeAuthRequest, checkMahindraCookies, removeCookies } from './context/utils'
import conf from '../config/config.json'
import Cookies from 'js-cookie'
import { useNavigate, useParams } from 'react-router-dom'
import { AppContext } from './context';
import Navbar from './Navbar'
import cross from "../img/cross.png"
import Multiselect from 'multiselect-react-dropdown'

const InspectionList = () => {
  
  const { isMahindraClient } = useContext(AppContext)
  
  const [filterApplied, setFilterApplied] = useState(false)
  const [processingCount, setProcessingCount] = useState(0)
  const [availableCount, setAvailableCount] = useState(0)
  const [rejectedCount, setRejectedCount] = useState(0)
  const [inspectionProcessingData, setInspectionProcessingData] = useState([])
  const [inspectionAvailableData, setInspectionAvailableData] = useState([])
  const [inspectionRejectedData, setInspectionRejectedData] = useState([])
  const [loading, setLoading] = useState(true)
  const [showFilter, setShowFilter] = useState(false)
  const [data, setData] = useState([])
  const [inspectionId, setInspectionId] = useState("")
  const [inspectionDate, setInspectionDate] = useState("")
  const [carGrade, setCarGrade] = useState("")

  const {urlLink} = conf
  const {id} = useParams()
  const navigate = useNavigate()

  const getData = async () => {
    setLoading(true)
    if(!id) return navigate("/vehicle-listing")
    const res = await makeAuthRequest(`${urlLink}fleet-record`, { access_token: Cookies.get("token"), tl_id: Cookies.get("MahindraTlId"), client_type: Cookies.get("MahindraClientType"), client_id: Cookies.get("client"), vin: id, region_id: Cookies.get("MahindraRegionId")})
    const data1 = await res.json()
    if(res.ok){
      setData(data1)
      setInspectionProcessingData(data1.processing)
      setInspectionAvailableData(data1.available)
      setInspectionRejectedData(data1.rejected)
      setShowFilter(false)
      setFilterApplied(false)
      setInspectionId("")
      setInspectionDate("")
      setCarGrade("")
      setLoading(false)
    }
  }

  const localTime = (time) => {
    let Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    let dateTime = time.split(", ")
    let mon = Months.indexOf(dateTime[0].split(" ")[1])
    let local = new Date(Date.UTC(dateTime[0].split(" ")[2], mon, dateTime[0].split(" ")[0], dateTime[1].split(":")[0], dateTime[1].split(":")[1]))
    let Dte = local.getDate()
    let Month = Months[local.getMonth()]
    let year = local.getFullYear()
    let hour = local.getHours() % 12 ? local.getHours() % 12 >= 10 ? local.getHours() % 12 : `0${local.getHours() % 12}` : 12 
    let minute = local.getMinutes() >= 10 ? local.getMinutes() : `0${local.getMinutes()}` 
    let ampm = local.getHours() >= 12 ? 'PM' : 'AM'

    return `${Dte} ${Month} ${year}, ${hour}:${minute} ${ampm}`
  }

  const vehicleAge = (date) => {

    let months = Math.max(
      (new Date().getFullYear() - new Date(date).getFullYear()) * 12 +
      new Date().getMonth() -
      new Date(date).getMonth(),
      0
    )
    
    if(months > 12){
      return Math.floor(months / 12) === 1 ? `${Math.floor(months / 12)} year` : `${Math.floor(months / 12)} years`
    }
    else return months === 1 ? `${months} month` : `${months} months`
  }

  const searchData = async() => {
    setLoading(true)
    let payload = { access_token: Cookies.get("token"), tl_id: Cookies.get("MahindraClientType") === "master" ? "" : Cookies.get("MahindraClientType") === "region" ? "" : Cookies.get("MahindraTlId"), client_type: Cookies.get("MahindraClientType"), client_id: Cookies.get("client"), vin: id, region_id: Cookies.get("MahindraClientType") === "master" ? "" : Cookies.get("MahindraClientType") === "agent" ? "" : Cookies.get("MahindraRegionId")}
    if(inspectionId) payload.inspection_id = inspectionId
    if(inspectionDate) payload.inspection_date = inspectionDate
    if(carGrade) payload.car_grade = carGrade
    if(Object.keys(payload).length <= 4) return setLoading(false)
    const res = await makeAuthRequest(`${urlLink}filter-value-fleet`, payload)
    const data1 = await res.json()
    if(res.ok){
      setInspectionProcessingData(data1.processing)
      setInspectionAvailableData(data1.available)
      setInspectionRejectedData(data1.rejected)
      setShowFilter(false)
      setFilterApplied(true)
      setLoading(false)
    }
  }

  useEffect(()=>{
    if(!checkMahindraCookies()){
        removeCookies()
        return navigate("/login")
    }
    getData()
  },[])

  return (
    <>
    <Navbar/>
    {loading ? 
    <div className={styles.loading} >
        <img src={loadingImg} alt='loading...'/>
    </div>: 
    <div className={styles.container}>
      <div className={styles.filterContainer}>
          <div className={styles.profile}>
              <img src={profileImg} alt="profile"></img>
              <div>
                  <span>{Cookies.get("MahindraDisplayName")}</span>
                  {Cookies.get("MahindraClientType") === "agent" && <span>({Cookies.get("MahindraTlName") ? Cookies.get("MahindraTlName") : "---"}, {Cookies.get("MahindraLocationName") ? Cookies.get("MahindraLocationName") : "---"})</span>}
              </div>
          </div>
          <div className={styles.filter}>
          <img src={filterimg} alt='filter' onClick={()=>setShowFilter(!showFilter)}></img>
              {filterApplied && <div>
                  <span className={styles.filterClear} onClick={()=>getData()}>clear</span>
              </div>}
          </div>
      </div>
      <div className={styles.info}>
        <img className={styles.infoimg} src={data.vehicle_sync_data[0].car_image ? data.vehicle_sync_data[0].car_image : car} alt='car'></img>
        <div className={styles.infoContent}>
            <div className={styles.headingContent}>
                <p style={{fontWeight: 600}}>VIN: {data.vehicle_sync_data[0].vin}</p>
                <p>{data.vehicle_sync_data[0].last_inspection_date ? localTime(data.vehicle_sync_data[0].last_inspection_date).split(", ")[0] : "---"}</p>
                <p>{data.vehicle_sync_data[0].last_inspection_date ? localTime(data.vehicle_sync_data[0].last_inspection_date).split(", ")[1] : "---"}</p>
            </div>
            <div className={styles.allData}>
                <div className={styles.address}>
                    <span>{data.vehicle_sync_data[0].model}, {data.vehicle_sync_data[0].variant}</span>
                    <div>
                        <img src={location} alt='loc'></img>
                        <span>{data.vehicle_sync_data[0].location_name}</span>
                    </div>
                </div>
                <div className={styles.carGrade}>
                    <span>Car Condition: {data.vehicle_sync_data[0].car_grade ? data.vehicle_sync_data[0].car_grade === "Grade A1" ? "No Damage" : (data.vehicle_sync_data[0].car_grade === "Grade A2" || data.vehicle_sync_data[0].car_grade === "Grade A3") ? "Minor Scratches / Damages" : "Multiple Damages" : "---"}</span>
                    <span>{data.vehicle_sync_data[0].status}</span>
                </div>
                <div className={styles.kms}>
                    <span style={{visibility: "hidden"}}>2000 KM</span>
                    <span>{data.vehicle_sync_data[0].odo ? data.vehicle_sync_data[0].odo + " KMs" : "---"}</span>
                </div>
                <div className={styles.age}>
                    <div className={styles.ageTitle}>
                        <span>Vehicle Age</span>
                        <span>Region</span>
                    </div>
                    <div className={styles.ageValues}>
                        <span>{data.vehicle_sync_data[0].addition_date ? vehicleAge(data.vehicle_sync_data[0].addition_date) : "---"}</span>
                        <span>{data.vehicle_sync_data[0].region_name ? data.vehicle_sync_data[0].region_name : "---"}</span>
                    </div>
                </div>
            </div>
        </div>    
      </div>

      <div className={styles.card}>
            <div className={styles.cardHeading}>
                <p className={styles.heading1}> Available ({inspectionAvailableData.length})</p>
            </div>
            {inspectionAvailableData.map((item, index) => {
                if(index >= 2 + availableCount) return
                return (
                    <div className={styles.carData} style={{cursor: "pointer"}} key={index} onClick={()=>{return navigate(`/pre-inspection/${item.inspection_id}`)}}>
                        <p className={styles.name}>{item.inspection_id ? item.inspection_id : "---"}</p>
                        <p className={styles.time}>{item.inspection_date ? localTime(item.inspection_date).split(", ")[0] : "---"}</p>
                        <p className={styles.time}>{item.inspection_date ? localTime(item.inspection_date).split(", ")[1] : "---"}</p>
                        <p className={styles.odo}>{item.odo ? item.odo + " KMs" : "---"}</p>
                        <p className={styles.carGr}>Car Condition: {item.car_grade ? item.car_grade === "Grade A1" ? "No Damage" : (item.car_grade === "Grade A2" || item.car_grade === "Grade A3") ? "Minor Scratches / Damages" : "Multiple Damages" : "---"}</p>
                    </div>
                )
            })}
            <div className={styles.buttonContainer}>
                {availableCount !== 0 && <div className={styles.minButton} onClick={()=>setAvailableCount(0)}>
                    <p>Minimize Inspection</p>
                    <img src={downArrow} alt="arrow"></img>
                </div>}
                {inspectionAvailableData.length > availableCount + 2 && <div className={styles.allButton} onClick={()=>setAvailableCount(availableCount + 10)}>
                    <p>View all inspections</p>
                    <img src={downArrow} alt="arrow"></img>
                </div>}
            </div>
        </div>

      <div className={styles.card}>
            <div className={styles.cardHeading}>
                <p className={styles.heading1}> Processing ({inspectionProcessingData.length})</p>
            </div>
            {inspectionProcessingData.map((item, index) => {
                if(index >= 2 + processingCount) return
                return (
                    <div className={styles.carData} key={index}>
                        <p className={styles.name}>{item.inspection_id ? item.inspection_id : "---"}</p>
                        <p className={styles.time}>{item.inspection_date ? localTime(item.inspection_date).split(", ")[0] : "---"}</p>
                        <p className={styles.time}>{item.inspection_date ? localTime(item.inspection_date).split(", ")[1] : "---"}</p>
                        <p className={styles.odo}>{item.odo ? item.odo + " KMs" : "---"}</p>
                        <p className={styles.carGr}>Car Condition: {item.car_grade ? item.car_grade === "Grade A1" ? "No Damage" : (item.car_grade === "Grade A2" || item.car_grade === "Grade A3") ? "Minor Scratches / Damages" : "Multiple Damages" : "---"}</p>
                    </div>
                )
            })}
            <div className={styles.buttonContainer}>
                {processingCount !== 0 && <div className={styles.minButton} onClick={()=>setProcessingCount(0)}>
                    <p>Minimize Inspection</p>
                    <img src={downArrow} alt="arrow"></img>
                </div>}
                {inspectionProcessingData.length > processingCount + 2 && <div className={styles.allButton} onClick={()=>setProcessingCount(processingCount + 10)}>
                    <p>View all inspections</p>
                    <img src={downArrow} alt="arrow"></img>
                </div>}
            </div>
        </div>

        <div className={styles.card} style={{marginBottom: "15%"}}>
            <div className={styles.cardHeading}>
                <p className={styles.heading1}> Rejected ({inspectionRejectedData.length})</p>
            </div>
            {inspectionRejectedData.map((item, index) => {
                if(index >= 2 + rejectedCount) return
                return (
                    <div className={styles.carData} key={index} onClick={()=> { return navigate(`/rejected/${item.inspection_id}`)}}>
                        <p className={styles.name}>{item.inspection_id ? item.inspection_id : "---"}</p>
                        <p className={styles.time}>{item.inspection_date ? localTime(item.inspection_date).split(", ")[0] : "---"}</p>
                        <p className={styles.time}>{item.inspection_date ? localTime(item.inspection_date).split(", ")[1] : "---"}</p>
                        <p className={styles.odo}>{item.odo ? item.odo + " KMs" : "---"}</p>
                        <p className={styles.carGr}>Car Condition: {item.car_grade ? item.car_grade === "Grade A1" ? "No Damage" : (item.car_grade === "Grade A2" || item.car_grade === "Grade A3") ? "Minor Scratches / Damages" : "Multiple Damages" : "---"}</p>
                    </div>
                )
            })}
            <div className={styles.buttonContainer}>
                {rejectedCount !== 0 && <div className={styles.minButton} onClick={()=>setRejectedCount(0)}>
                    <p>Minimize Inspection</p>
                    <img src={downArrow} alt="arrow"></img>
                </div>}
                {inspectionRejectedData.length > rejectedCount + 2 && <div className={styles.allButton} onClick={()=>setRejectedCount(rejectedCount + 10)}>
                    <p>View all inspections</p>
                    <img src={downArrow} alt="arrow"></img>
                </div>}
            </div>
        </div>

      <div className={styles.footer}>
          <img src={logo} alt='logo'></img>
          <p>© Copyright 2024 Inspektlabs Inc. All rights reserved.</p>
      </div>
      {showFilter && 
      <div className={styles.filterModal}>
        <div className={styles.filterHead}>
          <span>Filter</span>
          <img src={cross} alt="cross" onClick={() => setShowFilter(false)}></img>
        </div>
        <div className={styles.filterContentContainer}>
          <div className={styles.filterContent}>
            <span>Inspection ID</span>
            <input
              type="text"
              value={inspectionId}
              onChange={(e) => setInspectionId(e.target.value)}
              placeholder="Inspection ID"
              className={styles.inputDate}
            />
          </div>
        </div>
        <div className={styles.filterContentContainer}>
          <div className={styles.filterContent}>
            <span>Inspection Date</span>
            <input
              type="date"
              value={inspectionDate}
              onChange={(e) => setInspectionDate(e.target.value)}
              placeholder="DD/MM/YYYY"
              className={styles.inputDate}
            />
          </div>
        </div>
        <div className={styles.filterContentContainer}>
          <div className={styles.filterContent}>
            <span>Car Condition</span>
            <Multiselect
                isObject={false}
                customCloseIcon={<></>}
                onSearch={()=>{}}
                onSelect={(newList,item)=>setCarGrade(item)}
                selectedValues={[carGrade]}
                style={{
                  searchBox: {
                    'border': 'none',
                    'border-bottom': '1px solid #D9D9D9',
                    'border-radius': '0px',
                    'padding': '0px',
                    'width': '100%'
                  },
                  chips: {
                    'font-size': '10px',
                    'white-space': 'normal',
                  },

                  multiselectContainer: {
                      'font-size': '11px',
                      'word-wrap': 'break-word'
                  }
                }}
                options={["Grade A1", "Grade A2", "Grade A3", "Grade B1", "Grade B2", "Grade B3", "Grade C1", "Grade C2", "Grade C3", "Grade D"]}
                singleSelect
            />
          </div>
        </div>
        <div className={styles.filterBtn} onClick={()=>searchData()}>
          <span>Search</span>
        </div>
      </div>}
    </div>}
    </>
  );
}

export default InspectionList