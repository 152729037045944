import React, { useContext, useEffect, useRef, useState } from 'react'
import Navbar from './Navbar'
import Logo from '../img/logo.svg'
import DragDrop from '../img/DragDrop.svg'
// import DownArrow from '../img/DownBtnDark.svg'
import carTowing from '../img/car-towing.svg'
import ok from '../img/ok.svg'
import styles from'./style/NewInspection.module.scss'
// import Hr from '../img/hrLine.svg'
import { AppContext } from './context'
import { checkCookies, makeAuthFileRequest, removeCookies } from './context/utils'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import loading from '../img/loading.gif'
import lang from './language/NewInspection.json'
import completed from '../img/completed.jpg'
import config from '../config/config.json'

let caseId = ""
let inspectionId = ""

const NewInspection = () => {
    const {urlLink} = config

    const allowedExtensins = ["jpg", "png", "jpeg", "jfif", "mp4", "mov", "webm"]

    const { setLanguage, setModule, setLanguageList, openImageModal, setOpenImageModal, imageList, setImageList, urlList, setUrlList, showImageError, setShowImageError,
            openImageSuccessModal, setOpenImageSuccessModal, language } = useContext(AppContext)
    const [count, setCount] = useState(0)
    const [imageLoading, setImageLoading] = useState(false)

    const navigate = useNavigate()
    const fileRef = useRef()

    const loadImages = (files) => {
        let temp1 = imageList
        let temp2 = urlList
        for(let i = 0; i < files.length; i++){
            let objectUrl = URL.createObjectURL(files[i])
            temp1.push(files[i])
            temp2.push(objectUrl)
        }
        setImageList(temp1)
        setUrlList(temp2)
        setCount(count + 1)
    }

    const removeImage = (position) => {
        let temp1 = imageList
        let temp2 = urlList
        temp1.splice(position, 1)
        temp2.splice(position, 1)
        setImageList(temp1)
        setUrlList(temp2)
        setCount(count + 1)
    }

    const sendData = async() => {
        let image_case = true
        setImageLoading(true)
        setTimeout(()=>{
            setShowImageError("")
        }, 4000)
        if(imageList.length === 0){
            setImageLoading(false)
            return setShowImageError(lang["No Images"][language])
        }

        if(imageList.length > 20){
            setImageLoading(false)
            return setShowImageError(lang["Maximum"][language])
        }

        for(let i = 0; i < imageList.length; i++){
            if(!allowedExtensins.includes(imageList[i].name.split(".")[imageList[i].name.split(".").length - 1].toLowerCase())){
                setImageLoading(false)
                return setShowImageError(lang["prohibited"][language])
            }
            if(["mp4", "mov", "webm"].includes(imageList[i].name.split(".")[imageList[i].name.split(".").length - 1].toLowerCase()) && imageList.length !== 1){
                setImageLoading(false)
                return setShowImageError(lang["video"][language])
            }
            if(["mp4", "mov", "webm"].includes(imageList[i].name.split(".")[imageList[i].name.split(".").length - 1].toLowerCase()) && imageList.length === 1){
                image_case = false
            }
        }

        const formData = new FormData()
        for(let i = 0; i < imageList.length; i++){
            formData.append(`file_list`, imageList[i])
        }
        formData.append("client_id", Cookies.get('client'))
        formData.append("client_type", Cookies.get('clientType'))
        formData.append("is_image_case", image_case)
        formData.append("language", JSON.parse(Cookies.get('all-languages'))[0])

        const res = await makeAuthFileRequest(`${urlLink}portal-upload`, formData)
        const data = await res.json()

        if(data.status !== "success"){
            setImageLoading(false)
            return setShowImageError(data.message)
        }
        
        caseId = data.case_id
        inspectionId = data.inspection_id
        setImageLoading(false)
        setImageList([])
        setUrlList([])
        setOpenImageModal(false)
        setOpenImageSuccessModal(true)
        
    }

    useEffect(()=>{
        //logging out if any of the cookies has expired/missing/removed
        if(!checkCookies()){
            removeCookies()
            navigate('/login')
        }
        else{

            setLanguage(Cookies.get('current-language'))
            setModule(JSON.parse(Cookies.get('modules')))
            setLanguageList(JSON.parse(Cookies.get('all-languages')))

            if(Object.keys(config["portalUpload"]).includes(Cookies.get('master'))){
                if(!config["portalUpload"][Cookies.get('master')]){
                    navigate('/pre-inspection')
                }
            }
        }
    },[])

    return (
    <div>
        <Navbar />
        <div className={styles.newInspection}>
            <div className={styles.Instructions}>
                <img className={styles.instructionLogo} src={config["logo"].includes(Cookies.get("master")) ? `/logo/${Cookies.get("master")}.svg` : Logo} alt="logo" />
                <h4>{lang["Instructions"][language]}</h4>
                <div>
                    <ul>
                        <li> <img className={styles.liImg} src={ok} alt='ok'></img> {lang["files allowed"][language]}</li>
                        <li> <img className={styles.liImg} src={ok} alt='ok'></img> {lang["Upload maximum 20 images or one video with size - 100 MB for one car inspection"][language]}</li>
                        <li> <img className={styles.liImg} src={ok} alt='ok'></img> {lang["Photos/videos taken from 2-3 ft away"][language]}</li>
                        <li> <img className={styles.liImg} src={ok} alt='ok'></img> {lang["Upload any limos, vans, crossovers, pickup trucks, sports cars"][language]}</li>
                    </ul>
                </div>
            </div>
            <div className={styles.instructionBox}>
                <div className={styles.BoxContent}>
                    <div className="Drag-Drop"><img src={DragDrop}  alt="upload"/></div>
                    <p className={styles.DragTitle}>{lang["Drag & Drop"][language]}</p>
                    <p className={styles.DragSubTitle}>{lang["Drag and drop files here or click to browse"][language]}</p>
                    <button onClick={()=>setOpenImageModal(true)}>{lang["Upload Photo/Video"][language]}</button>
                    {/* <button>Point of impact <img src={Hr} alt="hr"/> <img src={DownArrow} alt="downArrow"/></button>
                    <button>Miscellaneous <img src={Hr} alt="hr"/> <img src={DownArrow} alt="downArrow"/></button> */}
                    <div className={styles.CarTowing}>
                    <img src={carTowing} alt="carTowing"/>
                    </div>
                </div>
            </div>
        </div>
        {openImageSuccessModal &&
        <div className={styles.ImageSuccessModalContainer}>
            <div className={styles.ImageSuccessModal}>
                <img src={completed}></img>
                <h3>{lang["Images/Video successfully uploaded"][language]}</h3>
                <p>CaseID: {caseId}</p>
                <p>InspectionID: {inspectionId}</p>
                <div className={styles.ImageSubmit} onClick={()=>{
                        caseId = ""
                        inspectionId = ""
                        setOpenImageSuccessModal(false)
                        }}>{lang["Exit"][language]}</div>
            </div>
        </div>
        }
        {openImageModal && 
        <div className={styles.ImageModalContainer}>
            <div className={styles.ImageModal}>
                <input ref={fileRef} type='file' multiple style={{visibility: 'hidden', height: '1px'}} onChange={(e)=>loadImages(e.target.files)}/>
                <div className={imageList.length === 0 ? styles.ImageUploadArea : styles.ImageUploadedArea} onClick={() => fileRef.current.click()}>
                {imageList.length === 0 ? 
                    <div className={styles.ImageText} style={{display: 'flex'}}>
                        <i className="fa-solid fa-cloud-arrow-up"></i>
                        <h3>{lang["Upload Images or Videos"][language]}</h3>
                    </div>
                :
                    <div className={styles.ImageContainer} style={{display: 'grid'}}>
                        {urlList.map((item,index) => {
                            return <div key={index}>
                                        <i className="fa-solid fa-circle-xmark" onClick={()=>removeImage(index)}></i>
                                        <img src={item}></img>
                                   </div>
                        })}
                    </div>
                }
                    
                </div>
                {showImageError && <h5 className={styles.ImageError}>{showImageError}</h5>}
                <div className={styles.ModalBtnContainer}>
                    <div className={styles.ImageSubmit} onClick={()=>sendData()}>{imageLoading ? <img src={loading}></img>: lang["Submit"][language]}</div>
                    <div className={styles.ImageSubmit} onClick={()=>{
                        setImageList([])
                        setUrlList([])
                        setOpenImageModal(false)
                        setImageLoading(false)
                        }}>{lang["Exit"][language]}</div>
                </div>
            </div>
        </div>}
    </div>
  )
}

export default NewInspection