import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import profileImg from "../img/profile.png"
import filterimg from "../img/filter.png"
import leftArrow from "../img/leftArrow.png"
import rightArrow from "../img/rightArrow.png"
import car from "../img/carFleet.png"
import start from "../img/startFleet.png"
import logo from "../img/logoBlack.png"
import { makeAuthRequest, checkMahindraCookies, removeCookies } from './context/utils'
import styles from './style/VinListing.module.scss'
import loadingImg from '../img/loading.gif'
import conf from '../config/config.json'
import Cookies from 'js-cookie'
import Navbar from './Navbar'
import cross from "../img/cross.png"
import Multiselect from 'multiselect-react-dropdown'

const limit = 20

const VinListing = () => {

  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [locationId, setLocationId] = useState("")
  const [locationName, setLocationName] = useState("")
  const [model, setModel] = useState("")
  const [variant, setVariant] = useState("")
  const [locationList, setLocationList] = useState([])
  const [ModelList, setModelList] = useState({})
  const [variantList, setVariantList] = useState([])
  const [lastInspectionDate, setLastInspectionDate] = useState("")
  const [carGrade, setCarGrade] = useState("")
  const [checkedInspected, setCheckedInspected] = useState(false)
  const [checkedNeverInspected, setCheckedNeverInspected] = useState(false)
  const [checkedActive, setCheckedActive] = useState(true)
  const [checkedInActive, setCheckedInActive] = useState(false)
  const [count, setCount] = useState(0)
  const [openComment, setOpenComment] = useState(false)
  const [comment, setComment] = useState("")
  const [commentVin, setCommentVin] = useState("")
  const [inspectionCount, setInspectionCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [dealerList, setDealerList] = useState([])
  const [regionList, setRegionList] = useState([])
  const [locationObject, setLocationObject] = useState({})
  const [dealerObject, setDealerObject] = useState({})
  const [dealerId, setDealerId] = useState("")
  const [dealerName, setDealerName] = useState("")
  const [regionId, setRegionId] = useState("")
  const [regionName, setRegionName] = useState("")

  const {urlLink} = conf

  const startInspection = async(item) => {
    const res = await makeAuthRequest(`${urlLink}generate-urlgen-token-mahindra`, { access_token: Cookies.get("token"), tl_id: Cookies.get("MahindraTlId"), client_type: Cookies.get("MahindraClientType"), client_id: Cookies.get("client"), tl_name: Cookies.get("MahindraTlName"), agent_id: Cookies.get("MahindraUserId"), agent_name: Cookies.get("MahindraUserName"), generation_time: Date.now(), 
    expiration_time: Date.now() + (43200*60000), persona_id: "1", source: 'Super', app_type: "custom", app_id: "", license_plate: "", vin: item.vin, region_id: Cookies.get("MahindraRegionId")})
    const data1 = await res.json()
    
    if(res.ok){
      window.open(data1.superapp_link)
    }
  };

  const getData = async (page) => {
    setLoading(true)
    const res = await makeAuthRequest(`${urlLink}vehicle-listing`, { access_token: Cookies.get("token"), tl_id: Cookies.get("MahindraTlId"), client_type: Cookies.get("MahindraClientType"), client_id: Cookies.get("client"), limit: limit, page_no: page, region_id: Cookies.get("MahindraRegionId")})
    const res1 = await makeAuthRequest(`${urlLink}get-model-variant-loc`, { access_token: Cookies.get("token"), tl_id: Cookies.get("MahindraClientType") === "agent" ? Cookies.get("MahindraTlId") : dealerId, client_type: Cookies.get("MahindraClientType"), client_id: Cookies.get("client"), region_id: Cookies.get("MahindraRegionId")})
    const res2 = await makeAuthRequest(`${urlLink}count-vehicle-vehicle-listing`, { access_token: Cookies.get("token"), tl_id: Cookies.get("MahindraTlId"), client_type: Cookies.get("MahindraClientType"), client_id: Cookies.get("client"), region_id: Cookies.get("MahindraRegionId")})
    
    const data1 = await res.json()
    const data2 = await res1.json()
    const data3 = await res2.json()
    if(res.ok){
      setData(data1)
      setLocationObject(data2["dealer_location_mapping_list"])
      setDealerObject(data2["region_dealer_mapping_list"])
      Cookies.get("MahindraClientType") === "master" ? setRegionList(data2["region_id_name_mapping"].sort((a,b)=>{
        if (a.region_name < b.region_name) {
          return -1;
        } else if (a.region_name > b.region_name) {
            return 1;
        }
        return 0;
      })) : setRegionList([])
      Cookies.get("MahindraClientType") === "region" && Object.keys(data2["region_dealer_mapping_list"]).includes(Cookies.get("MahindraRegionId")) ? setDealerList(data2["region_dealer_mapping_list"][Cookies.get("MahindraRegionId")].sort((a,b)=>{
        if (a.dealer_name < b.dealer_name) {
          return -1;
        } else if (a.dealer_name > b.dealer_name) {
            return 1;
        }
        return 0;
      })) : setDealerList([])
      Cookies.get("MahindraClientType") === "agent" && Object.keys(data2["dealer_location_mapping_list"]).includes(Cookies.get("MahindraTlId")) ? setLocationList(data2["dealer_location_mapping_list"][Cookies.get("MahindraTlId")].sort((a,b)=>{
        if (a.location_name < b.location_name) {
          return -1;
        } else if (a.location_name > b.location_name) {
            return 1;
        }
        return 0;
      })) : setLocationList([])
      setModelList(data2.model_variant_mapping_list)
      setVariantList([])
      setFilterApplied(false)
      setShowFilter(false)
      setLocationId("")
      setLocationName("")
      setDealerId("")
      setDealerName("")
      setRegionId("")
      setRegionName("")
      setModel("")
      setVariant("")
      setLastInspectionDate("")
      setCarGrade("")
      setCheckedInspected(false)
      setCheckedNeverInspected(false)
      setCheckedActive(true)
      setCheckedInActive(false)
      setInspectionCount(data3.count)
      setLoading(false)
    }
  }

  const searchData = async() => {
    setLoading(true)
    let payload = { access_token: Cookies.get("token"), tl_id: Cookies.get("MahindraClientType") === "agent" ? Cookies.get("MahindraTlId") : dealerId, client_type: Cookies.get("MahindraClientType"), client_id: Cookies.get("client"), status: checkedActive ? "active" : "inactive", region_id: Cookies.get("MahindraClientType") === "region" ? Cookies.get("MahindraRegionId") : regionId}
    if(locationId) payload.location = locationId
    if(model) payload.model = model
    if(variant) payload.variant = variant
    if(lastInspectionDate) payload.last_inspection_date = lastInspectionDate
    if(carGrade) payload.car_grade = carGrade
    if(checkedInspected || checkedNeverInspected) payload.is_inspected = checkedInspected
    if(Object.keys(payload).length <= 4) return setLoading(false)
    const res = await makeAuthRequest(`${urlLink}filter-vehicle-listing-page`, payload)
    const data1 = await res.json()
    if(res.ok){
      setData(data1.vehicle_sync_data)
      setFilterApplied(true)
      setShowFilter(false)
      setInspectionCount(1)
      setCurrentPage(1)
      setLoading(false)
    }
  }

  const localTime = (time) => {
    let Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    let dateTime = time.split(", ")
    let mon = Months.indexOf(dateTime[0].split(" ")[1])
    let local = new Date(Date.UTC(dateTime[0].split(" ")[2], mon, dateTime[0].split(" ")[0], dateTime[1].split(":")[0], dateTime[1].split(":")[1]))
    let Dte = local.getDate()
    let Month = Months[local.getMonth()]
    let year = local.getFullYear()
    let hour = local.getHours() % 12 ? local.getHours() % 12 >= 10 ? local.getHours() % 12 : `0${local.getHours() % 12}` : 12 
    let minute = local.getMinutes() >= 10 ? local.getMinutes() : `0${local.getMinutes()}` 
    let ampm = local.getHours() >= 12 ? 'PM' : 'AM'

    return `${Dte} ${Month} ${year}, ${hour}:${minute} ${ampm}`
  }

  const vehicleAge = (date) => {

    let months = Math.max(
      (new Date().getFullYear() - new Date(date).getFullYear()) * 12 +
      new Date().getMonth() -
      new Date(date).getMonth(),
      0
    )
    
    if(months > 12){
      return Math.floor(months / 12) === 1 ? `${Math.floor(months / 12)} year` : `${Math.floor(months / 12)} years`
    }
    else return months === 1 ? `${months} month` : `${months} months`
  }

  const showComment = (com, v) => {
    setComment(com)
    setCommentVin(v)
    setOpenComment(true)
  }

  const sendCommentData = async () => {
    let temp = comment
    const res = await makeAuthRequest(`${urlLink}comment/update`, { access_token: Cookies.get("token"), tl_id: Cookies.get("MahindraTlId"), client_type: Cookies.get("MahindraClientType"), client_id: Cookies.get("client"), vin: commentVin, comment: temp})
    const data1 = await res.json()
    if(res.ok){
      setComment("")
      setCommentVin("")
      setOpenComment(false)
      getData(currentPage)
    }
  }

  const clearFilter = () => {
    setVariantList([])
    setLocationId("")
    setLocationName("")
    setDealerId("")
    setDealerName("")
    setRegionId("")
    setRegionName("")
    setModel("")
    setVariant("")
    setLastInspectionDate("")
    setCarGrade("")
    setCheckedInspected(false)
    setCheckedNeverInspected(false)
    setCheckedActive(true)
    setCheckedInActive(false)
    if(Cookies.get("MahindraClientType") === "master"){
      setDealerList([])
      setLocationList([])
    }
    if(Cookies.get("MahindraClientType") === "region"){
      setLocationList([])
    }
  }

  useEffect(()=>{
    if(!checkMahindraCookies()){
      removeCookies()
      return navigate("/login")
    }
    setComment("")
    setCommentVin("")
    setOpenComment(false)
    getData(1)
  },[])

  return (
    <>
    <Navbar/>
    {loading ? 
      <div className={styles.loading} >
        <img src={loadingImg} alt='loading...'/>
      </div>: 
    <div className={styles.container}>
      <div className={styles.filterContainer}>
          <div className={styles.profile}>
              <img src={profileImg} alt="profile"></img>
              <div>
                  <span>{Cookies.get("MahindraDisplayName")}</span>
                  {Cookies.get("MahindraClientType") === "agent" && <span>({Cookies.get("MahindraTlName") ? Cookies.get("MahindraTlName") : "---"}, {Cookies.get("MahindraLocationName") ? Cookies.get("MahindraLocationName") : "---"})</span>}
              </div>
          </div>
          <div className={styles.filter}>
          <div className={styles.dashboardBtn} onClick={()=>navigate('/dashboard')}>
            <span>Dashboard</span>
          </div>
          <img onClick={()=>setShowFilter(!showFilter)} src={filterimg} alt='filter'></img>
              {filterApplied && <div>
                  <span className={styles.filterClear} onClick={()=>getData(currentPage)}>clear</span>
              </div>}
          </div>
      </div>
      <div className={styles.listing}>
          <p>Vehicle Listing page</p>
          <div>
              <img style={{cursor: "pointer"}} src={leftArrow} alt="left" onClick={()=>{
                if(currentPage <=1){
                  setCurrentPage(1)
                  getData(1)
                }else{
                  setCurrentPage(currentPage - 1)
                  getData(currentPage - 1)
                }
              }}></img>
              <p>{currentPage}/{Math.ceil(inspectionCount/limit) === 0 ? "1" : Math.ceil(inspectionCount/limit)}</p>
              <img style={{cursor: "pointer"}} src={rightArrow} alt="right" onClick={()=>{
                if(currentPage >= Math.ceil(inspectionCount/limit)){
                  setCurrentPage(Math.ceil(inspectionCount/limit))
                  getData(Math.ceil(inspectionCount/limit))
                }else{
                  setCurrentPage(currentPage + 1)
                  getData(currentPage + 1)
                }
                }}></img>
          </div>
      </div>
      <div className={styles.tableComponent}>
          <table>
          <thead>
              <tr>
              <th>VIN</th>
              <th>Model</th>
              <th>Variant</th>
              <th>Last Insp Date</th>
              <th>KMs</th>
              <th>Vehicle Age</th>
              <th>Car Condition</th>
              <th>Inspector Name</th>
              {Cookies.get("MahindraClientType") !== "agent" && <th>Region</th>}
              {Cookies.get("MahindraClientType") !== "agent" && <th>Dealer</th>}
              <th>Comment</th>
              {/* {Cookies.get("MahindraClientType") === "agent" &&<th>Action</th>} */}
              </tr>
          </thead>
          <tbody>
              {data.map((item, index) => (
              <tr key={index}>
                  <td>
                    <div className={styles.vin}>
                      <img src={item.car_image ? item.car_image : car} alt='car'></img>
                      <span onClick={()=>navigate(`/vehicle-inspection-list/${item.vin}`)}>{item.vin}</span>
                    </div>
                  </td>
                  <td>{item.model}</td>
                  <td>{item.variant}</td>
                  <td>{item.last_inspection_date ? localTime(item.last_inspection_date) : "---"}</td>
                  <td>{item.odo ? item.odo + " KMs" : "---"}</td>
                  <td>{item.addition_date ? vehicleAge(item.addition_date) : "---"}</td>
                  <td>{item.car_grade ? item.car_grade === "Not Inspected" ? "---" : item.car_grade === "Grade A1" ? "No Damage" : (item.car_grade === "Grade A2" || item.car_grade === "Grade A3") ? "Minor Scratches / Damages" : "Multiple Damages" : "---"}</td>
                  <td>{item.inspector_name ? item.inspector_name : "---"}</td>
                  {Cookies.get("MahindraClientType") !== "agent" && <td>{item.region_name ? item.region_name : "---"}</td>}
                  {Cookies.get("MahindraClientType") !== "agent" && <td>{item.branch_name ? item.branch_name : "---"}</td>}
                  <td>
                    <button className={styles.comment} onClick={() => showComment(item.comments, item.vin)}>Read More</button>
                  </td>
                  {/* {Cookies.get("MahindraClientType") === "agent" && <td className={styles.action}>
                    <div>
                      <span>Start</span>
                      <span>Inspection</span>
                    </div>
                    <img onClick={() => startInspection(item)} src={start} alt="start"></img>
                  </td>} */}
              </tr>
              ))}
          </tbody>
          </table>
      </div>
      <div className={styles.footer}>
          <img src={logo} alt='logo'></img>
          <p>© Copyright 2024 Inspektlabs Inc. All rights reserved.</p>
      </div>
      {showFilter && 
      <div className={styles.filterModal}>
        <div className={styles.filterHead}>
          <span>Filter</span>
          <img src={cross} alt="cross" onClick={() => setShowFilter(false)}></img>
        </div>
        {Cookies.get("MahindraClientType") === "master" && <div className={styles.filterContentContainer}>
          <div className={styles.filterContent}>
            <span>Region name</span>
            <Multiselect
                displayValue="region_name"
                customCloseIcon={<></>}
                onSearch={()=>{}}
                onSelect={(newList,item)=>{
                  console.log(dealerObject, item)
                  setDealerList(dealerObject[item["region_id"]].sort((a,b)=>{
                    if (a.dealer_name < b.dealer_name) {
                      return -1;
                    } else if (a.dealer_name > b.dealer_name) {
                        return 1;
                    }
                    return 0;
                  }))
                  setRegionId(item["region_id"])
                  setRegionName(item["region_name"])
                  setCount(count + 1)
                }}
                selectedValues={[{region_id: regionId, region_name: regionName}]}
                style={{
                  searchBox: {
                    'border': 'none',
                    'border-bottom': '1px solid #D9D9D9',
                    'border-radius': '0px',
                    'padding': '0px',
                    'width': '100%'
                  },
                  chips: {
                    'font-size': '10px',
                    'white-space': 'normal',
                  },

                  multiselectContainer: {
                      'font-size': '11px',
                      'word-wrap': 'break-word'
                  }
                }}
                options={regionList}
                singleSelect
            />
          </div>
        </div>}
        {Cookies.get("MahindraClientType") !== "agent" && <div className={styles.filterContentContainer}>
          <div className={styles.filterContent}>
            <span>Dealer name</span>
            <Multiselect
                displayValue="dealer_name"
                customCloseIcon={<></>}
                onSearch={()=>{}}
                onSelect={(newList,item)=>{
                  console.log(locationObject, item)
                  setLocationList(locationObject[item["dealer_id"]].sort((a,b)=>{
                    if (a.location_name < b.location_name) {
                      return -1;
                    } else if (a.location_name > b.location_name) {
                        return 1;
                    }
                    return 0;
                  }))
                  setDealerId(item["dealer_id"])
                  setDealerName(item["dealer_name"])
                  setCount(count + 1)
                }}
                selectedValues={[{dealer_id: dealerId, dealer_name: dealerName}]}
                style={{
                  searchBox: {
                    'border': 'none',
                    'border-bottom': '1px solid #D9D9D9',
                    'border-radius': '0px',
                    'padding': '0px',
                    'width': '100%'
                  },
                  chips: {
                    'font-size': '10px',
                    'white-space': 'normal',
                  },

                  multiselectContainer: {
                      'font-size': '11px',
                      'word-wrap': 'break-word'
                  }
                }}
                options={dealerList}
                singleSelect
            />
          </div>
        </div>}
        <div className={styles.filterContentContainer}>
          <div className={styles.filterContent}>
            <span>Location</span>
            <Multiselect
                displayValue="location_name"
                customCloseIcon={<></>}
                onSearch={()=>{}}
                onSelect={(newList,item)=>{
                  setLocationId(item.location_id)
                  setLocationName(item.location_name)
                  setCount(count + 1)
                }}
                selectedValues={[{location_id: locationId, location_name: locationName}]}
                style={{
                  searchBox: {
                    'border': 'none',
                    'border-bottom': '1px solid #D9D9D9',
                    'border-radius': '0px',
                    'padding': '0px',
                    'width': '100%'
                  },
                  chips: {
                    'font-size': '10px',
                    'white-space': 'normal',
                  },

                  multiselectContainer: {
                      'font-size': '11px',
                      'word-wrap': 'break-word'
                  }
                }}
                options={locationList}
                singleSelect
            />
          </div>
        </div>
        <div className={styles.filterContentContainer}>
          <div className={styles.filterContent}>
            <span>Model</span>
            <Multiselect
                isObject={false}
                customCloseIcon={<></>}
                onSearch={()=>{}}
                onSelect={(newList,item)=>{
                  setModel(item)
                  setVariantList(ModelList[item])
                }}
                selectedValues={[model]}
                style={{
                  searchBox: {
                    'border': 'none',
                    'border-bottom': '1px solid #D9D9D9',
                    'border-radius': '0px',
                    'padding': '0px',
                    'width': '100%'
                  },
                  chips: {
                    'font-size': '10px',
                    'white-space': 'normal',
                  },

                  multiselectContainer: {
                      'font-size': '11px',
                      'word-wrap': 'break-word'
                  }
                }}
                options={Object.keys(ModelList).sort()}
                singleSelect
            />
          </div>
        </div>
        <div className={styles.filterContentContainer}>
          <div className={styles.filterContent}>
            <span>Variant</span>
            <Multiselect
                isObject={false}
                customCloseIcon={<></>}
                onSearch={()=>{}}
                onSelect={(newList,item)=>setVariant(item)}
                selectedValues={[variant]}
                style={{
                  searchBox: {
                    'border': 'none',
                    'border-bottom': '1px solid #D9D9D9',
                    'border-radius': '0px',
                    'padding': '0px',
                    'width': '100%'
                  },
                  chips: {
                    'font-size': '10px',
                    'white-space': 'normal',
                  },

                  multiselectContainer: {
                      'font-size': '11px',
                      'word-wrap': 'break-word'
                  }
                }}
                options={variantList}
                singleSelect
            />
          </div>
        </div>
        <div className={styles.filterContentContainer}>
          <div className={styles.filterContent}>
            <span>Inspected upto</span>
            <input
              type="date"
              value={lastInspectionDate}
              onChange={(e) => setLastInspectionDate(e.target.value)}
              placeholder="DD/MM/YYYY"
              className={styles.inputDate}
            />
          </div>
        </div>
        <div className={styles.filterContentContainer}>
          <div className={styles.filterContent}>
            <span>Car Condition</span>
            <Multiselect
                isObject={false}
                customCloseIcon={<></>}
                onSearch={()=>{}}
                onSelect={(newList,item)=>setCarGrade(item)}
                selectedValues={[carGrade]}
                style={{
                  searchBox: {
                    'border': 'none',
                    'border-bottom': '1px solid #D9D9D9',
                    'border-radius': '0px',
                    'padding': '0px',
                    'width': '100%'
                  },
                  chips: {
                    'font-size': '10px',
                    'white-space': 'normal',
                  },

                  multiselectContainer: {
                      'font-size': '11px',
                      'word-wrap': 'break-word'
                  }
                }}
                options={["Grade A1", "Grade A2", "Grade A3", "Grade B1", "Grade B2", "Grade B3", "Grade C1", "Grade C2", "Grade C3", "Grade D"]}
                singleSelect
            />
          </div>
        </div>
        <div className={styles.filterContentContainer}>
          <div className={styles.filterContent}>
            <span>Inspection</span>
            <div className={styles.checkboxContainer}>
              <div className={styles.checkbox}>
                <span>Inspected</span>
                <input type="checkbox"
                  checked={checkedInspected}
                  onChange={() => {
                    setCheckedInspected(!checkedInspected)
                    setCheckedNeverInspected(false)
                  }}
                />
              </div>
              <div className={styles.checkbox}>
                <span>Never inspected</span>
                <input type="checkbox"
                  checked={checkedNeverInspected}
                  onChange={() => {
                    setCheckedNeverInspected(!checkedNeverInspected)
                    setCheckedInspected(false)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.filterContentContainer}>
          <div className={styles.filterContent}>
            <span>Status</span>
            <div className={styles.checkboxContainer}>
              <div className={styles.checkbox}>
                <span>Active</span>
                <input type="checkbox"
                  checked={checkedActive}
                  onChange={() => {
                    setCheckedActive(true)
                    setCheckedInActive(false)
                  }}
                />
              </div>
              <div className={styles.checkbox}>
                <span>Inactive</span>
                <input type="checkbox"
                  checked={checkedInActive}
                  onChange={() => {
                    setCheckedInActive(true)
                    setCheckedActive(false)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.filterBtn} onClick={()=>searchData()}>
          <span>Search</span>
        </div>
        <div className={styles.resetFilter}>
          <span className={styles.resetFilterBtn} onClick={()=>clearFilter()}>Reset Filters</span>
        </div>
      </div>}
      {openComment && 
      <div className={styles.remarkModalContainer}>
        <div className={styles.remarkModal}>
          <h3>Comments</h3>
          <textarea
            value = {comment}
            onChange={(e)=> setComment(e.target.value)}
            className={styles.remarkInput}
          >
          </textarea>
          <div className={styles.remarkModalButtonContainer}>
            <div onClick={()=>sendCommentData()}>Save</div>
            <div onClick={()=>{
              setOpenComment(false)
              setComment("")
              setCommentVin("")
            }}>Close</div>
          </div>
        </div>
      </div>}
    </div>}
    </>
  );
};


export default VinListing