import React from 'react'
import { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AppContext } from './context'
import { checkCookies, makeAuthRequest, removeCookies } from './context/utils'
import Navbar from './Navbar'
import loadingImg from '../img/loading.gif'
import styles from './style/ClaimResult.module.scss'
import Cookies from 'js-cookie'
import ClaimCarlevelResultDashboard from './ClaimCarLevelResultDashboard'
import ClaimPartLevelResultDashboard from './ClaimPartLevelResultDashboard'
import ClaimCarLevelResult from './ClaimCarLevelResult'
import ClaimPartLevelResult from './ClaimPartLevelResult'
import config from '../config/config.json'


const ClaimResult = () => {

  const { swipe, setPhotowiseDetails, photowiseLoading, setPhotowiseLoading, setLanguage, setLanguageList, setModule, setClientType,
          setIsGTEstimateActive, setOpenGTResponseModal, setConfigData, setOpen, setAllowDamageModal, setIsHailDamage, setIsSizeOfDamage, setIsInternalDamage} = useContext(AppContext)
    const {urlLink} = config
  
  const { id } = useParams()
  const navigate = useNavigate()

  const getInspectionDetails = async() => {
    setAllowDamageModal(false)
    setIsInternalDamage(false)
    setIsSizeOfDamage(false)
    setIsHailDamage(false)
    const res = await makeAuthRequest(`${urlLink}photowise-details`, { client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType'), agent_tl_view: Cookies.get("tlView"), agent_tl_id: Cookies.get("tlId") })
    const data = await res.json()
    if(res.ok){
      setPhotowiseDetails(data)
      if(data["unified_json"]["additionalFeatures"]["highProbabilityInternalDamages"].length > 0){
        setAllowDamageModal(true)
        setIsInternalDamage(true)
      }
      if(Object.keys(data["unified_json"]["additionalFeatures"]["sizeOfDamage"]).length > 0){
        setAllowDamageModal(true)
        setIsSizeOfDamage(true)
      }
      if((Object.keys(data["unified_json"]).includes("claim") && Object.keys(data["unified_json"]["claim"]).includes("damagedParts") && data["unified_json"]["claim"]["damagedParts"].length > 0)){
          for (let i = 0; i < data["unified_json"]["claim"]["damagedParts"].length; i++) {
              if(data["unified_json"]["claim"]["damagedParts"][i]["hailDentRepair"]["isHailDamage"]){
                  setAllowDamageModal(true)
                  setIsHailDamage(true)
                  break;
              }             
          }
      }
      getConfig()
    }
    
  }

  const getClientType = async() => {
    const res = await makeAuthRequest(`${urlLink}partner_clientType`, { client_id: Cookies.get('master'), inspection_id: id, client_type: Cookies.get('clientType') })
    const data = await res.json()
    if(res.ok){
      if(data.status === "Success"){
        setClientType(data.response)
      }
    }
  }

  const getGTEStimateStatus = async() => {
    const res = await makeAuthRequest(`${urlLink}check_gtEstimate_button`, { client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType') })
    const data = await res.json()
    if(res.ok){
      if(data.status === "Success"){
        setIsGTEstimateActive(data.response)
      }
    }
  }

  const getConfig = async() => {
    const res = await makeAuthRequest(`${urlLink}getConfig`, { client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType') })
    const data = await res.json()
    if(res.ok){
      setConfigData(data)
      setPhotowiseLoading(false)
    }
    
  }

  useEffect(()=>{
    setPhotowiseLoading(true)
    setIsGTEstimateActive(false)
    setOpenGTResponseModal(false)
    setOpen(false)
    setIsHailDamage(false)
    setIsInternalDamage(false)
    setIsSizeOfDamage(false)
    if(!checkCookies()){
      removeCookies()
      navigate('/login')
    }
    else{
      if(!id) return navigate('/claim')
      else {
        setLanguage(Cookies.get('current-language'))
        setModule(JSON.parse(Cookies.get('modules')))
        setLanguageList(JSON.parse(Cookies.get('all-languages')))
        getInspectionDetails()
        getClientType()
        getGTEStimateStatus()
      } 

    } 
  }, [navigate, setPhotowiseLoading, id, setLanguage, setLanguageList, setModule])
  
  return (
    <div className={styles.container}>
        <Navbar/>
        { photowiseLoading ? 
        <div className={styles.loading} >
          <img src={loadingImg} alt='loading...'/>
        </div>:
        <div className={styles.inspectionResultSwitch}>
        { swipe === "carLevelResult" && <ClaimCarLevelResult/>}
        { swipe === "carLevelResult" && <ClaimCarlevelResultDashboard/>}
        { swipe === "partlevelResult" && <ClaimPartLevelResult/>}
        { swipe === "partlevelResult" && <ClaimPartLevelResultDashboard />}
        </div>}
    </div>
  )
}

export default ClaimResult