import React, { useContext, useEffect, useRef } from 'react'
import './style/Module.scss';
import preInspection from '../img/modules/preinspection.svg';
import claim from '../img/modules/claim.svg';
import bgremoval from '../img/modules/bgremoval.svg'
import incremental from '../img/modules/incremental.svg'
import urlgen from '../img/modules/urlgen.svg'
import ModuleNavbar from './login/ModuleNavbar';
import DownArrow from '../img/modules/down.png';
import { AppContext } from './context';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'
import { checkCookies, removeCookies } from './context/utils';
import lang from './language/Module.json'

const Module = () => {

  const { setLanguage, language, isOpen, setIsOpen, module, setModule, languageList, setLanguageList, isProfileOpen, setIsProfileOpen } = useContext(AppContext)
  const navigate = useNavigate()
  const clickRef = useRef()
  const listRef = useRef()

  const profileRef = useRef()
  const profileListRef = useRef()

  const moduleList = {"Pre-Inspection": ['/pre-inspection', preInspection, lang["Pre-inspection Module View and Download Inspection Results"][language]], "Claim":['/claim', claim, lang["Claim Module View and Download Inspection Results"][language]],
  "Incremental Damage": ['/incremental-damage', incremental, lang["Incremental Damage"][language]] ,"URL Generator":['/url-generator', urlgen, lang["Generate web App URL"][language]], 
  "Background Removal":['/background-removal', bgremoval, lang["Background Removal"][language]]}
  
  const logOut = () => {
    removeCookies()
    setIsOpen(false)
    setIsProfileOpen(false)
    navigate('/login')
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const toggleProfileDropdown = () => {
    setIsProfileOpen(!isProfileOpen)
  }

  const handleSelect = (val) => {
    setLanguage(val)
    Cookies.set('current-language', val, {expires: 0.16})
    setIsOpen(false)
  }

  useEffect(()=>{
    //logging out if any of the cookies has expired/missing/removed
    if(Cookies.get("isMahindra") === "true"){
      navigate('/vehicle-inspection-list')
    }
    if(!checkCookies()){
      removeCookies()
      navigate('/login')
    }
    else{

      setLanguage(Cookies.get('current-language'))
      setModule(JSON.parse(Cookies.get('modules')))
      //update language list here according to selected language*****(remove comment after implementing language json)
      
      setLanguageList(JSON.parse(Cookies.get('all-languages')))
      
    }

    const handleClick = (event) => {
      if((clickRef.current && !clickRef.current.contains(event.target)) && (listRef.current && !listRef.current.contains(event.target))){
        setIsOpen(false)
      }

      if((profileRef.current && !profileRef.current.contains(event.target)) && (profileListRef.current && !profileListRef.current.contains(event.target))){
        setIsProfileOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClick)

    return () => document.removeEventListener("mousedown", handleClick)

  },[navigate, setLanguage, setModule, setLanguageList])

  return (
    <div className="main">
        <div className="car-grad"></div>
        <nav className="navbar">
          <ModuleNavbar />
          <div className="navbar-list">          
            <div className="dropdown">
              <div
                className="dropdown-header"
                onClick={toggleDropdown}
                role="button"
                ref={clickRef}
              >
                {lang[language][language] || "language"}
                <img src={DownArrow} style={{transform: isOpen ? 'rotate(180deg)' : "rotate(0deg)"}} alt="Down Arrow"/>
              </div>

              <ul ref={listRef} style={{display: isOpen ? "block" : "none" ,}} className="dropdown-list">
              {languageList.map((item, index) => (
                <li
                  className="dropdown-item"
                  key={index}
                  onClick={() => handleSelect(item)}
                >
                {lang[item][language]}
                </li>
                ))}
              </ul>
            </div>
            <div className="profile-dropdown">
              <div
                className="profile-dropdown-header"
                onClick={toggleProfileDropdown}
                role="button"
                ref={profileRef}
              >
                {lang["Profile"][language]}
                <img src={DownArrow} style={{transform: isProfileOpen ? 'rotate(180deg)' : "rotate(0deg)"}} alt="Down Arrow"/>
              </div>

              <ul ref={profileListRef} style={{display: isProfileOpen ? "block" : "none" ,}} className="profile-dropdown-list">
                <li>{lang["Client ID:"][language]} {Cookies.get('master')}</li>
                <li> {lang["Type:"][language]} {Cookies.get('clientType') === "master" ? "Master" : `Agent (${Cookies.get('client')})` }</li>
                <li style={{cursor:'pointer'}} onClick={logOut}>{lang["logout"][language]}</li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="content-module">
          <p className="content-title">{lang["Choose your Module"][language]}</p>
        </div>
        <div className="content-box">
        { module.map((item, index) => <div key={index} onClick={() => navigate(moduleList[item][0])} className="content-item">
          <div className='icons'>    
            <img src={moduleList[item][1]} alt="gif"  />
          </div> 
            <p>{lang[item][language]}</p>
            <h4> i </h4>
            <div className="hide">
              {moduleList[item][2]}
            </div>
          </div>)}  
        </div> 
        <div className="copy-right">{window.top.location.host === "webapp.inspek.ai" ? "" :window.top.location.host === "resultsjpn.octotelematics.com" ? "" :window.top.location.host === "results.autosnaply.com" ? "" : window.top.location.host === "results.octotelematics.com" ? "" : window.top.location.host === "inspection-vn.iglooinsure.com" ? "" : window.top.location.host === "results.easydetect.ai" ? "© Copyright 2023 easydetect.  All rights reserved." : Cookies.get('master') == "client_1312" ? "Copyright © 2024 SAWITT FZCO. All rights reserved." : lang["Copyright"][language]}</div>
    </div>
  )
}

export default Module