import React, {useEffect} from "react"

const VanSVG = ({data}) => {

    const setColors = () => {
        for(let i = 0; i < data.length; i++){
          if(data[i].damageSeverityScore <= 0.3 ){
            document.querySelectorAll(`.${data[i].partName.split(":").join("").split("/").join(" ").split(" ").join("_")}`).forEach(item => {
              item.classList.remove(item.classList[0])
              item.classList.add("yellow")
            })
          }
          else if(data[i].damageSeverityScore > 0.3 && data[i].damageSeverityScore <=0.7){
            document.querySelectorAll(`.${data[i].partName.split(":").join("").split("/").join(" ").split(" ").join("_")}`).forEach(item => {
              item.classList.remove(item.classList[0])
              item.classList.add("orange")
            })
          }else{
            document.querySelectorAll(`.${data[i].partName.split(":").join("").split("/").join(" ").split(" ").join("_")}`).forEach(item => {
              item.classList.remove(item.classList[0])
              item.classList.add("red")
            })
          }
        }
    }
      
    
    useEffect(()=>{
    setColors()
    },[])

    return(

		<svg 
            version="1.1" 
            id="Layer_1" 
            xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            x="0px" 
            y="0px"
	 	    viewBox="0 0 1000 1000" 
		    style={{enableBackground:"new 0 0 1000 1000",}} 
            xmlSpace="preserve"
            {...{data}}
            >
		    <style type="text/css">
			    {"\n\t.red{fill:#FF0000;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n\t.orange{fill:#FF8A00;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n\t.yellow{fill:#FFB800;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n\t.st0{fill:#FFFFFF;stroke:#231F20;stroke-miterlimit:10;}\n\t.st1{fill:#E0E1E2;stroke:#231F20;stroke-miterlimit:10;}\n\t.st2{display:none;fill:#E0E1E2;stroke:#231F20;stroke-miterlimit:10;}\n\t.st3{fill:#231F20;stroke:#231F20;stroke-miterlimit:10;}\n\t.st4{fill:#DFE21D;stroke:#231F20;stroke-miterlimit:10;}"}
		    </style>
            <g id="Rear">
                <path className="st0 Extra1" d="M375.1,865.9l-20.2-0.4c0.6,13.3-3.9,27.4-3.3,40.7c0.4,7.9,3.8,21.9,3.8,21.5l22.9,0.6L375.1,865.9z M364,924.9c-5.5,0-10-9.8-10-21.9c0-12.1,4.5-21.9,10-21.9s10,9.8,10,21.9C374,915.1,369.5,924.9,364,924.9z"/>
                <path className="st0 Extra2" d="M606.6,928.7l22.5,0.1c0,0.4,3.6-14.8,4-22.6c0.6-13.2-4-27.4-3.4-40.6l-19.7,0.3L606.6,928.7z"/>
                <path className="st0 Right_Pillar_D" d="M610,865.7l0.5-8.9l-3.6-140c0.1-1.9,0.2-3.7,0.3-5.1c4.1,0.5,6.4,0.9,8.7,2.8c2.2,1.8,4.1,5.5,4.9,8.3	c8,49,7.1,95,8.9,142.6C623.2,865.6,616.6,865.7,610,865.7z"/>
                <path className="st0 Left_Pillar_D" d="M374.6,856.9l0.5,8.9c-6.7-0.1-13.5-0.2-20.2-0.3c1.8-47.6,0.9-93.7,9.1-142.7c0.8-2.8,2.8-6.5,5-8.3	c2.4-1.9,4.7-2.3,8.9-2.8c0.1,1.5,0.2,3.2,0.3,5.2L374.6,856.9z"/>
                <path className="st0 Tail_Gate" d="M610.5,856.9l-0.5,8.9l-3.2,63.1L503.6,928h-10.9V814.1c27.4,0,55.3-2.6,83.6-7.4c10,0,18.2-8.1,18.2-18.2	v-44.7c0-10-8.1-18.2-18.2-18.2h-83.6v-18.4l114.1,4.5l0.1,5.2L610.5,856.9z"/>
                <path className="st0 Tail_Gate" d="M492.7,928h-11.2l-103.2,0.9v-0.6l-3.2-62.4v-0.1l-0.5-8.9l3.6-140l0.1-5.2l114.1-4.5v18.4h-76.9	c-10,0-18.2,8.1-18.2,18.2v44.7c0,10,8.2,18.2,18.2,18.2c25.2,5,50.9,7.4,77.1,7.4L492.7,928z"/>
                <path className="st1 Rear_Glass" d="M594.5,743.8v44.7c0,10.1-8.2,18.2-18.2,18.2c-28.3,4.9-56.2,7.4-83.6,7.4h-0.1c-26.2,0-51.9-2.4-77.1-7.4	c-10,0-18.2-8.2-18.2-18.2v-44.7c0-10.1,8.2-18.2,18.2-18.2h160.8C586.4,725.6,594.5,733.8,594.5,743.8z"/>
                <g>
                    <ellipse className="st0 Extra8" cx="465.3" cy="851.1" rx="13" ry="7.4"/>
                    <path className="st0 Extra9" d="M449.6,853.4c-0.2-0.4-0.5-1.2-0.5-2.3s0.3-2,0.5-2.5c9.7,0,19.5,0,29.2-0.1c0.1-0.1,1.4-0.6,2.6-0.1	c1.4,0.6,1.5,2.2,1.5,2.5c0,0.4,0.1,1.8-1.2,2.5c-1.1,0.6-2.7,0.1-2.7,0.1C469.1,853.5,459.5,853.5,449.6,853.4z"/>
	            </g>
                <path className="st0 Rear_Bumper" d="M357.1,945.7L625,947c2.5-1,3.4-2.1,3.8-5l0.3-13.3L355.6,928c0,3.8-0.9,8-0.6,11.9	C355.2,941.9,355.3,944.6,357.1,945.7z"/>
                <path className="st0 Rear_Bumper_Cover" d="M358.9,956.6l264.7,0.8c2.4-0.7,3.3-1.4,3.7-3.3l0.3-8.6l-270.3-0.4c0,2.5-0.9,5.1-0.6,7.6	C356.9,954.1,357,955.9,358.9,956.6z"/>
                <ellipse className="st0 Rear_PDC_Sensor" cx="492.7" cy="932.8" rx="3.2" ry="3.3"/>
                <ellipse className="st0 Rear_PDC_Sensor" cx="385.8" cy="933.9" rx="3.2" ry="3.3"/>
                <ellipse className="st0 Rear_PDC_Sensor" cx="600" cy="936.1" rx="3.2" ry="3.3"/>
                <rect x="462.7" y="937.2" className="st0 License_Plate" width="58.7" height="12.5"/>
                <path className="st0 Emblem" d="M495.9,898.6h18l-9-15.3C502.9,886.7,495.9,898.6,495.9,898.6z"/>
                <ellipse className="st1 Right_Taillight" cx="621.2" cy="903" rx="10" ry="21.9"/>
                <ellipse className="st1 Left_Taillight" cx="364" cy="903" rx="10" ry="21.9"/>
                <path className="st0 Tow_Hook_Cover_Rear" d="M424.4,940.5h-7.2c-0.8,0-1.4-0.6-1.4-1.4v-5.9c0-0.8,0.6-1.4,1.4-1.4h7.2c0.8,0,1.4,0.6,1.4,1.4v5.9	C425.8,939.8,425.2,940.5,424.4,940.5z"/>
                <path className="st1 Right_Rear_Reflector_Bottom" d="M618.6,953.3l-8.7,1l-6.2-1.3c-1.1-0.3-1.4-1.7-0.3-2.2c2.4-1.2,5.5-2.6,7.3-2.6c1.9-0.1,5.6,1.5,8.3,2.9	C620.1,951.5,619.8,953.2,618.6,953.3z"/>
                <path className="st1 Left_Rear_Reflector_Bottom" d="M382,953.3l-8.7,1l-6.2-1.3c-1.1-0.3-1.4-1.7-0.3-2.2c2.4-1.2,5.5-2.6,7.3-2.6c1.9-0.1,5.6,1.5,8.3,2.9	C383.5,951.5,383.2,953.2,382,953.3z"/>
                <path className="st1 Rear_Reflector_Top" d="M500.2,717.4h-15.1c-2.8,0-5.1-2.3-5.1-5.1l0,0c0-2.8,2.3-5.1,5.1-5.1h15.1c2.8,0,5.1,2.3,5.1,5.1l0,0	C505.4,715.1,503.1,717.4,500.2,717.4z"/>
            </g>
            <g id="Front">
	            <g>
		            <path className="st0 Extra23" d="M617.6,219c-0.4,4.3,4.4,7.1,7.9,4.6c6-4.2,12.5-10.1,13.6-17c1-6.8-5.4-13.6-11.4-18.3	c-3.3-2.6-8.2-0.3-8.2,3.9c0,0.1,0,0.2,0,0.3c0,2.1,1.3,4,3.2,4.8c14,5.5,13.4,10.3-2.1,17c-1.7,0.7-2.9,2.3-3.1,4.2	C617.7,218.6,617.6,218.8,617.6,219z"/>
		            <path className="st1 Right_Side_View_Mirror" d="M638.8,182.5h-11.3c-1.5,0-2.8,1.2-2.8,2.8v39.3c0,1.5,1.2,2.8,2.8,2.8h16.4c1.5,0,2.8-1.2,2.8-2.8v-34.2	C646.7,186,643.2,182.5,638.8,182.5z"/>
                </g>
                <g>
                    <path className="st0 Extra25" d="M366.4,215c0.4,4.3-4.4,7.1-7.9,4.6c-6-4.2-12.5-10.1-13.6-17c-1-6.8,5.4-13.6,11.4-18.3    c3.3-2.6,8.2-0.3,8.2,3.9c0,0.1,0,0.2,0,0.3c0,2.1-1.3,4-3.2,4.8c-14,5.5-13.4,10.3,2.1,17c1.7,0.7,2.9,2.4,3.1,4.2    C366.4,214.7,366.4,214.8,366.4,215z"/>
                    <path className="st1 Left_Side_View_Mirror" d="M345.2,178.5h11.3c1.5,0,2.8,1.2,2.8,2.8v39.3c0,1.5-1.2,2.8-2.8,2.8h-16.4c-1.5,0-2.8-1.2-2.8-2.8v-34.2    C337.3,182.1,340.8,178.5,345.2,178.5z"/>
                </g>
                <g>
                    <path className="st0 Front_Bumper_Cover" d="M625.1,86.1l-2.8-16.3c-0.1-0.4-0.3-0.7-0.7-1.1c-3-2.8-9.6-3.3-27-4.9c-18.3-1.7-105.3-2.2-105.3-2.2	s-85.7,0.8-105.1,2.7c-11.9,1.1-18.4,2.5-21.2,4.9c-0.4,0.3-0.6,0.7-0.7,1.1c-1.1,5.2-2.1,10.5-3.1,15.8c0,0.4,0.1,0.8,0.3,1.1	c0.1-0.3,0.2-0.6,0.4-0.8c2.8-4.5,9.5-7.1,21.6-9.2c19.8-3.5,107.3-5.1,107.3-5.1s88.8,1,107.5,4.1c17.8,3,24.5,4,27.6,9.3	c0.4,0.7,0.6,1.4,0.7,2.1C625.1,87.1,625.2,86.6,625.1,86.1z"/>
                    <path className="st0 Front_Bumper" d="M628.1,118.3l-2.9-30.8l0,0c-0.1-0.7-0.3-1.4-0.7-2.1c-3.1-5.3-9.8-6.3-27.7-9.3C578,73,488.9,72,488.9,72	s-87.8,1.6-107.7,5.1c-12.2,2.1-18.9,4.7-21.7,9.2c-0.2,0.2-0.3,0.5-0.4,0.8c-0.2,0.4-0.2,0.8-0.3,1.3c-1.1,9.9-2.1,19.8-3.2,29.7	c-0.2,3,2.1,5.6,5.1,5.6l262.4,0.1C626.1,123.8,628.4,121.2,628.1,118.3z"/>
                    <ellipse className="st2 Extra1001" cx="609.6" cy="105.2" rx="10" ry="8"/>
                    <ellipse className="st2 Extra1002" cx="390.2" cy="89.2" rx="7" ry="5.7"/>
                    <ellipse className="st2 Extra1003" cx="592.5" cy="87.7" rx="7" ry="5.7"/>
                    <ellipse className="st2 Extra1004" cx="374.6" cy="106.5" rx="10" ry="8"/>
                    <path className="st1 Front_Glass" d="M374.3,198.7c25.7-13.2,83.9-15,114.2-14c30.2,1,96.3-1.6,122.2,14c3.8,2.3,7.3,11.6,8,16	c1.8,10.6,1.9,19.5-8,68.7v0.1c-39.7,1-80.5,1.3-122.2,1.3c-28.6,0-114.2-2-114.2-2s-0.2-0.8-0.2-0.9c-9.9-47.5-11.8-57.7-8-68.8	c0.1-0.3,0.2-0.6,0.2-1C366.6,210,367.8,202,374.3,198.7z"/>
                </g>
                <path className="st0 Hood" d="M627.3,127.3c-0.2-1.9-2.4-3.5-5-3.5H361c-2.7,0-4.8,1.6-4.9,3.6c-0.7,8.5-0.6,17.8,0.1,27.7	c0.1,1,0.7,2,1.7,2.6c0.8-0.6,1.9-1,3.1-1l261.8-0.8c1.2,0,2.2,0.4,3.1,1c0.8-0.6,1.3-1.5,1.4-2.4	C627.9,144.4,628.1,135.2,627.3,127.3z"/>
                <path className="st0 Front_Panel" d="M625.8,156.9c-0.9-0.6-1.9-1-3.1-1l-261.8,0.8c-1.2,0-2.2,0.4-3.1,1c-1.4,1-2.2,2.8-1.9,4.7l5.4,36.3    c0.6,4.4,6.2,6.1,9,2.6c0.3-0.3,0.5-0.7,0.8-1c0.5-0.5,1.1-1,1.7-1.3c35.8-16.4,93.3-14.4,121.9-14.4c47.5-0.1,99.2,3.2,115.9,14.2    c1.2,0.8,2.3,1.9,3.4,3.2c2.8,3.7,8.5,2.2,9.1-2.3c1.7-13.4,3-24.7,4.7-38.1C628,159.7,627.2,157.9,625.8,156.9z"/>
                <path className="st0 Bumper_Grill_Bottom" d="M430.7,100.8h119.8c3.3,0,5.7-3.1,4.9-6.3c-0.6-2.3-1.6-4.9-3.5-7.4c-4.9-6.7-11.9-8.7-14.4-9.3	c-0.4-0.1-0.7-0.1-1.1-0.1h-92.1c-0.6,0-1.2,0.1-1.8,0.3c-3.6,1.4-12.5,5.5-16.3,15c-0.2,0.4-0.3,0.8-0.4,1.2	C424.8,97.5,427.3,100.8,430.7,100.8z"/>
                <path className="st0 Bumper_Grill_Top" d="M402.7,123.8H582c3.6,0,6.1-3.7,4.7-7c-0.9-2.1-2.4-4.5-4.8-6.7c-7.5-7-18.2-8.9-21.5-9.3	c-0.2,0-0.5-0.1-0.7-0.1H424.2c-0.4,0-0.8,0-1.2,0.1c-4.6,1.1-18.7,5.2-24.6,15.2c-0.1,0.2-0.2,0.3-0.2,0.5	C396.5,119.9,398.9,123.8,402.7,123.8z"/>
                <path className="st0 Emblem" d="M479.6,129.6h18l-9,15.3C486.6,141.5,479.6,129.6,479.6,129.6z"/>
                <rect x="458.6" y="83.9" className="st0 License_Plate" width="66.4" height="12.9"/>
                <path className="st0 Tow_Hook_Cover_Front" d="M422.2,77.7H415c-0.8,0-1.4,0.6-1.4,1.4V85c0,0.8,0.6,1.4,1.4,1.4h7.2c0.8,0,1.4-0.6,1.4-1.4v-5.9    C423.6,78.3,422.9,77.7,422.2,77.7z"/>
                <path className="st0 Front_PDC_Sensor" d="M391.3,66.5h0.2c3.1,0,5.1,3.2,3.5,5.6l-0.1,0.1c-1.6,2.4-5.4,2.4-6.9,0c0-0.1-0.1-0.1-0.1-0.1	C386.2,69.7,388.2,66.5,391.3,66.5z"/>
                <path className="st0 Front_PDC_Sensor" d="M490.1,63.1h0.2c3.1,0,5.1,3.2,3.5,5.6l-0.1,0.1c-1.6,2.4-5.4,2.4-6.9,0c0-0.1-0.1-0.1-0.1-0.1	C485,66.3,487,63.1,490.1,63.1z"/>
                <path className="st0 Front_PDC_Sensor" d="M592.4,65.9h0.2c3.1,0,5.1,3.2,3.5,5.6l-0.1,0.1c-1.6,2.4-5.4,2.4-6.9,0c0-0.1-0.1-0.1-0.1-0.1	C587.3,69,589.3,65.9,592.4,65.9z"/>
                <g>
                    <path className="st0 Right_Headlight" d="M622.6,115.7l-7,3.6c-5.3,2.7-17.4-3.3-21-5.3c-0.8-0.4-1.6-1.2-2.1-2.3c-0.7-1.2-2.1-3.9-3.1-5.8    c-0.5-1-0.8-2-0.8-2.9s0.3-1.6,0.8-1.9c0,0,0.1-0.1,0.2-0.1l15.6-4.4c0.4-0.1,0.7,0,1.2,0.2l10.2,4.7c0.9,0.4,1.8,1.4,2.4,2.6    l3.7,7.1C623.7,113.1,623.7,115.2,622.6,115.7z"/>
                    <path className="st1 Right_Headlight" d="M615.4,108.6c0-4.7-3.8-8.4-8.4-8.4c-4.7,0-8.4,3.8-8.4,8.4s3.8,8.4,8.4,8.4    C611.6,117,615.4,113.2,615.4,108.6z"/>
                    <circle className="st1 Right_Fog_Light" cx="594.6" cy="86.6" r="6"/>
                </g>
                <g>
                    <path className="st0 Left_Headlight" d="M360.1,116.2l7,3.6c5.3,2.7,17.4-3.3,21-5.3c0.8-0.4,1.6-1.2,2.1-2.3c0.7-1.2,2.1-3.9,3.1-5.8    c0.5-1,0.8-2,0.8-2.9s-0.3-1.6-0.8-1.9c0,0-0.1-0.1-0.2-0.1l-15.6-4.4c-0.4-0.1-0.7,0-1.2,0.2l-10.2,4.7c-0.9,0.4-1.8,1.4-2.4,2.6    l-3.7,7.1C359,113.6,359.1,115.6,360.1,116.2z"/>
                    <path className="st0 Left_Headlight" d="M367.3,109c0-4.7,3.8-8.4,8.4-8.4c4.7,0,8.4,3.8,8.4,8.4s-3.8,8.4-8.4,8.4C371.1,117.4,367.3,113.6,367.3,109    z"/>
                    <circle className="st1 Left_Fog_Light" cx="388.2" cy="87" r="6"/>
                </g>
            </g>
            <g id="Right">
                <path className="st0 Right_Qtr_Panel" d="M781.9,679.3c-0.2,2.8-0.4,5.6-0.4,6.8l-1.4,21.5l-60.4-0.8h-0.1l-7.6-0.1c-17,0-32.3-1-33.7-1.1l-1.8-13.1	c0-9.2-0.1-14.5,0-18.4c0-0.8,0-1.6,0-2.3c0.2-18.2,0.3-117.3,0.3-124.6l97.4,0.1l4-0.1c0,8.9-0.1,19.1-0.2,37.5	c-1.7-0.3-3.4-0.4-5.2-0.5c-0.5,0-1,0-1.5,0c-25.3,0-45.9,21.6-45.9,48.2c0,26.6,20.6,48.3,45.9,48.3c2.2,0,4.3-0.2,6.4-0.5	C779.1,680,780.5,679.7,781.9,679.3z"/>
                <path className="st3 Right_Qtr_Extender" d="M782.3,673c0,1.1-0.2,3.7-0.4,6.4c-1.4,0.3-2.8,0.6-4.2,0.8c-2.1,0.3-4.2,0.5-6.4,0.5	c-25.3,0-45.9-21.6-45.9-48.3c0-26.6,20.6-48.2,45.9-48.2c0.5,0,1,0,1.5,0c1.8,0.1,3.5,0.2,5.2,0.5c0,2.2,0,4.5,0,6.9	c-0.5,0-0.9,0-1.4,0c-22.3,0-40.5,18.3-40.5,40.9c0,22.6,18.2,40.9,40.5,40.9C778.6,673.4,780.5,673.2,782.3,673z"/>
                <g>
                    <path className="st0 Right_Rear_Tyre" d="M744.8,632.4c0-20.3,16.5-36.8,36.8-36.8s36.8,16.5,36.8,36.8s-16.5,36.8-36.8,36.8S744.8,652.7,744.8,632.4    L744.8,632.4z"/>
                    <path className="st0 Right_Rear_Wheel_Rim" d="M757.5,633.4c0,13.3,10.8,24.1,24.1,24.1s24.1-10.8,24.1-24.1s-10.8-24.1-24.1-24.1S757.5,620.1,757.5,633.4    L757.5,633.4z"/>
                </g>
                <g>
                    <path className="st0 Right_Front_Tyre" d="M743.6,265.5c0-20.3,16.5-36.8,36.8-36.8s36.8,16.5,36.8,36.8s-16.5,36.8-36.8,36.8S743.6,285.8,743.6,265.5    L743.6,265.5z"/>
                    <path className="st0 Right_Front_Wheel_Rim" d="M756.3,266.5c0,13.3,10.8,24.1,24.1,24.1s24.1-10.8,24.1-24.1s-10.8-24.1-24.1-24.1S756.3,253.2,756.3,266.5    L756.3,266.5z"/>
                </g>
	            <path className="st0 Right_Front_Door" d="M779.6,308.8c0,1.7-0.5,91.6-0.5,92.8c-0.2,0.1-0.3,0.1-0.5,0.1c-0.1,0-0.2,0-0.3,0c-2,0.2-8,0.2-8,0.2	c-8.6-0.3-21.9-1.5-37.7-0.5c-2,0.1-3.6,0.4-4.9,0.9l-0.5,0.2c-5.5,2-7.4,6-19.8,8c-3.1,0.5-11.8,1.6-24.7,0.9	c-0.3,0-5.9-0.1-5.9-0.2c0,0,0.5-1.3,1.2-3.4l0,0c0.8-2.2,1.9-5.4,3-8.6c2.3-6.6,4.9-13.9,5.6-16.1c9.3-24.8,14.8-78.3,10.8-79.9	c-5.8-2.5-12.3-3.8-16.1-4.5c-0.1,0-1.1-0.1-1.5-0.2c-5.3-0.5-11.9-0.4-13-0.4h-0.2l5.5-10.7l0.2-0.4c3.2,0,47.6,2,68.3,1.3	c4-0.1,1.7,6.1,18.8,15.5c1.6,0.8,4.8,2.5,10.6,3.9C771.2,307.8,779.5,307.4,779.6,308.8z"/>
	            <g id="Handle2_00000068643607807053289460000006032552241182906004_">
                    <ellipse className="st0 Right_Front_Door_Handle" cx="706.9" cy="382.3" rx="5" ry="8.6"/>
                    <path className="st0 Right_Front_Door_Handle" d="M705.6,392.2c-1.6-3.8,0.7-15.2,0-18.9	c0-0.2-0.6-0.7-0.6-1.1c0.2-1.5,3.7-1.9,4.2-0.3c0.1,0.4-0.6,0.9-0.6,1.2c-0.5,5.6,1.5,18.4,0.1,19.1	C708,392.5,705.8,392.6,705.6,392.2z"/>
                </g>
                <path className="st0 Right_Rear_Door" d="M779.1,401.6l-0.8,145.6H676.8V411.3c0,0.1,5.6,0.2,5.9,0.2c12.9,0.7,21.6-0.4,24.7-0.9c12.4-2,14.3-6,19.8-8    l0.5-0.2c1.3-0.5,2.9-0.8,4.9-0.9c15.8-1,29.1,0.2,37.7,0.5C770.3,402,777,401.8,779.1,401.6z"/>
                <ellipse className="st0 Right_Rear_Door_Handle" cx="701.1" cy="430.3" rx="5.8" ry="10.2"/>
                <path className="st0 Right_Rear_Door_Handle" d="M702.9,418c-0.3-0.2-1-0.4-1.8-0.4c-0.9,0-1.6,0.3-1.9,0.4c0,7.6,0,15.3-0.1,22.9c-0.1,0.1-0.5,1.1-0.1,2	c0.5,1.1,1.7,1.1,1.9,1.2c0.3,0,1.4,0,2-0.9c0.5-0.9,0.1-2.1,0.1-2.2C703,433.3,703,425.8,702.9,418z"/>
                <path className="st0 Right_Front_Bumper" d="M754.6,228.5c-7.4-12.4-8.2-12.4-9.5-15.1c-0.6-1.2-0.4-1.8,0.4-21.9c0.1-3.4,9.8-2.4,23.8-2    c1.5,0,6,4.1,6.4,4.1c1.3,0.2,2.4,0.4,3.5,0c4.9-1.5,9.1,0.7,10.4,2.2c0.6,0.7,2.7,27.5,2.4,27.4    C768.4,217.6,754.9,229.1,754.6,228.5z"/>
                <path className="st0 Right_Fender" d="M750.5,221.9c-0.1,0-0.7,0.3-1,0.4c-8.5,4-16.1,14-19.9,27c-0.7,2.3-1.2,4.7-1.7,7.2	c-2.1,11.9-0.6,23.2,3.5,31.4c0.1,0.1,0.1,0.3,0.2,0.5c0,0-48-0.6-59-1.3c-0.8,0,3.7-7.8,11.5-23.8c9.6-19.3,11.9-25.9,17.7-37.9	c0-0.1,0.1-0.1,0.1-0.2c1.7-3.5,5.7-11,13-17.1c6.2-5.3,13.2-8.2,15.1-9c0,0,4.3-0.8,9.4-7.1c2.2-2.8,6.3-1.4,6.3-1.4	c-0.4,6.9-0.9,14.2-0.8,22.2C744.8,213,747.8,217.7,750.5,221.9z"/>
                <path className="st0 Right_Running_Board" d="M788.8,313c0.1,0,0.1,0.1,0.1,0.2l-1.1,277.9c0,0.3-0.1,0.5-0.2,0.5c-3.1,0.8-6.3,0.6-9.6,0l1.6-282.8    C779.3,308.4,786.1,311.5,788.8,313z"/>
                <path className="st0 Right_Stair" d="M802.6,344.8c0.1,0-1,213-1,213c0,0.2-0.1,0.4-0.2,0.4c-3.3,0.1-6.3,0.1-9.6,0l1.7-216.2    C793.3,341.7,799.8,344,802.6,344.8z"/>
                <path className="st3 Right_Front_Door_Moulding" d="M772.9,392c0.2-25.7,0.4-51.5,0.6-77.2c0-0.1,0-0.2-0.1-0.2c-3.1-0.4-7.7-1.9-12.8-3.1c-0.1,0-0.1,0-0.2,0.2    c-0.4,26.9-0.8,53.8-1.2,80.7C763.8,392.3,768.3,392.2,772.9,392z"/>
                <path className="st3 Right_Rear_Door_Moulding" d="M759.7,410.2c-0.6,42.8-1.2,85.7-1.8,128.5c4.9-1.3,9.1-2.3,13-2.9c0.2,0,0.3-0.1,0.3-0.3    c0.3-41.9,0.6-83.8,0.9-125.7C768,410,763.8,410.1,759.7,410.2z"/>
                <path className="st0 Right_Rear_Bumper" d="M719.7,706.9l60.4,0.8l-1.5,15.3c-8.3,15.1-50.2,9.5-60.7,4.6L719.7,706.9z"/>
                <path className="st1 Right_Qtr_Window_Glass" d="M676.8,562.3L676.8,562.3v3.8l-0.3,105.6c0,3.4-2.8,6.1-6.2,6.1h-39.7c-1,0-2-0.3-2.9-0.7    c-1.1-0.6-2-1.5-2.6-2.6c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.2-0.2-0.5-0.3-0.8s-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.9V562.3    c0-0.4,0-0.8,0.1-1.1c0.2-1.1,0.7-2.1,1.4-2.9c1.1-1.3,2.8-2.1,4.6-2.1h39.7c3,0,5.8,2.2,6.4,5.1l0,0    C676.7,561.7,676.8,562,676.8,562.3z"/>
                <path className="st0 Right_Pillar_A" d="M672.5,287.1l-5.2,10.3l-0.4,0.7l-0.1,0.1c-19.2,15.9-31,36.5-38.8,54.1c-0.2,0.4-1.7,4.6-1.8,4.9    c-2.2,7.4-3.2,17-3,25.4c0,0.1,0,0.1,0,0.2v2.5c0,0.1,0,2.9,0.1,4.1c0,0.1,0.6,6.2,0.8,8.6c0.1,0.8,0.1,1.6,0.2,2.3c0,0,0,0,0,0.1    v2.8l0.1,6.9l-6.5-0.2h-1.4c-0.2-0.4-0.3-0.5-0.4-0.8c-0.1-0.3-0.1-0.6-0.2-1.2c0-0.4-0.1-0.8-0.1-1.2    c-4.8-62.5,33.4-96.7,33.4-96.7c10.4-11.1,21.1-20.8,23-22.6C672.4,287.2,672.5,287.1,672.5,287.1z"/>
                <path className="st0 Right_Pillar_D" d="M719.7,706.9c0,0-1.4,17.1-1.7,20.6c0,0.4-5.8-2.1-11.4-2.5c-4.3-0.4-7.6-0.1-9.9,0.2    c-8,0.7-19.7-0.9-43.2-4.1c-7-1-2.8-0.5-17.3-2.9c0,0-5.8-1.1-15.5-3.5c-2.3-0.6-3.3-1-3.8-1.3c-6.5-3.9-5.7-16-5.7-16    c5.6-6.6,11-13.2,16.6-19.9c0.8,0.4,1.6,0.6,2.5,0.6h40.2c2.5,0,5.2-1.3,5.9-4c0,4,0,9.2,0,18.4l1.8,13.1    c1.4,0.1,16.7,1.1,33.7,1.1L719.7,706.9L719.7,706.9z"/>
                <path className="st0 Right_Side_Roof" d="M627.7,677.4c-5.6,6.7-11,13.1-16.7,19.8l0.2-276.1c0-6.3,2.5-11.4,5.4-11.4h1.4l6.4,0.4V436   c0,15,0,124.8,0,126.3v109.5c0,2.3,1.3,4.4,3.2,5.4C627.7,677.3,627.7,677.4,627.7,677.4z"/>
                <circle className="st0 Fuel_Door" cx="707.7" cy="676.8" r="12.4"/>
                <path className="st3 Right_Fender_Extender" d="M754.6,228.5c0,0-0.2,0.2-0.4,0.3c-5.6,3-10.5,9.2-13.8,17.3c0,0.1-0.1,0.1-0.1,0.2c-0.9,2.2-1.7,4.6-2.3,7.1	c-0.5,2.1-0.9,4.3-1.2,6.5c-0.2,2-0.2,4.3-0.1,6.6l0,0c0.5,7.3,2.5,15.3,3.6,19.2c0.4,1.5,0.7,2.5,0.7,2.5	c-0.3,0.3-9.5,0.2-9.5,0.2c-0.1-0.2-0.2-0.4-0.2-0.5c-4.1-8.2-5.6-19.5-3.5-31.4c0.5-2.5,1-4.9,1.7-7.2c3.8-13,11.4-23,19.9-27	c0.4-0.2,0.9-0.4,1-0.4l0,0C750.6,222.1,754.6,228.5,754.6,228.5z"/>
                <path className="st4 Right_Indicator" d="M714,274.3h0.4c1.8,0,3.3-1.5,3.3-3.3v-7.6c0-1.8-1.5-3.3-3.3-3.3H714c-1.8,0-3.3,1.5-3.3,3.3v7.6    C710.8,272.9,712.2,274.3,714,274.3z"/>
                <path className="st1 Right_Front_Window_Glass" d="M686.7,383.1c-0.8,2.2-3.3,9.5-5.6,16.1c-1.2,3.3-2.3,6.4-3,8.6l0,0c-0.3,0.5-0.7,1-1.2,1.4    c-1.2,0.9-3,1.4-4.2,1.6l-44.1-1.1c-2.2-0.5-3.8-1.6-4-2.8c0-0.1,0-0.2,0-0.3c0-0.8,0-2.9,0-3.4v-2.8c0-0.1,0-0.1,0-0.1   c-0.1-0.7-0.1-1.5-0.2-2.3c-0.2-2.4-0.8-8.5-0.8-8.6c-0.1-1.2-0.1-4-0.1-4.1v-2.5c0-0.1,0-0.1,0-0.2c-0.2-8.4,0.8-18,3-25.4   c0.1-0.3,1.6-4.5,1.8-4.9c7.8-17.6,19.7-38.2,38.8-54.1l0.1-0.1c1.1,0,7.6-0.1,13,0.4c0.4,0,1.4,0.2,1.5,0.2    c3.8,0.7,10.3,2,16.1,4.5C701.5,304.8,696,358.3,686.7,383.1z"/>
                <path className="st1 Right_Rear_Window_Glass" d="M676.8,427.7v109.5c0,0.5,0,0.9-0.1,1.3c-0.1,0.3-0.1,0.6-0.3,0.9c-0.8,2.2-2.9,3.8-5.4,3.8h-40.8   c-1.1,0-2.1-0.3-3-0.9c-0.4-0.3-0.8-0.6-1.1-0.9l-0.8-0.9c-0.4-0.6-0.6-1.2-0.8-2v-0.1c0-0.2-0.1-0.5-0.1-0.7c0-0.1,0-0.1,0-0.2   v-0.4c0-0.1,0-0.1,0-0.2V427.7c0-0.8,0.1-1.6,0.4-2.3c0.4-1.1,1.2-2.1,2.2-2.8c0.2-0.2,0.5-0.3,0.8-0.5c0.8-0.4,1.6-0.6,2.5-0.6    h40.8c2.3,0.1,4.4,1.7,5.2,3.9C676.6,426.1,676.8,426.9,676.8,427.7z"/>
                <path className="st0 Right_Pillar_B" d="M676.8,409.2v16.2h-0.5c-0.9-2.2-2.9-3.8-5.2-3.9h-40.8c-0.9,0-1.7,0.2-2.5,0.6c-0.3,0.2-0.6,0.3-0.8,0.5	c-1,0.7-1.8,1.6-2.2,2.8h-0.5v-18.8c0,0.1,0,0.2,0,0.3c0.3,1.2,1.9,2.2,4,2.8l44.1,1.1C673.8,410.6,675.6,410.1,676.8,409.2z"/>
                <path className="st0 Right_Pillar_C" d="M676.7,538.5v22.8c-0.5-2.9-3.3-5.1-6.4-5.1h-39.7c-1.9,0-3.5,0.8-4.6,2.1c-0.7,0.8-1.2,1.8-1.4,2.9v-22.7	c0.2,0.8,0.4,1.4,0.8,2l0.8,0.9c0.3,0.3,0.7,0.6,1.1,0.9c0.9,0.6,1.9,0.9,3,0.9H671c2.5,0,4.6-1.6,5.4-3.8	C676.5,539.1,676.6,538.8,676.7,538.5z"/>
            </g>
            <g id="Left">
                <path className="st0 Left_Qtr_Panel" d="M203.4,679.6c0.2,2.8,0.4,5.6,0.4,6.8l1.4,21.5l60.4-0.8h0.1l7.6-0.1c17,0,32.3-1,33.7-1.1l1.8-13.1    c0-9.2,0.1-14.5,0-18.4c0-0.8,0-1.6,0-2.3c-0.2-18.2-0.3-117.3-0.3-124.6l-97.4,0.1l-4-0.1c0,8.9,0.1,19.1,0.2,37.5    c1.7-0.3,3.4-0.4,5.2-0.5c0.5,0,1,0,1.5,0c25.3,0,45.9,21.6,45.9,48.2c0,26.6-20.6,48.3-45.9,48.3c-2.2,0-4.3-0.2-6.4-0.5    C206.2,680.2,204.8,679.9,203.4,679.6z"/>
                <path className="st3 Left_Qtr_Extender" d="M203,673.2c0,1.1,0.2,3.7,0.4,6.4c1.4,0.3,2.8,0.6,4.2,0.8c2.1,0.3,4.2,0.5,6.4,0.5    c25.3,0,45.9-21.6,45.9-48.3c0-26.6-20.6-48.2-45.9-48.2c-0.5,0-1,0-1.5,0c-1.8,0.1-3.5,0.2-5.2,0.5c0,2.2,0,4.5,0,6.9    c0.5,0,0.9,0,1.4,0c22.3,0,40.5,18.3,40.5,40.9c0,22.6-18.2,40.9-40.5,40.9C206.7,673.6,204.8,673.5,203,673.2z"/>
                <g>
                    <path className="st0 Left_Rear_Tyre" d="M240.5,632.6c0-20.3-16.5-36.8-36.8-36.8s-36.8,16.5-36.8,36.8s16.5,36.8,36.8,36.8S240.5,652.9,240.5,632.6    L240.5,632.6z"/>
                    <path className="st0 Left_Rear_Wheel_Rim" d="M227.8,633.6c0,13.3-10.8,24.1-24.1,24.1s-24.1-10.8-24.1-24.1s10.8-24.1,24.1-24.1S227.8,620.3,227.8,633.6    L227.8,633.6z"/>
                </g>
                <g>
                    <path className="st0 Left_Front_Tyre" d="M241.7,265.7c0-20.3-16.5-36.8-36.8-36.8s-36.8,16.5-36.8,36.8s16.5,36.8,36.8,36.8S241.7,286,241.7,265.7	L241.7,265.7z"/>
                    <path className="st0 Left_Front_Wheel_Rim" d="M229,266.7c0,13.3-10.8,24.1-24.1,24.1s-24.1-10.8-24.1-24.1c0-13.3,10.8-24.1,24.1-24.1S229,253.4,229,266.7    L229,266.7z"/>
                </g>
                <path className="st0 Left_Front_Door" d="M205.7,309c0,1.7,0.5,91.6,0.5,92.8c0.2,0.1,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.3,0c2,0.2,8,0.2,8,0.2    c8.6-0.3,21.9-1.5,37.7-0.5c2,0.1,3.6,0.4,4.9,0.9l0.5,0.2c5.5,2,7.4,6,19.8,8c3.1,0.5,11.8,1.6,24.7,0.9c0.3,0,5.9-0.1,5.9-0.2    c0,0-0.5-1.3-1.2-3.4l0,0c-0.8-2.2-1.9-5.4-3-8.6c-2.3-6.6-4.9-13.9-5.6-16.1c-9.3-24.8-14.8-78.3-10.8-79.9    c5.8-2.5,12.3-3.8,16.1-4.5c0.1,0,1.1-0.1,1.5-0.2c5.3-0.5,11.9-0.4,13-0.4h0.2l-5.5-10.7l-0.2-0.4c-3.2,0-47.6,2-68.3,1.3    c-4-0.1-1.7,6.1-18.8,15.5c-1.6,0.8-4.8,2.5-10.6,3.9C214.1,308,205.8,307.6,205.7,309z"/>
                <g id="Handle2_00000093157300350243381610000017892435138779295884_">
                    <ellipse className="st0 Left_Front_Door_Handle" cx="278.4" cy="382.5" rx="5" ry="8.6"/>
                    <path className="st0 Left_Front_Door_Handle" d="M279.7,392.4c1.6-3.8-0.7-15.2,0-18.9	c0-0.2,0.6-0.7,0.6-1.1c-0.2-1.5-3.7-1.9-4.2-0.3c-0.1,0.4,0.6,0.9,0.6,1.2c0.5,5.6-1.5,18.4-0.1,19.1	C277.3,392.7,279.5,392.8,279.7,392.4z"/>
                </g>
                <path className="st0 Left_Rear_Door" d="M206.2,401.8l0.8,145.6h101.5V411.5c0,0.1-5.6,0.2-5.9,0.2c-12.9,0.7-21.6-0.4-24.7-0.9c-12.4-2-14.3-6-19.8-8    l-0.5-0.2c-1.3-0.5-2.9-0.8-4.9-0.9c-15.8-1-29.1,0.2-37.7,0.5C215,402.2,208.3,402.1,206.2,401.8z"/>
                <ellipse className="st0 Left_Rear_Door_Handle" cx="284.2" cy="430.5" rx="5.8" ry="10.2"/>
                <path className="st0 Left_Rear_Door_Handle" d="M282.4,418.2c0.3-0.2,1-0.4,1.8-0.4c0.9,0,1.6,0.3,1.9,0.4c0,7.6,0,15.3,0.1,22.9c0.1,0.1,0.5,1.1,0.1,2	c-0.5,1.1-1.7,1.1-1.9,1.2c-0.3,0-1.4,0-2-0.9c-0.5-0.9-0.1-2.1-0.1-2.2C282.3,433.5,282.3,426,282.4,418.2z"/>
                <path className="st0 Left_Front_Bumper" d="M230.7,228.7c7.4-12.4,8.2-12.4,9.5-15.1c0.6-1.2,0.4-1.8-0.4-21.9c-0.1-3.4-9.8-2.4-23.8-2    c-1.5,0-6,4.1-6.4,4.1c-1.3,0.2-2.4,0.4-3.5,0c-4.9-1.5-9.1,0.7-10.4,2.2c-0.6,0.7-2.7,27.5-2.4,27.4    C216.9,217.8,230.4,229.3,230.7,228.7z"/>
                <path className="st0 Left_Fender" d="M234.8,222.1c0.1,0,0.7,0.3,1,0.4c8.5,4,16.1,14,19.9,27c0.7,2.3,1.2,4.7,1.7,7.2c2.1,11.9,0.6,23.2-3.5,31.4	c-0.1,0.1-0.1,0.3-0.2,0.5c0,0,48-0.6,59-1.3c0.8,0-3.7-7.8-11.5-23.8c-9.6-19.3-11.9-25.9-17.7-37.9c0-0.1-0.1-0.1-0.1-0.2	c-1.7-3.5-5.7-11-13-17.1c-6.2-5.3-13.2-8.2-15.1-9c0,0-4.3-0.8-9.4-7.1c-2.2-2.8-6.3-1.4-6.3-1.4c0.4,6.9,0.9,14.2,0.8,22.2	C240.5,213.3,237.5,217.9,234.8,222.1z"/>
                <path className="st0 Left_Running_Board" d="M196.5,313.2c-0.1,0-0.1,0.1-0.1,0.2l1.1,277.9c0,0.3,0.1,0.5,0.2,0.5c3.1,0.8,6.3,0.6,9.6,0L205.7,309    C206,308.6,199.2,311.7,196.5,313.2z"/>
                <path className="st0 Left_Stair" d="M182.7,345c-0.1,0,1,213,1,213c0,0.2,0.1,0.4,0.2,0.4c3.3,0.1,6.3,0.1,9.6,0l-1.7-216.2    C192,341.9,185.5,344.2,182.7,345z"/>
                <path className="st3 Left_Front_Door_Moulding" d="M212.4,392.2c-0.2-25.7-0.4-51.5-0.6-77.2c0-0.1,0-0.2,0.1-0.2c3.1-0.4,7.7-1.9,12.8-3.1c0.1,0,0.1,0,0.2,0.2    c0.4,26.9,0.8,53.8,1.2,80.7C221.5,392.5,217,392.4,212.4,392.2z"/>
                <path className="st3 Left_Rear_Door_Moulding" d="M225.6,410.4c0.6,42.8,1.2,85.7,1.8,128.5c-4.9-1.3-9.1-2.3-13-2.9c-0.2,0-0.3-0.1-0.3-0.3    c-0.3-41.9-0.6-83.8-0.9-125.7C217.3,410.2,221.5,410.3,225.6,410.4z"/>
                <path className="st0 Left_Rear_Bumper" d="M265.6,707.1l-60.4,0.8l1.5,15.3c8.3,15.1,50.2,9.5,60.7,4.6L265.6,707.1z"/>
                <path className="st1 Left_Qtr_Window_Glass" d="M308.5,562.6L308.5,562.6v3.8l0.3,105.6c0,3.4,2.8,6.1,6.2,6.1h39.7c1,0,2-0.3,2.9-0.7c1.1-0.6,2-1.5,2.6-2.6    c0.1-0.1,0.1-0.2,0.2-0.4s0.2-0.5,0.3-0.8c0.1-0.3,0.1-0.5,0.2-0.8c0-0.3,0.1-0.6,0.1-0.9V562.6c0-0.4,0-0.8-0.1-1.1    c-0.2-1.1-0.7-2.1-1.4-2.9c-1.1-1.3-2.8-2.1-4.6-2.1H315c-3,0-5.8,2.2-6.4,5.1l0,0C308.6,561.9,308.5,562.2,308.5,562.6z"/>
                <path className="st0 Left_Pillar_A" d="M312.8,287.3l5.2,10.3l0.4,0.7l0.1,0.1c19.2,15.9,31,36.5,38.8,54.1c0.2,0.4,1.7,4.6,1.8,4.9    c2.2,7.4,3.2,17,3,25.4c0,0.1,0,0.1,0,0.2v2.5c0,0.1,0,2.9-0.1,4.1c0,0.1-0.6,6.2-0.8,8.6c-0.1,0.8-0.1,1.6-0.2,2.3c0,0,0,0,0,0.1    v2.8l-0.1,6.9l6.5-0.2h1.4c0.2-0.4,0.3-0.5,0.4-0.8s0.1-0.6,0.2-1.2c0-0.4,0.1-0.8,0.1-1.2c4.8-62.5-33.4-96.7-33.4-96.7    c-10.4-11.1-21.1-20.8-23-22.6C312.9,287.4,312.8,287.4,312.8,287.3z"/>
                <path className="st0 Left_Pillar_D" d="M265.6,707.1c0,0,1.4,17.1,1.7,20.6c0,0.4,5.8-2.1,11.4-2.5c4.3-0.4,7.6-0.1,9.9,0.2c8,0.7,19.7-0.9,43.2-4.1	c7-1,2.8-0.5,17.3-2.9c0,0,5.8-1.1,15.5-3.5c2.3-0.6,3.3-1,3.8-1.3c6.5-3.9,5.7-16,5.7-16c-5.6-6.6-11-13.2-16.6-19.9	c-0.8,0.4-1.6,0.6-2.5,0.6h-40.2c-2.5,0-5.2-1.3-5.9-4c0,4,0,9.2,0,18.4l-1.8,13.1c-1.4,0.1-16.7,1.1-33.7,1.1L265.6,707.1	L265.6,707.1z"/>
                <path className="st0 Left_Side_Roof" d="M357.6,677.6c5.6,6.7,11,13.1,16.7,19.8l-0.2-276.1c0-6.3-2.5-11.4-5.4-11.4h-1.4l-6.4,0.4v25.9	c0,15,0,124.8,0,126.3V672c0,2.3-1.3,4.4-3.2,5.4C357.6,677.5,357.6,677.6,357.6,677.6z"/>
                <path className="st3 Left_Fender_Extender" d="M230.7,228.7c0,0,0.2,0.2,0.4,0.3c5.6,3,10.5,9.2,13.8,17.3c0,0.1,0.1,0.1,0.1,0.2c0.9,2.2,1.7,4.6,2.3,7.1    c0.5,2.1,0.9,4.3,1.2,6.5c0.2,2,0.2,4.3,0.1,6.6l0,0c-0.5,7.3-2.5,15.3-3.6,19.2c-0.4,1.5-0.7,2.5-0.7,2.5c0.3,0.3,9.5,0.2,9.5,0.2    c0.1-0.2,0.2-0.4,0.2-0.5c4.1-8.2,5.6-19.5,3.5-31.4c-0.5-2.5-1-4.9-1.7-7.2c-3.8-13-11.4-23-19.9-27c-0.4-0.2-0.9-0.4-1-0.4l0,0    C234.7,222.3,230.7,228.7,230.7,228.7z"/>
                <path className="st4 Left_Indicator" d="M271.3,274.5h-0.4c-1.8,0-3.3-1.5-3.3-3.3v-7.6c0-1.8,1.5-3.3,3.3-3.3h0.4c1.8,0,3.3,1.5,3.3,3.3v7.6    C274.5,273.1,273.1,274.5,271.3,274.5z"/>
                <path className="st1 Left_Front_Window_Glass" d="M298.6,383.3c0.8,2.2,3.3,9.5,5.6,16.1c1.2,3.3,2.3,6.4,3,8.6l0,0c0.3,0.5,0.7,1,1.2,1.4    c1.2,0.9,3,1.4,4.2,1.6l44.1-1.1c2.2-0.5,3.8-1.6,4-2.8c0-0.1,0-0.2,0-0.3c0-0.8,0-2.9,0-3.4v-2.8c0-0.1,0-0.1,0-0.1    c0.1-0.7,0.1-1.5,0.2-2.3c0.2-2.4,0.8-8.5,0.8-8.6c0.1-1.2,0.1-4,0.1-4.1V383c0-0.1,0-0.1,0-0.2c0.2-8.4-0.8-18-3-25.4    c-0.1-0.3-1.6-4.5-1.8-4.9c-7.8-17.6-19.7-38.2-38.8-54.1l-0.1-0.1c-1.1,0-7.6-0.1-13,0.4c-0.4,0-1.4,0.2-1.5,0.2    c-3.8,0.7-10.3,2-16.1,4.5C283.8,305,289.3,358.5,298.6,383.3z"/>
                <path className="st1 Left_Rear_Window_Glass" d="M308.5,427.9v109.5c0,0.5,0,0.9,0.1,1.3c0.1,0.3,0.1,0.6,0.3,0.9c0.8,2.2,2.9,3.8,5.4,3.8h40.8    c1.1,0,2.1-0.3,3-0.9c0.4-0.3,0.8-0.6,1.1-0.9l0.8-0.9c0.4-0.6,0.6-1.2,0.8-2v-0.1c0-0.2,0.1-0.5,0.1-0.7c0-0.1,0-0.1,0-0.2v-0.4    c0-0.1,0-0.1,0-0.2V427.9c0-0.8-0.1-1.6-0.4-2.3c-0.4-1.1-1.2-2.1-2.2-2.8c-0.2-0.2-0.5-0.3-0.8-0.5c-0.8-0.4-1.6-0.6-2.5-0.6    h-40.8c-2.3,0.1-4.4,1.7-5.2,3.9C308.7,426.3,308.5,427.1,308.5,427.9z"/>
                <path className="st0 Left_Pillar_B" d="M308.5,409.5v16.2h0.5c0.9-2.2,2.9-3.8,5.2-3.9H355c0.9,0,1.7,0.2,2.5,0.6c0.3,0.2,0.6,0.3,0.8,0.5	c1,0.7,1.8,1.6,2.2,2.8h0.5v-18.8c0,0.1,0,0.2,0,0.3c-0.3,1.2-1.9,2.2-4,2.8l-44.1,1.1C311.5,410.9,309.7,410.4,308.5,409.5z"/>
                <path className="st0 Left_Pillar_C" d="M308.6,538.8v22.8c0.5-2.9,3.3-5.1,6.4-5.1h39.7c1.9,0,3.5,0.8,4.6,2.1c0.7,0.8,1.2,1.8,1.4,2.9v-22.7	c-0.2,0.8-0.4,1.4-0.8,2l-0.8,0.9c-0.3,0.3-0.7,0.6-1.1,0.9c-0.9,0.6-1.9,0.9-3,0.9h-40.8c-2.5,0-4.6-1.6-5.4-3.8	C308.8,539.4,308.7,539.1,308.6,538.8z"/>
            </g>
            <rect x="374.3" y="282.8" className="st0 Roof" width="236.7" height="424.3"/>
            <path className="st1 Sun_Roof" d="M536.5,365.8h-78.8c-10.5,0-19-8.5-19-19v-19.4c0-10.5,8.5-19,19-19h78.8c10.5,0,19,8.5,19,19v19.4	C555.6,357.2,547,365.8,536.5,365.8z"/>
        </svg>
    )}

export default VanSVG;