import Cookies from 'js-cookie'
import React, { useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { AppContext } from './context'
import { makeAuthRequest } from './context/utils'
import config from '../config/config.json'


const GTclaim = () => {
    const {urlLink} = config

    const { GTUrl, setGTUrl } = useContext(AppContext)

    const { id } = useParams()

    const getGTUrl = async() => {
        const res = await makeAuthRequest(`${urlLink}gt-edit`, {inspection_id: id, client_id: Cookies.get('client'), client_type: Cookies.get('clientType')})
        const data = await res.json()
        if(res.ok){
          setGTUrl(data.url)
        }
    }

    useEffect(()=>{
        getGTUrl()
    },[])

    return (
    <iframe src={GTUrl} style={{height: "100vh", width: "100vw"}}>

    </iframe>
    )
}

export default GTclaim