import guidanceImg from '../img/guidance.png'

const GuidanceSVG = ({data}) => {

    return(
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            width: "130px",
            height: "130px"
        }}>
            <svg width="500" height="300"  viewBox="0 0 125 126" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <path d="M118.183 39.7117C124.304 54.4873 124.304 71.0891 118.183 85.8647L62.4718 62.7882L118.183 39.7117Z" id="view_0"  fill={data["R"]} stroke="white" strokeWidth="2"/>
                <path d="M85.6773 7.13015C100.439 13.2846 112.151 25.051 118.237 39.8408L62.4718 62.7882L85.6773 7.13015Z" id="view_1" fill={data["RFI"]} stroke="white" strokeWidth="2"/>
                <path d="M39.3953 7.07653C54.1709 0.956257 70.7727 0.956262 85.5484 7.07654L62.4718 62.7882L39.3953 7.07653Z" id="view_2" fill={data["F"]} stroke="white" strokeWidth="2"/>
                <path d="M6.76014 39.7117C9.79059 32.3955 14.2324 25.7479 19.832 20.1483C25.4315 14.5488 32.0792 10.107 39.3953 7.07652L62.4718 62.7882L6.76014 39.7117Z" id="view_3" fill={data["LFI"]} stroke="white" strokeWidth="2"/>
                <path d="M6.76013 85.8648C3.72968 78.5486 2.16992 70.7072 2.16992 62.7882C2.16992 54.8692 3.72968 47.0278 6.76014 39.7117L62.4718 62.7882L6.76013 85.8648Z" id="view_4" fill={data["L"]} stroke="white" strokeWidth="2"/>
                <path d="M39.3953 118.5C32.0791 115.469 25.4315 111.028 19.8319 105.428C14.2324 99.8285 9.79058 93.1809 6.76013 85.8647L62.4718 62.7882L39.3953 118.5Z" id="view_5" fill={data["LRI"]} stroke="white" strokeWidth="2"/>
                <path d="M85.5956 118.48C78.282 121.517 70.4419 123.083 62.523 123.09C54.604 123.097 46.7613 121.544 39.4426 118.519L62.4718 62.7882L85.5956 118.48Z" id="view_6" fill={data["B"]} stroke="white" strokeWidth="2"/>
                <path d="M118.183 85.8647C115.153 93.1809 110.711 99.8286 105.112 105.428C99.5121 111.028 92.8645 115.469 85.5483 118.5L62.4718 62.7882L118.183 85.8647Z" id="view_7" fill={data["RRI"]} stroke="white" strokeWidth="2"/>
                <circle cx="62.4716" cy="62.7883" r="25.5849" fill="#EEEEEE" stroke="white" strokeWidth="2"/>
                <rect x="45" y="28" width="33" height="69" fill="url(#pattern0)"/>
            </svg>
            <img style={{
                width: "45px",
                height: "80px",
                position: "absolute"
            }} src={guidanceImg} alt="image"></img>
        </div>
    )

}

export default GuidanceSVG