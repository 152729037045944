import React from 'react'
import logo from '../../img/logo.svg'
import './Login.scss';

const LoginNavbar = () =>{
  return (
    <div className="loginNavbar">
        <div className="logo"><img src={window.top.location.host === "webapp.inspek.ai" ? '/logo/sawitt.svg' : window.top.location.host === "resultsjpn.octotelematics.com" ? '/logo/client_1217.svg' : window.top.location.href === "https://results.autosnaply.com/login" ? '/logo/client_1255.svg' : window.top.location.href === "https://results.octotelematics.com/login" ? '/logo/client_1217.svg' : window.top.location.href === "https://inspection-vn.iglooinsure.com/login" ? '/logo/client_1124.svg' : window.top.location.href === "https://results.easydetect.ai/login" ? '/logo/easyautotech.svg' : logo} alt="logo"/></div>
        {/* <div className="navbar-list">
          <p>Language</p>
          <p>Logout</p>
        </div> */}
    </div>
  )
}

export default LoginNavbar