import React, {useEffect} from "react";

const PickupTruckSVG = ({data}) => {

    const setColors = () => {
        for(let i = 0; i < data.length; i++){
          if(data[i].damageSeverityScore <= 0.3 ){
            document.querySelectorAll(`.${data[i].partName.split(":").join("").split("/").join(" ").split(" ").join("_")}`).forEach(item => {
              item.classList.remove(item.classList[0])
              item.classList.add("yellow")
            })
          }
          else if(data[i].damageSeverityScore > 0.3 && data[i].damageSeverityScore <=0.7){
            document.querySelectorAll(`.${data[i].partName.split(":").join("").split("/").join(" ").split(" ").join("_")}`).forEach(item => {
              item.classList.remove(item.classList[0])
              item.classList.add("orange")
            })
          }else{
            document.querySelectorAll(`.${data[i].partName.split(":").join("").split("/").join(" ").split(" ").join("_")}`).forEach(item => {
              item.classList.remove(item.classList[0])
              item.classList.add("red")
            })
          }
        }
    }
      
    
    useEffect(()=>{
    setColors()
    },[])

	return(

		<svg 
		version="1.1" 
		id="Layer_1" 
		xmlns="http://www.w3.org/2000/svg" 
		xmlnsXlink="http://www.w3.org/1999/xlink" 
		x="0px" 
		y="0px"
	 	viewBox="0 0 1000 1000" 
		style={{enableBackground:"new 0 0 1000 1000", }}
		xmlSpace="preserve"
        {...{data}}
		>
		<style type="text/css">
			{
				"\n\t.red{fill:#FF0000;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n\t.orange{fill:#FF8A00;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n\t.yellow{fill:#FFB800;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n\t.st0{fill:#FFFFFF;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n\t.st1{fill:none;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n\t.st2{stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n\t.st3{fill:#FFE600;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"
			}	
		</style>
		<path className="st0 Extra1" d="M430.3,437.2c0-0.1,0-0.3-0.1-0.4c-0.1-0.4-0.2-0.9-0.2-1.3c-0.1-0.7-0.3-1.4-0.4-2.1c-0.1-0.7-0.3-1.4-0.4-2.1	c-0.3-1.4-0.6-2.8-1-4.2c-0.2-0.7-0.3-1.4-0.5-2.1c-0.6-2.1-1.2-4.2-1.8-6.3c-0.2-0.6-0.4-1.3-0.6-1.9c-4.8-15-11.7-29.5-19.6-43.5	v-0.1c0,0,0,0,0-0.1c-6-10.7-12.4-21.1-18.6-31.3c-6.3-10.2-12.3-20.2-17.6-29.8c-1.4-2.5-2.2-4.9-2.5-7.1c-0.5-3.8,0.2-7,1.2-9.5	c0.9,1.3,32.3,47.1,58.1,115.1c0.1,0.4,0.1,0.7,0.2,1.1c0,0-0.1,0-0.1,0C427.9,419.8,429.2,428.4,430.3,437.2z"/>
		<path className="st0 Rear_Bumper_Cover" d="M627.8,944.1v12.4c0,6.4-5.2,11.6-11.6,11.6H396.6c-6.4,0-11.6-5.2-11.6-11.6v-12.4	c0-2.6,2.1-4.6,4.6-4.6h57.2c2.7,0,5,1.8,5.8,4.3l3.9,13.4c0.7,2.6,3.1,4.3,5.8,4.3h88.2c2.7,0,5-1.8,5.8-4.3l3.9-13.4	c0.7-2.6,3.1-4.3,5.8-4.3h57.2C625.7,939.4,627.8,941.5,627.8,944.1z"/>
		<path className="st0 Tail_Gate" d="M604.5,852.8v71.3c0,5-3.9,9-8.8,9.2c-0.1,0-0.3,0-0.4,0H417.5c-0.3,0-0.6,0-0.9-0.1c-0.1,0-0.2,0-0.2,0	c-0.2,0-0.4-0.1-0.6-0.1v0c0,0-0.1,0-0.1,0c-4.2-0.9-7.4-4.6-7.4-9v-71.3H604.5z"/>
		<path className="st0 Right_Qtr_Panel" d="M645.1,583.9l-3.5,255.3c-0.1,7.4,5.9,13.5,13.4,13.5h65.5v-66.8c-36.1-16.3-60.8-50.2-60.8-89.3	c0-49.2,39.1-90.1,90.7-98.4v0.2c0.3,0,0.6-0.1,0.9-0.2v-30C739.1,572.5,683.1,581.3,645.1,583.9z M673.7,784.3	c5.5,0,9.9,4.4,9.9,9.9c0,5.5-4.4,9.9-9.9,9.9c-5.5,0-9.9-4.4-9.9-9.9C663.8,788.7,668.3,784.3,673.7,784.3z"/>
		<path className="st0 Extra5" d="M660,852.7"/>
		<ellipse className="st0 Extra1001" cx="673.7" cy="794.2" rx="9.9" ry="9.9" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -364.2508 709.0208)" />
		<path className="st1 Extra6" d="M392.5,584.5L392.5,584.5c0.6,0,1.3,0,1.9,0H392.5z M434.5,584.5C434.5,584.6,434.5,584.6,434.5,584.5	l143.7,0.1c0,0,0,0,0-0.1H434.5z M427.6,701h-19.3v115.7h19.3c6.7,0,12.1-5.4,12.1-12.1v-91.5C439.7,706.4,434.3,701,427.6,701z M585.2,701c-6.7,0-12.1,5.4-12.1,12.1v91.5c0,6.7,5.4,12.1,12.1,12.1h19.3V701H585.2z"/>
		<path className="st0 Inner_Lining" d="M401.9,678.9v147c0,8.1,6.5,14.6,14.6,14.6h179.8c8.1,0,14.6-6.5,14.6-14.6h0.1v-147H401.9z M604.5,826.1H408.3	V691h196.2V826.1z"/>
		<path className="st0 Extra8" d="M751.4,568.2v30c-0.3,0.1-0.7,0.2-1,0.2c0,0-0.1,0-0.1,0c-51.5,8.1-90.5,49-90.5,98.2c0,39.1,24.8,73,60.8,89.3	v66.8h-0.1v-66.8c-36.1-16.3-60.8-50.2-60.8-89.3c0-49.2,39.1-90.1,90.7-98.4v0.2c0.3,0,0.6-0.1,0.9-0.2L751.4,568.2	C751.4,568.2,751.4,568.2,751.4,568.2z"/>
		<path className="st0 Roof" d="M586.3,411.5c-1.5,8.4-2.7,17.2-3.9,26.2v0.1c-0.6,3.6-1,7.3-1.3,11c0,0,0,0.1,0,0.2c0,0.5-0.1,1-0.1,1.5	c-1.8,36.3-2.5,88.6-2.7,115.9c-0.1,10.4-0.1,17.2-0.1,18.1c0,0.1,0,0.1,0,0.1H434.5c0,0,0,0,0-0.1c0-0.9,0-7.7-0.1-18.1	c0-6.6-0.1-14.7-0.2-23.7c-0.4-28-1.1-64.6-2.5-92.2v-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.3,0-0.4c0-0.3,0-0.5,0-0.8	v-0.2c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1c-0.2-3.5-0.7-6.9-1.2-10.3c0-0.1,0-0.3-0.1-0.4v-0.1c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0	c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c-1.1-8.8-2.4-17.3-3.8-25.7c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0c0.8-0.1,3-0.5,6.5-1	c0,0,0.1,0,0.1,0c0.4,0,0.7-0.1,1.1-0.2c1.1-0.2,2.3-0.3,3.6-0.5c0,0,0,0,0.1,0c0.9-0.1,1.8-0.2,2.8-0.4c0.5-0.1,1-0.1,1.5-0.2	c0.4,0,0.8-0.1,1.2-0.2c0.2,0,0.4,0,0.6-0.1c0.4,0,0.8-0.1,1.2-0.2c0.2,0,0.5,0,0.7-0.1c0.6-0.1,1.3-0.2,2-0.2	c3.4-0.4,7.1-0.8,11.1-1.2c1.2-0.1,2.4-0.2,3.6-0.3c0.1,0,0.1,0,0.2,0c0.8-0.1,1.6-0.1,2.5-0.2c0.4,0,0.7-0.1,1.1-0.1	c0.8-0.1,1.7-0.1,2.6-0.2c2.6-0.2,5.3-0.4,8-0.6c0.9-0.1,1.8-0.1,2.7-0.2c0.5,0,1-0.1,1.4-0.1c0.9,0,1.8-0.1,2.8-0.1	c7.2-0.4,14.8-0.6,22.4-0.6c9.5,0,18.9,0.3,27.7,0.9c1.2,0.1,2.3,0.1,3.4,0.2c1.1,0.1,2.3,0.2,3.4,0.2c0.6,0,1.1,0.1,1.7,0.	c1.9,0.1,3.7,0.3,5.5,0.5c1.8,0.2,3.6,0.3,5.3,0.5c1.2,0.1,2.3,0.2,3.4,0.3c2,0.2,3.8,0.4,5.7,0.6c0.5,0,0.9,0.1,1.4,0.1	C577.2,410,585.9,411.4,586.3,411.5z"/>
		<path className="st1 Extra10" d="M585.2,701c-6.7,0-12.1,5.4-12.1,12.1v91.5c0,6.7,5.4,12.1,12.1,12.1h19.3V701H585.2z M427.6,701h-19.3v115.7	h19.3c6.7,0,12.1-5.4,12.1-12.1v-91.5C439.7,706.4,434.3,701,427.6,701z"/>
		<path className="st0 Rear_Glass" d="M593,666.3c-14.5,4.5-41.4,10.2-86.5,10.7c-1.1,0-2.2,0-3.2,0c-43.1-0.7-69.1-6.2-83.2-10.6	c11.6-28.4,24.2-72,24.2-72h124.6C568.8,594.3,581.3,637.9,593,666.3z"/>
		<g id="_x38_3">
			<path className="st0 Inner_Lining" d="M604.5,701v115.7h-19.3c-6.7,0-12.1-5.4-12.1-12.1v-91.5c0-6.7,5.4-12.1,12.1-12.1H604.5z"/>
			<path className="st0 Inner_Lining" d="M439.7,713.1v91.5c0,6.7-5.4,12.1-12.1,12.1h-19.3V701h19.3C434.3,701,439.7,706.4,439.7,713.1z"/>
		</g>
		<path className="st0 Truck_Bed_Floor" d="M573.1,713.1v91.5c0,6.7,5.4,12.1,12.1,12.1h19.3v9.4H408.3v-9.4h19.3c6.7,0,12.1-5.4,12.1-12.1v-91.5	c0-6.7-5.4-12.1-12.1-12.1h-19.3v-10h196.2v10h-19.3C578.5,701,573.1,706.4,573.1,713.1z"/>
		<path className="st0 Front_Bumper" d="M626.1,47.2c-2.8-6-10.1-8.4-15.6-8.4c-5.4,0-104.1,0-104.1,0s-98.7,0-104.1,0s-12.8,2.4-15.6,8.4	c-2.8,5.9-10.9,25.8-10.9,33.3c0,13.9,0,23.6,0,23.6c17.3,0,22.6,12.7,22.6,12.7c3.4-1.7,7.8-2.8,13-2.8c10.3,0,23.3,7,30.2,11.3	c1.9,1.2,1.4,4-0.8,4.4l-27.3,5.7h185.7l-27.3-5.7c-2.2-0.5-2.7-3.3-0.8-4.4c7-4.3,19.9-11.3,30.2-11.3c5.2,0,9.6,1.2,13,2.8	c0,0,5.3-12.7,22.6-12.7c0,0,0-9.6,0-23.6C637,73,629,53.1,626.1,47.2z M445.2,55.2c0.7-2,2.6-3.4,4.7-3.4h113	c2.2,0,4.1,1.4,4.7,3.4l2.7,8.3H442.5L445.2,55.2z M417.2,65.8l-14.4,4.9c-2.8,1-5.9-0.6-6.8-3.4l-2.4-7.5c-1.4-4.6,2.5-9,7.2-8	l15.7,3.3C422.1,56.2,422.6,64,417.2,65.8z M579.9,106.4c-1.8,2-7.7,8.4-12.3,11.4s-14.3,7.7-22.5,7.7s-38.8,0-38.8,0	s-30.6,0-38.8,0s-17.9-4.7-22.5-7.7s-10.5-9.3-12.3-11.4c-1.8-2-0.7-6.5,3.3-7.9c13.6-3.7,39.9-8.6,54-8.6c14,0,16.3,0,16.3,0	s2.2,0,16.3,0c14,0,40.3,4.9,54,8.6C580.6,99.9,581.7,104.4,579.9,106.4z M619.2,59.8l-2.4,7.5c-0.9,2.9-4,4.4-6.8,3.4l-14.4-4.9	c-5.4-1.8-4.9-9.7,0.7-10.9l15.7-3.3C616.7,50.8,620.7,55.2,619.2,59.8z"/>
		<path className="st0 Tow_Hook_Cover_Front" d="M464.5,45.9c0,2.3-3.5,4.2-7.8,4.2s-7.7-1.9-7.7-4.2s3.5-4.2,7.7-4.2	C461.1,41.7,464.5,43.6,464.5,45.9z"/>
		<path className="st0 Tow_Hook_Cover_Rear" d="M453.4,962.6c0,2.3-3.5,4.2-7.8,4.2s-7.7-1.9-7.7-4.2s3.5-4.2,7.7-4.2	C449.9,958.4,453.4,960.3,453.4,962.6z"/>
		<path className="st0 Right_Qtr_Panel" d="M629.3,886.5c0,2.7-7.1,52.9-7.1,52.9h-27v-6.1c5.1,0,9.2-4.1,9.2-9.2c0,0,15.3-23.9,19.9-52.1	h-19.9v-19.2h23.3C627.8,852.8,629.3,883.8,629.3,886.5z"/>
		<path className="st0 Left_Qtr_Panel" d="M415.7,933.1v6.3h-25.1c0,0-7.1-50.2-7.1-52.9s1.5-33.8,1.5-33.8h23.3V872h-19.9	c4.5,28.2,19.9,52.1,19.9,52.1C408.3,928.5,411.5,932.2,415.7,933.1z"/>
		<path className="st0 Extra20" d="M734.9,167.3c0.3-0.3,0.5-0.7,0.7-1.2C735.4,166.6,735.2,167,734.9,167.3z"/>
		<path className="st0 Right_Rear_Door" d="M751.2,463.1c-2.6,0-54.5-0.8-102.8,2.1v0.2l-0.1,8.5l-0.8,53.2c-1.1,6.6-4.4,16.7-6.9,20.6	l-0.6,0.9l-0.5,0.8c0,0,0,0,0,0l-8,13.4h0.1c13.8-0.3,29.7-0.8,46.8-2c27-1.9,45.6-4.4,57.2-6.3c0.8-0.1,1.5-0.3,2.2-0.4	c0.4-0.1,0.7-0.1,1.1-0.2c0.8-0.1,1.5-0.3,2.2-0.4c0.3,0,0.6-0.1,0.8-0.2c0.4-0.1,0.8-0.2,1.2-0.2s0.7-0.1,1.1-0.2	c0.2,0,0.4-0.1,0.5-0.1c0.5-0.1,1-0.2,1.4-0.3c1.2-0.2,2.1-0.5,2.9-0.6c0.3-0.1,0.5-0.1,0.7-0.2c0.3-0.1,0.6-0.1,0.8-0.2	c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.1,0,0.2,0c0.2,0,0.2-0.1,0.2-0.1v-88.3C751.3,463.1,751.3,463.1,751.2,463.1z M665.9,516.4	c-1.4,0-2.6,1.2-2.6,2.6v26.1c0,1.4,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6V519C668.5,517.6,667.3,516.4,665.9,516.4z"/>
		<path className="st0 Left_Rear_Door" d="M374,550.4c-0.2-0.3-0.4-0.5-0.4-0.5c-0.5-0.7-1-1.4-1.5-2.2c-2.5-4-5.8-14-6.9-20.6l-0.8-52.8	l-0.1-9.1c-49.5-3-102.8-2.1-102.8-2.1v88.3c0,0,20.9,5.7,72.9,9.4c18.2,1.3,35.2,1.9,49.6,2.1C382.4,561.2,375.7,552.5,374,550.4z	 M346.9,516.4c-1.4,0-2.6,1.2-2.6,2.6v26.1c0,1.4,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6V519C349.5,517.6,348.4,516.4,346.9,516.4z"/>
		<path className="st0 Right_Front_Door" d="M739.6,340.5c-15.1-2-55.1-6.5-89.4-2.9v7.6l-0.3,17.6v0.1l2.1-1.6c1.2-1,2.9-0.9,4,0.2l1.7,1.7	c0.8,0.7,1.1,1.7,0.9,2.7c0,0.1,0,0.1,0,0.2c-0.1,0.5-0.3,1-0.7,1.4l-8.2,9.1v2.4l-0.1,6.6l-1,69.2l-0.2,10.4v0.2	c49.5-3,102.8-2.1,102.8-2.1v-120C747.4,342.6,743.5,341.6,739.6,340.5z M665.9,420.4c-1.4,0-2.6,1.2-2.6,2.6v26.1	c0,1.4,1.2,2.6,2.6,2.6c1.4,0,2.6-1.2,2.6-2.6V423C668.5,421.6,667.3,420.4,665.9,420.4z"/>
		<path className="st0 Left_Front_Door" d="M349.3,422c-0.3-0.6-0.7-1.1-1.4-1.4c-0.1,0-0.2-0.1-0.2-0.1c-0.2-0.1-0.3-0.1-0.5-0.1	c-0.1,0-0.2,0-0.3,0c-1.4,0-2.6,1.2-2.6,2.6v26.1c0,1.4,1.2,2.6,2.6,2.6c1.4,0,2.6-1.2,2.6-2.6V423	C349.5,422.7,349.5,422.3,349.3,422z M364.2,455.1l0-0.3v-0.1l-1-69v-0.1l-0.1-6.5v-2.7l-8.2-9.1c-1.1-1.2-1-3.1,0.2-4.3l1.7-1.7	c1.1-1.1,2.8-1.2,4-0.2l2.1,1.6l-0.3-17.5v-7.8c-0.7-0.1-1.5-0.2-2.2-0.2c-0.6-0.1-1.2-0.1-1.8-0.2c-1-0.1-2-0.2-3-0.2	c-1.2-0.1-2.4-0.2-3.5-0.2c-1.6-0.1-3.1-0.2-4.7-0.2c-1.3-0.1-2.5-0.1-3.8-0.1c-1.3,0-2.6-0.1-3.9-0.1h-5.3	c-25.3,0-49.2,2.7-60.4,4.2c0,0,0,0,0,0c-0.2,0-0.4,0-0.6,0.1c-3.9,1.1-7.8,2.1-11.6,2.9v119.8c0,0,53.1-0.9,102.5,2.1	c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0L364.2,455.1z"/>
		<rect x="728.6" y="467.8" className="st0 Right_Rear_Door_Moulding" width="15.5" height="79.9"/>
		<rect x="728" y="360.2" className="st0 Right_Front_Door_Moulding" width="15.5" height="80"/>
		<rect x="269.6" y="467.8" className="st0 Left_Rear_Door_Moulding" width="15.5" height="79.9"/>
		<rect x="269.1" y="360.2" className="st0 Left_Front_Door_Moulding" width="15.5" height="80"/>
		<path className="st0 Extra25" d="M642.8,474.3c0,0,0,44.3,0,53.3c0,3.8-3.9,13.5-6.6,17.9c-2.7,4.4-6.4,4.3-15.6,4	c-6.4-0.2-15.3-1.5-20.2-2.3c-2.1-0.3-3.6-2.1-3.6-4.2v-66.9L642.8,474.3z"/>
		<g id="_x36_8">
			<path className="st0 Right_Front_Window_Glass" d="M645.2,351.9v14.6l-2.6,2c-3.6-2.1-7.7-2.1-10.7-1.6C639.9,355.8,645.2,351.9,645.2,351.9z"/>
			<path className="st0 Right_Front_Window_Glass" d="M644.8,386.2l-1.8,69.6l-45.5,7.2c-0.2-25,6.8-47.4,21.4-74.9c3-5.7,6-10.7,8.7-14.8V390	C631.7,388.1,639.7,386.8,644.8,386.2z"/>
		</g>
		<path className="st0 Left_Rear_Window_Glass" d="M416,476.1V543c0,2.1-1.5,3.9-3.6,4.2c-4.9,0.8-13.8,2.1-20.2,2.3c-9.2,0.3-12.9,0.4-15.6-4	s-6.6-14.2-6.6-17.9c0-9,0-53.3,0-53.3L416,476.1z"/>
		<g id="_x36_6">
			<path className="st0 Left_Front_Window_Glass" d="M380.9,366.9c-3-0.5-7.1-0.5-10.7,1.6l-2.6-2v-14.6C367.6,351.9,372.9,355.8,380.9,366.9z"/>
			<path className="st0 Left_Front_Window_Glass" d="M415.3,463l-45.5-7.2l-1.8-69.6c5.1,0.7,13.1,1.9,17.2,3.8v-16.8c2.7,4.2,5.6,9.1,8.7,14.8	C408.5,415.5,415.5,437.9,415.3,463z"/>
		</g>
		<path className="st0 Right_Front_Wheel_Rim" d="M736,250.8c0,21.8,17.4,39.5,38.8,39.5c21.4,0,38.8-17.7,38.8-39.5s-17.4-39.5-38.8-39.5	C753.4,211.2,736,228.9,736,250.8z"/>
		<path className="st0 Left_Front_Wheel_Rim" d="M276.8,250.8c0,21.8-17.4,39.5-38.8,39.5s-38.8-17.7-38.8-39.5s17.4-39.5,38.8-39.5	C259.4,211.2,276.8,228.9,276.8,250.8z"/>
		<path className="st0 Right_Rear_Wheel_Rim" d="M736,697.6c0,21.8,17.4,39.5,38.8,39.5c21.4,0,38.8-17.7,38.8-39.5s-17.4-39.5-38.8-39.5	C753.4,658,736,675.7,736,697.6z"/>
		<path className="st0 Left_Rear_Wheel_Rim" d="M276.8,697.6c0,21.8-17.4,39.5-38.8,39.5s-38.8-17.7-38.8-39.5s17.4-39.5,38.8-39.5	S276.8,675.7,276.8,697.6z"/>
		<g id="_x36_1_1_">
			<path className="st0 Right_Fog_Light" d="M600.7,61.1c0,3.9,3.2,7.1,7.1,7.1c3.9,0,7.1-3.2,7.1-7.1s-3.2-7.1-7.1-7.1C603.9,53.9,600.7,57.1,600.7,61.1z	"/>
		</g>
		<g id="_x36_0_1_">
			<path className="st0 Left_Fog_Light" d="M412.1,61.1c0,3.9-3.2,7.1-7.1,7.1s-7.1-3.2-7.1-7.1s3.2-7.1,7.1-7.1C408.9,53.9,412.1,57.1,412.1,61.1z"/>
		</g>
		<path className="st0 Extra37" d="M319.6,176.9c0.2,0.8,0.5,1.7,0.7,2.7c1.7,5.7,3.8,11.1,6.4,15.8c0,0,0,0,0,0	C323.8,190,321.4,183.5,319.6,176.9z"/>
		<path className="st0 Extra38" d="M358.7,313.7v9c0,3-2.4,5.4-5.4,5.4s-5.4-2.4-5.4-5.4v-9c0-3,2.4-5.4,5.4-5.4c1.5,0,2.9,0.6,3.8,1.6	C358.1,310.8,358.7,312.2,358.7,313.7z"/>
		<path className="st0 Extra39" d="M660,852.7"/>
		<path className="st1 Extra40" x1="352.8" y1="852.7" x2="352.7" y2="852.7"/>
		<path className="st0 Right_Qtr_Extender" d="M760.7,763.9l-6,74.6c-0.5-0.1-1-0.2-1.6-0.2h-17.4c-2.6,0-5,1.2-6.5,3.2c0,0-0.1,0.1-0.1,0.1	c-1.1,1.4-1.7,3.2-1.6,5.1l0.1,6h-7.1v-66.8c-36.1-16.3-60.8-50.2-60.8-89.3c0-49.2,39.1-90.1,90.7-98.4v34.2c0,0-0.1,0-0.1,0	c-31.9,6.4-55.7,33.1-55.7,65.1C694.6,732.9,723.8,761.8,760.7,763.9z"/>
		<path className="st0 Left_Qtr_Extender" d="M353.1,696.6c0,39.1-24.7,73-60.8,89.3v66.8h-7.1l0.1-6c0-1.9-0.6-3.7-1.6-5.1c0,0-0.1-0.1-0.1-0.1	c-1.5-1.9-3.8-3.2-6.5-3.2h-17.4c-0.6,0-1.1,0.1-1.6,0.2l-6-74.6c36.9-2.1,66.1-31,66.1-66.4c0-32-23.8-58.6-55.7-65.1	c0,0-0.1,0-0.1,0v-34.2C314,606.5,353.1,647.4,353.1,696.6z"/>
		<path className="st0 Right_Side_Roof" d="M598.6,420.7c-0.1,0.5-0.2,1-0.4,1.5c-0.2,0.8-0.3,1.6-0.5,2.4c-2,9.4-3.4,20.6-4.2,29.7	c-0.1,1.5-0.3,3-0.4,4.4c-0.1,0.7-0.1,1.4-0.2,2c-0.1,1.1-0.2,2.2-0.3,3.2c-0.4,4.7-0.5,7.7-0.5,7.7v71.1h-7.8	c-0.1-0.9-0.2-1.8-0.2-2.8V503c0-15.6-2-52.7,6.4-84.1l0.1,0L598.6,420.7z"/>
		<path className="st0 Left_Side_Roof" d="M428.6,503v36.9c0,0.9-0.1,1.9-0.2,2.8h-7.8v-71.1c0,0-0.2-3-0.5-7.7c-0.8-10.5-2.6-29.5-5.9-43.1	c-0.2-0.9-0.5-1.7-0.7-2.6l7.3-3.9c0.2,0.5,0.3,0.9,0.4,1.4c0.3,1.1,0.6,2.3,0.9,3.4C430.5,450.3,428.6,487.4,428.6,503z"/>
		<rect x="469.8" y="449.8" className="st0 Sun_Roof" width="73.3" height="45"/>
		<ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -364.2508 709.0208)" className="st0 Fuel_Door" cx="673.7" cy="794.2" rx="9.9" ry="9.9"/>
		<path className="st0 Hood" d="M614.6,305c0,0-44.2-10.9-108.2-10.9S398.2,305,398.2,305s-2.9-47,8.3-89.1	c11.2-42.1,19.9-56.4,22.6-61.6c2.5-4.8,9.6-10.3,17.6-12c8-1.8,37.2-6.3,59.7-6.3s51.7,4.6,59.7,6.3c8.1,1.7,15.1,7.2,17.6,12	c2.7,5.2,11.4,19.6,22.6,61.6C617.5,258,614.6,305,614.6,305z"/>
		<path className="st0 Left_Qtr_Panel" d="M371.2,839.2c0.1,7.4-5.9,13.5-13.4,13.5h-65.5v-66.8c36.1-16.3,60.8-50.2,60.8-89.3	c0-49.2-39.1-90.1-90.7-98.4v0.2c-0.3-0.1-0.5-0.2-0.9-0.2v-29.9c8.4,2.9,38.1,8.1,68.2,11.8c4.3,0.5,8.6,1,12.9,1.5	c0.8,0.1,1.5,0.2,2.3,0.2c1.3,0.1,2.6,0.3,3.9,0.4c2.1,0.2,4.1,0.4,6.1,0.6c1.1,0.1,2.2,0.2,3.3,0.3c2.2,0.2,4.4,0.4,6.5,0.5	c0.5,0,1.1,0.1,1.6,0.1c0.5,0,1,0.1,1.5,0.1L371.2,839.2z"/>
		<g id="_x34_6">
			<ellipse className="st2 Rear_PDC_Sensor" cx="415.7" cy="964.5" rx="3.3" ry="1.9"/>
			<ellipse className="st2 Rear_PDC_Sensor" cx="505.5" cy="964.5" rx="3.3" ry="1.9"/>
			<ellipse className="st2 Rear_PDC_Sensor" cx="595.3" cy="964.5" rx="3.3" ry="1.9"/>
		</g>
		<g id="_x34_5_2_">
			<polygon className="st0 Emblem" points="517.3,927.3 493.6,927.3 505.5,908.2 	"/>
			<polygon className="st0 Emblem" points="493.6,146 517.3,146 505.5,165.1 	"/>
		</g>
		<rect x="604.5" y="931.8" className="st0 Right_Rear_Reflector_Bottom" width="7.9" height="5.5"/>
		<rect x="398.4" y="931.8" className="st0 Left_Rear_Reflector_Bottom" width="7.9" height="5.5"/>
		<path className="st0 Rear_Bumper" d="M595.3,933.3v6.1H566c-2.7,0-5,1.8-5.8,4.3l-3.9,13.4c-0.7,2.6-3.1,4.3-5.8,4.3h-88.2	c-2.7,0-5-1.8-5.8-4.3l-3.9-13.4c-0.7-2.6-3.1-4.3-5.8-4.3h-31.2v-6.3c0.6,0.1,1.2,0.2,1.9,0.2H595.3L595.3,933.3z"/>
		<path className="st0 Right_Rear_Door_Handle" d="M668.5,519v26.1c0,1.4-1.2,2.6-2.6,2.6c-1.4,0-2.6-1.2-2.6-2.6V519c0-1.4,1.2-2.6,2.6-2.6	S668.5,517.6,668.5,519z"/>
		<path className="st0 Right_Front_Door_Handle" d="M668.5,423v26.1c0,1.4-1.2,2.6-2.6,2.6c-1.4,0-2.6-1.2-2.6-2.6V423c0-1.4,1.2-2.6,2.6-2.6	S668.5,421.6,668.5,423z"/>
		<path className="st0 Left_Rear_Door_Handle" d="M349.5,519v26.1c0,1.4-1.2,2.6-2.6,2.6s-2.6-1.2-2.6-2.6V519c0-1.4,1.2-2.6,2.6-2.6	S349.5,517.6,349.5,519z"/>
		<path className="st0 Left_Front_Door_Handle" d="M349.5,423v26.1c0,1.4-1.2,2.6-2.6,2.6s-2.6-1.2-2.6-2.6V423c0-1.4,1.2-2.6,2.6-2.6	S349.5,421.6,349.5,423z"/>
		<path className="st0 Right_Stair" d="M793.6,335.8V614c0,3.8-3.4,6.8-7.7,6.8c-4.2,0-7.7-3-7.7-6.8V335.8c0-3.8,3.4-6.8,7.7-6.8	c2.1,0,4,0.8,5.4,2C792.8,332.2,793.6,333.9,793.6,335.8z"/>
		<path className="st0 Left_Stair" d="M230.9,335.8V614c0,3.8-3.4,6.8-7.7,6.8c-4.2,0-7.7-3-7.7-6.8V335.8c0-3.8,3.4-6.8,7.7-6.8	c2.1,0,4,0.8,5.4,2C230,332.2,230.9,333.9,230.9,335.8z"/>
		<path className="st0 Right_Pillar_C" d="M640.1,548.6l-8.5,14.2c-9.2,0.2-17.5,0.2-24.4,0.1c-11.7-0.1-21.4-8.9-22.7-20.3h7.8	c0,5.2,4,9.5,9.1,9.9l25.5,2.1C634.5,555.3,637.6,552.2,640.1,548.6z"/>
		<path className="st0 Left_Pillar_C" d="M428.4,542.7c-0.3,2.2-0.9,4.3-1.7,6.3c-0.1,0.3-0.3,0.6-0.4,0.8c-0.1,0.3-0.3,0.6-0.5,0.9	c-0.2,0.3-0.3,0.6-0.5,0.9c-0.3,0.5-0.7,1.1-1,1.6c-0.2,0.3-0.4,0.6-0.6,0.8c-0.2,0.3-0.4,0.5-0.6,0.8c-0.2,0.3-0.5,0.5-0.7,0.8	c-0.2,0.3-0.5,0.5-0.7,0.7c-0.3,0.3-0.5,0.5-0.8,0.8c-4,3.6-9.3,5.8-15.2,5.9c-6.2,0-13.5,0-21.6-0.1c-1.6-1.7-8.3-10.3-10-12.5	c0.6,0.8,1.4,1.5,2.2,2.1c1.4,1,3,1.7,5.2,2.1c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0c1.1,0.1,2.4,0.1,3.9,0l25.5-2.1	c0.6,0,1.3-0.2,1.9-0.3c0.1,0,0.3-0.1,0.5-0.1c2.8-0.9,5-3,6.1-5.7c0,0,0,0,0,0c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.1-0.3,0.1-0.4	c0.1-0.2,0.1-0.5,0.2-0.7c0.1-0.2,0.1-0.5,0.1-0.7c0-0.2,0.1-0.5,0.1-0.7c0-0.2,0-0.5,0-0.7H428.4z"/>
		<path className="st0 Right_Pillar_B" d="M648.6,454.8l-0.2,10.4l-0.1,8.7l-5.5,0.4l-46,1.8l-4.6,0.4v-5c0,0,0.2-3,0.5-7.7l4.8-0.9l45.5-7.2	L648.6,454.8z"/>
		<path className="st0 Left_Pillar_B" d="M420.6,471.5v4.5H416l-46-1.8h-5.5l-0.1-9.1l-0.2-10.4l5.6,0.9l45.5,7.2l4.8,0.8	C420.4,468.5,420.6,471.5,420.6,471.5z"/>
		<path className="st0 Right_Pillar_A" d="M650.2,337.6v7.5c0,0-6.9-0.1-10.1,2.9c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.3-0.4,0.5	c-0.1,0.1-0.3,0.3-0.5,0.5c-0.2,0.2-0.4,0.5-0.6,0.7c-0.1,0.1-0.2,0.2-0.3,0.4c-0.2,0.2-0.4,0.5-0.6,0.7	c-6.1,7.6-21.6,28.3-31.6,50.1c-0.4,0.7-0.7,1.5-1,2.2c-0.3,0.8-0.7,1.5-1,2.3c-0.3,0.8-0.6,1.5-0.9,2.3c-0.4,0.9-0.7,1.8-1,2.7	c-0.3,0.9-0.7,1.8-1,2.7c-0.2,0.5-0.3,0.9-0.5,1.4c-0.7,2-1.3,4-1.8,6l-7.9-1.8c0.3-1.1,0.6-2.3,0.9-3.3c0.1-0.3,0.2-0.5,0.2-0.8	c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.1,0-0.2s0-0.1,0-0.2c0.3-1,0.7-2.2,1-3.5c0-0.1,0-0.2,0.1-0.3c0,0,0,0,0,0c0.1-0.3,0.2-0.6,0.3-0.9	c0.4-1.1,0.7-2.2,1.1-3.4c0.3-0.6,0.5-1.3,0.7-1.9c0.4-1.2,0.9-2.5,1.3-3.8c0.3-0.8,0.6-1.6,0.9-2.4c0.2-0.6,0.5-1.3,0.7-1.9	c0.5-1.4,1.1-2.9,1.6-4.3c0.2-0.5,0.4-1.1,0.6-1.6c0.3-0.7,0.5-1.4,0.8-2.1c0.1-0.2,0.2-0.5,0.3-0.7c0.1-0.3,0.2-0.6,0.4-0.9l0,0	c0,0,0,0,0-0.1c0-0.1,0.1-0.1,0.1-0.2c0.3-0.6,0.6-1.3,0.8-1.9c0.2-0.4,0.4-0.9,0.5-1.3c0.3-0.8,0.6-1.5,0.9-2.2	c0.3-0.7,0.6-1.4,0.8-2c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.5,0.3-0.7c0.2-0.3,0.3-0.6,0.4-0.9v-0.1	c0.1-0.3,0.2-0.5,0.3-0.8c0-0.1,0-0.1,0.1-0.2c0.1-0.2,0.1-0.3,0.2-0.5c0,0,0-0.1,0-0.1c0.1-0.2,0.2-0.4,0.2-0.5c0,0,0,0,0-0.1	c0-0.1,0.1-0.2,0.1-0.3c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.3c0,0,34.6-57.5,38.1-65	c0.5-1.2,0.9-2.3,1.1-3.3h2.1v32.8C649,337.7,649.6,337.7,650.2,337.6z"/>
		<path className="st0 Left_Pillar_A" d="M420.5,413.1c0.1,0.4,0.2,0.7,0.3,1.1l-7.3,3.9c-0.4-1.5-0.9-2.9-1.4-4.4c-0.5-1.4-1-2.8-1.5-4.2	c-10.9-28.3-34-57.4-37.4-61.1c-0.1-0.1-0.1-0.1-0.2-0.2c-3.1-3.2-10.4-3.1-10.4-3.1v-7.6c1.3,0.1,2.6,0.3,3.9,0.5v-26h3	c10.6,19.4,24.3,39.8,36.2,61.2v0.1c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.2,0.5c0.1,0.2,0.2,0.5,0.4,0.9c0.1,0.3,0.2,0.5,0.3,0.8	c0.1,0.2,0.2,0.5,0.3,0.8v0.1c0.1,0.3,0.2,0.6,0.4,0.9c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.2,0.4,0.2,0.5c0.1,0.1,0.1,0.2,0.2,0.4	c0.1,0.2,0.2,0.4,0.2,0.6c0.1,0.3,0.3,0.7,0.4,1c0.3,0.7,0.6,1.4,0.9,2.2c0,0.1,0,0.1,0.1,0.2c0.1,0.3,0.2,0.6,0.4,0.9	c0,0,0,0.1,0.1,0.1c0.3,0.6,0.5,1.2,0.8,1.9c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.3,0.2,0.6,0.3,0.8c0.4,0.9,0.7,1.9,1.1,2.8	c0.2,0.5,0.4,1.1,0.6,1.6c1.8,4.7,3.7,9.7,5.2,14.2c0.4,1.2,0.7,2.3,1.1,3.4c0.5,1.7,1,3.2,1.4,4.7	C420.7,413.8,420.6,413.4,420.5,413.1z"/>
		<path className="st0 Right_Side_View_Mirror" d="M649.7,379l-0.1,6.6c0,0-2,0.2-4.8,0.5c-5.1,0.7-13.1,1.9-17.2,3.8v-16.8c1.5-2.3,2.9-4.4,4.3-6.3	c3-0.5,7.1-0.5,10.7,1.6C645.5,370.2,648.2,373.4,649.7,379z"/>
		<path className="st0 Left_Side_View_Mirror" d="M385.2,373.2V390c-4.2-1.9-12.1-3.2-17.2-3.8c-2.8-0.4-4.8-0.5-4.8-0.5l-0.1-6.6	c1.5-5.6,4.2-8.8,7.1-10.5c3.6-2.1,7.7-2.1,10.7-1.6C382.3,368.8,383.7,370.9,385.2,373.2z"/>
		<path className="st0 Right_Running_Board" d="M768.7,319l-2.8,312.2H765c-5,0-9.9,0.5-14.7,1.4v-34.2c0,0,0.1,0,0.1,0c0.3,0,0.6-0.1,0.9-0.2v-281	c5,1.1,10.2,1.8,15.5,1.8H768.7z"/>
		<path className="st0 Left_Running_Board" d="M262.5,598.4v34.2c-4.7-0.9-9.6-1.4-14.7-1.4h-0.9L244.1,319h1.9c5.3,0,10.5-0.6,15.5-1.8v281	C261.9,598.2,262.2,598.3,262.5,598.4C262.5,598.4,262.5,598.4,262.5,598.4z"/>
		<path className="st0 Right_Fender_Extender" d="M751.3,317.2v26.1c-3.8-0.8-7.7-1.7-11.6-2.9c-27.1-7.9-54.9-25.2-64.8-57.4c-11.3-36.8-0.8-65.9,11.3-87.7	c3.7-6.6,6.4-14.8,8.4-23c0,0,11.7,10.8,11.7,20.7s0,25.2,0,25.2c-5.1,9.6-8,20.5-8,32.1C698.2,282.9,720.9,310.2,751.3,317.2z"/>
		<path className="st0 Left_Fender_Extender" d="M338,283.1c-9,29.3-32.8,46.3-57.5,55c-2.5,0.9-4.9,1.7-7.3,2.4c-3.9,1.1-7.8,2.1-11.6,2.9v-26.1	c30.4-7,53.1-34.3,53.1-66.8c0-11.6-2.9-22.6-8-32.1c0,0,0-15.2,0-25.2c0-9.5,10.8-19.8,11.6-20.6c0.6,2.4,1.2,4.7,1.9,7	c1.7,5.7,3.8,11.1,6.4,15.8c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1C338.7,217.2,349.3,246.3,338,283.1z"/>
		<path className="st0 Extra71" d="M326.8,195.4C326.7,195.4,326.7,195.4,326.8,195.4c-2.6-4.6-4.7-10.1-6.5-15.8c-0.7-2.3-1.3-4.7-1.9-7	c0,0,0.1-0.1,0.1-0.1c0.2,0.7,0.3,1.3,0.5,2c0.1,0.6,0.3,1.2,0.5,1.7c0,0.2,0.1,0.4,0.2,0.7C321.4,183.5,323.8,190,326.8,195.4z"/>
		<path className="st3 Right_Indicator" d="M663.5,313.7v9c0,3-2.4,5.4-5.4,5.4s-5.4-2.4-5.4-5.4v-9c0-3,2.4-5.4,5.4-5.4c1.5,0,2.8,0.6,3.8,1.6	C662.9,310.8,663.5,312.2,663.5,313.7z"/>
		<path className="st3 Left_Indicator" d="M358.7,313.7v9c0,3-2.4,5.4-5.4,5.4s-5.4-2.4-5.4-5.4v-9c0-3,2.4-5.4,5.4-5.4c1.5,0,2.9,0.6,3.8,1.6	C358.1,310.8,358.7,312.2,358.7,313.7z"/>
		<rect x="477.4" y="940.9" className="st0 License_Plate" width="56.3" height="12.6"/>
		<path className="st0 Right_Front_Bumper" d="M760.4,133.8c-24.3,0-24.5,15.5-24.5,19.8c0,4.3,0,0.9,0,6.6c0,2,0,3.6-0.1,4.8	c0,0.2-0.1,0.4-0.1,0.5c0,0,0,0,0,0c0,0.2-0.1,0.4-0.1,0.5c-0.1,0.5-0.4,0.9-0.7,1.2c0,0.1-0.1,0.1-0.2,0.2s-0.1,0.1-0.2,0.2	c0,0,0,0,0,0c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0.1-0.4,0.1-0.6,0.1	c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0c-0.2,0-0.4,0-0.6,0c-5.3,0-12.9-18.7-12.9-27.7s0-12.9,0-12.9	c-9,0-16.8,0.9-23.5,2.4c0,0,4.6,20.7,0,42.1c0,0,11.7,10.8,11.7,20.7s0,25.2,0,25.2c11.5-21.7,34.3-36.4,60.6-36.4	c3.6,0,7.2,0.3,10.6,0.8l0.9-30.7C778.4,151.9,769,142.1,760.4,133.8z"/>
		<path className="st0 Left_Front_Bumper" d="M323.2,131.5c0,0-5.9,18.9-4.7,41c0,0,0,0-0.1,0.1c0,0,0-0.1,0-0.1c0,0.1-0.1-0.1-0.1-0.1	s-11.7,10.8-11.7,20.7c0,10,0,25.2,0,25.2c-11.5-21.6-34.3-36.4-60.6-36.4c-3.6,0-7.2,0.3-10.6,0.8l-0.9-30.7c0,0,9.4-9.8,18-18.1	c24.3,0,24.5,15.5,24.5,19.8c0,4.3,0,0.9,0,6.6s-0.4,8.2,4.9,8.2c5.3,0,12.9-18.7,12.9-27.7s0-12.9,0-12.9	C306.1,127.8,315.6,129.2,323.2,131.5z"/>
		<path className="st0 Right_Fender" d="M739.7,340.4c-27.1-7.9-54.9-25.2-64.8-57.4c-11.3-36.8-0.8-65.9,11.3-87.7	c3.7-6.6,6.4-14.8,8.4-23c4.6-21.4,0-42.1,0-42.1c-6.5,1.5-11.9,3.4-16.1,5.7c-6,3.2-9.8,6.9-11.2,10.3	c-18.1,59.3-25.4,144.5-25.4,144.5c0.6,0.5,1.9,2.2,2.9,4.7c0,0.1,0.1,0.2,0.1,0.3c0,0,2.7,3.6,1.4,9.3h2.1v32.8	c0.6-0.1,1.2-0.1,1.8-0.2C684.5,334,724.5,338.5,739.7,340.4c3.8,1.2,7.7,2.2,11.5,2.9v119.8c0.1,0,0.1,0,0.1,0V343.3	C747.5,342.5,743.6,341.6,739.7,340.4z M663.5,322.7c0,3-2.4,5.4-5.4,5.4s-5.4-2.4-5.4-5.4v-9c0-3,2.4-5.4,5.4-5.4	c1.5,0,2.8,0.6,3.8,1.6c1,1,1.6,2.3,1.6,3.8V322.7z"/>
		<path className="st0 Left_Fender" d="M370.9,290.7c0,0-7.3-85.2-25.4-144.5c-1.4-3.4-5.2-7.1-11.2-10.3c-3.1-1.7-6.8-3.2-11.2-4.5	c0,0-5.9,18.9-4.7,41c0.2,0.7,0.3,1.3,0.5,2c0.1,0.5,0.3,1.1,0.5,1.7c0,0.2,0.1,0.4,0.2,0.7c1.8,6.6,4.2,13.1,7.2,18.4	c0,0,0,0.1,0.1,0.1c11.9,21.7,22.5,50.8,11.2,87.6c-9,29.3-32.8,46.3-57.5,55c-2.1,0.9-4.3,1.6-6.6,2.3c11.3-1.5,35.2-4.2,60.4-4.2	h5.3c1.3,0,2.6,0.1,3.9,0.1c1.3,0,2.5,0,3.8,0.1c1.6,0,3.1,0.1,4.7,0.2c1.1,0,2.3,0.1,3.5,0.2c1,0,2,0.1,3,0.2	c0.6,0.1,1.2,0.1,1.8,0.2c0.7,0,1.5,0.1,2.2,0.2v0.1c1.3,0.1,2.6,0.3,3.9,0.5v-25.9h3c-1.4-2.5-2.2-4.9-2.5-7.1	c-0.1-0.1-0.1-0.1-0.2-0.2c-0.5-3.7,0.2-6.9,1.2-9.4C369,292.9,370.3,291.2,370.9,290.7z M358.7,322.7c0,3-2.4,5.4-5.4,5.4	s-5.4-2.4-5.4-5.4v-9c0-3,2.4-5.4,5.4-5.4c1.5,0,2.9,0.6,3.8,1.6c1,1,1.6,2.3,1.6,3.8V322.7z"/>
		<path className="st0 Right_Rear_Tyre" d="M719.3,697.6c0,31.3,24.9,56.6,55.5,56.6c30.7,0,55.5-25.4,55.5-56.6c0-31.3-24.9-56.6-55.5-56.6	C744.1,640.9,719.3,666.3,719.3,697.6z M736,697.6c0-21.8,17.4-39.5,38.8-39.5c21.4,0,38.8,17.7,38.8,39.5s-17.4,39.5-38.8,39.5	S736,719.4,736,697.6z"/>
		<path className="st0 Left_Rear_Tyre" d="M238,640.9c-30.7,0-55.5,25.4-55.5,56.6c0,31.3,24.9,56.6,55.5,56.6c30.7,0,55.5-25.4,55.5-56.6	C293.5,666.3,268.7,640.9,238,640.9z M238,737.1c-21.4,0-38.8-17.7-38.8-39.5s17.4-39.5,38.8-39.5s38.8,17.7,38.8,39.5	C276.8,719.4,259.4,737.1,238,737.1z"/>
		<path className="st0 Right_Front_Tyre" d="M719.3,250.8c0,31.3,24.9,56.6,55.5,56.6c30.7,0,55.5-25.4,55.5-56.6c0-31.3-24.9-56.6-55.5-56.6	C744.1,194.1,719.3,219.5,719.3,250.8z M736,250.8c0-21.8,17.4-39.5,38.8-39.5c21.4,0,38.8,17.7,38.8,39.5s-17.4,39.5-38.8,39.5	S736,272.6,736,250.8z"/>
		<path className="st0 Left_Front_Tyre" d="M238,194.1c-30.7,0-55.5,25.4-55.5,56.6c0,31.3,24.9,56.6,55.5,56.6c30.7,0,55.5-25.4,55.5-56.6	S268.7,194.1,238,194.1z M238,290.3c-21.4,0-38.8-17.7-38.8-39.5s17.4-39.5,38.8-39.5s38.8,17.7,38.8,39.5S259.4,290.3,238,290.3z"	/>
		<g id="_x38_">
			<ellipse className="st2 Front_PDC_Sensor" cx="419" cy="46.1" rx="5.1" ry="3"/>
			<ellipse className="st2 Front_PDC_Sensor" cx="506.4" cy="46.1" rx="5.1" ry="3"/>
			<ellipse className="st2 Front_PDC_Sensor" cx="597" cy="46.1" rx="5.1" ry="3"/>
		</g>
		<rect x="468.1" y="71" className="st0 License_Plate" width="76.6" height="12.2"/>
		<path className="st0 Bumper_Grill_Bottom" d="M570.3,63.4H442.5l2.7-8.3c0.7-2,2.6-3.4,4.7-3.4h113c2.2,0,4.1,1.4,4.7,3.4L570.3,63.4z"/>
		<path className="st0 Bumper_Grill_Top" d="M579.9,106.4c-1.8,2-7.7,8.4-12.3,11.4s-14.3,7.7-22.5,7.7s-38.8,0-38.8,0s-30.6,0-38.8,0	s-17.9-4.7-22.5-7.7s-10.5-9.3-12.3-11.4c-1.8-2-0.7-6.5,3.3-7.9c13.6-3.7,39.9-8.6,54-8.6c14,0,16.3,0,16.3,0s2.2,0,16.3,0	c14,0,40.3,4.9,54,8.6C580.6,99.9,581.7,104.4,579.9,106.4z"/>
		<rect x="428.5" y="18.9" className="st0 Front_Bumper_Cover" width="155.9" height="13.1"/>
		<path className="st0 Front_Glass" d="M614.6,305c0,0-17.7,44.7-28.3,106.5c-0.4-0.1-9.1-1.5-22.4-3c-0.4,0-0.9-0.1-1.4-0.1c-1.8-0.2-3.7-0.4-5.7-0.6	c-1.1-0.1-2.2-0.2-3.4-0.3c-1.7-0.2-3.5-0.3-5.3-0.5c-1.8-0.2-3.7-0.3-5.5-0.5c-0.6,0-1.1-0.1-1.7-0.1c-1.1-0.1-2.3-0.2-3.4-0.2	c-1.1-0.1-2.3-0.1-3.4-0.2c-8.8-0.5-18.2-0.9-27.7-0.9c-7.6,0-15.2,0.2-22.4,0.6c-0.9,0-1.9,0.1-2.8,0.1c-0.5,0-1,0.1-1.4,0.1	c-0.9,0-1.8,0.1-2.7,0.2c-2.7,0.2-5.4,0.4-8,0.6c-0.9,0.1-1.7,0.1-2.6,0.2c-0.4,0-0.7,0.1-1.1,0.1c-0.8,0.1-1.7,0.1-2.5,0.2	c-0.1,0-0.1,0-0.2,0c-1.2,0.1-2.5,0.2-3.6,0.3c-4,0.4-7.7,0.8-11.1,1.2c-0.7,0.1-1.3,0.2-2,0.2c-0.2,0-0.5,0.1-0.7,0.1	c-0.4,0.1-0.8,0.1-1.2,0.2c-0.2,0-0.4,0.1-0.6,0.1c-0.4,0.1-0.8,0.1-1.2,0.2c-0.5,0.1-1,0.1-1.5,0.2c-1,0.1-1.9,0.3-2.8,0.4	c0,0,0,0-0.1,0c-1.7,0.2-3.3,0.5-4.7,0.6c0,0-0.1,0-0.1,0c-3.5,0.5-5.8,0.9-6.5,1c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0	c-0.1-0.4-0.1-0.8-0.2-1.1C415.7,349.2,398.2,305,398.2,305s44.2-10.9,108.2-10.9S614.6,305,614.6,305z"/>
		<path className="st0 Left_Pillar_C" d="M434.4,566.4c0,12.6-11.3,17.4-40,18.1h-1.9v0c-2.1,0-4.4,0.1-6.7,0.1c-5.2,0-11.3-0.3-18-0.7	c-0.5,0-1-0.1-1.6-0.1c-0.5,0-1.1-0.1-1.6-0.1c-2.1-0.2-4.3-0.3-6.5-0.5c-1.1-0.1-2.2-0.2-3.3-0.3c-2-0.2-4.1-0.4-6.1-0.6	c-1.3-0.1-2.6-0.3-3.9-0.4c-0.8-0.1-1.5-0.2-2.3-0.2c-4.3-0.5-8.6-1-12.9-1.5c-30.2-3.7-59.9-8.9-68.2-11.8v-16.8	c0,0,20.9,5.7,72.9,9.4c18.2,1.3,35.2,1.9,49.6,2.1c8.1,0.1,15.4,0.1,21.6,0.1c5.9-0.1,11.2-2.3,15.3-6c0.3-0.2,0.5-0.5,0.8-0.8	c0.3-0.2,0.5-0.5,0.7-0.7c0.3-0.3,0.5-0.5,0.7-0.8c0.2-0.3,0.4-0.5,0.6-0.8c0.2-0.3,0.4-0.5,0.6-0.8c0.4-0.5,0.7-1.1,1-1.6	c0.2-0.3,0.3-0.6,0.5-0.9c0.2-0.3,0.3-0.6,0.5-0.9c0.1-0.3,0.3-0.6,0.4-0.8c0.9-2,1.4-4.1,1.7-6.3h5.8	C434.3,551.7,434.4,559.8,434.4,566.4z"/>
		<path className="st0 Extra89" d="M394.4,584.5c-0.6,0-1.2,0-1.9,0v0H394.4z"/>
		<path className="st0 Left_Side_Roof" d="M434.2,542.7h-5.8c-0.3,2.2-0.9,4.3-1.7,6.3c0.8-2,1.4-4,1.6-6.2c0,0,0-0.1,0-0.1c0.1-0.9,0.2-1.7,0.2-2.7v-37	c0-11.6,1.1-34.9-1.8-59c-0.1-0.8-0.2-1.6-0.3-2.3c-0.1-0.6-0.2-1.2-0.2-1.8c-0.1-0.8-0.2-1.7-0.4-2.6c-0.9-6.2-2.2-12.4-3.8-18.4	c-0.3-1.1-0.6-2.2-0.9-3.4c-0.1-0.5-0.2-0.9-0.4-1.4c-0.4-1.5-0.9-3-1.4-4.7c-0.4-1.1-0.7-2.2-1.1-3.4c-1.5-4.5-3.4-9.5-5.2-14.2	c-0.2-0.5-0.4-1.1-0.6-1.6c-0.4-0.9-0.7-1.9-1.1-2.8c-0.1-0.2-0.2-0.5-0.3-0.8c-0.1-0.1-0.1-0.3-0.2-0.4c-0.3-0.7-0.5-1.3-0.8-1.9	c0,0,0-0.1-0.1-0.1c-0.1-0.3-0.3-0.6-0.4-0.9c0-0.1-0.1-0.2-0.1-0.3c-0.3-0.8-0.6-1.5-0.9-2.2c-0.1-0.3-0.3-0.6-0.4-0.9	c-0.1-0.2-0.2-0.4-0.2-0.6c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.1-0.4-0.2-0.5c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.3-0.2-0.6-0.4-0.9	v-0.1c-0.1-0.3-0.2-0.5-0.3-0.8c-0.1-0.3-0.2-0.6-0.3-0.8c-0.1-0.3-0.3-0.6-0.4-0.9c-0.1-0.2-0.1-0.3-0.2-0.5c0-0.1-0.1-0.2-0.1-0.3	c7.8,14,14.8,28.5,19.6,43.5c0.2,0.6,0.4,1.3,0.6,1.9c0.6,2.1,1.2,4.2,1.8,6.3c0.2,0.7,0.4,1.4,0.5,2.1c0.3,1.4,0.7,2.8,1,4.2	c0.2,0.7,0.3,1.4,0.4,2.1c0.1,0.7,0.3,1.4,0.4,2.1c0.1,0.4,0.2,0.9,0.2,1.3c0,0.1,0,0.3,0.1,0.4c0,0,0,0,0,0.1c0,0,0,0,0,0	c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2v0.1c0,0.2,0,0.3,0.1,0.5c0.5,3.4,0.8,6.9,1.2,10.3c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1	c0,0,0,0,0,0.1v0.2c0,0.3,0,0.5,0,0.8c0,0.1,0,0.3,0,0.4c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1v0.1C433.1,478.1,433.8,514.7,434.2,542.7z	"/>
		<path className="st0 Front_Bumper_Protection_Strip" d="M294.7,127.8c0,0,0,3.9,0,12.9s-7.6,27.7-12.9,27.7c-5.3,0-4.9-2.5-4.9-8.2s0-2.3,0-6.6	c0-4.3-0.2-19.8-24.5-19.8c6.3-6.2,12.3-11.5,13.6-11.5h22.4C293.2,122.3,294.7,127.8,294.7,127.8z"/>
		<path className="st0 Left_Rear_Bumper" d="M285.4,846.8l-0.1,6l-0.2,9.3c-0.1,3.2-2.7,5.8-5.9,5.8H263c-3,0-5.6-2.1-6.4-5l-3.8-15.7	c-0.9-3.9,1.5-7.7,5.2-8.5c0.5-0.1,1.1-0.2,1.6-0.2H277C281.8,838.4,285.5,842.2,285.4,846.8z"/>
		<path className="st0 Extra93" d="M425.3,416.8c-4.8-15-11.8-29.5-19.6-43.5C413.6,387.3,420.6,401.8,425.3,416.8z"/>
		<path className="st0 Extra94" d="M430.3,437.4C430.3,437.4,430.3,437.4,430.3,437.4c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1	c-1.1-8.8-2.4-17.3-3.8-25.7c0,0,0,0,0.1,0c-0.1-0.4-0.1-0.8-0.2-1.1c0.1,0.4,0.3,0.7,0.4,1c0,0,0,0,0,0c0,0,0,0,0,0	c1.2,8.5,2.5,17.2,3.6,26.1C430.3,437.5,430.3,437.5,430.3,437.4z"/>
		<path className="st0 Left_Headlight" d="M440.8,129.6l-27.3,5.7c0,0-18-0.7-21.4-1.4s-3.4-3.3-3.4-7c0-2.4,3.3-7.1,9.6-10.2c3.4-1.7,7.8-2.8,13-2.8	c10.3,0,23.3,7,30.2,11.3C443.5,126.3,443,129.2,440.8,129.6z"/>
		<path className="st0 Left_Side_View_Mirror" d="M370.2,368.5c-3,1.7-5.6,4.9-7.1,10.5v-2.6l-8.2-9.1c-1.1-1.2-1-3.1,0.2-4.3l1.7-1.7c1.1-1.1,2.8-1.2,4-0.2	l2.1,1.6l4.7,3.7L370.2,368.5z"/>
		<path className="st0 Extra97" d="M369.8,455.7l-5.6-0.9l-1-69.2c0,0,2,0.2,4.8,0.5L369.8,455.7z"/>
		<path className="st0 Extra98" d="M420.1,463.8L420.1,463.8l-4.8-0.8v-0.2c0-4.9-0.2-9.7-0.7-14.5c-2.1-19.4-9-38.2-20.7-60.2	c-1.1-2.1-2.3-4.2-3.4-6.1c-0.9-1.6-1.8-3.2-2.7-4.6c-0.9-1.5-1.8-2.8-2.6-4.1c-1.5-2.3-2.9-4.4-4.3-6.3c0,0,0-0.1-0.1-0.1	c-8-11-13.2-14.9-13.2-14.9v14.6l-4.7-3.7v-0.2l-0.3-17.5c0,0,7.3-0.1,10.4,3.1c0,0,0.1,0.1,0.2,0.2c3.5,3.9,28.8,35.8,38.9,65.2	c0.5,1.5,1,3,1.4,4.4c0,0,0,0.1,0,0.1c0.3,0.9,0.5,1.6,0.7,2.5c3.3,13.4,5.1,32.4,5.9,42.9C420.1,463.7,420.1,463.7,420.1,463.8z"/>
		<path className="st0 Extra99" d="M420.6,476.1v66.6c0,5.2-4,9.5-9.1,9.9l-25.5,2.1c-6.4,0.5-9.6-1.5-11.9-4.3c-0.2-0.3-0.4-0.5-0.4-0.5	c-0.5-0.7-1-1.4-1.5-2.2c-2.5-4-5.8-14-6.9-20.6l-0.8-52.8h5.5c0,0,0,44.3,0,53.3c0,3.8,3.9,13.5,6.6,17.9s6.4,4.3,15.6,4	c6.4-0.2,15.3-1.5,20.2-2.3c2.1-0.3,3.6-2.1,3.6-4.2v-66.9L420.6,476.1L420.6,476.1z"/>
		<path className="st0 Right_Taillight" d="M624.4,872c-4.5,28.2-19.9,52.1-19.9,52.1V872H624.4z"/>
		<path className="st0 Right_Pillar_C" d="M751.3,551.4v16.8c-12,4.2-68.2,13.1-106.3,15.6c-6.7,0.5-12.8,0.7-18,0.7c-35.1,0-48.6-4.4-48.6-18.2	c0-6.6,0.1-14.7,0.2-23.7h5.8c0,0.1,0,0.1,0,0.2c1.4,11.4,11,20.2,22.7,20.3c6.9,0.1,15.2,0,24.4-0.1c13.8-0.3,29.8-0.8,46.9-2	c26.9-2,45.5-4.5,57.1-6.5c0.8-0.1,1.5-0.3,2.2-0.4c0.4-0.1,0.7-0.1,1.1-0.2c0.8-0.1,1.5-0.3,2.2-0.4c0.3,0,0.6-0.1,0.8-0.2	c0.4-0.1,0.8-0.2,1.2-0.2s0.7-0.1,1.1-0.2c0.2,0,0.4-0.1,0.5-0.1c0.5-0.1,1-0.2,1.4-0.3c1.2-0.2,2.1-0.5,2.9-0.6	c0.3-0.1,0.5-0.1,0.7-0.2c0.3-0.1,0.6-0.1,0.8-0.2c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.1,0,0.2,0C751.3,551.4,751.3,551.4,751.3,551.4z"	/>
		<path className="st0 Right_Side_Roof" d="M607.1,373.3c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.4c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.2-0.1,0.3	c0,0,0,0,0,0.1c-0.1,0.1-0.1,0.3-0.2,0.5c0,0,0,0.1,0,0.1c-0.1,0.2-0.1,0.3-0.2,0.5c0,0.1,0,0.1-0.1,0.2c-0.1,0.3-0.2,0.5-0.3,0.8	v0.1c-0.1,0.3-0.2,0.6-0.4,0.9c-0.1,0.2-0.2,0.4-0.3,0.7c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c-0.3,0.6-0.5,1.3-0.8,2	s-0.6,1.4-0.9,2.2c-0.2,0.4-0.4,0.9-0.5,1.3c-0.2,0.6-0.5,1.3-0.8,1.9c0,0-0.1,0.1-0.1,0.2c0,0,0,0,0,0.1l0,0c0,0,0,0.1-0.1,0.1	c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.2-0.2,0.5-0.3,0.7c-0.3,0.7-0.5,1.4-0.8,2.1c-0.2,0.5-0.4,1.1-0.6,1.6c-0.5,1.4-1.1,2.8-1.6,4.3	c-0.2,0.6-0.5,1.3-0.7,1.9c-0.3,0.8-0.6,1.6-0.9,2.4c-0.5,1.3-0.9,2.5-1.3,3.8c-0.2,0.6-0.4,1.3-0.7,1.9c-0.4,1.2-0.7,2.3-1.1,3.4	c-0.1,0.3-0.2,0.6-0.3,0.9c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.3c-0.4,1.2-0.7,2.4-1,3.5c0,0.1,0,0.1,0,0.2s0,0.1,0,0.2	c0,0.1,0,0.2-0.1,0.3c-0.1,0.3-0.2,0.5-0.2,0.8c-0.3,1.1-0.6,2.3-0.9,3.3c0,0,0,0,0,0.1c-8.4,31.4-6.4,68.5-6.4,84.1V540	c0,0.8,0.1,1.8,0.2,2.6h-5.8c0.4-28,1.1-64.7,2.5-92.2c0-0.5,0.1-1,0.1-1.5c0,0,0-0.1,0-0.2c0.4-3.7,0.8-7.4,1.3-11v-0.1	c0.2-1.4,0.5-2.7,0.7-4.1C587.3,412.5,596.5,392.5,607.1,373.3z"/>
		<path className="st0 Front_Bumper_Protection_Strip" d="M735.9,153.6c0,4.3,0,0.9,0,6.6c0,2,0,3.6-0.1,4.8c0,0.2-0.1,0.4-0.1,0.5c0,0,0,0,0,0c0,0.2-0.1,0.4-0.1,0.5	c-0.1,0.5-0.4,0.9-0.7,1.2c0,0.1-0.1,0.1-0.2,0.2s-0.1,0.1-0.2,0.2c0,0,0,0,0,0c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.1-0.3,0.2	c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.4,0.1-0.6,0.1c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0c-0.2,0-0.4,0-0.6,0	c-5.3,0-12.9-18.7-12.9-27.7s0-12.9,0-12.9s1.5-5.5,6.3-5.5h22.4c1.3,0,7.3,5.3,13.6,11.5C736.1,133.8,735.9,149.3,735.9,153.6z"/>
		<path className="st0 Right_Rear_Bumper" d="M727.4,846.8l0.1,6l0.2,9.3c0.1,3.2,2.7,5.8,5.9,5.8h16.2c3,0,5.6-2.1,6.4-5l3.8-15.7c0.9-3.9-1.5-7.7-5.2-8.5	c-0.5-0.1-1.1-0.2-1.6-0.2h-17.4C731,838.4,727.3,842.2,727.4,846.8z"/>
		<path className="st0 Extra105" d="M607.1,373.3c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.4c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.2-0.1,0.3	c0,0,0,0,0,0.1c-0.1,0.1-0.1,0.3-0.2,0.5c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c-11,19.9-20.1,40.7-23.6,62.5c0.2-1.4,0.3-2.8,0.5-4.2	C587.3,412.5,596.5,392.5,607.1,373.3z"/>
		<path className="st0 Extra106" d="M646.4,305c-0.2,1-0.6,2.1-1.1,3.3c-3.5,7.5-38.1,65-38.1,65c-10.6,19.3-19.8,39.2-23.9,60.2	c1-7.5,2.1-14.8,3.4-22c12.9-34.2,27.3-62.9,38.6-83.2c10.5-19,18.3-30.6,19.7-32.6C644.9,295.7,647.6,299.3,646.4,305z"/>
		<path className="st0 Right_Headlight" d="M624.1,126.9c0,3.7,0,6.3-3.4,7s-21.4,1.4-21.4,1.4l-27.3-5.7c-2.2-0.5-2.7-3.3-0.8-4.4	c7-4.3,19.9-11.3,30.2-11.3c5.2,0,9.6,1.2,13,2.8C620.8,119.8,624.1,124.5,624.1,126.9z"/>
		<path className="st0 Right_Side_View_Mirror" d="M657.9,367.3l-8.2,9.1v2.6c-1.5-5.6-4.2-8.8-7.1-10.5l2.6-2l4.7-3.7l2.1-1.6c1.2-1,2.9-0.9,4,0.2l1.7,1.7	C659,364.2,659,366.1,657.9,367.3z"/>
		<path className="st0 Extra109" d="M650.2,345.1v0.1l-0.3,17.6l-4.7,3.7v-14.6c0,0-5.3,3.9-13.2,14.9c0,0-0.1,0.1-0.1,0.1c-1.4,1.9-2.8,4-4.3,6.3	c-0.9,1.3-1.7,2.7-2.6,4.2c-0.9,1.5-1.8,3-2.7,4.6c-1.1,1.9-2.2,3.9-3.4,6c-13.1,24.6-20,45.1-21.2,67.1c-0.1,2.6-0.2,5.2-0.2,7.8	l-4.8,0.9c0.1-1,0.2-2,0.3-3.2c0.9-10.4,2.5-26.4,5.3-38.5c0.1-0.5,0.2-1,0.4-1.5c0.5-2,1.1-4,1.8-6c0.2-0.5,0.3-0.9,0.5-1.4	c0.3-0.9,0.6-1.8,1-2.7c0.3-0.9,0.7-1.8,1-2.7c0.3-0.8,0.6-1.5,0.9-2.3c0.3-0.8,0.7-1.5,1-2.3c0.3-0.7,0.7-1.5,1-2.2	c10-21.8,25.5-42.5,31.6-50.1c0.2-0.3,0.4-0.5,0.6-0.7c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.3,0.4-0.5,0.6-0.7c0.2-0.2,0.3-0.4,0.5-0.5	c0.2-0.2,0.3-0.4,0.4-0.5c0.1-0.1,0.2-0.2,0.3-0.3C643.4,345,650.2,345.1,650.2,345.1z"/>
		<path className="st0 Extra110" d="M649.6,385.6l-1,69.2l-5.6,1l1.8-69.6C647.7,385.8,649.6,385.6,649.6,385.6z"/>
		<path className="st0 Right_Rear_Window_Glass" d="M596.8,476.1V543c0,2.1,1.5,3.9,3.6,4.2c4.9,0.8,13.8,2.1,20.2,2.3c9.2,0.3,12.9,0.4,15.6-4	c2.7-4.4,6.6-14.2,6.6-17.9c0-9,0-53.3,0-53.3L596.8,476.1z M596.8,476.1V543c0,2.1,1.5,3.9,3.6,4.2c4.9,0.8,13.8,2.1,20.2,2.3	c9.2,0.3,12.9,0.4,15.6-4c2.7-4.4,6.6-14.2,6.6-17.9c0-9,0-53.3,0-53.3L596.8,476.1z"/>
		<path className="st0 Extra112" d="M648.3,473.9l-0.8,53.2c-1.1,6.6-4.4,16.7-6.9,20.6c-0.2,0.3-0.4,0.6-0.6,0.9c-2.4,3.6-5.6,6.7-13.2,6.1	l-25.5-2.1c-5.2-0.4-9.1-4.7-9.1-9.9v-66.2l4.6-0.4V543c0,2.1,1.5,3.9,3.6,4.2c4.9,0.8,13.8,2.1,20.2,2.3c9.2,0.3,12.9,0.4,15.6-4	c2.7-4.4,6.6-14.2,6.6-17.9c0-9,0-53.3,0-53.3L648.3,473.9z"/>
		<path className="st0 Right_Fog_Light" d="M612,51.7L596.3,55c-5.6,1.2-6.1,9-0.7,10.9l14.4,4.9c2.8,1,5.9-0.6,6.8-3.4l2.4-7.5	C620.7,55.2,616.7,50.8,612,51.7z M607.9,68.2c-4,0-7.1-3.2-7.1-7.1s3.2-7.1,7.1-7.1c3.9,0,7.1,3.2,7.1,7.1S611.8,68.2,607.9,68.2z"	/>
		<path className="st1 Extra114" d="M640.1,548.6"/>
		<path className="st0 Left_Fog_Light" d="M416.5,55l-15.7-3.3c-4.7-1-8.7,3.5-7.2,8l2.4,7.5c0.9,2.9,4,4.4,6.8,3.4l14.4-4.9	C422.6,64,422.1,56.2,416.5,55z M404.9,68.2c-3.9,0-7.1-3.2-7.1-7.1s3.2-7.1,7.1-7.1c4,0,7.1,3.2,7.1,7.1	C412.1,65,408.9,68.2,404.9,68.2z"/>
		<path className="st0 Left_Taillight" d="M408.3,872v52.1c0,0-15.3-23.9-19.9-52.1H408.3z"/>
		<path className="st1 Extra117" x1="428.4" y1="542.7" x2="428.4" y2="542.7"/>
	</svg>
	)
}

export default PickupTruckSVG