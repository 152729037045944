import React, { useContext, useEffect, useRef } from 'react';
import logo from '../img/logo.svg';
import Menu from '../img/hamburger-menu.png';
import SideMenuBar from './SideMenuBar'
import { AppContext } from './context';
import config from '../config/config.json'
import styles from './style/Navbar.module.scss';
import { removeCookies } from './context/utils';
import { useNavigate } from 'react-router-dom';
import DownArrow from '../img/down.png';
import lang from './language/Navbar.json'
import Cookies from 'js-cookie';

const Navbar = () => {

  const navigate = useNavigate();

  const clickRef = useRef()
  const listRef = useRef()
  const profileRef = useRef()
  const profileListRef = useRef()

  const { openMenu , setOpenMenu, navLaunguageOpen, setNavLaunguageOpen, languageList, language, setLanguage, isNavProfileOpen, setIsNavProfileOpen, isMahindraClient } = useContext(AppContext);
  
  const onOpenMenu = () => { 
    setOpenMenu(true)
  }

  const toggleDropdown = () => {
    setNavLaunguageOpen(!navLaunguageOpen);
  }

  const toggleProfileDropdown = () => {
    setIsNavProfileOpen(!isNavProfileOpen);
  }

  const handleSelect = (value) => {
    setLanguage(value)
    Cookies.set('current-language', value, {expires: 0.16})
    setNavLaunguageOpen(false)
  }

  useEffect(()=>{
    const handleClick = (event) => {
      if((clickRef.current && !clickRef.current.contains(event.target)) && (listRef.current && !listRef.current.contains(event.target))){
        setNavLaunguageOpen(false)
      }

      if((profileRef.current && !profileRef.current.contains(event.target)) && (profileListRef.current && !profileListRef.current.contains(event.target))){
        setIsNavProfileOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClick)

    return () => document.removeEventListener("mousedown", handleClick)
  },[])

  return (
    <>
    <nav className={styles.Navbar}>
      <div className={styles.NavbarContainer}>
        <div className={styles.NavbarLeft}>
          <div className={styles.NavbarLogo} >
              <img className={styles.img} src={Menu} onClick={onOpenMenu} alt="menu btn" />
              {openMenu && <div open={true} closeIcon={<></>}  center>
                    <SideMenuBar/>
                </div>}
          </div>
          <div className={styles.CompanyLogo} onClick={()=> {
              if(Cookies.get("isMahindra")) {
                return navigate('/vehicle-listing')
              }
              return navigate('/')
            }}>
            <img src={config["logo"].includes(Cookies.get("master")) ? `/logo/${Cookies.get("master")}.svg` : logo} alt="Company Logo" />
          </div>
          </div>
          <div className={styles.NavbarBtn}>
          <div className={styles.NavbarList}>
              <div className={styles.dropdown}>
                <div
                  className={styles.dropdownHeader}
                  onClick={toggleDropdown}
                  role="button"
                  ref={clickRef}
                >
                  {lang[language][language] || "language"}
                  <img
                    src={DownArrow}
                    style={{
                      transform: navLaunguageOpen
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    }}
                    alt="Down Arrow"
                  />
                </div>

                <ul
                  style={{ display: navLaunguageOpen ? "block" : "none" }}
                  className={styles.DropdownList}
                  ref={listRef}
                >
                  {languageList.map((item, index) => (
                    <li
                      className={styles.DropdownItem}
                      key={index}
                      onClick={() => handleSelect(item)}
                    >
                      {lang[item][language]}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className={styles.ProfileNavbarList}>
              <div className={styles.Profiledropdown}>
                <div
                  className={styles.ProfiledropdownHeader}
                  onClick={toggleProfileDropdown}
                  role="button"
                  ref={profileRef}
                >
                  {lang["Profile"][language]}
                  <img
                    src={DownArrow}
                    style={{
                      transform: isNavProfileOpen
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    }}
                    alt="Down Arrow"
                  />
                </div>

                <ul
                  style={{ display: isNavProfileOpen ? "block" : "none" }}
                  className={styles.ProfileDropdownList}
                  ref={profileListRef}
                >
                  <li>{lang["Client ID:"][language]} {Cookies.get('master')}</li>
                  <li>{lang["Type:"][language]} {Cookies.get('clientType') === "master" ? "Master" : `Agent (${Cookies.get('client')})` }</li>
                  <li style={{cursor:'pointer'}} onClick={()=>{
                    removeCookies()
                    setOpenMenu(false)
                    setIsNavProfileOpen(false)
                    setNavLaunguageOpen(false)
                    navigate('/login')
                  }}>{lang["logout"][language]}</li>
                </ul>
              </div>
            </div>
        </div>
      </div>
    </nav>
    </>
  );
};

export default Navbar;
