import React,{ useContext } from 'react'
import style from './style/SideMenuBar.module.scss'
import Close from '../img/close.svg'
import Home from '../img/home.svg'
import Inspection from '../img/preInspection.svg'
import Claim from '../img/claim.svg'
import Damage from '../img/damage.svg'
import BgRemove from '../img/bgRemove.svg'
import Url from '../img/urlLink.svg'
import { AppContext } from './context'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

const SideMenuBar = () => {

    const { setOpenMenu, module, isMahindraClient} = useContext(AppContext)
    const navigate = useNavigate();

    const moduleList = {"Pre-Inspection": ['/pre-inspection', Inspection, 'Pre-inspection Module View and Download Inspection Results'], "Claim":['/claim', Claim, 'Pre-inspection Module View and Download Inspection Results'],
    "Incremental Damage": ['/incremental-damage', Damage, 'Pre-inspection Module View and Download Inspection Results'] ,"URL Generator":['/url-generator', Url, 'Pre-inspection Module View and Download Inspection Results'], 
    "Background Removal":['/background-removal', BgRemove, 'Pre-inspection Module View and Download Inspection Results']}

    const onCloseMenu = () =>{ 
        setOpenMenu(false)
    }

    return (
    <div className={style.SideMenuBar}>
        <div className={style.SideMenuBarContent}>
            <div className={style.SideMenuBarContainer}>
                <div className={style.SideMenuBarClose}>
                    <img src={Close} onClick={onCloseMenu} alt="Close"/>
                </div>
                <div className={style.SideMenuBtn}>
                    <div onClick={() => {
                        if(Cookies.get("isMahindra")) {
                            setOpenMenu(false)
                            return navigate('/vehicle-listing')
                        }
                        navigate("/")
                        setOpenMenu(false)
                        }}>
                        <img src={Home} alt="" />
                    </div>
                { module.map((item, index) => <div key={index} onClick={() => {navigate(moduleList[item][0])
                    setOpenMenu(false)}}>
                        <img src={moduleList[item][1]} alt="" />
                    </div>)}  
                </div>
            </div>
        </div>
    </div>
  )
}

export default SideMenuBar