import Cookies from 'js-cookie'

export const checkCookies = () => {
  if(Cookies.get('current-language') && Cookies.get('all-languages') && Cookies.get('modules') && Cookies.get('token') && Cookies.get('loggedIn') && Cookies.get('client') && Cookies.get('clientType') && Cookies.get('master')){
    return true
  }
  return false
}

export const checkMahindraCookies = () => {
  if(Cookies.get('current-language') && Cookies.get('all-languages') && Cookies.get('modules') && Cookies.get('token') && Cookies.get('loggedIn') && Cookies.get('client') && Cookies.get('clientType') && Cookies.get('master') && Cookies.get('isMahindra') && Cookies.get("MahindraClientType")){
    return true
  }
  return false
}

export const removeCookies = () => {
  Cookies.remove('current-language')
  Cookies.remove('all-languages')
  Cookies.remove('modules')
  Cookies.remove('token')
  Cookies.remove('loggedIn')
  Cookies.remove('client')
  Cookies.remove('clientType')
  Cookies.remove('master')
  Cookies.remove('isMahindra') 
  Cookies.remove("MahindraTlId") 
  Cookies.remove("MahindraClientType")
  Cookies.remove('MahindraDisplayName')
  Cookies.remove('MahindraRegionId')
  Cookies.remove('MahindraRegionName')
  Cookies.remove('MahindraTlName')
  Cookies.remove('MahindraUserId')
  Cookies.remove('MahindraUserName')
}

//non authenticated request
export const makeRequest = async (URL, DATA) => {

    const res = await fetch(URL, { 
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      body: JSON.stringify(DATA)
    })
    return res
}

//authenticated request
export const makeAuthRequest = async(URL, DATA) => {
  const res = await fetch(URL, { 
    method: 'POST',
    headers: { "Content-Type": "application/json",
               "Authorization": `Bearer ${Cookies.get('token')}` },
    mode: "cors",
    body: JSON.stringify(DATA)
  })
  return res
}

//authenticated request
export const makeAuthFileRequest = async(URL, DATA) => {
  const res = await fetch(URL, { 
    method: 'POST',
    headers: { 
              "Authorization": `Bearer ${Cookies.get('token')}` },
    mode: "cors",
    body: DATA
  })
  return res
}