import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AppContext } from './context'
import { makeAuthRequest } from './context/utils'
import config from '../config/config.json'
import { useNavigate } from 'react-router-dom'
import styles from './style/Dashboard.module.scss'
import profileImg from "../img/profile.png"
import loadingImg from '../img/loading.gif'

let dashboardNo = Cookies.get("MahindraClientType") === "agent" ? 5 : Cookies.get("MahindraClientType") === "region" ? 7 : 4

const Dashboard = () => {
    const {urlLink} = config
    const navigate = useNavigate()
    const [dashboardUrl, setDashboardUrl] = useState("")
    const [loading, setLoading] = useState(false)
    const [dashboardNumber, setDashboardNumber] = useState(Cookies.get("MahindraClientType") === "agent" ? 5 : Cookies.get("MahindraClientType") === "region" ? 7 : 4)

    const { id } = useParams()

    const getDashboard = async(dash) => {
        setDashboardUrl("")
        setLoading(true)
        const res = await makeAuthRequest(`${urlLink}iframe-metabase-url`, { dashboard_number: dash, tl_id: Cookies.get("MahindraClientType") === "agent" ? Cookies.get("MahindraTlId") : "", client_id: Cookies.get("client"), region_id: Cookies.get("MahindraClientType") === "region" ? Cookies.get("MahindraRegionId") : ""})
        const data = await res.json()
        if(res.ok){
            setDashboardUrl(data.url)
            setLoading(false)
        }
    }

    useEffect(()=>{
        if(Cookies.get('isMahindra')){
            getDashboard(dashboardNo)
        }else{
            navigate('/vehicle-listing')
        }
        
    },[])

    return (
        <>
            { loading ? 
            
            <div className={styles.loading} >
                <img src={loadingImg} alt='loading...'/>
            </div>
            : 
            <div>
                <div className={styles.profileContainer}>
                    <div className={styles.profile}>
                        <img src={profileImg} alt="profile"></img>
                        <div>
                            <span>{Cookies.get("MahindraDisplayName")}</span>
                            {Cookies.get("MahindraClientType") === "agent" && <span>({Cookies.get("MahindraTlName") ? Cookies.get("MahindraTlName") : "---"}, {Cookies.get("MahindraLocationName") ? Cookies.get("MahindraLocationName") : "---"})</span>}               
                        </div>
                    </div>
                    <span>Mahindra Testdrive Vehicle Inspection Status</span>
                    <div className={styles.profileFilter}>
                        <label>Filter: </label>
                        {Cookies.get("MahindraClientType") === "agent" ? <select value={dashboardNumber} onChange={(e)=>{
                            setDashboardNumber(parseInt(e.target.value))
                            getDashboard(parseInt(e.target.value))
                        }}>
                            <option value={2}>All time</option>
                            <option value={5}>last 30 days</option>
                        </select>:
                        Cookies.get("MahindraClientType") === "region" ? <select value={dashboardNumber} onChange={(e)=>{
                            setDashboardNumber(parseInt(e.target.value))
                            getDashboard(parseInt(e.target.value))
                        }}>
                            <option value={6}>All time</option>
                            <option value={7}>last 30 days</option>
                        </select>:
                        <select value={dashboardNumber} onChange={(e)=>{
                            setDashboardNumber(parseInt(e.target.value))
                            getDashboard(parseInt(e.target.value))
                        }}>
                            <option value={1}>All time</option>
                            <option value={4}>last 30 days</option>
                        </select>}
                    </div>
                </div>
                <iframe src={dashboardUrl} style={{height: "100vh", width: "100vw"}}>

                </iframe>
            </div>}
        </>
    )
}

export default Dashboard
