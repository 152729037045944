import React, {useEffect} from "react";

const MotorcycleSVG = ({data}) => {

    const setColors = () => {
        for(let i = 0; i < data.length; i++){
          if(data[i].damageSeverityScore <= 0.3 ){
            document.querySelectorAll(`.${data[i].partName.split(":").join("").split("/").join(" ").split(" ").join("_")}`).forEach(item => {
              item.classList.remove(item.classList[0])
              item.classList.add("yellow")
            })
          }
          else if(data[i].damageSeverityScore > 0.3 && data[i].damageSeverityScore <=0.7){
            document.querySelectorAll(`.${data[i].partName.split(":").join("").split("/").join(" ").split(" ").join("_")}`).forEach(item => {
              item.classList.remove(item.classList[0])
              item.classList.add("orange")
            })
          }else{
            document.querySelectorAll(`.${data[i].partName.split(":").join("").split("/").join(" ").split(" ").join("_")}`).forEach(item => {
              item.classList.remove(item.classList[0])
              item.classList.add("red")
            })
          }
        }
    }
      
    
    useEffect(()=>{
    setColors()
    },[])

    return(

        <svg 
        version="1.1" 
        id="Layer_1" 
        xmlns="http://www.w3.org/2000/svg" 
        xmlnsXlink="http://www.w3.org/1999/xlink" 
        x="0px" 
        y="0px"
        viewBox="0 0 432 432" 
        style={{enableBackground:"new 0 0 432 432"}} 
        xmlSpace="preserve"
        {...{data}}
        >
        <style type="text/css">
            {
                "\n\t.red{fill:#FF0000;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n\t.orange{fill:#FF8A00;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n\t.yellow{fill:#FFB800;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n\t.st0{fill:#FFFFFF;stroke:#000000;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n\t.st1{fill:none;stroke:#000000;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n\t.st2{stroke:#000000;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"
            }
        </style>
        <polygon className="st0 Extra1" points="282.3157,168.6328 280.8157,170.0228 277.7457,167.2928 277.9158,164.7128 281.1857,167.6228 "/>
        
        <path className="st1 Extra2" d="M320.9742,191.045l-0.19,5.16c-1.0099,0.22-2.08,0.38-3.21,0.49c-0.88,0.08-1.8,0.12-2.76,0.12
            c-0.59,0-1.17-0.02-1.74-0.06l-1.41-5.19c1.11,0.2,2.27,0.32,3.4901,0.32C317.3243,191.885,319.2643,191.555,320.9742,191.045z"/>
        <path className="st1 Extra3" d="M340.0443,170.775c0,1.18-0.08,2.34-0.24,3.47c-0.15,1.11-0.38,2.2-0.68,3.25l-4.97,0.3l-0.44-0.12
            c0.8-2.15,1.23-4.47,1.23-6.9c0-0.75-0.04-1.5-0.13-2.24l5.22,1.6C340.0443,170.345,340.0443,170.565,340.0443,170.775z"/>
        <path className="st1 Extra4" d="M296.6843,163.045c-0.93,2.19-1.47,4.58-1.55,7.08l-5.12,0.06c0.03-1.43,0.19-2.84,0.45-4.2
            c0.04-0.2,0.08-0.41,0.1299-0.61c0.11-0.51,0.2401-1.02,0.38-1.51c0.23-0.8,0.4901-1.58,0.8-2.34l4.7599,1.34L296.6843,163.045z"/>
        <path className="st1 Extra5" d="M327.3043,148.975l-1.05,5.35c-1.61-1.1-3.38-1.97-5.28-2.56l3.52-4.14h0.01
            C325.4742,148.005,326.4043,148.465,327.3043,148.975z"/>
        <path className="st1 Extra6" d="M334.1543,154.655l-4.55,2.54h-0.01c-0.25-0.27-0.52-0.54-0.79-0.8c-0.79-0.77-1.64-1.46-2.55-2.07l1.05-5.35
            C329.9142,150.445,332.2343,152.375,334.1543,154.655z"/>
        <path className="st1 Extra7" d="M340.0342,170.135l-5.22-1.6c-0.02-0.19-0.04-0.38-0.07-0.57c-0.42-2.9901-1.51-5.77-3.1-8.18l5.27-1.14
            c0.44,0.79,0.84,1.61,1.19,2.45C339.2743,163.885,339.9543,166.935,340.0342,170.135z"/>
        <path className="st1 Extra8" d="M316.8542,150.945c-0.6-0.06-1.21-0.09-1.83-0.09c-2.44,0-4.78,0.44-6.94,1.25l0.54-5.52
            c0.95-0.25,1.91-0.45,2.9-0.58c1.14-0.17,2.31-0.25,3.5-0.25c0.25,0,0.5,0,0.75,0.02L316.8542,150.945z"/>
        <polygon className="st0 Left_Side" points="278.0243,162.995 277.9142,164.715 277.5543,170.325 265.4943,170.465 267.2743,169.745 
            275.7242,166.315 275.7242,163.115 "/>
        <path className="st0 Left_Side" d="M249.2143,151.595l-4.06-0.2c-0.25-0.81-0.46-1.58-0.63-2.27c-0.4901-2-0.66-3.37-0.66-3.37
            c2.12,0.3,4.4,0.39,5.7-0.24C249.5643,145.515,248.9343,149.365,249.2143,151.595z"/>
        <path className="st0 Left_Side" d="M240.3736,142.805l-0.71,0.89l-0.67,0.85l-9.71-5.22c-0.56-1.31-1.5-2.52-2.73-3.58l0.2-0.29L240.3736,142.805z
            "/>
        <path className="st0 Left_Side" d="M232.2636,151.275c-0.19,0.3-0.39,0.61-0.6,0.93c-0.21,0.33-0.43,0.66-0.65,1l-6.37-3.22
            c0.78-0.47,1.49-0.99,2.11-1.54L232.2636,151.275z"/>
        <path className="st0 Left_Side" d="M234.5136,147.665c0,0-0.02,0.04-0.07,0.1201c-0.0699,0.11-0.1899,0.31-0.35,0.58
            c-0.16,0.26-0.36,0.58-0.59,0.95l-5.09-2.73c0.41-0.59,0.75-1.21,0.99-1.86L234.5136,147.665z"/>
        <path className="st0 Left_Side" d="M204.4236,151.005l-7.79,4.53c-0.27-0.26-0.54-0.52-0.8199-0.8c-0.28-0.28-0.56-0.57-0.85-0.87l7.14-4.11
            C202.8136,150.215,203.5936,150.625,204.4236,151.005z"/>
        <path className="st1 Extra15" d="M143.7436,168.355c-0.07,2.45-0.69,4.77-1.74,6.83c-0.13,0.25-0.26,0.49-0.4,0.73
            c-0.27,0.48-0.57,0.94-0.9,1.38c-0.11,0.16-0.22,0.31-0.35,0.47c-1.52,1.95-3.49,3.54-5.76,4.61c-0.16,0.09-0.32,0.16-0.49,0.22
            c-0.51,0.23-1.04,0.43-1.58,0.59c-0.07,0.03-0.14,0.05-0.21,0.07c-1.45,0.44-3,0.67-4.59,0.67c-0.67,0-1.34-0.04-1.99-0.13
            c-0.41-0.04-0.82-0.11-1.22-0.2c-0.47-0.09-0.93-0.2-1.39-0.34c-0.31-0.09-0.62-0.2-0.92-0.3c-1.62-0.6-3.11-1.45-4.43-2.51h-0.01
            c-0.7599-0.59-1.45-1.26-2.07-1.98c-0.0699-0.06-0.12-0.12-0.16-0.18c-0.32-0.36-0.61-0.7401-0.89-1.13
            c-0.92-1.29-1.65-2.72-2.15-4.26c-0.21-0.63-0.38-1.28-0.5-1.95c-0.08-0.35-0.13-0.71-0.17-1.07c-0.06-0.45-0.1-0.91-0.12-1.37
            c-0.01-0.2-0.01-0.41-0.01-0.61c0-1.74,0.28-3.41,0.79-4.98c0.15-0.46,0.32-0.91,0.51-1.35c0.23-0.53,0.49-1.05,0.77-1.55v-0.01
            c0.08-0.14,0.16-0.28,0.25-0.42v-0.01c1.1-1.8,2.55-3.38,4.25-4.62c0.1-0.07,0.19-0.14,0.29-0.21c0.77-0.54,1.58-1.01,2.44-1.4
            c0.17-0.08,0.34-0.16,0.51-0.23c1.91-0.81,4.0099-1.25,6.22-1.25c0.26,0,0.52,0.01,0.77,0.02h0.1c1.04,0.06,2.05,0.21,3.02,0.46
            c0.13,0.03,0.25,0.06,0.37,0.09c0.69,0.19,1.37,0.43,2.02,0.71l-1.6801,2.56l-1.9,2.92l-1.15,1.74l-0.45,0.7
            c-0.7599-0.23-1.57-0.35-2.41-0.35c-0.58,0-1.14,0.06-1.69,0.17c-0.18,0.03-0.35,0.08-0.52,0.12c-0.38,0.1-0.75,0.23-1.11,0.38
            c-0.36,0.16-0.7,0.33-1.03,0.53c-0.54,0.32-1.03,0.7-1.48,1.1201c-0.54,0.5099-1.02,1.09-1.4,1.73l-0.01,0.01
            c-0.21,0.34-0.4,0.69-0.56,1.07c-0.09,0.21-0.17,0.43-0.25,0.65c-0.28,0.85-0.44,1.76-0.44,2.71v0.17c0.03,1.07,0.24,2.1,0.63,3.05
            c0.41,0.99,1,1.89,1.73,2.66c0.22,0.22,0.44,0.43,0.68,0.63c0.28,0.23,0.58,0.45,0.89,0.65c0.18,0.12,0.37,0.23,0.56,0.33
            c0.81,0.43,1.69,0.74,2.62,0.89c0.45,0.07,0.91,0.11,1.38,0.11c1.24,0,2.41-0.26,3.47-0.74c0.38-0.17,0.7401-0.37,1.09-0.59
            c0.76-0.48,1.43-1.08,2-1.77c0.12-0.15,0.24-0.3,0.35-0.46c0.23-0.31,0.4301-0.65,0.62-1c0.1-0.2,0.2-0.41,0.29-0.63
            c0.43-1.01,0.67-2.13,0.67-3.3c0-0.84-0.12-1.65-0.35-2.42c-0.35-1.2-0.97-2.28-1.77-3.19l1.84-2.88
            c1.69,1.71,3.19,3.12,4.48,4.2599l0.01,0.01c0.91,0.79,1.7,1.44,2.38,1.96c0.26,0.19,0.5,0.36,0.72,0.51l0.02,0.01l0.02,0.0099
            c0,0.01,0,0.01,0,0.01s0,0,0.0099,0.01c0.01,0.01,0.01,0.01,0.02,0.01c0.05,0.04,0.09,0.07,0.13,0.09c0.06,0.05,0.12,0.09,0.18,0.12
            c0.36,0.24,0.66,0.42,0.9,0.53C143.5836,168.295,143.6736,168.325,143.7436,168.355z"/>
        <path className="st1 Extra16" d="M152.4536,169.205c0,0.81-0.04,1.61-0.11,2.4c-0.12,1.37-0.36,2.72-0.69,4.02c-0.32,1.26-0.73,2.48-1.22,3.65
            c-0.5,1.2-1.09,2.35-1.75,3.44c-0.79,1.29-1.68,2.5-2.67,3.63v0.0099c-0.78,0.89-1.63,1.7401-2.53,2.52h-0.01
            c-1.07,0.94-2.21,1.78-3.43,2.52c-1.19,0.75-2.45,1.39-3.76,1.92c-1.4,0.58-2.85,1.03-4.36,1.35c-1.03,0.23-2.08,0.39-3.15,0.48
            c-0.78,0.07-1.56,0.11-2.36,0.11c-0.71,0-1.41-0.03-2.11-0.09c-1.69-0.1299-3.33-0.43-4.92-0.8799h-0.0099
            c-1.48-0.4-2.91-0.95-4.28-1.61c-0.9-0.43-1.78-0.92-2.62-1.46v-0.01c-1.25-0.78-2.43-1.68-3.53-2.67
            c-1.14-1.04-2.1899-2.17-3.1299-3.39h-0.01c-0.97-1.25-1.83-2.6-2.56-4.02c-0.52-1.01-0.98-2.06-1.36-3.14
            c-0.47-1.3-0.83-2.64-1.08-4.03c-0.27-1.44-0.42-2.94-0.43-4.46c-0.01-0.1-0.01-0.19-0.01-0.29c0-1.21,0.08-2.4,0.25-3.57
            c0.17-1.32,0.45-2.61,0.83-3.86c0.38-1.3,0.86-2.55,1.44-3.75c0.64-1.35,1.39-2.63,2.25-3.84c0.73-1.04,1.54-2.02,2.42-2.93v-0.01
            c0.91-0.96,1.88-1.84,2.93-2.64c1.14-0.89,2.36-1.68,3.65-2.37h0.01c1.2-0.65,2.47-1.2,3.78-1.65c1.13-0.39,2.29-0.71,3.49-0.93
            v-0.01c1.55-0.3,3.16-0.47,4.8-0.48h0.2c1.12,0,2.23,0.07,3.31,0.21c1.27,0.16,2.52,0.42,3.73,0.76c1.48,0.41,2.91,0.96,4.27,1.62
            c0.28,0.13,0.56,0.27,0.83,0.42l-4.55,6.97l-1.6801,2.56l-1.9,2.92l-1.15,1.74l-0.45,0.7c-0.7599-0.23-1.57-0.35-2.41-0.35
            c-0.58,0-1.14,0.06-1.69,0.17c-0.18,0.03-0.35,0.08-0.52,0.12c-0.38,0.1-0.75,0.23-1.11,0.38c-0.36,0.16-0.7,0.33-1.03,0.53
            c-0.54,0.32-1.03,0.7-1.48,1.1201c-0.54,0.5099-1.02,1.09-1.4,1.73l-0.01,0.01c-0.21,0.34-0.4,0.69-0.56,1.07
            c-0.09,0.21-0.17,0.43-0.25,0.65c-0.28,0.85-0.44,1.76-0.44,2.71v0.17c0.03,1.07,0.24,2.1,0.63,3.05c0.41,0.99,1,1.89,1.73,2.66
            c0.22,0.22,0.44,0.43,0.68,0.63c0.28,0.23,0.58,0.45,0.89,0.65c0.18,0.12,0.37,0.23,0.56,0.33c0.81,0.43,1.69,0.74,2.62,0.89
            c0.45,0.07,0.91,0.11,1.38,0.11c1.24,0,2.41-0.26,3.47-0.74c0.38-0.17,0.7401-0.37,1.09-0.59c0.76-0.48,1.43-1.08,2-1.77
            c0.12-0.15,0.24-0.3,0.35-0.46c0.23-0.31,0.4301-0.65,0.62-1c0.1-0.2,0.2-0.41,0.29-0.63c0.43-1.01,0.67-2.13,0.67-3.3
            c0-0.84-0.12-1.65-0.35-2.42c-0.35-1.2-0.97-2.28-1.77-3.19l1.84-2.88c1.69,1.71,3.19,3.12,4.48,4.2599l0.01,0.01
            c0.91,0.79,1.7,1.44,2.38,1.96c0.26,0.19,0.5,0.36,0.72,0.51l0.02,0.01l0.02,0.0099c0,0.01,0,0.01,0,0.01s0,0,0.0099,0.01
            c0.01,0.01,0.01,0.01,0.02,0.01c0.05,0.04,0.09,0.07,0.13,0.09c0.06,0.05,0.12,0.09,0.18,0.12c0.36,0.24,0.66,0.42,0.9,0.53
            c0.09,0.05,0.18,0.08,0.25,0.11c0.06,0.03,0.11,0.05,0.16,0.06c0.26,0.06,0.53,0.08,0.79,0.06h0.01c0.01,0,0.01,0,0.03-0.0099
            c0.03,0,0.07-0.01,0.11-0.01c0.06-0.01,0.12-0.02,0.18-0.04c0.18-0.05,0.34-0.12,0.49-0.21c0.07-0.04,0.14-0.09,0.2-0.15
            c0.05-0.04,0.09-0.08,0.14-0.13c0.02-0.02,0.04-0.04,0.05-0.06c0.04-0.04,0.07-0.09,0.1-0.14c0,0,0.0099-0.0099,0.0099-0.02
            c0.04-0.05,0.07-0.11,0.09-0.16c0.04-0.09,0.07-0.18,0.09-0.28c0.03-0.15,0.0601-0.29,0.08-0.44c0.02-0.09,0.03-0.18,0.04-0.27
            c0-0.01,0.0099-0.02,0-0.04c0.04-0.25,0.0699-0.5,0.08-0.75c0.01-0.12,0.02-0.24,0.02-0.37c0.01-0.14,0.01-0.27,0.01-0.41
            c0-0.24-0.01-0.49-0.03-0.73c-0.01-0.33-0.05-0.66-0.1-0.98c-0.03-0.24-0.07-0.46-0.12-0.69c-0.02-0.14-0.05-0.27-0.09-0.41
            c-0.04-0.2-0.09-0.39-0.14-0.58c-0.06-0.23-0.1299-0.46-0.21-0.69c-0.07-0.25-0.16-0.4901-0.26-0.73c-0.04-0.12-0.09-0.24-0.14-0.36
            s-0.1-0.24-0.15-0.35c-0.13-0.31-0.28-0.62-0.43-0.92c-0.11-0.23-0.23-0.46-0.36-0.68c-0.03-0.07-0.07-0.13-0.11-0.19
            c-0.09-0.16-0.18-0.32-0.28-0.48c-0.13-0.22-0.26-0.44-0.4-0.64c-0.12-0.19-0.25-0.37-0.37-0.55c-0.28-0.41-0.57-0.79-0.84-1.14
            c-1.09-1.39-2.04-2.3-2.31-2.54c-0.02-0.03-0.04-0.04-0.05-0.05s-0.02-0.02-0.02-0.02l2.32-3.64c1.73,1.33,3.28,2.87,4.61,4.59
            c0.79,1,1.5,2.05,2.1201,3.16c0.64,1.12,1.2,2.29,1.67,3.51c0.5,1.31,0.9,2.66,1.19,4.06c0.26,1.29,0.43,2.61,0.49,3.96
            C152.4436,168.325,152.4536,168.765,152.4536,169.205z"/>
        <path className="st1 Extra17" d="M152.4536,169.205c0,0.81-0.04,1.61-0.11,2.4c-0.12,1.37-0.36,2.72-0.69,4.02c-0.32,1.26-0.73,2.48-1.22,3.65
            c-0.5,1.2-1.09,2.35-1.75,3.44c-0.79,1.29-1.68,2.5-2.67,3.63v0.0099c-0.78,0.89-1.63,1.7401-2.53,2.52h-0.01
            c-1.07,0.94-2.21,1.78-3.43,2.52c-1.19,0.75-2.45,1.39-3.76,1.92c-1.4,0.58-2.85,1.03-4.36,1.35c-1.03,0.23-2.08,0.39-3.15,0.48
            c-0.78,0.07-1.56,0.11-2.36,0.11c-0.71,0-1.41-0.03-2.11-0.09c-1.69-0.1299-3.33-0.43-4.92-0.8799h-0.0099
            c-1.48-0.4-2.91-0.95-4.28-1.61c-0.9-0.43-1.78-0.92-2.62-1.46v-0.01c-1.25-0.78-2.43-1.68-3.53-2.67
            c-1.14-1.04-2.1899-2.17-3.1299-3.39h-0.01c-0.97-1.25-1.83-2.6-2.56-4.02c-0.52-1.01-0.98-2.06-1.36-3.14
            c-0.47-1.3-0.83-2.64-1.08-4.03c-0.27-1.44-0.42-2.94-0.43-4.46c-0.01-0.1-0.01-0.19-0.01-0.29c0-1.21,0.08-2.4,0.25-3.57
            c0.17-1.32,0.45-2.61,0.83-3.86c0.38-1.3,0.86-2.55,1.44-3.75c0.64-1.35,1.39-2.63,2.25-3.84c0.73-1.04,1.54-2.02,2.42-2.93v-0.01
            c0.91-0.96,1.88-1.84,2.93-2.64c1.14-0.89,2.36-1.68,3.65-2.37h0.01c1.2-0.65,2.47-1.2,3.78-1.65c1.13-0.39,2.29-0.71,3.49-0.93
            v-0.01c1.55-0.3,3.16-0.47,4.8-0.48h0.2c1.12,0,2.23,0.07,3.31,0.21c1.27,0.16,2.52,0.42,3.73,0.76c1.48,0.41,2.91,0.96,4.27,1.62
            c0.28,0.13,0.56,0.27,0.83,0.42l-4.55,6.97l-1.6801,2.56l-1.9,2.92l-1.15,1.74l-0.45,0.7c-0.7599-0.23-1.57-0.35-2.41-0.35
            c-0.58,0-1.14,0.06-1.69,0.17c-0.18,0.03-0.35,0.08-0.52,0.12c-0.38,0.1-0.75,0.23-1.11,0.38c-0.36,0.16-0.7,0.33-1.03,0.53
            c-0.54,0.32-1.03,0.7-1.48,1.1201c-0.54,0.5099-1.02,1.09-1.4,1.73l-0.01,0.01c-0.21,0.34-0.4,0.69-0.56,1.07
            c-0.09,0.21-0.17,0.43-0.25,0.65c-0.28,0.85-0.44,1.76-0.44,2.71v0.17c0.03,1.07,0.24,2.1,0.63,3.05c0.41,0.99,1,1.89,1.73,2.66
            c0.22,0.22,0.44,0.43,0.68,0.63c0.28,0.23,0.58,0.45,0.89,0.65c0.18,0.12,0.37,0.23,0.56,0.33c0.81,0.43,1.69,0.74,2.62,0.89
            c0.45,0.07,0.91,0.11,1.38,0.11c1.24,0,2.41-0.26,3.47-0.74c0.38-0.17,0.7401-0.37,1.09-0.59c0.76-0.48,1.43-1.08,2-1.77
            c0.12-0.15,0.24-0.3,0.35-0.46c0.23-0.31,0.4301-0.65,0.62-1c0.1-0.2,0.2-0.41,0.29-0.63c0.43-1.01,0.67-2.13,0.67-3.3
            c0-0.84-0.12-1.65-0.35-2.42c-0.35-1.2-0.97-2.28-1.77-3.19l1.84-2.88c1.69,1.71,3.19,3.12,4.48,4.2599l0.01,0.01
            c0.91,0.79,1.7,1.44,2.38,1.96c0.26,0.19,0.5,0.36,0.72,0.51l0.02,0.01l0.02,0.0099c0,0.01,0,0.01,0,0.01s0,0,0.0099,0.01
            c0.01,0.01,0.01,0.01,0.02,0.01c0.05,0.04,0.09,0.07,0.13,0.09c0.06,0.05,0.12,0.09,0.18,0.12c0.36,0.24,0.66,0.42,0.9,0.53
            c0.09,0.05,0.18,0.08,0.25,0.11c0.06,0.03,0.11,0.05,0.16,0.06c0.26,0.06,0.53,0.08,0.79,0.06h0.01c0.01,0,0.01,0,0.03-0.0099
            c0.03,0,0.07-0.01,0.11-0.01c0.06-0.01,0.12-0.02,0.18-0.04c0.18-0.05,0.34-0.12,0.49-0.21c0.07-0.04,0.14-0.09,0.2-0.15
            c0.05-0.04,0.09-0.08,0.14-0.13c0.02-0.02,0.04-0.04,0.05-0.06c0.04-0.04,0.07-0.09,0.1-0.14c0,0,0.0099-0.0099,0.0099-0.02
            c0.04-0.05,0.07-0.11,0.09-0.16c0.04-0.09,0.07-0.18,0.09-0.28c0.03-0.15,0.0601-0.29,0.08-0.44c0.02-0.09,0.03-0.18,0.04-0.27
            c0-0.01,0.0099-0.02,0-0.04c0.04-0.25,0.0699-0.5,0.08-0.75c0.01-0.12,0.02-0.24,0.02-0.37c0.01-0.14,0.01-0.27,0.01-0.41
            c0-0.24-0.01-0.49-0.03-0.73c-0.01-0.33-0.05-0.66-0.1-0.98c-0.03-0.24-0.07-0.46-0.12-0.69c-0.02-0.14-0.05-0.27-0.09-0.41
            c-0.04-0.2-0.09-0.39-0.14-0.58c-0.06-0.23-0.1299-0.46-0.21-0.69c-0.07-0.25-0.16-0.4901-0.26-0.73c-0.09-0.24-0.19-0.48-0.29-0.71
            c-0.13-0.31-0.28-0.62-0.43-0.92c-0.11-0.23-0.23-0.46-0.36-0.68c-0.03-0.07-0.07-0.13-0.11-0.19c-0.09-0.16-0.18-0.32-0.28-0.48
            c-0.13-0.22-0.26-0.44-0.4-0.64c-0.12-0.19-0.25-0.37-0.37-0.55c-0.28-0.41-0.57-0.79-0.84-1.14c-1.09-1.39-2.04-2.3-2.31-2.54
            c-0.02-0.03-0.04-0.04-0.05-0.05s-0.02-0.02-0.02-0.02l2.32-3.64c1.73,1.33,3.28,2.87,4.61,4.59c0.79,1,1.5,2.05,2.1201,3.16
            c0.64,1.12,1.2,2.29,1.67,3.51c0.5,1.31,0.9,2.66,1.19,4.06c0.26,1.29,0.43,2.61,0.49,3.96
            C152.4436,168.325,152.4536,168.765,152.4536,169.205z"/>
        <path className="st1 Extra18" d="M145.4836,135.555l-0.45,0.7c-4.44-2.62-9.79-4.64-16.21-5.65
            C134.8536,130.965,140.5136,132.725,145.4836,135.555z"/>
        <path className="st0 Extra19" d="M131.7736,169.495c0,3.38-2.75,6.13-6.13,6.13s-6.13-2.75-6.13-6.13s2.75-6.13,6.13-6.13
            c0.54,0,1.06,0.07,1.55,0.21l-2.12,3.24c0.95,1.91,3.95,2.68,3.95,2.68l1.94-3.04
            C131.4736,167.345,131.7736,168.385,131.7736,169.495z"/>
        <path className="st0 Extra20" d="M134.9036,169.205c0,1.17-0.24,2.29-0.67,3.3c-0.09,0.22-0.19,0.43-0.29,0.63c-0.1899,0.35-0.39,0.69-0.62,1
            c-0.11,0.16-0.23,0.31-0.35,0.46c-0.57,0.69-1.24,1.29-2,1.77c-0.35,0.22-0.71,0.42-1.09,0.59c-1.06,0.48-2.23,0.74-3.47,0.74
            c-0.47,0-0.93-0.04-1.38-0.11c-0.93-0.15-1.81-0.46-2.62-0.89c-0.19-0.1-0.38-0.21-0.56-0.33c-0.31-0.2-0.61-0.42-0.89-0.65
            c-0.24-0.2-0.46-0.41-0.68-0.63c-0.73-0.77-1.32-1.67-1.73-2.66c-0.39-0.95-0.6-1.98-0.63-3.05v-0.17c0-0.95,0.16-1.86,0.44-2.71
            c0.08-0.22,0.16-0.44,0.25-0.65c0.16-0.38,0.35-0.73,0.56-1.07l0.01-0.01c0.38-0.64,0.86-1.22,1.4-1.73
            c0.45-0.42,0.94-0.8,1.48-1.1201c0.33-0.2,0.67-0.37,1.03-0.53c0.36-0.15,0.73-0.28,1.11-0.38c0.17-0.04,0.34-0.09,0.52-0.12
            c0.55-0.11,1.11-0.17,1.69-0.17c0.84,0,1.65,0.12,2.41,0.35l-1.63,2.51c-0.49-0.14-1.0099-0.21-1.55-0.21
            c-3.38,0-6.13,2.75-6.13,6.13s2.75,6.13,6.13,6.13s6.13-2.75,6.13-6.13c0-1.11-0.3-2.15-0.81-3.04l1.82-2.86
            c0.8,0.91,1.42,1.99,1.77,3.19C134.7836,167.555,134.9036,168.365,134.9036,169.205z"/>
        <path className="st1 Extra21" d="M152.4536,169.205c0,0.81-0.04,1.61-0.11,2.4c-0.12,1.37-0.36,2.72-0.69,4.02c-0.32,1.26-0.73,2.48-1.22,3.65
            c-0.5,1.2-1.09,2.35-1.75,3.44c-0.79,1.29-1.68,2.5-2.67,3.63v0.0099c-0.78,0.89-1.63,1.7401-2.53,2.52h-0.01
            c-1.07,0.94-2.21,1.78-3.43,2.52c-1.19,0.75-2.45,1.39-3.76,1.92c-1.4,0.58-2.85,1.03-4.36,1.35c-1.03,0.23-2.08,0.39-3.15,0.48
            c-0.78,0.07-1.56,0.11-2.36,0.11c-0.71,0-1.41-0.03-2.11-0.09c-1.69-0.1299-3.33-0.43-4.92-0.8799h-0.0099
            c-1.48-0.4-2.91-0.95-4.28-1.61c-0.9-0.43-1.78-0.92-2.62-1.46v-0.01c-1.25-0.78-2.43-1.68-3.53-2.67
            c-1.14-1.04-2.1899-2.17-3.1299-3.39h-0.01c-0.97-1.25-1.83-2.6-2.56-4.02c-0.52-1.01-0.98-2.06-1.36-3.14
            c-0.47-1.3-0.83-2.64-1.08-4.03c-0.27-1.44-0.42-2.94-0.43-4.46c-0.01-0.1-0.01-0.19-0.01-0.29c0-1.21,0.08-2.4,0.25-3.57
            c0.17-1.32,0.45-2.61,0.83-3.86c0.38-1.3,0.86-2.55,1.44-3.75c0.64-1.35,1.39-2.63,2.25-3.84c0.73-1.04,1.54-2.02,2.42-2.93v-0.01
            c0.91-0.96,1.88-1.84,2.93-2.64c1.14-0.89,2.36-1.68,3.65-2.37h0.01c1.2-0.65,2.47-1.2,3.78-1.65c1.13-0.39,2.29-0.71,3.49-0.93
            v-0.01c1.55-0.3,3.16-0.47,4.8-0.48h0.2c1.12,0,2.23,0.07,3.31,0.21c1.27,0.16,2.52,0.42,3.73,0.76c1.48,0.41,2.91,0.96,4.27,1.62
            c0.28,0.13,0.56,0.27,0.83,0.42l-4.55,6.97l-1.6801,2.56l-1.9,2.92l-1.15,1.74l-0.45,0.7c-0.7599-0.23-1.57-0.35-2.41-0.35
            c-0.58,0-1.14,0.06-1.69,0.17c-0.18,0.03-0.35,0.08-0.52,0.12c-0.38,0.1-0.75,0.23-1.11,0.38c-0.36,0.16-0.7,0.33-1.03,0.53
            c-0.54,0.32-1.03,0.7-1.48,1.1201c-0.54,0.5099-1.02,1.09-1.4,1.73l-0.01,0.01c-0.21,0.34-0.4,0.69-0.56,1.07
            c-0.09,0.21-0.17,0.43-0.25,0.65c-0.28,0.85-0.44,1.76-0.44,2.71v0.17c0.03,1.07,0.24,2.1,0.63,3.05c0.41,0.99,1,1.89,1.73,2.66
            c0.22,0.22,0.44,0.43,0.68,0.63c0.28,0.23,0.58,0.45,0.89,0.65c0.18,0.12,0.37,0.23,0.56,0.33c0.81,0.43,1.69,0.74,2.62,0.89
            c0.45,0.07,0.91,0.11,1.38,0.11c1.24,0,2.41-0.26,3.47-0.74c0.38-0.17,0.7401-0.37,1.09-0.59c0.76-0.48,1.43-1.08,2-1.77
            c0.12-0.15,0.24-0.3,0.35-0.46c0.23-0.31,0.4301-0.65,0.62-1c0.1-0.2,0.2-0.41,0.29-0.63c0.43-1.01,0.67-2.13,0.67-3.3
            c0-0.84-0.12-1.65-0.35-2.42c-0.35-1.2-0.97-2.28-1.77-3.19l1.84-2.88c1.69,1.71,3.19,3.12,4.48,4.2599l0.01,0.01
            c0.91,0.79,1.7,1.44,2.38,1.96c0.26,0.19,0.5,0.36,0.72,0.51l0.02,0.01l0.02,0.0099c0,0.01,0,0.01,0,0.01s0,0,0.0099,0.01
            c0.01,0.01,0.01,0.01,0.02,0.01c0.05,0.04,0.09,0.07,0.13,0.09c0.06,0.05,0.12,0.09,0.18,0.12c0.36,0.24,0.66,0.42,0.9,0.53
            c0.09,0.05,0.18,0.08,0.25,0.11c0.06,0.03,0.11,0.05,0.16,0.06c0.26,0.06,0.53,0.08,0.79,0.06h0.01c0.01,0,0.01,0,0.03-0.0099
            c0.03,0,0.07-0.01,0.11-0.01c0.06-0.01,0.12-0.02,0.18-0.04c0.18-0.05,0.34-0.12,0.49-0.21c0.07-0.04,0.14-0.09,0.2-0.15
            c0.05-0.04,0.09-0.08,0.14-0.13c0.02-0.02,0.04-0.04,0.05-0.06c0.04-0.04,0.07-0.09,0.1-0.14c0,0,0.0099-0.0099,0.0099-0.02
            c0.04-0.05,0.07-0.11,0.09-0.16c0.04-0.09,0.07-0.18,0.09-0.28c0.03-0.15,0.0601-0.29,0.08-0.44c0.02-0.09,0.03-0.18,0.04-0.27
            c0-0.01,0.0099-0.02,0-0.04c0.04-0.25,0.0699-0.5,0.08-0.75c0.01-0.12,0.02-0.24,0.02-0.37c0.01-0.14,0.01-0.27,0.01-0.41
            c0-0.24-0.01-0.49-0.03-0.73c-0.01-0.33-0.05-0.66-0.1-0.98c-0.03-0.24-0.07-0.46-0.12-0.69c-0.02-0.14-0.05-0.27-0.09-0.41
            c-0.04-0.2-0.09-0.39-0.14-0.58c-0.06-0.23-0.1299-0.46-0.21-0.69c-0.07-0.25-0.16-0.4901-0.26-0.73c-0.09-0.24-0.19-0.48-0.29-0.71
            c-0.13-0.31-0.28-0.62-0.43-0.92c-0.11-0.23-0.23-0.46-0.36-0.68c-0.03-0.07-0.07-0.13-0.11-0.19c-0.09-0.16-0.18-0.32-0.28-0.48
            c-0.13-0.22-0.26-0.44-0.4-0.64c-0.12-0.19-0.25-0.37-0.37-0.55c-0.28-0.41-0.57-0.79-0.84-1.14c-1.09-1.39-2.04-2.3-2.31-2.54
            c-0.02-0.03-0.04-0.04-0.05-0.05s-0.02-0.02-0.02-0.02l2.32-3.64c1.73,1.33,3.28,2.87,4.61,4.59c0.79,1,1.5,2.05,2.1201,3.16
            c0.64,1.12,1.2,2.29,1.67,3.51c0.5,1.31,0.9,2.66,1.19,4.06c0.26,1.29,0.43,2.61,0.49,3.96
            C152.4436,168.325,152.4536,168.765,152.4536,169.205z"/>
        <path className="st1 Extra22" d="M162.9636,156.525c-2.58-5.61-6.98-12.51-14.28-17.88l0.47-0.73
            C155.4736,142.515,160.3536,148.995,162.9636,156.525z"/>
        <polygon className="st0 Front_Suspension" points="153.6436,132.635 152.1336,134.885 150.4836,137.335 149.8136,136.885 146.2036,134.455 
            145.5636,134.025 147.2736,131.485 148.7236,129.325 149.3036,129.715 152.8536,132.105 "/>
        <path className="st1 Extra24" d="M145.4836,135.555l-0.45,0.7c-4.44-2.62-9.79-4.64-16.21-5.65
            C134.8536,130.965,140.5136,132.725,145.4836,135.555z"/>
        <path className="st1 Extra25" d="M162.9636,156.525c-2.58-5.61-6.98-12.51-14.28-17.88l0.47-0.73
            C155.4736,142.515,160.3536,148.995,162.9636,156.525z"/>
        <polygon className="st0 Front_Suspension" points="163.8436,116.825 161.2536,120.675 160.4736,120.155 157.0536,117.845 156.2136,117.285 
            158.8036,113.425 159.5936,113.955 162.9736,116.235 "/>
        <line className="st1 Extra27" x1="137.7227" y1="145.7559" x2="124.7247" y2="160.8852"/>
        <line className="st1 Extra28" x1="126.2183" y1="143.1672" x2="130.4241" y2="158.6202"/>
        <line className="st1 Extra29" x1="129.7227" y1="143.3766" x2="132.3277" y2="155.7097"/>
        <line className="st1 Extra30" x1="133.4517" y1="144.1337" x2="122.0644" y2="161.9151"/>
        <polyline className="st1 Extra31" points="114.1436,146.235 118.0936,149.9351 121.0236,152.6651 121.5036,153.1151 125.3836,156.735 
            126.9936,158.2451 129.2736,160.3651 129.2836,160.3751 "/>
        <polyline className="st1 Extra32" points="120.5836,163.035 120.6536,161.425 120.6936,160.5151 120.8636,156.4951 120.9936,153.345 
            121.0236,152.6651 121.4136,143.6551 "/>
        <line className="st1 Extra33" x1="126.4136" y1="160.7172" x2="110.4835" y2="148.602"/>
        <polyline className="st1 Extra34" points="118.3636,166.495 117.6736,165.525 114.8436,161.535 113.7636,160.015 113.7636,160.005 
            113.4036,159.495 107.5536,151.255 "/>
        <line className="st1 Extra35" x1="117.9236" y1="144.5816" x2="118.6147" y2="165.8466"/>
        <line className="st1 Extra36" x1="124.2074" y1="161.0076" x2="102.8817" y2="158.0234"/>
        <line className="st1 Extra37" x1="105.1341" y1="154.1829" x2="117.9236" y2="169.3702"/>
        <line className="st1 Extra38" x1="101.4398" y1="161.7766" x2="123.0983" y2="161.3891"/>
        <polyline className="st1 Extra39" points="100.7936,173.9651 100.8036,173.955 106.3636,171.175 110.1936,169.2651 111.7036,168.5051 
            115.5636,166.5751 117.6736,165.5251 118.5836,165.065 119.1736,164.7751 "/>
        <line className="st1 Extra40" x1="100.6066" y1="165.6384" x2="118.5545" y2="172.4226"/>
        <line className="st1 Extra41" x1="116.5666" y1="167.7588" x2="101.8791" y2="177.9853"/>
        <line className="st1 Extra42" x1="100.3655" y1="169.4972" x2="120.2848" y2="175.0817"/>
        <line className="st1 Extra43" x1="117.2625" y1="171.9342" x2="108.9448" y2="188.5342"/>
        <line className="st1 Extra44" x1="103.2442" y1="181.1279" x2="122.4114" y2="176.6957"/>
        <line className="st1 Extra45" x1="120.9596" y1="175.7126" x2="124.3022" y2="195.1727"/>
        <line className="st1 Extra46" x1="128.771" y1="195.1516" x2="121.85" y2="176.3672"/>
        <polyline className="st1 Extra47" points="105.8136,185.1451 111.8336,182.7751 114.9136,181.565 117.7636,180.4451 121.5236,178.9651 
            122.6436,178.5251 125.0336,177.5851 "/>
        <polyline className="st1 Extra48" points="112.4736,191.205 114.9136,181.565 115.6936,178.4651 115.7536,178.235 116.7936,174.1051 
            117.3436,171.9651 117.9236,169.6551 "/>
        <line className="st1 Extra49" x1="127.9873" y1="177.5503" x2="115.0908" y2="192.6754"/>
        <polyline className="st1 Extr50" points="119.3836,194.285 123.0936,188.155 125.0236,184.975 125.7336,183.795 128.5036,179.235 
            128.6736,178.955 129.8836,176.955 "/>
        <polyline className="st1 Extra51" points="140.0536,191.405 140.0436,191.395 135.2036,186.295 132.5136,183.455 132.3136,183.255 
            128.5036,179.235 127.4736,178.155 128.6736,178.955 132.6036,181.595 134.1036,182.595 134.6936,182.985 143.4736,188.875 "/>
        <line className="st1 Extra52" x1="132.9717" y1="174.5939" x2="131.9255" y2="194.6725"/>
        <line className="st1" x1="136.2831" y1="193.3208" x2="133.3264" y2="174.1328"/>
        <polyline className="st1 Extra54" points="151.9236,163.9351 146.6536,166.6351 146.2736,166.8251 "/>
        <polyline className="st1 Extra55" points="133.9436,173.1351 137.2736,171.4351 138.2336,170.9351 143.4936,168.2451 "/>
        <polyline className="st1 Extra56" points="134.2336,172.5051 136.7636,170.565 137.4436,170.0451 141.3136,167.0851 141.4936,166.9451 "/>
        <line className="st1 Extra57" x1="150.7336" y1="159.8751" x2="146.2936" y2="163.2751"/>
        <line className="st1 Extra58" x1="152.3436" y1="171.6051" x2="144.7036" y2="168.4751"/>
        <polyline className="st1 Extra59" points="142.2136,167.455 141.3136,167.0851 132.7836,163.595 139.1036,164.9751 "/>
        <polyline className="st1 Extra60" points="146.3136,166.5551 146.6536,166.6351 152.4136,167.8951 152.4236,167.8951 "/>
        <polyline className="st1 Extra61" points="148.6836,182.715 144.8936,178.445 142.6236,175.885 142.0036,175.185 138.2336,170.935 
            137.4436,170.045 134.5536,166.785 136.7636,170.565 137.2736,171.435 139.9236,175.955 140.7036,177.295 141.0436,177.875 
            146.0136,186.345 "/>
        <line className="st1 Extra62" x1="131.2237" y1="176.2016" x2="151.6567" y2="175.6272"/>
        <line className="st1 Extra63" x1="150.4394" y1="179.2711" x2="130.9736" y2="176.3672"/>
        <line className="st1 Extra64" x1="142.3911" y1="154.8648" x2="146.948" y2="153.2011"/>
        <line className="st1 Extra65" x1="144.0073" y1="157.1965" x2="149.0694" y2="156.3652"/>
        <path className="st1 Extra66" d="M162.0036,145.755c0,0,6.73-12.51,1.36-23.75l0.94-1.7"/>
        <path className="st0 Extra67" d="M180.2832,93.1528l-2.03,3.31l-2.35,3.84l-1.13,1.84l-0.3-0.24l-1.65-1.12l2.74-4.31l-0.86-1.82
            c0,0,0.21-0.45,0.21-1.15c0.08,0.02,1.08,0.25,1.47,0c0.42-0.27,3.38-3.67,3.38-3.67
            C179.9332,91.6428,180.2832,93.1528,180.2832,93.1528z"/>
        <path className="st0 Extra68" d="M181.6132,102.5028c-0.33,0.41-0.58,0.7-0.63,0.71c-0.2,0-3.54-2.24-5.08-2.91l2.35-3.84
            c0,0,0.65,0.09,1.27,0.3c0.31,0.1,0.62,0.2401,0.82,0.41c0.61,0.51,2.52,2.27,2.78,2.72
            C183.2932,100.1928,182.3032,101.6228,181.6132,102.5028z"/>
        <path className="st0 Extra69" d="M181.4832,93.4928l-1.96,3.27c-0.62-0.21-1.27-0.3-1.27-0.3l2.03-3.31
            C180.6832,93.3028,181.0832,93.4228,181.4832,93.4928z"/>
        <path className="st0 Extra70" d="M179.7643,89.835c0,0-2.96,3.4-3.38,3.67c-0.39,0.25-1.39,0.02-1.47,0v-0.08c0.14-1.77,0.25-3.9,0.32-6.3
            c1.27-0.42,2.81-0.81,4.55-1.07C179.5942,87.195,179.6443,88.585,179.7643,89.835z"/>
        <path className="st0 Extra71" d="M171.0936,96.345c0,0-5.03-3.51-7.88-5.72c-0.12-0.09-0.23-0.18-0.33-0.26c-0.85-0.67-1.45-1.19-1.56-1.41
            c0.4301-1.32,4.67-1.61,8.11-0.78C170.2336,93.295,171.0936,96.345,171.0936,96.345z"/>
        <path className="st0 Extra72" d="M186.2136,72.595c-0.07,2.54-0.12,4.99-0.12,4.99h-0.23c-0.2,0.06-0.39,0.14-0.56,0.25
            C185.3036,77.835,183.9436,73.795,186.2136,72.595z"/>
        <path className="st0 Extra73" d="M193.1436,75.515c-0.76,0.58-1.28,1.31-1.61,2.07h-4.1c0,0,0.07-3.02,0.1-5.32
            C190.3036,72.015,192.7436,73.515,193.1436,75.515z"/>
        <path className="st0 Extra74" d="M184.2036,78.955c-1.17,1.93-1.05,5.08-1.05,5.08c-1.05-0.25-1.92-0.3-2.33-0.04
            c-0.56,0.39-0.87,1.15-1.03,2.06h-0.01c-1.74,0.26-3.28,0.65-4.55,1.07c-2.45,0.8-3.92,1.65-3.92,1.65c1.2-1.85,2.58-3.39,3.98-4.67
            c4.35-3.94,8.91-5.28,8.91-5.16C184.2036,78.955,184.2036,78.955,184.2036,78.955z"/>
        <polygon className="st0 Extra75" points="170.2743,109.505 168.9943,111.815 166.7143,110.375 168.1143,108.165 "/>
        <path className="st0 Left_Side" d="M256.6843,190.855c-0.62,1.61-2.07,4.26-4.81,4.26h-15.23c-2.04,0-5.19-2.68-5.19-2.68s-9.87,0.29-10.68,0
            c-0.81-0.3-3.4901-1.07-5.11-1.07s-3.74,1.07-6.55,1.07s-9.58-3.49-10.64-3.49s-5.23,0-5.23,0l-8.13-10.47c0,0,0,0-1.53,0
            c-1.54,0-2-0.72-2-1.7s0-0.77,0-2.51c0-1.75,1.49-1.96,2.89-1.96s1.91-0.77,1.91-0.77s-3.38-14.08-5.54-19.47
            c-1.16-6.75-2.22-12.14-3.9399-16.3v-0.18c0.04-6.3-2.4301-13.41-2.83-13.71c-0.14-0.11-1.22-0.68-2.56-1.38
            c-2.42-1.26-5.69-2.94-5.69-2.94l3.17-5.74l1.28-2.31l4.2-7.6l0.3,0.24l1.13-1.84c1.54,0.67,4.8799,2.91,5.08,2.91l-0.33,0.56
            l-1.48,2.54l-3.81,6.54l2.43,1.45l8.17,34.42c0,0,0.17,0.35,0.47,0.97v0.01c-0.13,2.05,2.25,9.71,2.25,9.71s0,2.25,0,5.78
            c0,1.14,0.73,1.91,1.72,2.43c0.03,0.02,0.0699,0.03,0.1,0.05c0.59,4.01,4.06,20.72,21.54,21.06c15.34,0.29,27.63,0.62,32.44,0.74
            l0.21,0.0601c1.39,0.35,3.06,0.66,4.97,0.85C251.3442,190.545,253.8343,190.715,256.6843,190.855z"/>
        <path className="st0 Extra77" d="M244.4836,189.475c-4.81-0.12-17.1-0.45-32.44-0.74c-17.48-0.34-20.95-17.05-21.54-21.06
            c2.03,1.01,5.04,1.01,5.16,1.01c0.01,0,0.01,0,0.01,0c2.76,5.66,5.21,10.66,5.91,12.04c1.83,3.6201,7.02,6.9,16.55,6.9
            s21.4,0,21.4,0S241.2436,188.645,244.4836,189.475z"/>
        <path className="st0 Extra78" d="M180.8436,152.065c-0.19-0.48-0.37-0.89-0.54-1.21c-2.07-3.96-3.42-8.88-3.4-15.09
            C178.6236,139.925,179.6836,145.315,180.8436,152.065z"/>
        <path className="st0 Left_Side" d="M176.9036,135.585v0.18c-0.23-0.57-0.48-1.11-0.74-1.63c-2.12-4.24-4.66-13.05-4.78-13.45l-1.98,2.82l-5.1-3.2
            l1.52-2.75c0,0,3.27,1.68,5.69,2.94c1.34,0.7,2.42,1.27,2.56,1.38C174.4736,122.175,176.9436,129.285,176.9036,135.585z"/>
        <path className="st0 Extra80" d="M191.3836,82.195l-2.33,3.08l0.91,0.7l-0.91,1.03c-1.12-1.11-3.21-2.16-5.03-2.73
            c-0.3-0.09-0.59-0.17-0.87-0.24c0,0-0.12-3.15,1.05-5.08c0,0,0,0,0-0.01c0.3-0.47,0.64-0.85,1.1-1.11c0.17-0.11,0.36-0.19,0.56-0.25
            h5.67C190.6136,79.615,190.9836,81.885,191.3836,82.195z"/>
        <path className="st0 Extra81" d="M193.6936,84.035l-1.59,1.97l-0.81,1l-1.33-1.03l-0.91-0.7l2.33-3.08
            C191.9836,82.655,193.6936,84.035,193.6936,84.035z"/>
        <path className="st0 Extra82" d="M196.81,85.46c-0.43,0.99-1.27,1.45-2.39,1.54c-1.1,0.09-1.9-0.56-2.32-1l1.59-1.97
            c0.37,0.15,0.73,0.27,1.06,0.36C195.4,84.78,196.09,85.13,196.81,85.46z"/>
        <path className="st0 Clutch_Lever" d="M203.34,87.32c-1.8701-0.23-4.31-0.88-6.53-1.86c-0.72-0.33-1.41-0.68-2.06-1.07
            c-1.94-1.17-3.44-2.63-3.78-4.3c0.02-0.77,0.1801-1.67,0.5601-2.51c0.33-0.76,0.85-1.48,1.61-2.07c0.23-0.16,0.49-0.33,0.77-0.48
            c2.2-1.14,4.35-0.25,5.5,1.24c-0.15-0.09-0.3-0.17-0.46-0.23c-2.35-1.0099-5.35,0.63-6.13,3.54c-0.06,0.21-0.09,0.42-0.1,0.62
            c0,0,0,0.01,0,0.02c0.04,0.19,0.4,1.64,2.28,2.82c0.68,0.43,1.75,0.9,2.93,1.34c2.34,0.88,5.09,1.66,5.9,1.81
            C204.13,86.87,203.83,87.28,203.34,87.32z"/>
        <path className="st0 Extra84" d="M194.75,84.39c-0.33-0.09-0.69-0.21-1.06-0.36c0,0-1.71-1.38-2.31-1.84c-0.23-0.17-0.46-1.02-0.41-2.1
            C191.31,81.76,192.81,83.22,194.75,84.39z"/>
        <path className="st0 Left_Handle" d="M195.62,81.39c1.13,0.65,2.55,1.16,3.98,1.55c-0.3199,0.53-0.84,1.11-1.67,1.44c-1.18-0.44-2.25-0.91-2.93-1.34
            c-1.88-1.18-2.24-2.63-2.28-2.82c0-0.0099,0-0.02,0-0.02c0.01-0.2,0.04-0.41,0.1-0.62c0.78-2.91,3.78-4.55,6.13-3.54
            c0.16,0.07,0.31,0.15,0.46,0.2401c0.5,0.64,0.81,1.39,0.86,2.12c-1.52-0.41-2.6-0.82-2.6-0.82C195.92,78,195.09,81.04,195.62,81.39z
            "/>
        <path className="st0 Extra86" d="M182.2336,107.245c0,0-0.52,1.19-1.57,1.16c-1.05-0.02-1.54-1.03-1.49-2.09l1.48-2.54l0.65,1.37
            L182.2336,107.245z"/>
        <path className="st0 Left_Side" d="M292.8136,186.265v4.17c0,0-4.47,0.51-8.56,0.86c-2.16,0.19-4.21,0.33-5.44,0.33c-2.66,0-13.86-0.33-22.13-0.77
            c-2.85-0.14-5.34-0.31-7.02-0.47c-1.91-0.19-3.58-0.5-4.97-0.85c-0.0699-0.02-0.14-0.04-0.21-0.0601c-3.24-0.83-4.95-1.85-4.95-1.85
            v-4.91c0,0,4.05-1.05,7.2-0.88c1,0.06,3.59,0.2,6.92,0.39h0.01c7.1,0.42,17.58,1.07,23.49,1.53c1.15,0.09,2.25,0.2,3.33,0.32h0.05
            C287.5736,184.895,292.8136,186.265,292.8136,186.265z"/>
        <path className="st0 Left_Side" d="M225.6636,180.795v1.96c-3.52,0.01-6.9,0.02-9.06,0.02c-5.86,0-9.25-2.99-12.15-7.95c0,0,2.35,1.53,3.18,1.79
            c0.46,0.14,1.44,1.12,2.57,1.97c0.92,0.69,1.94,1.29,2.86,1.29h11.71C225.2736,179.895,225.6636,180.295,225.6636,180.795z"/>
        <path className="st0 Extra89" d="M224.7736,179.875h-11.71c-0.92,0-1.94-0.6-2.86-1.29h11.38
            C223.1136,178.585,224.7736,179.875,224.7736,179.875z"/>
        <path className="st0 Left_Side" d="M227.3536,178.885v3.87h-1.69v-1.96c0-0.5-0.39-0.9-0.89-0.92c0,0-1.66-1.29-3.19-1.29h-11.38
            c-1.13-0.85-2.11-1.83-2.57-1.97c-0.83-0.26-3.18-1.79-3.18-1.79c-0.19-0.33-0.39-0.68-0.58-1.03c-0.07-0.14-0.15-0.28-0.23-0.44
            c1.6,1.22,3.55,1.91,5.58,2.26c0,0,0,0,0.01,0c0.94,0.84,2.17,1.35,3.51,1.35c1.29,0,2.47-0.47,3.39-1.23
            c0.23-0.03,0.44-0.05,0.66-0.08h7.34C225.9136,175.655,227.3536,177.095,227.3536,178.885z"/>
        <path className="st0 Extra91" d="M212.0136,105.885c-1.05-0.11-2.74-0.34-4.68-0.8c0,0,0,0-0.01,0c-1.17-0.28-2.42-0.64-3.68-1.11
            c-4.72-1.75-10.69-3.1899-12.06-3c-0.48,0.0601-1.89,0.6-3.52,1.27c-3.01,1.22-6.76,2.9-6.76,2.9l-0.65-1.37l0.33-0.56
            c0.05-0.01,0.3-0.3,0.63-0.71l3.57-1.26l8.63-3.05c0,0,15,4.79,16.79,5.3C211.5236,103.755,211.8836,104.865,212.0136,105.885z"/>
        <path className="st0 Extra92" d="M207.3236,105.085c-11.17-0.33-19.2599-2.84-19.2599-2.84c1.6299-0.67,3.04-1.21,3.52-1.27
            c1.37-0.1899,7.34,1.25,12.06,3C204.9036,104.445,206.1536,104.805,207.3236,105.085z"/>
        <polygon className="st0 Speedometer/Odometer" points="214.4643,100.785 213.4843,102.385 204.6243,97.255 205.6843,95.675 "/>
        <polygon className="st0 Speedometer/Odometer" points="204.3436,95.135 203.2636,96.465 194.7336,91.515 195.7636,90.065 "/>
        <path className="st0 Left_Side" d="M201.1436,132.385l-2.16,0.98l-13.08,5.94c-2.84-1.72-3.32-5.55-1.82-6.35s8.33-4.05,8.33-4.05
            s1.56,1.37,3.12,0.79c1.57-0.57,4.31-1.96,4.31-1.96L201.1436,132.385z"/>
        <path className="st0 Left_Side" d="M197.8436,139.305c-0.2,0.46-0.35,0.94-0.45,1.43l-9.05,4.88l-0.47-0.5c-0.1-0.14-0.19-0.28-0.28-0.41
            L197.8436,139.305z"/>
        <path className="st0 Left_Side" d="M198.9836,137.395c-0.47,0.61-0.85,1.24-1.14,1.91l-10.25,5.4c-0.23-0.34-0.44-0.65-0.63-0.95L198.9836,137.395
            z"/>
        <path className="st0 Left_Side" d="M229.6936,140.595c-0.1-0.4301-0.24-0.86-0.41-1.27c-0.56-1.31-1.5-2.52-2.73-3.58
            c-2.98-2.55-7.69-4.2-12.99-4.2c-5.63,0-10.59,1.85-13.52,4.67c-0.39,0.38-0.74,0.77-1.06,1.18c-0.47,0.61-0.85,1.24-1.14,1.91
            c-0.2,0.46-0.35,0.94-0.45,1.43c-0.1,0.47-0.15,0.95-0.15,1.44c0,0.39,0.03,0.77,0.09,1.15c0.07,0.39,0.16,0.77,0.29,1.15
            c0.12,0.34,0.2599,0.67,0.42,1c0.17,0.34,0.37,0.6801,0.59,1.01c0.2401,0.35,0.5,0.68,0.78,1c0.26,0.29,0.53,0.57,0.83,0.84
            c0.26,0.25,0.55,0.48,0.85,0.72c0.31,0.2401,0.65,0.48,1.01,0.71c0.71,0.46,1.49,0.87,2.32,1.25c0.07,0.02,0.14,0.05,0.21,0.08
            c2.56,1.09,5.63,1.73,8.9301,1.73c3.0699,0,5.93-0.55,8.3799-1.51c0.08-0.03,0.16-0.06,0.2401-0.1c0.48-0.19,0.94-0.4,1.38-0.62
            c0.37-0.19,0.73-0.39,1.08-0.6c0.78-0.47,1.49-0.99,2.11-1.54c0.37-0.33,0.7-0.67,1.0099-1.03c0.23-0.27,0.45-0.54,0.65-0.83
            c0.41-0.59,0.75-1.21,0.99-1.86c0.16-0.42,0.28-0.86,0.36-1.3c0.07-0.41,0.11-0.83,0.11-1.25
            C229.8736,141.635,229.8136,141.115,229.6936,140.595z M213.7336,151.275c-4.96,0-8.97-4.02-8.97-8.97s4.01-8.97,8.97-8.97
            c4.95,0,8.96,4.02,8.96,8.97S218.6836,151.275,213.7336,151.275z"/>
        <path className="st0 Left_Side" d="M200.0436,136.215c-0.39,0.38-0.74,0.77-1.06,1.18l-12.02,6.36c-0.28-0.41-0.51-0.79-0.72-1.14l13.56-7.05
            L200.0436,136.215z"/>
        <path className="st0 Left_Side" d="M199.8036,135.565l-13.56,7.05c-0.21-0.35-0.38-0.67-0.52-0.97l13.69-7.11L199.8036,135.565z"/>
        <path className="st0 Left_Side" d="M199.4136,134.535l-13.69,7.11c-0.52-1.14-0.51-1.87,0.18-2.34l13.08-5.94L199.4136,134.535z"/>
        <path className="st0 Left_Side" d="M213.7336,133.335c-4.96,0-8.97,4.02-8.97,8.97s4.01,8.97,8.97,8.97c4.95,0,8.96-4.02,8.96-8.97
            S218.6836,133.335,213.7336,133.335z M213.7336,145.815c-1.94,0-3.52-1.57-3.52-3.51s1.58-3.51,3.52-3.51
            c1.93,0,3.51,1.57,3.51,3.51S215.6636,145.815,213.7336,145.815z"/>
        <path className="st0 Left_Side" d="M217.2436,142.305c0,1.94-1.58,3.51-3.51,3.51c-1.94,0-3.52-1.57-3.52-3.51s1.58-3.51,3.52-3.51
            C215.6636,138.795,217.2436,140.365,217.2436,142.305z"/>
        <path className="st1 Extra104" d="M199.8436,127.735c0,0-2.74,1.39-4.31,1.96c-1.56,0.58-3.12-0.79-3.12-0.79s-6.83,3.25-8.33,4.05
            s-1.02,4.63,1.82,6.35c-0.69,0.47-0.7,1.2-0.18,2.34c0.14,0.3,0.31,0.62,0.52,0.97s0.44,0.73,0.72,1.14c0.19,0.3,0.4,0.61,0.63,0.95
            c0.09,0.13,0.18,0.27,0.28,0.41c0,0-1.3,2.54-1.44,4.58c-0.3-0.62-0.47-0.97-0.47-0.97l-8.17-34.42l-2.43-1.45l3.81-6.54
            c-0.05,1.06,0.44,2.07,1.49,2.09c1.05,0.03,1.57-1.16,1.57-1.16l0.4,0.92c-3.3799,5.86-2.44,11.94-1.79,13.4
            c0.88,1.98,7.03,6.32,8.76,6.38C190.4836,127.975,194.4136,127.855,199.8436,127.735z"/>
        <path className="st0 Left_Side" d="M195.6636,168.685c-0.12,0-3.13,0-5.16-1.01c-0.03-0.02-0.07-0.03-0.1-0.05c-0.99-0.52-1.72-1.29-1.72-2.43
            c0-3.53,0-5.78,0-5.78l1.61-1.77C191.9536,161.055,193.8836,165.005,195.6636,168.685z"/>
        <path className="st0 Left_Side" d="M239.5336,182.715v4.91c0,0-11.87,0-21.4,0s-14.72-3.28-16.55-6.9c-0.7-1.3799-3.15-6.3799-5.91-12.04
            c0,0,0,0-0.01,0c-1.78-3.68-3.71-7.63-5.37-11.04c-1.71-3.54-3.15-6.49-3.86-7.94v-0.01c0.14-2.04,1.44-4.58,1.44-4.58l0.47,0.5
            l0.81,0.87l0.87,0.93l0.91,0.97l0.48,0.51c0,0,0.06,0.12,0.18,0.36c0.1,0.21,0.25,0.52,0.44,0.91v0.01
            c0.09,0.18,0.19,0.37,0.29,0.59c0.01,0.01,0.02,0.03,0.03,0.04c0.07,0.14,0.14,0.31,0.22,0.47h0.01
            c2.39,4.88,7.88,16.04,10.61,21.24c0.16,0.3,0.3101,0.58,0.45,0.84c0.08,0.16,0.16,0.3,0.23,0.44c0.19,0.35,0.39,0.7,0.58,1.03
            c2.9,4.96,6.29,7.95,12.15,7.95c2.16,0,5.54-0.01,9.06-0.02h1.69C233.5136,182.735,239.5336,182.715,239.5336,182.715z"/>
        <path className="st0 Left_Side" d="M197.3936,140.735c-0.1,0.47-0.15,0.95-0.15,1.44l-8.09,4.31l-0.81-0.87L197.3936,140.735z"/>
        <path className="st0 Left_Side" d="M197.3336,143.325l-7.31,4.09l-0.87-0.93l8.09-4.31C197.2436,142.565,197.2736,142.945,197.3336,143.325z"/>
        <path className="st0 Left_Side" d="M197.6236,144.475l-6.69,3.91l-0.91-0.97l7.31-4.09C197.4036,143.715,197.4936,144.095,197.6236,144.475z"/>
        <path className="st0 Left_Side" d="M198.0436,145.475l-6.45,3.78c-0.12-0.24-0.18-0.36-0.18-0.36l-0.48-0.51l6.69-3.91
            C197.7436,144.815,197.8836,145.145,198.0436,145.475z"/>
        <path className="st0 Left_Side" d="M192.0336,150.175v-0.01c-0.19-0.39-0.34-0.7-0.44-0.91l6.45-3.78c0.17,0.34,0.37,0.6801,0.59,1.01
            L192.0336,150.175z"/>
        <path className="st0 Left_Side" d="M199.4136,147.485l-6.74,3.74c-0.11-0.14-0.21-0.28-0.32-0.42c-0.01-0.01-0.02-0.03-0.03-0.04
            c-0.1-0.22-0.2-0.41-0.29-0.59l6.6-3.69C198.8736,146.835,199.1336,147.165,199.4136,147.485z"/>
        <line className="st1 Left_Side" x1="203.3177" y1="150.4613" x2="195.8098" y2="154.7363"/>
        <path className="st0 Left_Side" d="M243.8336,138.095c-0.55,1.15-2.47,3.45-2.47,3.45s-7.44-4-10.12-5.58c-1.35-0.79-2.39-1.72-3.1-2.54
            c-0.71-0.81-1.08-1.51-1.11-1.87v-0.01l1.01-2.2599c1.88-0.21,4.39,1.3199,5.41,1.47c1.02,0.15,1.96-0.58,2.34-0.45
            s7.96,4.55,7.96,4.55S244.3836,136.945,243.8336,138.095z"/>
        <line className="st1 Extra115" x1="225.9258" y1="149.2213" x2="231.6673" y2="152.2077"/>
        <line className="st1 Left_Side" x1="229.0966" y1="145.6255" x2="234.0906" y2="148.3658"/>
        <line className="st1 Extra117" x1="228.4162" y1="137.6468" x2="239.6668" y2="143.6952"/>
        <path className="st0 Left_Side" d="M228.0436,129.285l-1.01,2.2599v0.01l-0.3,0.67c0,0-5.41-2.43-5.62-3.0699c-0.21-0.64,0.68-1.69,0.68-1.69
            c0.2,0,0.4-0.01,0.6-0.01C224.0636,127.875,226.9736,128.665,228.0436,129.285z"/>
        <path className="st1 Extra119" d="M228.1436,133.425l-1.39,2.03l-0.2,0.29c-2.98-2.55-7.69-4.2-12.99-4.2c-5.63,0-10.59,1.85-13.52,4.67
            l-0.24-0.65l-0.39-1.03l-0.43-1.17l2.16-0.98l5.98-2.72l-0.89-2.06c3.81-0.06,7.95-0.11,12.09-0.11c1.2,0,2.36-0.01,3.47-0.03
            c0,0-0.89,1.05-0.68,1.69s5.62,3.0699,5.62,3.0699l0.3-0.67C227.0636,131.915,227.4336,132.615,228.1436,133.425z"/>
        <path className="st0 Left_Side" d="M207.1236,129.665l-5.98,2.72l-1.3-4.65c1.96-0.04,4.11-0.09,6.39-0.13L207.1236,129.665z"/>
        <path className="st1 Extra121" d="M244.5236,149.125c1.76-0.83,3.52-1.46,3.52-1.46"/>
        <path className="st0 Left_Side" d="M212.7436,166.435c-2.91,0-5.27,2.36-5.27,5.26c0,1.56,0.68,2.96,1.75,3.92c0,0,0,0,0.01,0
            c0.94,0.84,2.17,1.35,3.51,1.35c1.29,0,2.47-0.47,3.39-1.23c1.14-0.98,1.8701-2.42,1.8701-4.04
            C218.0036,168.795,215.6436,166.435,212.7436,166.435z M212.7436,174.305c-1.44,0-2.61-1.16-2.61-2.61c0-1.44,1.17-2.6,2.61-2.6
            s2.6,1.16,2.6,2.6C215.3436,173.145,214.1836,174.305,212.7436,174.305z"/>
        <path className="st0 Left_Side" d="M215.3436,171.695c0,1.45-1.16,2.61-2.6,2.61s-2.61-1.16-2.61-2.61c0-1.44,1.17-2.6,2.61-2.6
            S215.3436,170.255,215.3436,171.695z"/>
        <path className="st0 Left_Side" d="M222.1836,166.815v7.76c0,0-2.28,0.69-5.39,1.08c-0.22,0.03-0.43,0.05-0.66,0.08
            c1.14-0.98,1.8701-2.42,1.8701-4.04c0-2.9-2.36-5.26-5.26-5.26c-2.91,0-5.27,2.36-5.27,5.26c0,1.56,0.68,2.96,1.75,3.92
            c-2.03-0.35-3.98-1.04-5.58-2.26c-0.14-0.2599-0.29-0.54-0.45-0.84c0,0-0.06-2.35-0.35-6.4c1.93-1.48,4.39-2.65,7.13-3.06
            c1.7-0.25,3.51-0.21,5.37,0.22h0.01C217.6036,163.805,219.9136,164.925,222.1836,166.815z"/>
        <path className="st0 Left_Side" d="M209.9736,163.055c-2.74,0.41-5.2,1.58-7.13,3.06l-0.38-3.21l6.29-2.09L209.9736,163.055z"/>
        <path className="st0 Left_Side" d="M223.7136,163.725l-1.53,3.09c-2.27-1.89-4.58-3.01-6.83-3.54l1.82-2.46L223.7136,163.725z"/>
        <path className="st0 Left_Side" d="M249.7943,165.395l-17.62-0.11l-1.16-3.46c0.05,0.01,0.11,0.01,0.16,0.01c1.43,0,2.6-0.65,2.6-1.46
            c0-0.06-0.01-0.12-0.01-0.17h9.17C244.6243,161.505,247.0543,163.345,249.7943,165.395z"/>
        <path className="st1 Extra128" d="M228.5743,160.375c0,0.78,1.07,1.41,2.44,1.45l1.16,3.46l-4.21-0.03v5.55l7.79,0.89
            c-0.55,0.26-1.09,3.9-1.66,5.27c-0.58,1.37-6.74,3.48-6.74,3.48v-1.56c0-1.79-1.44-3.23-3.22-3.23h-7.34
            c3.11-0.39,5.39-1.08,5.39-1.08v-7.76l1.53-3.09l-6.54-2.91l-1.82,2.46h-0.01c-1.86-0.43-3.67-0.47-5.37-0.22l-1.22-2.24l-6.29,2.09
            l0.3799,3.21c0.29,4.05,0.35,6.4,0.35,6.4c-2.73-5.2-8.22-16.36-10.61-21.24l0.09-0.05c0.24,0.3101,0.49,0.61,0.74,0.91
            c0.23,0.28,0.47,0.55,0.71,0.82c0.28,0.31,0.56,0.62,0.84,0.91c0.29,0.3,0.5699,0.59,0.85,0.87c0.28,0.28,0.55,0.54,0.82,0.8
            c0.3,0.28,0.58,0.54,0.86,0.8h0.01c0.32,0.29,0.63,0.56,0.92,0.8c0.36,0.32,0.7,0.59,1,0.82c0.79,0.63,1.31,0.97,1.39,0.95
            c0.26-0.09,0.77-1.45,1.19-1.96c0.43-0.51,3.75-1.87,3.75-1.87l-0.17-0.59l-0.34-1.22l-0.33-1.2l-0.28-0.98
            c2.56,1.09,5.63,1.73,8.93,1.73c3.07,0,5.93-0.55,8.38-1.51l-0.27,1.02l-0.3,1.11l-0.35,1.3l-0.29,1.1l3.65,1.92v0.51
            c0,0,0.13,0.64,1.37,1.53c0.3-0.09,0.75-0.5,1.28-1.11c0.2-0.22,0.4-0.46,0.61-0.72l0.18-0.2401c0,0,9.33,0.16,10.1-1.71
            c0.76-1.86-3.48-8.23-3.48-8.23c0.05-0.08,0.07-0.1201,0.07-0.1201l1.28,0.94l0.94-1.19l0.77-0.98l0.73-0.93l0.62-0.78
            c0,0,0.3,0.09,0.81,0.21l2.56,5.44l0.42,0.9l2.51,0.12c0.7599,2.46,1.93,5.33,3.66,7.74c-4.01-2.93-7.03-5.15-7.03-5.15l-1.31,4.34
            c0,0,0.9301,0.72,2.46,1.88h-9.17c-0.16-0.74-1.26-1.3-2.59-1.3C229.7343,158.905,228.5743,159.555,228.5743,160.375z"/>
        <path className="st0 Left_Side" d="M237.9236,156.015c-0.77,1.87-10.1,1.71-10.1,1.71c0.19-0.23,0.37-0.47,0.56-0.73
            c0.23-0.31,0.47-0.64,0.71-0.98c0.19-0.26,0.38-0.53,0.57-0.81c0.23-0.34,0.47-0.68,0.71-1.03c0.21-0.33,0.43-0.65,0.64-0.97
            c0.22-0.34,0.44-0.67,0.65-1c0.21-0.32,0.41-0.63,0.6-0.93c0.21-0.31,0.4-0.62,0.58-0.9c0.24-0.39,0.46-0.74,0.66-1.06
            c0.23-0.37,0.43-0.69,0.59-0.95c0.16-0.27,0.28-0.47,0.35-0.58C234.4436,147.785,238.6836,154.155,237.9236,156.015z"/>
        <path className="st0 Left_Side" d="M233.7736,160.375c0,0.81-1.17,1.46-2.6,1.46c-0.05,0-0.11,0-0.16-0.01c-1.37-0.04-2.44-0.67-2.44-1.45
            c0-0.82,1.16-1.47,2.6-1.47c1.33,0,2.43,0.56,2.59,1.3C233.7636,160.255,233.7736,160.315,233.7736,160.375z"/>
        <path className="st0 Left_Side" d="M238.9936,144.545l-0.14,0.18l-0.62,0.78l-8.54-4.91c-0.1-0.4301-0.24-0.86-0.41-1.27L238.9936,144.545z"/>
        <path className="st0 Left_Side" d="M238.2336,145.505l-0.73,0.93l-7.63-4.26c0-0.54-0.06-1.06-0.1801-1.58L238.2336,145.505z"/>
        <path className="st0 Left_Side" d="M237.5036,146.435l-0.77,0.98l-6.97-3.99c0.07-0.41,0.11-0.83,0.11-1.25L237.5036,146.435z"/>
        <path className="st0 Left_Side" d="M236.7336,147.415l-0.94,1.19l-1.28-0.94l-5.11-2.94c0.16-0.42,0.28-0.86,0.36-1.3L236.7336,147.415z"/>
        <path className="st0 Left_Side" d="M230.3736,154.175c-0.2401,0.35-0.48,0.69-0.71,1.03l-7.48-4c0.48-0.19,0.94-0.4,1.38-0.62L230.3736,154.175z"
            />
        <path className="st0 Left_Side" d="M231.0136,153.205c-0.21,0.32-0.43,0.64-0.64,0.97l-6.81-3.59c0.37-0.19,0.73-0.39,1.08-0.6L231.0136,153.205z"
            />
        <path className="st0 Left_Side" d="M232.8436,150.375c-0.18,0.28-0.37,0.59-0.58,0.9l-5.5099-2.83c0.37-0.33,0.7-0.67,1.0099-1.03
            L232.8436,150.375z"/>
        <path className="st0 Left_Side" d="M233.5036,149.315c-0.2,0.32-0.42,0.67-0.66,1.06l-5.08-2.96c0.23-0.27,0.45-0.54,0.65-0.83L233.5036,149.315z"
            />
        <path className="st0 Left_Side" d="M229.6636,155.205c-0.19,0.28-0.38,0.55-0.57,0.81l-7.42-3.69l0.27-1.02c0.08-0.03,0.16-0.06,0.2401-0.1
            L229.6636,155.205z"/>
        <path className="st0 Left_Side" d="M229.0936,156.015c-0.24,0.34-0.48,0.67-0.71,0.98l-7.0099-3.56l0.3-1.11L229.0936,156.015z"/>
        <path className="st0 Left_Side" d="M228.3836,156.995c-0.19,0.26-0.37,0.5-0.56,0.73l-0.18,0.2401l-6.62-3.23l0.35-1.3L228.3836,156.995z"/>
        <path className="st0 Left_Side" d="M227.6436,157.965c-0.21,0.2599-0.41,0.5-0.61,0.72l-6.3-2.85l0.29-1.1L227.6436,157.965z"/>
        <path className="st0 Left_Side" d="M227.0336,158.685c-0.53,0.61-0.98,1.02-1.28,1.11c-1.2401-0.89-1.3701-1.53-1.3701-1.53v-0.51l-3.65-1.92
            L227.0336,158.685z"/>
        <path className="st0 Left_Side" d="M205.7536,155.075c0,0-3.32,1.36-3.75,1.87c-0.42,0.51-0.9301,1.87-1.19,1.96c-0.08,0.02-0.6-0.32-1.39-0.95
            l6.16-3.47L205.7536,155.075z"/>
        <path className="st0 Left_Side" d="M205.5836,154.485l-6.16,3.47c-0.3-0.23-0.64-0.5-1-0.82l6.82-3.87L205.5836,154.485z"/>
        <path className="st0 Left_Side" d="M205.2436,153.265l-6.82,3.87c-0.29-0.24-0.6-0.51-0.92-0.8l7.41-4.27L205.2436,153.265z"/>
        <path className="st0 Left_Side" d="M204.9136,152.065l-7.41,4.27h-0.01c-0.28-0.26-0.56-0.52-0.86-0.8l7.79-4.53c0.07,0.02,0.14,0.05,0.21,0.08
            L204.9136,152.065z"/>
        <path className="st0 Left_Side" d="M200.2436,148.325l-6.83,3.81c-0.25-0.3-0.5-0.6-0.74-0.91l6.74-3.74
            C199.6736,147.775,199.9436,148.055,200.2436,148.325z"/>
        <path className="st0 Left_Side" d="M202.1036,149.755l-7.14,4.11c-0.28-0.29-0.56-0.6-0.84-0.91l6.97-3.91
            C201.4036,149.285,201.7436,149.525,202.1036,149.755z"/>
        <path className="st0 Left_Side" d="M201.0936,149.045l-6.97,3.91c-0.2401-0.27-0.48-0.54-0.71-0.82l6.83-3.81
            C200.5036,148.575,200.7936,148.805,201.0936,149.045z"/>
        <path className="st0 Left_Side" d="M255.2836,170.835c-2.4-1.66-12.07-2.52-12.07-2.52s-6.92,3.13-7.46,3.38c-0.55,0.26-1.09,3.9-1.66,5.27
            c-0.58,1.37-6.74,3.48-6.74,3.48v2.31c6.16-0.02,12.18-0.04,12.18-0.04s4.05-1.05,7.2-0.88c1,0.06,3.59,0.2,6.92,0.39h0.01
            l4.01-4.91L255.2836,170.835z M253.1536,178.535c-0.75,0.75-1.78,1.21-2.91,1.21h-7.12c-2.27,0-4.12-1.85-4.12-4.12
            c0-1.14,0.46-2.16,1.21-2.91c0.74-0.75,1.78-1.21,2.91-1.21h7.12c2.27,0,4.11,1.85,4.11,4.12
            C254.3536,176.765,253.8936,177.785,253.1536,178.535z"/>
        <path className="st0 Left_Side" d="M280.4843,184.075c-1.08-0.12-2.18-0.23-3.33-0.32c-5.91-0.46-16.39-1.11-23.4901-1.53l4.01-4.91l-2.39-6.48
            c-2.4-1.66-12.0699-2.52-12.0699-2.52s-6.92,3.13-7.46,3.38l-7.79-0.89v-5.55l4.21,0.03l17.62,0.11c0.01,0,0.01,0,0.01,0
            c7.4,5.53,17.01,12.5,20.16,13.85c2.15,0.92,5.69,1.42,9.17,1.69C279.5643,182.005,280.1343,183.315,280.4843,184.075z"/>
        <path className="st1 Extra153" d="M336.6032,184.9028c-0.39,0.66-1.17,1.91-2.19,3.14l-2.9-3.29c1.5,0.04,2.91,0.09,4.18,0.12
            C336.0032,184.8828,336.3132,184.8928,336.6032,184.9028z"/>
        <path className="st0 Extra154" d="M325.4043,184.575c-0.19,0.22-3.3,3.77-10.59,3.77c-7.53,0-11.4-4.47-11.4-4.47c0.22,0,0.45,0.01,0.68,0.02
            c5.63,0.19,13.76,0.44,21.07,0.67C325.2443,184.565,325.3243,184.565,325.4043,184.575z"/>
        <path className="st1 Extra155" d="M300.3442,186.115l-2,4.19c-0.84-0.78-1.59-1.57-2.24-2.33L300.3442,186.115z"/>
        <path className="st1 Extra156" d="M302.9343,187.785l-1.6,4.91c-1.1-0.76-2.1-1.57-2.9901-2.39l2-4.19l-4.24,1.86c-2.58-2.99-3.76-5.59-3.76-5.59
            c0.21,0.07,0.42,0.14,0.63,0.21c1.4301,0.5,3.03,0.94,4.22,1.03c0.2,0.01,0.59,0.03,1.15,0.06
            C298.3442,183.685,300.0043,185.735,302.9343,187.785z"/>
        <path className="st1 Extra157" d="M305.1042,189.145l-3.77,3.55l1.6-4.91C303.5942,188.245,304.3143,188.705,305.1042,189.145z"/>
        <path className="st1 Extra158" d="M311.6543,191.565l-3.21,4.42v-5.31C309.4543,191.045,310.5143,191.355,311.6543,191.565z"/>
        <path className="st1 Extra159" d="M308.4443,190.675l-3.14,4.2c-1.46-0.62-2.78-1.37-3.97-2.18l3.77-3.55
            C306.1143,189.715,307.2343,190.245,308.4443,190.675z"/>
        <path className="st1 Extra160" d="M308.4443,190.675v5.31c-1.11-0.3-2.16-0.67-3.14-1.11L308.4443,190.675z"/>
        <line className="st1 Extra161" x1="315.6651" y1="191.8808" x2="317.5768" y2="196.6924"/>
        <path className="st1 Extra162" d="M313.0743,196.755c-1.65-0.11-3.19-0.38-4.63-0.77l3.21-4.42h0.0099L313.0743,196.755z"/>
        <path className="st1 Extra163" d="M323.9843,189.855v5.45c-1,0.36-2.06,0.66-3.2,0.9l0.19-5.16
            C322.0743,190.715,323.0843,190.305,323.9843,189.855z"/>
        <path className="st1 Extra164" d="M328.0443,193.435c-1.21,0.7-2.56,1.34-4.06,1.87v-5.45L328.0443,193.435z"/>
        <path className="st1 Extra165" d="M331.5143,190.985c-0.99,0.84-2.14,1.68-3.47,2.45l-4.06-3.58c1.14-0.56,2.13-1.19,2.97-1.81L331.5143,190.985z
            "/>
        <path className="st1 Extra166" d="M334.4132,188.0428c-0.75,0.9-1.71,1.92-2.9,2.94l-4.56-2.94c2.24-1.65,3.4-3.22,3.48-3.32
            c0.37,0.01,0.73,0.02,1.08,0.03L334.4132,188.0428z"/>
        <path className="st0 Extra167" d="M322.9943,172.475v-0.0099l-0.24-2.65l-14.4,0.16l-2.1,0.02l-7.64,0.09l-3.48,0.04l-5.12,0.06l-12.46,0.14
            l-12.06,0.14l-1.04,0.0099c1.92,1.3701,3.33,2.36,3.85,2.6801c2.55,1.5699,4.51,2.2599,6.04,2.47c1.53,0.21,15.96,0.51,15.96,0.51
            l0.29,0.01l5.29,0.09l3.36,0.07l21.14,0.39h0.01l2.86,0.06c0.04-0.05,0.07-0.1,0.12-0.14L322.9943,172.475z M317.1642,175.685
            c-1.23,0-2.23-1-2.23-2.23s1-2.23,2.23-2.23c1.2401,0,2.2401,1,2.2401,2.23C319.4043,174.685,318.4043,175.685,317.1642,175.685z"/>
        <path className="st0 Extra168" d="M325.2443,170.935c0,2.13-0.7,4.09-1.87,5.68l-0.38-4.14v-0.0099c0.11-0.5,0.16-1,0.16-1.53
            c0-4.11-3.33-7.43-7.4301-7.43c-3.78,0-6.91,2.81-7.37,6.47l-2.1,0.02c0.03-0.31,0.08-0.62,0.14-0.92c0.12-0.61,0.3-1.21,0.54-1.77
            c1.42-3.46,4.82-5.89,8.79-5.89C320.9742,161.415,325.2443,165.675,325.2443,170.935z"/>
        <path className="st0 Extra169" d="M323.1543,170.935c0,0.53-0.05,1.03-0.16,1.53l-0.24-2.65l-14.4,0.16c0.46-3.66,3.59-6.47,7.37-6.47
            C319.8243,163.505,323.1543,166.825,323.1543,170.935z"/>
        <path className="st0 Extra170" d="M331.5143,171.555c0,1.87-0.31,3.66-0.89,5.33l-7.24-0.13l-0.01-0.14c1.17-1.59,1.87-3.55,1.87-5.68
            c0-5.26-4.27-9.52-9.52-9.52c-3.97,0-7.37,2.4301-8.79,5.89c-0.16-1.05-1.06-1.85-2.15-1.85c-1.2,0-2.17,0.97-2.17,2.17
            c0,1.19,0.97,2.17,2.17,2.17c0.64,0,1.21-0.28,1.61-0.72c-0.06,0.3-0.11,0.61-0.14,0.92l-7.64,0.09c0.11-1.29,0.38-2.54,0.77-3.73
            l1.03,1.27c0,0,3.19-1.83,4.02-4.19l-1.85-2.68c3.02-3.48,7.48-5.68,12.44-5.68C324.1343,155.075,331.5143,162.455,331.5143,171.555
            z"/>
        <line className="st1 Extra171" x1="290.4656" y1="165.9823" x2="295.8943" y2="165.2637"/>
        <path className="st2 Extra172" d="M336.9543,133.835c-1.38,0.31-3,1.07-4.82,2.28l-7.41-0.39c-7.53-4.14-17.61-0.91-17.61-0.91l-13.32-0.69
            C317.5942,120.035,336.9543,133.835,336.9543,133.835z"/>
        <path className="st2 Extra173" d="M352.7543,154.435l-6.15-1.39h-0.01c-3.28-6.5-7.25-9.46-7.25-9.46c1.7-0.53,2.21-4.47,1.79-7.16
            C349.2443,143.495,352.7543,154.435,352.7543,154.435z"/>
        <path className="st0 Extra174" d="M343.5043,130.455v3.47c-0.74-0.61-1.48-1.19-2.23-1.73L343.5043,130.455z"/>
        <path className="st0 Carrier" d="M349.6743,124.885v9.79c0,1.69-3.52,1.72-3.52,1.72c-0.86-0.89-1.75-1.71-2.65-2.46v-3.48l-2.23,1.74
            c-0.6-0.44-1.21-0.85-1.81-1.24l4.03-2.88v-5.43C343.4943,122.645,349.6743,121.435,349.6743,124.885z"/>
        <polyline className="st1 Extra176" points="345.7336,122.4451 345.7336,128.7151 343.5036,130.455 "/>
        <path className="st0 Left_Rear_Indicator" d="M339.3442,143.585c-0.6299,0.19-1.48,0.11-2.39-0.15c1.83-4.48,0.85-9.75,0.85-9.75
            c0.83-0.1,1.56-0.01,2.18,0.26c0.56,0.24,0.96,1.23,1.15,2.48C341.5543,139.115,341.0443,143.055,339.3442,143.585z"/>
        <path className="st0 Left_Rear_Indicator" d="M336.9543,143.435c-2.67-0.74-5.95-2.93-6.29-3.75c-0.45-1.08,1.47-3.57,1.47-3.57
            c1.82-1.21,3.44-1.97,4.82-2.28c0.29-0.08,0.58-0.13,0.85-0.15C337.8043,133.685,338.7842,138.955,336.9543,143.435z"/>
        <path className="st2 Extra179" d="M284.6943,148.325c-4.77,6.53-6.49,13.09-6.59,13.49l0.03-0.55c-0.16-6.98,1.84-12.01,4.47-15.51
            L284.6943,148.325z"/>
        <path className="st2 Extra180" d="M294.7842,138.995c-1.65,1.03-3.53,2.4-4.78,3.5l-1.39-2.01c1.9-1.04,3.59-1.5,4.57-1.5
            C293.4843,138.985,294.0342,138.995,294.7842,138.995z"/>
        <path className="st1 Extra181" d="M296.5342,162.865l-4.7599-1.34c0.38-0.98,0.84-1.93,1.35-2.85l0.28,0.34l1.1899,1.46l1.1,1.36
            L296.5342,162.865z"/>
        <line className="st1 Extra182" x1="311.5284" y1="145.9998" x2="313.7502" y2="150.8572"/>
        <path className="st1 Extra183" d="M324.4943,147.625l-3.52,4.14c-1.04-0.33-2.12-0.57-3.23-0.72l2.57-4.72
            C321.7643,146.625,323.1543,147.065,324.4943,147.625z"/>
        <line className="st1 Extra184" x1="330.6651" y1="151.466" x2="328.8031" y2="156.3936"/>
        <path className="st1 Extra185" d="M336.9142,158.645l-5.27,1.14h-0.01c-0.6-0.92-1.28-1.79-2.03-2.59l4.55-2.54
            C335.2043,155.885,336.1243,157.225,336.9142,158.645z"/>
        <line className="st1 Extra186" x1="334.1559" y1="164.8702" x2="338.103" y2="161.0985"/>
        <line className="st1 Extra187" x1="334.7464" y1="167.9665" x2="338.3885" y2="165.9822"/>
        <path className="st1 Extra188" d="M339.805,174.2415c-0.003,0.0218-4.9871-2.2543-4.8614-2.2543"/>
        <path className="st1 Extra189" d="M339.1243,177.495c-0.14,0.52-0.3,1.03-0.48,1.53l-4.49-1.23L339.1243,177.495z"/>
        <path className="st0 Extra190" d="M319.4043,173.455c0,1.23-1,2.23-2.2401,2.23c-1.23,0-2.23-1-2.23-2.23s1-2.23,2.23-2.23
            C318.4043,171.225,319.4043,172.225,319.4043,173.455z"/>
        <path className="st0 Rear_Suspension" d="M306.9343,167.305c-0.24,0.56-0.42,1.16-0.54,1.77c-0.4,0.44-0.97,0.72-1.61,0.72c-1.2,0-2.17-0.98-2.17-2.17
            c0-1.2,0.97-2.17,2.17-2.17C305.8743,165.455,306.7743,166.255,306.9343,167.305z"/>
        <line className="st1 Extra192" x1="277.8436" y1="165.455" x2="275.7236" y2="166.315"/>
        <path className="st0 Extra193" d="M290.9742,163.865c-0.14,0.49-0.27,1-0.38,1.51c0,0-0.64,0.09-1.4399,0.08l-0.4-2.59
            C288.8943,162.865,290.3442,162.915,290.9742,163.865z"/>
        <path className="st0 Extra194" d="M289.3643,166.785l-3.63,3.24l-3.95-2.98l6.63-6.32l0.33,2.14c0,0,0,0,0.01,0l0.4,2.59L289.3643,166.785z"/>
        <path className="st0 Extra195" d="M332.1343,136.115l-7.41-0.39l-17.61-0.91l-13.32-0.69h-0.01l-3.5099-0.18c-2.76,1.58-5.91,3-10.14,3.6
            c-0.53,1.68-1.27,3.4-2.32,5.82h6.91c1.29-1.24,2.64-2.19,3.89-2.88c1.9-1.04,3.59-1.5,4.57-1.5c0.3,0,0.85,0.01,1.6,0.01h0.01
            c7.64,0.11,35.87,0.69,35.87,0.69C330.2143,138.605,332.1343,136.115,332.1343,136.115z M283.9443,143.025
            c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.53,2.54-2.53s2.53,1.13,2.53,2.53C286.4742,141.885,285.3442,143.025,283.9443,143.025z"/>
        <path className="st0 Rear_Fender" d="M284.7242,143.365c-0.73,0.7-1.45,1.49-2.12,2.39c-2.63,3.5-4.63,8.53-4.47,15.51l-4.98,0.57
            c0,0-0.32-6.76,2.17-12.7c0.98-2.33,1.8-4.2,2.48-5.77H284.7242z"/>
        <path className="st1 Extra515" d="M320.3143,146.325l-2.57,4.72c-0.29-0.05-0.59-0.08-0.89-0.1l-1.08-5.17
            C317.3243,145.815,318.8442,145.995,320.3143,146.325z"/>
        <path className="st1 Extra197" d="M308.6243,146.585l-0.54,5.52c-1.15,0.42-2.25,0.95-3.28,1.58l-0.15-5.68
            C305.9243,147.425,307.2443,146.945,308.6243,146.585z"/>
        <path className="st1 Extra198" d="M304.8043,153.685l-3.89-3.57c1.18-0.81,2.43-1.52,3.7401-2.11L304.8043,153.685z"/>
        <path className="st1 Extra199" d="M304.8043,153.685c-0.82,0.49-1.6,1.04-2.34,1.64l-4.52-2.82c0.93-0.88,1.91-1.67,2.96-2.39h0.0099
            L304.8043,153.685z"/>
        <path className="st1 Extra200" d="M302.4643,155.325c-0.78,0.64-1.51,1.33-2.19,2.08l-0.37-0.54l-1.14-1.66l-1.15-1.66v-0.0099l-0.3-0.4301
            c0.21-0.21,0.42-0.41,0.63-0.6L302.4643,155.325z"/>
        <path className="st0 Left_Side" d="M275.3243,149.135c0.98-2.33,1.8-4.2,2.48-5.77h0.01c1.05-2.42,1.79-4.14,2.32-5.82
            c-8.47,1.19-26.17-0.2-35.15-7.7c-2.45-1.98-4.75-6.6201-5.45-8.11c0.02,0.07,0.04,0.14,0.05,0.21c0.35,2.46-2.12,3.9-6.73,4.7
            c0,0,9.79,3.02,13.45,8.21h-2.55c0,0,0.63,2.09,0.08,3.2401c-0.55,1.15-2.47,3.45-2.47,3.45l-0.99,1.26l-0.71,0.89l-0.67,0.85
            l-0.14,0.18c0,0,0.3,0.09,0.81,0.21c0.92,0.23,2.51,0.59,4.2,0.82c2.12,0.3,4.4,0.39,5.7-0.24c0,0,0.97-7.23,1.42-8.68
            c0.45-1.44,17.53,1.68,19.4,1.81c1.88,0.13-0.29,14.77-0.89,16c-0.59,1.24-2.13,0.94-5.36,0.55c-3.23-0.38-12.38-1.87-14.09-2.04
            c-0.48-0.05-0.73-0.6801-0.83-1.56l-4.06-0.2c0.7599,2.46,1.93,5.33,3.66,7.74c2.67,1.95,5.79,4.22,8.74,6.36
            c-0.01-0.08-0.01-0.15-0.01-0.23c0-2.74,2.22-4.95,4.95-4.95c2.13,0,3.96,1.35,4.65,3.25l2.28-0.12l6.3-0.33l2.3-0.12v-0.01
            l0.0699-1.15c0,0,0-0.01,0.01-0.02l0.03-0.55l-4.98,0.57C273.1543,161.835,272.8343,155.075,275.3243,149.135z M264.2143,159.015
            c0,0-3.47-2.31-3.73-2.68c-0.25-0.37,3.67-1.3,4.56,0.78C265.5942,158.325,264.2143,159.015,264.2143,159.015z M272.8343,139.595
            l3.93,0.73l-0.42,4.37h-3.99L272.8343,139.595z"/>
        <path className="st0 Left_Side" d="M245.1543,151.395l-2.51-0.12l-0.42-0.9c0.64-0.43,1.47-0.87,2.3-1.25
            C244.6943,149.815,244.9043,150.585,245.1543,151.395z"/>
        <path className="st0 Left_Side" d="M244.5243,149.125c-0.83,0.38-1.66,0.82-2.3,1.25l-2.56-5.44c0.92,0.23,2.51,0.59,4.2,0.82
            C243.8643,145.755,244.0342,147.125,244.5243,149.125z"/>
        <path className="st0 Left_Side" d="M241.3636,141.545l-0.99,1.26l-13.62-7.35l1.39-2.03c0.71,0.82,1.75,1.75,3.1,2.54
            C233.9236,137.545,241.3636,141.545,241.3636,141.545z"/>
        <path className="st0 Left_Side" d="M246.3036,134.855h-2.55c0,0-7.58-4.42-7.96-4.55s-1.32,0.6-2.34,0.45c-1.02-0.15-3.53-1.68-5.41-1.47
            c-1.07-0.6201-3.98-1.41-5.65-1.83c4.16-0.09,7.69-0.33,10.46-0.81C232.8536,126.645,242.6436,129.665,246.3036,134.855z"/>
        <polygon className="st0 Left_Side" points="275.7242,163.115 275.7242,166.315 267.2743,169.745 269.4243,163.445 "/>
        <path className="st0 Left_Side" d="M264.2136,159.015c0,0-3.47-2.31-3.73-2.68c-0.25-0.37,3.67-1.3,4.56,0.78
            C265.5936,158.325,264.2136,159.015,264.2136,159.015z"/>
        <path className="st0 Left_Side" d="M267.4443,165.265c0,2.27-1.53,4.18-3.62,4.76c-1.81-1.3-3.99-2.87-6.27-4.52v-0.01
            c-0.01-0.08-0.01-0.15-0.01-0.23c0-2.74,2.22-4.95,4.95-4.95c2.13,0,3.96,1.35,4.65,3.25
            C267.3442,164.095,267.4443,164.665,267.4443,165.265z"/>
        <path className="st0 Left_Side" d="M269.4243,163.445l-2.15,6.3l-1.78,0.72l-1.04,0.0099c-0.2-0.15-0.41-0.3-0.63-0.45
            c2.09-0.58,3.62-2.49,3.62-4.76c0-0.6-0.1-1.17-0.3-1.7L269.4243,163.445z"/>
        <path className="st0 Silencer/Exhaust" d="M290.3043,176.135l-0.89,5.11c0,0-5.07,0.1-10.28-0.31c-3.48-0.27-7.02-0.77-9.17-1.69
            c-3.15-1.35-12.76-8.32-20.16-13.85c0,0,0,0-0.01,0c-2.74-2.05-5.17-3.89-6.86-5.19c-1.53-1.16-2.46-1.88-2.46-1.88l1.31-4.34
            c0,0,3.02,2.22,7.03,5.15c2.67,1.95,5.79,4.22,8.74,6.36v0.01c2.28,1.65,4.46,3.22,6.27,4.52c0.22,0.15,0.43,0.3,0.63,0.45
            c1.92,1.3701,3.33,2.36,3.85,2.6801c2.55,1.5699,4.51,2.2599,6.04,2.47C275.8743,175.835,290.3043,176.135,290.3043,176.135z"/>
        <path className="st0 Left_Side" d="M269.4936,154.645c-0.59,1.24-2.13,0.94-5.36,0.55c-3.23-0.38-12.3799-1.87-14.09-2.04
            c-0.48-0.05-0.73-0.6801-0.83-1.56c-0.28-2.23,0.35-6.08,0.35-6.08s0.97-7.23,1.42-8.68c0.45-1.44,17.53,1.68,19.3999,1.81
            C272.2636,138.775,270.0936,153.415,269.4936,154.645z"/>
        <path className="st0 Left_Side" d="M272.8336,139.595l-0.48,5.1h3.99l0.42-4.37L272.8336,139.595z M274.5636,143.675c-0.73,0-1.31-0.58-1.31-1.3
            c0-0.73,0.58-1.31,1.31-1.31c0.72,0,1.3,0.58,1.3,1.31C275.8636,143.095,275.2836,143.675,274.5636,143.675z"/>
        <path className="st0 Left_Side" d="M275.8636,142.375c0,0.72-0.58,1.3-1.3,1.3c-0.73,0-1.31-0.58-1.31-1.3c0-0.73,0.58-1.31,1.31-1.31
            C275.2836,141.065,275.8636,141.645,275.8636,142.375z"/>
        <path className="st0 Rear_Suspension" d="M286.4742,140.485c0,1.4-1.13,2.54-2.53,2.54s-2.54-1.14-2.54-2.54s1.14-2.53,2.54-2.53
            S286.4742,139.085,286.4742,140.485z"/>
        <g id="_x32_6">
            <path className="st0 Left_Front_Indicator" d="M189.8536,88.595c-0.21,1.94-3.07,4.98-6.83,5.04c-0.63-8.48,1-9.36,1-9.36c1.82,0.57,3.91,1.62,5.03,2.73
                C189.5936,87.535,189.9136,88.085,189.8536,88.595z"/>
            <path className="st0 Left_Front_Indicator" d="M183.0232,93.6328c-0.5,0.0099-1.01-0.03-1.54-0.14c-0.4-0.07-0.8-0.19-1.2-0.34c0,0-0.35-1.51-0.52-3.32
                c-0.12-1.25-0.17-2.64,0.02-3.78h0.01c0.16-0.91,0.47-1.67,1.03-2.06c0.41-0.26,1.28-0.21,2.33,0.04c0.28,0.07,0.57,0.15,0.87,0.24
                C184.0232,84.2728,182.3932,85.1528,183.0232,93.6328z"/>
        </g>
        <path className="st0 Speedometer/Odometer" d="M214.4643,102.955l-1.34,3.03c0,0-0.41-0.02-1.11-0.1c-0.13-1.02-0.49-2.13-1.41-2.39
            c-1.79-0.51-16.79-5.3-16.79-5.3l-8.63,3.05l8.57-10.29l0.97,0.56h0.01l8.53,4.95l1.36,0.79l8.86,5.13L214.4643,102.955z"/>
        <path className="st0 Silencer/Exhaust" d="M343.4132,180.3428l-1.07,4.73c0,0-2.24-0.07-5.74-0.17c-0.29-0.01-0.6-0.02-0.91-0.03
            c-1.27-0.03-2.68-0.08-4.18-0.12c-0.35-0.01-0.71-0.02-1.08-0.03c-0.38-0.02-0.76-0.03-1.15-0.04c-1.25-0.04-2.55-0.08-3.88-0.11
            c-0.08-0.01-0.16-0.01-0.24-0.01c-7.31-0.23-15.44-0.48-21.07-0.67c-0.23-0.0099-0.46-0.02-0.68-0.02c-1.34-0.05-2.52-0.1-3.49-0.13
            c-0.62-0.02-1.15-0.04-1.58-0.06c-0.56-0.03-0.95-0.05-1.15-0.06c-1.19-0.09-2.79-0.53-4.22-1.03c-0.21-0.07-0.42-0.14-0.63-0.21
            c-1.64-0.58-2.93-1.14-2.93-1.14l0.89-5.11l0.29,0.0099l5.29,0.09l3.36,0.07l21.14,0.39h0.01l2.86,0.06h0.13l7.2401,0.13
            l0.28,0.0099l2.81,0.78l0.44,0.12l4.49,1.23L343.4132,180.3428z"/>
        <g id="_x32_3">
            <path className="st0 Rear_Suspension" d="M298.7643,155.205c0,0-1.08,3.13-5.36,3.81l-0.28-0.34v-0.01l-1.27-1.55c4.04-0.65,5.76-3.57,5.76-3.57
                L298.7643,155.205z"/>
            <path className="st0 Rear_Suspension" d="M299.9043,156.865c0,0-2.3,3.14-5.3101,3.61l-1.1899-1.46c4.28-0.68,5.36-3.81,5.36-3.81L299.9043,156.865z"/>
            <path className="st0 Rear_Suspension" d="M300.9043,158.325c0,0-1.4301,3.45-5.21,3.51l-1.1-1.36c3.01-0.47,5.3101-3.61,5.3101-3.61l0.37,0.54
                L300.9043,158.325z"/>
            <path className="st0 Rear_Suspension" d="M304.4343,163.435c-0.83,2.36-4.02,4.19-4.02,4.19l-1.03-1.27l-2.7-3.31l-0.15-0.18l-0.84-1.03
                c3.78-0.06,5.21-3.51,5.21-3.51l1.68,2.43L304.4343,163.435z"/>
            <path className="st0 Rear_Suspension" d="M297.6143,153.535v0.0099c0,0-1.72,2.92-5.76,3.57h-0.01v-0.01l-1.28-1.5699
                c4.96-1.23,5.67-3.9901,5.67-3.9901l1.08,1.56L297.6143,153.535z"/>
            <path className="st0 Rear_Suspension" d="M296.2343,151.545c0,0-0.71,2.76-5.67,3.9901l-1.2-1.48c5.06-1.43,5.77-4.11,5.77-4.11L296.2343,151.545z"/>
            <path className="st0 Rear_Suspension" d="M295.1343,149.945c0,0-0.71,2.68-5.77,4.11l-1.39-1.7c0,0,4.28-0.7,6-4.1L295.1343,149.945z"/>
            <path className="st0 Rear_Suspension" d="M293.9742,148.255c-1.72,3.4-6,4.1-6,4.1l-1.33-1.64c4.83-1.23,6.17-4.13,6.17-4.13L293.9742,148.255z"/>
            <path className="st0 Rear_Suspension" d="M292.8143,146.585c0,0-1.34,2.9-6.17,4.13l-1.12-1.37c0,0,4.38-1.52,6.09-4.5L292.8143,146.585z"/>
            <path className="st0 Rear_Suspension" d="M291.6143,144.835v0.01c-1.71,2.98-6.09,4.5-6.09,4.5l-0.4-0.4901c1.83-2.61,5.7-5.17,5.7-5.17
                L291.6143,144.835z"/>
            <path className="st0 Rear_Suspension" d="M290.8243,143.685c0,0-3.87,2.56-5.7,5.17l-0.43-0.53l-2.09-2.57c0.67-0.9,1.39-1.69,2.12-2.39
                c1.29-1.24,2.64-2.19,3.89-2.88l1.39,2.01L290.8243,143.685z"/>
        </g>
        <g id="_x32_2_1_">
            <path className="st0 Front_Suspension" d="M149.8136,136.885l-0.66,1.03l-0.47,0.73l-4.59,7.21l-1.76,2.76l-2.32,3.64l-2.09,3.27l-3.3,5.19l-1.84,2.88
                l-1.82,2.86l-1.94,3.04c0,0-3-0.77-3.95-2.68l2.12-3.24l1.63-2.51l0.45-0.7l1.15-1.74l1.9-2.92l1.6801-2.56l4.55-6.97l1.79-2.74
                l4.69-7.18l0.45-0.7l0.72-1.1L149.8136,136.885z"/>
            <polygon className="st0 Front_Suspension" points="175.5643,96.475 172.8243,100.785 168.1143,108.165 166.7143,110.375 162.9742,116.235 
                159.5942,113.955 163.2143,108.405 171.0942,96.345 174.4142,94.045 174.7043,94.655 	"/>
            <polygon className="st0 Front_Suspension" points="160.4736,120.155 152.8536,132.105 149.3036,129.715 157.0536,117.845 	"/>
        </g>
        <g id="_x32_1">
            <path className="st0 Rear_Wheel_Rim" d="M330.4343,184.725c-0.08,0.1-1.24,1.67-3.48,3.32c-0.84,0.62-1.83,1.25-2.97,1.81
                c-0.9,0.45-1.91,0.86-3.01,1.19c-1.71,0.51-3.65,0.84-5.8199,0.84c-1.22,0-2.38-0.12-3.4901-0.32h-0.0099
                c-1.14-0.21-2.2-0.52-3.21-0.89c-1.21-0.43-2.33-0.96-3.34-1.53c-0.79-0.44-1.51-0.9-2.17-1.36c-2.93-2.05-4.59-4.1-4.59-4.1
                c0.4301,0.02,0.96,0.04,1.58,0.06c0.97,0.03,2.15,0.08,3.49,0.13c0,0,3.87,4.47,11.4,4.47c7.29,0,10.4-3.55,10.59-3.77
                c1.33,0.03,2.6299,0.07,3.8799,0.11C329.6743,184.695,330.0543,184.705,330.4343,184.725z"/>
            <path className="st0 Rear_Wheel_Rim" d="M334.9443,170.775c0,2.43-0.43,4.75-1.23,6.9l-2.81-0.78l-0.28-0.01c0.58-1.67,0.89-3.46,0.89-5.33
                c0-9.1-7.38-16.48-16.49-16.48c-4.96,0-9.42,2.2-12.44,5.68l-1.68-2.43l-0.63-0.92c0.68-0.75,1.41-1.44,2.19-2.08
                c0.74-0.6,1.52-1.15,2.34-1.64c1.03-0.63,2.13-1.16,3.28-1.58c2.16-0.81,4.5-1.25,6.94-1.25c0.62,0,1.23,0.03,1.83,0.09
                c0.3,0.02,0.6,0.05,0.89,0.1c1.11,0.15,2.19,0.39,3.23,0.72c1.9,0.59,3.67,1.46,5.28,2.56c0.91,0.61,1.76,1.3,2.55,2.07
                c0.27,0.26,0.54,0.53,0.79,0.8h0.01c0.75,0.8,1.43,1.67,2.03,2.59h0.01c1.59,2.41,2.68,5.1899,3.1,8.18
                c0.03,0.19,0.05,0.38,0.07,0.57C334.9043,169.275,334.9443,170.025,334.9443,170.775z"/>
            <path className="st0 Rear_Wheel_Rim" d="M299.3843,166.355c-0.39,1.19-0.66,2.44-0.77,3.73l-3.48,0.04c0.08-2.5,0.62-4.89,1.55-7.08L299.3843,166.355z
                "/>
        </g>
        <path className="st0 Front_Wheel_Rim" d="M155.7036,169.205c0,16.18-13.11,29.3-29.29,29.3s-29.3-13.12-29.3-29.3s13.12-29.29,29.3-29.29
            c5.04,0,9.79,1.28,13.93,3.52l-1.79,2.74c-0.27-0.15-0.55-0.29-0.83-0.42c-1.36-0.66-2.79-1.21-4.27-1.62
            c-1.21-0.34-2.46-0.6-3.73-0.76c-1.08-0.14-2.19-0.21-3.31-0.21h-0.2c-1.64,0.01-3.25,0.1801-4.8,0.48v0.01
            c-1.2,0.22-2.36,0.54-3.49,0.93c-1.31,0.45-2.58,1-3.78,1.65h-0.01c-1.29,0.69-2.5099,1.48-3.65,2.37
            c-1.05,0.8-2.02,1.6801-2.93,2.64v0.01c-0.88,0.91-1.69,1.89-2.42,2.93c-0.86,1.21-1.61,2.49-2.25,3.84
            c-0.58,1.2-1.06,2.45-1.44,3.75c-0.3799,1.25-0.66,2.54-0.83,3.86c-0.17,1.17-0.25,2.36-0.25,3.57c0,0.1,0,0.19,0.01,0.29
            c0.0099,1.52,0.16,3.02,0.43,4.46c0.25,1.39,0.61,2.73,1.08,4.03c0.38,1.08,0.84,2.13,1.36,3.14c0.73,1.42,1.59,2.77,2.56,4.02h0.01
            c0.94,1.22,1.99,2.35,3.1299,3.39c1.1,0.99,2.28,1.89,3.53,2.67v0.01c0.84,0.54,1.72,1.03,2.62,1.46c1.37,0.66,2.8,1.21,4.28,1.61
            h0.0099c1.59,0.45,3.23,0.75,4.92,0.8799c0.7,0.06,1.4,0.09,2.11,0.09c0.8,0,1.58-0.04,2.36-0.11c1.07-0.09,2.12-0.25,3.15-0.48
            c1.51-0.3199,2.96-0.77,4.36-1.35c1.31-0.53,2.57-1.17,3.76-1.92c1.22-0.74,2.36-1.58,3.43-2.52h0.01c0.9-0.78,1.75-1.63,2.53-2.52
            v-0.0099c0.9901-1.13,1.88-2.34,2.67-3.63c0.66-1.09,1.25-2.2401,1.75-3.44c0.49-1.17,0.9-2.39,1.22-3.65
            c0.33-1.3,0.57-2.65,0.69-4.02c0.07-0.79,0.11-1.59,0.11-2.4c0-0.44-0.01-0.88-0.04-1.31c-0.06-1.35-0.23-2.67-0.49-3.96
            c-0.29-1.4-0.69-2.75-1.19-4.06c-0.47-1.22-1.03-2.39-1.67-3.51c-0.6201-1.11-1.33-2.16-2.1201-3.16
            c-1.33-1.72-2.8799-3.26-4.61-4.59l1.76-2.76C151.1536,151.195,155.7036,159.665,155.7036,169.205z"/>
        <g id="_x31_9">
            <path className="st0 Rear_Tyre" d="M293.1257,158.6628v0.01c-0.5099,0.92-0.97,1.87-1.35,2.85c-0.31,0.76-0.57,1.54-0.8,2.34
                c-0.63-0.95-2.08-1-2.22-1c-0.01,0-0.01,0-0.01,0l-0.33-2.14l-6.63,6.32l-0.6,0.58l-3.27-2.91l0.11-1.72v-0.01l0.07-1.15
                c0,0,0-0.0099,0.01-0.02c0.1-0.4,1.82-6.96,6.59-13.49l0.43,0.53l0.4,0.49l1.12,1.37l1.33,1.64l1.39,1.7l1.2,1.48l1.28,1.57v0.01
                h0.01L293.1257,158.6628z"/>
            <path className="st0 Rear_Tyre" d="M290.5958,165.3728c-0.05,0.2-0.09,0.41-0.13,0.61c-0.26,1.36-0.42,2.77-0.45,4.2l-12.46,0.14l0.1899-3.03
                l3.07,2.73l1.5-1.39l-1.13-1.01l0.6-0.58l3.95,2.98l3.63-3.24l-0.21-1.33C289.9557,165.4628,290.5958,165.3728,290.5958,165.3728z"
                />
            <path className="st0 Rear_Tyre" d="M350.9032,172.3028c0,20.08-18.81,34.38-36.34,34.38c-17.54,0-26.49-9.59-30.31-15.39
                c4.09-0.35,8.56-0.86,8.56-0.86v-4.17c0,0-5.24-1.37-12.28-2.1899h-0.05c-0.35-0.76-0.92-2.07-1.35-3.14
                c5.21,0.41,10.28,0.31,10.28,0.31s1.29,0.56,2.93,1.14c0,0,1.18,2.6,3.76,5.59c0.65,0.76,1.4,1.55,2.24,2.33
                c0.89,0.82,1.89,1.63,2.99,2.39c1.19,0.81,2.51,1.56,3.97,2.18c0.98,0.44,2.03,0.81,3.14,1.11c1.44,0.39,2.98,0.66,4.63,0.77
                c0.57,0.04,1.15,0.06,1.74,0.06c0.96,0,1.88-0.04,2.76-0.12c1.13-0.11,2.2-0.27,3.21-0.4901c1.14-0.24,2.2-0.54,3.2-0.9
                c1.5-0.53,2.85-1.17,4.06-1.87c1.33-0.77,2.48-1.61,3.47-2.45c1.19-1.02,2.15-2.04,2.9-2.94c1.02-1.23,1.8-2.48,2.19-3.14
                c3.5,0.1,5.74,0.17,5.74,0.17l1.07-4.73l-4.77-1.32c0.18-0.5,0.34-1.01,0.48-1.53c0.3-1.05,0.53-2.14,0.68-3.25
                c0.16-1.13,0.24-2.29,0.24-3.47c0-0.21,0-0.43-0.01-0.64c-0.08-3.2-0.76-6.2401-1.93-9.03v-0.01c-0.35-0.84-0.75-1.66-1.19-2.45
                c-0.79-1.42-1.71-2.7599-2.76-3.99c-1.92-2.28-4.24-4.21-6.85-5.68c-0.9-0.51-1.83-0.97-2.8-1.35h-0.0099
                c-1.34-0.56-2.73-1-4.1801-1.3c-1.47-0.33-2.99-0.51-4.54-0.55c-0.25-0.02-0.5-0.02-0.75-0.02c-1.19,0-2.35,0.08-3.49,0.25h-0.01
                c-0.99,0.13-1.95,0.33-2.9,0.58c-1.38,0.36-2.7,0.84-3.97,1.42c-1.31,0.59-2.56,1.3-3.74,2.11h-0.01
                c-1.05,0.72-2.03,1.51-2.96,2.39c-0.21,0.19-0.42,0.39-0.63,0.6l-1.08-1.56l-1.1-1.6l-1.16-1.69l-1.16-1.67l-1.2-1.74v-0.01
                l-0.79-1.15l-0.82-1.19c1.25-1.1,3.13-2.47,4.78-3.5h0.01c7.64,0.11,35.87,0.69,35.87,0.69c0.34,0.82,3.62,3.01,6.29,3.75
                c0.91,0.26,1.76,0.34,2.39,0.15c0,0,3.97,2.96,7.25,9.46h0.01C348.9232,157.6328,350.9032,163.9828,350.9032,172.3028z"/>
            <path className="st0 Rear_Tyre" d="M324.7242,135.725l-17.61-0.91C307.1143,134.815,317.1943,131.585,324.7242,135.725z"/>
        </g>
        <path className="st0 Front_Tyre" d="M165.0936,169.205c0,21.37-17.32,38.68-38.68,38.68c-21.37,0-38.68-17.31-38.68-38.68
            c0-21.36,17.31-38.68,38.68-38.68c0.8,0,1.59,0.02,2.38,0.07c0.01,0,0.02,0,0.03,0.0099c6.42,1.01,11.77,3.03,16.21,5.65l-4.69,7.18
            c-4.14-2.24-8.89-3.52-13.93-3.52c-16.18,0-29.3,13.11-29.3,29.29s13.12,29.3,29.3,29.3s29.29-13.12,29.29-29.3
            c0-9.54-4.55-18.01-11.61-23.35l4.59-7.21c7.3,5.37,11.7,12.27,14.28,17.88C164.3436,160.495,165.0936,164.765,165.0936,169.205z"/>
        <path className="st0 Fuel_Tank" d="M232.8536,126.645c-2.77,0.48-6.3,0.72-10.46,0.81c-0.2,0-0.4,0.01-0.6,0.01
            c-1.11,0.02-2.27,0.03-3.47,0.03c-4.14,0-8.28,0.05-12.09,0.11c-2.28,0.04-4.43,0.09-6.39,0.13c-5.43,0.12-9.36,0.24-10.24,0.21
            c-1.73-0.06-7.88-4.4-8.76-6.38c-0.65-1.46-1.59-7.54,1.79-13.4c1.2-2.0699,2.94-4.12,5.4301-5.92c0,0,8.09,2.51,19.2599,2.84
            c0.01,0,0.01,0,0.01,0c1.94,0.46,3.63,0.69,4.68,0.8c0.7,0.08,1.11,0.1,1.11,0.1s22.3,9.98,25.96,14.93
            c0.09,0.1201,0.17,0.23,0.24,0.35c0,0,0.01,0.02,0.03,0.07c0.03,0.07,0.09,0.19,0.16,0.35c0.01,0.01,0.02,0.03,0.02,0.05
            c0.02,0.07,0.04,0.14,0.05,0.21C239.9336,124.405,237.4636,125.845,232.8536,126.645z"/>
        <g id="_x31_6">
            <path className="st0 Headlight" d="M171.0936,96.345l-7.88,12.06v-17.78C166.0636,92.835,171.0936,96.345,171.0936,96.345z"/>
            <path className="st0 Headlight" d="M161.1236,91.845v14.16c-1.26-1.75-2.61-4.36-2.61-7.42C158.5136,95.355,159.9136,93.155,161.1236,91.845z"/>
            <path className="st0 Headlight" d="M163.2136,90.625v17.78c0,0-1.01-0.88-2.09-2.39v-14.17c0.94-1,1.7599-1.48,1.7599-1.48
                C162.9836,90.445,163.0936,90.535,163.2136,90.625z"/>
        </g>
        <g id="_x31_5">
            <path className="st0 Taillight" d="M358.8743,146.395l-5.56,0.71c-1.63-3.75-3.72-6.89-6.05-9.5099h11.61
                C358.8743,137.595,359.9643,142.175,358.8743,146.395z"/>
            <path className="st1 Extra245" d="M349.6726,137.5942c0,0,3.6986,5.4701,5.2392,9.3039"/>
        </g>
        <path className="st0 Rider_Seat" d="M285.15,114.9l-5.02,22.64c-8.47,1.2-26.17-0.19-35.15-7.69c-2.45-1.99-4.75-6.63-5.45-8.11
            c0-0.02-0.01-0.05-0.02-0.06c-0.03-0.11-0.09-0.23-0.16-0.35c-0.02-0.05-0.03-0.07-0.03-0.07c-0.07-0.12-0.15-0.23-0.24-0.34
            c-1.59-2.95,2.07-3.5,7.22,0.7c5.15,4.21,16.13,7.79,25.11,3.53C280.38,120.9,283.24,115.62,285.15,114.9z"/>
        <path className="st0 Passenger_Seat" d="M322.81,120.82c0,0-0.29,2.71-1.44,3.57c-0.01,0-0.01,0-0.01,0c-0.09,0.07-0.19,0.13-0.29,0.17
            c-4.6,0.04-17.96,2.42-23.11,4.85c-2.58,1.21-4.93,2.95-7.69,4.53s-5.91,3-10.14,3.6l5.02-22.64c1.92-0.73,17.19-0.39,32.55,2.17
            C318.85,117.46,322.81,120.82,322.81,120.82z"/>
        <path className="st0 Rear_Fender" d="M355.6843,154.435h-2.93c0,0-3.51-10.94-11.62-18.01c-0.19-1.25-0.59-2.24-1.15-2.48
            c-0.62-0.27-1.35-0.36-2.18-0.26c-0.27,0.02-0.56,0.07-0.85,0.15c0,0-19.36-13.8-43.16,0.29h-0.01l-3.5099-0.18
            c2.7599-1.58,5.11-3.32,7.69-4.53c5.15-2.43,18.5099-4.81,23.11-4.85c0.1-0.04,0.2-0.1,0.29-0.17c0,0,0,0,0.01,0
            c0.41,0.04,9.3,0.93,18.09,6.56c0.6,0.39,1.21,0.8,1.81,1.24c0.75,0.54,1.49,1.12,2.23,1.73v0.01c0.9,0.75,1.79,1.57,2.65,2.46
            c0.3799,0.39,0.75,0.79,1.11,1.2c2.33,2.62,4.42,5.7599,6.05,9.5099C354.2842,149.325,355.0843,151.765,355.6843,154.435z"/>
        <g id="_x31_1">
            <path className="st0 Front_Fender" d="M145.5636,134.025l0.64,0.43l-0.72,1.1c-4.97-2.83-10.63-4.59-16.66-4.95
                c-0.0099-0.0099-0.02-0.0099-0.03-0.0099c-2.31-0.36-4.76-0.59-7.36-0.67c0,0-2.68-1.03-6-3.45c0,0,16.11-4.39,31.84,5.01
                L145.5636,134.025z"/>
            <path className="st0 Front_Fender" d="M170.4536,169.495c0,0-2.04-0.57-4.09-2.81c0,0-0.74-4.4-3.4-10.16c-2.61-7.53-7.49-14.01-13.81-18.61
                l0.66-1.03l0.67,0.45l1.65-2.45c1.41,1.12,2.79,2.38,4.15,3.78c2.25,2.42,4.14,4.79,5.72,7.09
                C169.4236,156.495,170.2436,165.545,170.4536,169.495z"/>
        </g>
        <path className="st0 Left_Foot_Peg" d="M254.3536,175.625c0,1.14-0.46,2.16-1.2,2.91c-0.75,0.75-1.78,1.21-2.91,1.21h-7.12
            c-2.27,0-4.12-1.85-4.12-4.12c0-1.14,0.46-2.16,1.21-2.91c0.74-0.75,1.78-1.21,2.91-1.21h7.12
            C252.5136,171.505,254.3536,173.355,254.3536,175.625z"/>
        <path className="st0 Left_Handle" d="M205.0836,84.035c-1.19-0.16-3.34-0.4901-5.48-1.09c-1.43-0.4-2.85-0.91-3.98-1.56
            c-0.53-0.35,0.3-3.39,2.05-3.8c0,0,1.08,0.41,2.6,0.82c1.52,0.4,3.46,0.81,5.19,0.81
            C206.9636,79.215,207.0636,84.295,205.0836,84.035z"/>
        <path className="st0 Extra253" d="M146.4236,164.985c0,0.14,0,0.27-0.01,0.41c0,0.13-0.01,0.25-0.02,0.37
            c-0.01,0.25-0.04,0.5-0.08,0.75c0.0099,0.02,0,0.03,0,0.04c-0.01,0.09-0.02,0.18-0.04,0.27c-0.02,0.15-0.05,0.29-0.08,0.44
            c-0.02,0.1-0.05,0.19-0.09,0.28c-0.02,0.05-0.05,0.11-0.09,0.16c0,0.01-0.0099,0.02-0.0099,0.02c-0.03,0.05-0.0601,0.1-0.1,0.14
            c-0.01,0.02-0.03,0.04-0.05,0.06c-0.05,0.05-0.09,0.09-0.14,0.13c-0.06,0.06-0.13,0.11-0.2,0.15c-0.15,0.09-0.31,0.16-0.49,0.21
            c-0.06,0.02-0.12,0.03-0.18,0.04c-0.04,0-0.08,0.01-0.11,0.01c-0.02,0.0099-0.02,0.0099-0.03,0.0099h-0.01
            c-0.2599,0.02-0.53,0-0.79-0.06c-0.05-0.0099-0.1-0.03-0.16-0.06c-0.07-0.03-0.16-0.06-0.25-0.11c-0.24-0.11-0.54-0.29-0.9-0.53
            c-0.06-0.03-0.12-0.07-0.18-0.12c-0.04-0.02-0.08-0.05-0.13-0.09c-0.01,0-0.01,0-0.02-0.01c-0.0099-0.01-0.0099-0.01-0.0099-0.01
            s0,0,0-0.01l-0.02-0.0099l-0.02-0.01c-0.22-0.15-0.46-0.32-0.72-0.51c-0.68-0.52-1.47-1.17-2.38-1.96l-0.01-0.01
            c-1.29-1.14-2.79-2.55-4.48-4.2599l3.3-5.19l2.09-3.27c0,0,0.01,0.01,0.02,0.02s0.03,0.02,0.05,0.05c0.27,0.24,1.22,1.15,2.31,2.54
            c0.27,0.35,0.56,0.73,0.84,1.14c0.12,0.18,0.25,0.36,0.37,0.55c0.14,0.2,0.27,0.42,0.4,0.64c0.1,0.16,0.1899,0.32,0.28,0.48
            c0.04,0.06,0.08,0.12,0.11,0.19c0.13,0.22,0.25,0.45,0.36,0.68c0.15,0.3,0.3,0.61,0.43,0.92c0.1,0.23,0.2,0.47,0.29,0.71
            c0.1,0.24,0.19,0.48,0.26,0.73c0.08,0.23,0.15,0.46,0.21,0.69c0.05,0.19,0.1,0.3799,0.14,0.58c0.04,0.14,0.07,0.27,0.09,0.41
            c0.05,0.23,0.09,0.45,0.12,0.69c0.05,0.32,0.09,0.65,0.1,0.98C146.4136,164.495,146.4236,164.745,146.4236,164.985z"/>
        <path className="st0 Lef_Side_View_Mirror" d="M193.2136,69.855c0,0.32-1.35,0.71-1.35-0.19c0-0.89,0-2.61-2.2-2.61s-2.14,1.69-2.1,2.8
            c0.0099,0.36,0,1.31-0.03,2.41c-0.03,2.3-0.1,5.32-0.1,5.32h-1.34c0,0,0.05-2.45,0.12-4.99c0.05-1.5,0.1-3.04,0.16-4.11
            c0.16-2.87,3.48-2.61,3.96-3.54c0.48-0.93,1.57-6.26,1.82-6.57c0.26-0.32,1-0.39,1.06,0
            C193.2736,58.755,193.2136,69.545,193.2136,69.855z"/>
        <g id="_x31_">
            <path className="st0 Front_Glass" d="M174.9142,93.425c-0.0099,0.03-0.0099,0.05-0.0099,0.08c0,0,0,0,0.0099,0c0,0.7-0.21,1.15-0.21,1.15
                l-0.29-0.61l-3.32,2.3c0,0-0.86-3.05-1.66-8.17c-0.69-4.41-1.32-10.36-1.32-17.21c0-8.83,0.45-18.7401,1.27-27.4
                c0,0.9,0.02,30.1,1.93,45.21c0,0,1.47-0.85,3.92-1.65C175.1642,89.525,175.0543,91.655,174.9142,93.425z"/>
            <path className="st0 Front_Glass" d="M175.2936,84.105c-1.4,1.28-2.78,2.82-3.98,4.67c-1.91-15.11-1.9301-44.31-1.9301-45.21c0-0.01,0-0.02,0-0.02
                c0.56-5.86,1.27-11.15,2.13-15.14C173.3336,19.885,175.6736,60.975,175.2936,84.105z"/>
        </g>
        <polygon className="st0 Extra257" points="164.7766,359.1073 166.2766,360.4973 169.3466,357.7673 169.1766,355.1873 165.9066,358.0973 "/>
        <path className="st1 Extra513" d="M126.1181,381.5195l0.19,5.16c1.0099,0.22,2.08,0.38,3.21,0.49c0.88,0.08,1.8,0.12,2.76,0.12
            c0.59,0,1.17-0.02,1.74-0.06l1.41-5.19c-1.11,0.2-2.27,0.32-3.4901,0.32C129.7681,382.3595,127.8281,382.0295,126.1181,381.5195z"/>
        <path className="st1 Extra258" d="M107.0481,361.2495c0,1.18,0.08,2.34,0.24,3.47c0.15,1.11,0.38,2.2,0.68,3.25l4.97,0.3l0.44-0.12
            c-0.8-2.15-1.23-4.47-1.23-6.9c0-0.75,0.04-1.5,0.13-2.24l-5.22,1.6C107.0481,360.8195,107.0481,361.0395,107.0481,361.2495z"/>
        <path className="st1 Extra259" d="M150.4081,353.5195c0.93,2.19,1.47,4.58,1.55,7.08l5.12,0.06c-0.03-1.43-0.19-2.84-0.45-4.2
            c-0.04-0.2-0.08-0.41-0.1299-0.61c-0.11-0.51-0.2401-1.02-0.38-1.51c-0.23-0.8-0.4901-1.58-0.8-2.34l-4.7599,1.34L150.4081,353.5195
            z"/>
        <path className="st1 Extra260" d="M119.7881,339.4495l1.05,5.35c1.61-1.1,3.38-1.97,5.28-2.56l-3.52-4.14h-0.01
            C121.6181,338.4795,120.688,338.9395,119.7881,339.4495z"/>
        <path className="st1 Extra261" d="M112.938,345.1295l4.55,2.54h0.01c0.25-0.27,0.52-0.54,0.79-0.8c0.79-0.77,1.64-1.46,2.55-2.07l-1.05-5.35
            C117.1781,340.9195,114.8581,342.8495,112.938,345.1295z"/>
        <path className="st1 Extra262" d="M107.0581,360.6095l5.22-1.6c0.02-0.19,0.04-0.38,0.07-0.57c0.42-2.9901,1.51-5.77,3.1-8.18l-5.27-1.14
            c-0.44,0.79-0.84,1.61-1.19,2.45C107.8181,354.3595,107.1381,357.4095,107.0581,360.6095z"/>
        <path className="st1 Extra263" d="M130.2381,341.4195c0.6-0.06,1.21-0.09,1.83-0.09c2.44,0,4.78,0.44,6.94,1.25l-0.54-5.52
            c-0.95-0.25-1.91-0.45-2.9-0.58c-1.14-0.17-2.31-0.25-3.5-0.25c-0.25,0-0.5,0-0.75,0.02L130.2381,341.4195z"/>
        <polygon className="st0 Right_Side" points="169.0681,353.4695 169.1781,355.1895 169.5381,360.7995 181.5981,360.9395 179.8181,360.2195 
            171.3681,356.7895 171.3681,353.5895 "/>
        <path className="st0 Right_Side" d="M197.8781,342.0695l4.06-0.2c0.25-0.81,0.46-1.58,0.63-2.27c0.4901-2,0.66-3.37,0.66-3.37
            c-2.12,0.3-4.4,0.39-5.7-0.24C197.5281,335.9895,198.1581,339.8395,197.8781,342.0695z"/>
        <path className="st0 Right_Side" d="M206.7187,333.2795l0.71,0.89l0.67,0.85l9.71-5.22c0.56-1.31,1.5-2.52,2.73-3.58l-0.2-0.29L206.7187,333.2795z"
            />
        <path className="st0 Right_Side" d="M214.8288,341.7495c0.19,0.3,0.39,0.61,0.6,0.93c0.21,0.33,0.43,0.66,0.65,1l6.37-3.22
            c-0.78-0.47-1.49-0.99-2.11-1.54L214.8288,341.7495z"/>
        <path className="st0 Right_Side" d="M212.5788,338.1395c0,0,0.02,0.04,0.07,0.1201c0.0699,0.11,0.1899,0.31,0.35,0.58
            c0.16,0.26,0.36,0.58,0.59,0.95l5.09-2.73c-0.41-0.59-0.75-1.21-0.99-1.86L212.5788,338.1395z"/>
        <path className="st0 Right_Side" d="M242.6687,341.4795l7.79,4.53c0.27-0.26,0.54-0.52,0.8199-0.8c0.28-0.28,0.56-0.57,0.85-0.87l-7.14-4.11
            C244.2787,340.6895,243.4987,341.0995,242.6687,341.4795z"/>
        <path className="st1 Extra270" d="M303.3487,358.8295c0.07,2.45,0.69,4.77,1.74,6.83c0.13,0.25,0.26,0.49,0.4,0.73c0.27,0.48,0.57,0.94,0.9,1.38
            c0.11,0.16,0.22,0.31,0.35,0.47c1.52,1.95,3.49,3.54,5.76,4.61c0.16,0.09,0.32,0.16,0.49,0.22c0.51,0.23,1.04,0.43,1.58,0.59
            c0.07,0.03,0.14,0.05,0.21,0.07c1.45,0.44,3,0.67,4.59,0.67c0.67,0,1.34-0.04,1.99-0.13c0.41-0.04,0.82-0.11,1.22-0.2
            c0.47-0.09,0.93-0.2,1.39-0.34c0.31-0.09,0.62-0.2,0.92-0.3c1.62-0.6,3.11-1.45,4.43-2.51h0.01c0.7599-0.59,1.45-1.26,2.07-1.98
            c0.0699-0.06,0.12-0.12,0.16-0.18c0.32-0.36,0.61-0.7401,0.89-1.13c0.92-1.29,1.65-2.72,2.15-4.26c0.21-0.63,0.38-1.28,0.5-1.95
            c0.08-0.35,0.13-0.71,0.17-1.07c0.06-0.45,0.1-0.91,0.12-1.37c0.01-0.2,0.01-0.41,0.01-0.61c0-1.74-0.28-3.41-0.79-4.98
            c-0.15-0.46-0.32-0.91-0.51-1.35c-0.23-0.53-0.49-1.05-0.77-1.55v-0.01c-0.08-0.14-0.16-0.28-0.25-0.42v-0.01
            c-1.1-1.8-2.55-3.38-4.25-4.62c-0.1-0.07-0.19-0.14-0.29-0.21c-0.77-0.54-1.58-1.01-2.44-1.4c-0.17-0.08-0.34-0.16-0.51-0.23
            c-1.91-0.81-4.0099-1.25-6.22-1.25c-0.26,0-0.52,0.01-0.77,0.02h-0.1c-1.04,0.06-2.05,0.21-3.02,0.46
            c-0.13,0.03-0.25,0.06-0.37,0.09c-0.69,0.19-1.37,0.43-2.02,0.71l1.6801,2.56l1.9,2.92l1.15,1.74l0.45,0.7
            c0.7599-0.23,1.5699-0.35,2.41-0.35c0.58,0,1.14,0.06,1.69,0.17c0.18,0.03,0.35,0.08,0.52,0.12c0.38,0.1,0.75,0.23,1.11,0.38
            c0.36,0.16,0.7,0.33,1.03,0.53c0.54,0.32,1.03,0.7,1.48,1.1201c0.54,0.5099,1.02,1.09,1.4,1.73l0.01,0.01
            c0.21,0.34,0.4,0.69,0.56,1.07c0.09,0.21,0.17,0.43,0.25,0.65c0.28,0.85,0.44,1.76,0.44,2.71v0.17c-0.03,1.07-0.24,2.1-0.63,3.05
            c-0.41,0.99-1,1.89-1.73,2.66c-0.22,0.22-0.44,0.43-0.68,0.63c-0.28,0.23-0.58,0.45-0.89,0.65c-0.18,0.12-0.37,0.23-0.56,0.33
            c-0.81,0.43-1.69,0.74-2.62,0.89c-0.45,0.07-0.91,0.11-1.38,0.11c-1.24,0-2.41-0.26-3.47-0.74c-0.38-0.17-0.7401-0.37-1.09-0.59
            c-0.76-0.48-1.43-1.08-2-1.77c-0.12-0.15-0.24-0.3-0.35-0.46c-0.23-0.31-0.4301-0.65-0.62-1c-0.1-0.2-0.2-0.41-0.29-0.63
            c-0.43-1.01-0.67-2.13-0.67-3.3c0-0.84,0.12-1.65,0.35-2.42c0.35-1.2,0.97-2.28,1.77-3.19l-1.84-2.88
            c-1.69,1.71-3.19,3.12-4.48,4.2599l-0.01,0.01c-0.91,0.79-1.7,1.44-2.38,1.96c-0.26,0.19-0.5,0.36-0.72,0.51l-0.02,0.01
            l-0.02,0.0099c0,0.01,0,0.01,0,0.01s0,0-0.0099,0.01c-0.01,0.01-0.01,0.01-0.02,0.01c-0.05,0.04-0.09,0.07-0.13,0.09
            c-0.06,0.05-0.12,0.09-0.18,0.12c-0.36,0.24-0.66,0.42-0.9,0.53C303.5088,358.7695,303.4187,358.7995,303.3487,358.8295z"/>
        <path className="st1 Extra271" d="M294.6388,359.6795c0,0.81,0.04,1.61,0.11,2.4c0.12,1.37,0.36,2.72,0.69,4.02c0.32,1.26,0.73,2.48,1.22,3.65
            c0.5,1.2,1.09,2.35,1.75,3.44c0.79,1.29,1.68,2.5,2.67,3.63v0.0099c0.78,0.89,1.63,1.7401,2.53,2.52h0.01
            c1.07,0.94,2.21,1.78,3.43,2.52c1.19,0.75,2.45,1.39,3.76,1.92c1.4,0.58,2.85,1.03,4.36,1.35c1.03,0.23,2.08,0.39,3.15,0.48
            c0.78,0.07,1.56,0.11,2.36,0.11c0.71,0,1.41-0.03,2.11-0.09c1.69-0.1299,3.33-0.43,4.92-0.8799h0.0099c1.48-0.4,2.91-0.95,4.28-1.61
            c0.9-0.43,1.78-0.92,2.62-1.46v-0.01c1.25-0.78,2.43-1.68,3.53-2.67c1.14-1.04,2.1899-2.17,3.1299-3.39h0.01
            c0.97-1.25,1.83-2.6,2.56-4.02c0.52-1.01,0.98-2.06,1.36-3.14c0.47-1.3,0.83-2.64,1.08-4.03c0.27-1.44,0.42-2.94,0.43-4.46
            c0.01-0.1,0.01-0.19,0.01-0.29c0-1.21-0.08-2.4-0.25-3.57c-0.17-1.32-0.45-2.61-0.83-3.86c-0.38-1.3-0.86-2.55-1.44-3.75
            c-0.64-1.35-1.39-2.63-2.25-3.84c-0.73-1.04-1.54-2.02-2.42-2.93v-0.01c-0.91-0.96-1.88-1.84-2.93-2.64
            c-1.14-0.89-2.36-1.68-3.65-2.37h-0.01c-1.2-0.65-2.47-1.2-3.78-1.65c-1.13-0.39-2.29-0.71-3.49-0.93v-0.01
            c-1.55-0.3-3.16-0.47-4.8-0.48h-0.2c-1.12,0-2.23,0.07-3.31,0.21c-1.27,0.16-2.52,0.42-3.73,0.76c-1.48,0.41-2.91,0.96-4.27,1.62
            c-0.28,0.13-0.56,0.27-0.83,0.42l4.55,6.97l1.6801,2.56l1.9,2.92l1.15,1.74l0.45,0.7c0.7599-0.23,1.5699-0.35,2.41-0.35
            c0.58,0,1.14,0.06,1.69,0.17c0.18,0.03,0.35,0.08,0.52,0.12c0.38,0.1,0.75,0.23,1.11,0.38c0.36,0.16,0.7,0.33,1.03,0.53
            c0.54,0.32,1.03,0.7,1.48,1.1201c0.54,0.5099,1.02,1.09,1.4,1.73l0.01,0.01c0.21,0.34,0.4,0.69,0.56,1.07
            c0.09,0.21,0.17,0.43,0.25,0.65c0.28,0.85,0.44,1.76,0.44,2.71v0.17c-0.03,1.07-0.24,2.1-0.63,3.05c-0.41,0.99-1,1.89-1.73,2.66
            c-0.22,0.22-0.44,0.43-0.68,0.63c-0.28,0.23-0.58,0.45-0.89,0.65c-0.18,0.12-0.37,0.23-0.56,0.33c-0.81,0.43-1.69,0.74-2.62,0.89
            c-0.45,0.07-0.91,0.11-1.38,0.11c-1.24,0-2.41-0.26-3.47-0.74c-0.38-0.17-0.7401-0.37-1.09-0.59c-0.76-0.48-1.43-1.08-2-1.77
            c-0.12-0.15-0.24-0.3-0.35-0.46c-0.23-0.31-0.4301-0.65-0.62-1c-0.1-0.2-0.2-0.41-0.29-0.63c-0.43-1.01-0.67-2.13-0.67-3.3
            c0-0.84,0.12-1.65,0.35-2.42c0.35-1.2,0.97-2.28,1.77-3.19l-1.84-2.88c-1.69,1.71-3.19,3.12-4.48,4.2599l-0.01,0.01
            c-0.91,0.79-1.7,1.44-2.38,1.96c-0.26,0.19-0.5,0.36-0.72,0.51l-0.02,0.01l-0.02,0.0099c0,0.01,0,0.01,0,0.01s0,0-0.0099,0.01
            c-0.01,0.01-0.01,0.01-0.02,0.01c-0.05,0.04-0.09,0.07-0.13,0.09c-0.06,0.05-0.12,0.09-0.18,0.12c-0.36,0.24-0.66,0.42-0.9,0.53
            c-0.09,0.05-0.18,0.08-0.25,0.11c-0.06,0.03-0.11,0.05-0.16,0.06c-0.26,0.06-0.53,0.08-0.79,0.06h-0.01c-0.01,0-0.01,0-0.03-0.0099
            c-0.03,0-0.07-0.01-0.11-0.01c-0.06-0.01-0.12-0.02-0.18-0.04c-0.18-0.05-0.34-0.12-0.49-0.21c-0.07-0.04-0.14-0.09-0.2-0.15
            c-0.05-0.04-0.09-0.08-0.14-0.13c-0.02-0.02-0.04-0.04-0.05-0.06c-0.04-0.04-0.07-0.09-0.1-0.14c0,0-0.0099-0.0099-0.0099-0.02
            c-0.04-0.05-0.07-0.11-0.09-0.16c-0.04-0.09-0.07-0.18-0.09-0.28c-0.03-0.15-0.0601-0.29-0.08-0.44c-0.02-0.09-0.03-0.18-0.04-0.27
            c0-0.01-0.0099-0.02,0-0.04c-0.04-0.25-0.0699-0.5-0.08-0.75c-0.01-0.12-0.02-0.24-0.02-0.37c-0.01-0.14-0.01-0.27-0.01-0.41
            c0-0.24,0.01-0.49,0.03-0.73c0.01-0.33,0.05-0.66,0.1-0.98c0.03-0.24,0.07-0.46,0.12-0.69c0.02-0.14,0.05-0.27,0.09-0.41
            c0.04-0.2,0.09-0.39,0.14-0.58c0.06-0.23,0.1299-0.46,0.21-0.69c0.07-0.25,0.16-0.4901,0.26-0.73c0.04-0.12,0.09-0.24,0.14-0.36
            s0.1-0.24,0.15-0.35c0.13-0.31,0.28-0.62,0.43-0.92c0.11-0.23,0.23-0.46,0.36-0.68c0.03-0.07,0.07-0.13,0.11-0.19
            c0.09-0.16,0.18-0.32,0.28-0.48c0.13-0.22,0.26-0.44,0.4-0.64c0.12-0.19,0.25-0.37,0.37-0.55c0.28-0.41,0.57-0.79,0.84-1.14
            c1.09-1.39,2.04-2.3,2.31-2.54c0.02-0.03,0.04-0.04,0.05-0.05s0.02-0.02,0.02-0.02l-2.32-3.64c-1.73,1.33-3.28,2.87-4.61,4.59
            c-0.79,1-1.5,2.05-2.1201,3.16c-0.64,1.12-1.2,2.29-1.67,3.51c-0.5,1.31-0.9,2.66-1.19,4.06c-0.26,1.29-0.43,2.61-0.49,3.96
            C294.6488,358.7995,294.6388,359.2395,294.6388,359.6795z"/>
        <path className="st1 Extra272" d="M294.6388,359.6795c0,0.81,0.04,1.61,0.11,2.4c0.12,1.37,0.36,2.72,0.69,4.02c0.32,1.26,0.73,2.48,1.22,3.65
            c0.5,1.2,1.09,2.35,1.75,3.44c0.79,1.29,1.68,2.5,2.67,3.63v0.0099c0.78,0.89,1.63,1.7401,2.53,2.52h0.01
            c1.07,0.94,2.21,1.78,3.43,2.52c1.19,0.75,2.45,1.39,3.76,1.92c1.4,0.58,2.85,1.03,4.36,1.35c1.03,0.23,2.08,0.39,3.15,0.48
            c0.78,0.07,1.56,0.11,2.36,0.11c0.71,0,1.41-0.03,2.11-0.09c1.69-0.1299,3.33-0.43,4.92-0.8799h0.0099c1.48-0.4,2.91-0.95,4.28-1.61
            c0.9-0.43,1.78-0.92,2.62-1.46v-0.01c1.25-0.78,2.43-1.68,3.53-2.67c1.14-1.04,2.1899-2.17,3.1299-3.39h0.01
            c0.97-1.25,1.83-2.6,2.56-4.02c0.52-1.01,0.98-2.06,1.36-3.14c0.47-1.3,0.83-2.64,1.08-4.03c0.27-1.44,0.42-2.94,0.43-4.46
            c0.01-0.1,0.01-0.19,0.01-0.29c0-1.21-0.08-2.4-0.25-3.57c-0.17-1.32-0.45-2.61-0.83-3.86c-0.38-1.3-0.86-2.55-1.44-3.75
            c-0.64-1.35-1.39-2.63-2.25-3.84c-0.73-1.04-1.54-2.02-2.42-2.93v-0.01c-0.91-0.96-1.88-1.84-2.93-2.64
            c-1.14-0.89-2.36-1.68-3.65-2.37h-0.01c-1.2-0.65-2.47-1.2-3.78-1.65c-1.13-0.39-2.29-0.71-3.49-0.93v-0.01
            c-1.55-0.3-3.16-0.47-4.8-0.48h-0.2c-1.12,0-2.23,0.07-3.31,0.21c-1.27,0.16-2.52,0.42-3.73,0.76c-1.48,0.41-2.91,0.96-4.27,1.62
            c-0.28,0.13-0.56,0.27-0.83,0.42l4.55,6.97l1.6801,2.56l1.9,2.92l1.15,1.74l0.45,0.7c0.7599-0.23,1.5699-0.35,2.41-0.35
            c0.58,0,1.14,0.06,1.69,0.17c0.18,0.03,0.35,0.08,0.52,0.12c0.38,0.1,0.75,0.23,1.11,0.38c0.36,0.16,0.7,0.33,1.03,0.53
            c0.54,0.32,1.03,0.7,1.48,1.1201c0.54,0.5099,1.02,1.09,1.4,1.73l0.01,0.01c0.21,0.34,0.4,0.69,0.56,1.07
            c0.09,0.21,0.17,0.43,0.25,0.65c0.28,0.85,0.44,1.76,0.44,2.71v0.17c-0.03,1.07-0.24,2.1-0.63,3.05c-0.41,0.99-1,1.89-1.73,2.66
            c-0.22,0.22-0.44,0.43-0.68,0.63c-0.28,0.23-0.58,0.45-0.89,0.65c-0.18,0.12-0.37,0.23-0.56,0.33c-0.81,0.43-1.69,0.74-2.62,0.89
            c-0.45,0.07-0.91,0.11-1.38,0.11c-1.24,0-2.41-0.26-3.47-0.74c-0.38-0.17-0.7401-0.37-1.09-0.59c-0.76-0.48-1.43-1.08-2-1.77
            c-0.12-0.15-0.24-0.3-0.35-0.46c-0.23-0.31-0.4301-0.65-0.62-1c-0.1-0.2-0.2-0.41-0.29-0.63c-0.43-1.01-0.67-2.13-0.67-3.3
            c0-0.84,0.12-1.65,0.35-2.42c0.35-1.2,0.97-2.28,1.77-3.19l-1.84-2.88c-1.69,1.71-3.19,3.12-4.48,4.2599l-0.01,0.01
            c-0.91,0.79-1.7,1.44-2.38,1.96c-0.26,0.19-0.5,0.36-0.72,0.51l-0.02,0.01l-0.02,0.0099c0,0.01,0,0.01,0,0.01s0,0-0.0099,0.01
            c-0.01,0.01-0.01,0.01-0.02,0.01c-0.05,0.04-0.09,0.07-0.13,0.09c-0.06,0.05-0.12,0.09-0.18,0.12c-0.36,0.24-0.66,0.42-0.9,0.53
            c-0.09,0.05-0.18,0.08-0.25,0.11c-0.06,0.03-0.11,0.05-0.16,0.06c-0.26,0.06-0.53,0.08-0.79,0.06h-0.01c-0.01,0-0.01,0-0.03-0.0099
            c-0.03,0-0.07-0.01-0.11-0.01c-0.06-0.01-0.12-0.02-0.18-0.04c-0.18-0.05-0.34-0.12-0.49-0.21c-0.07-0.04-0.14-0.09-0.2-0.15
            c-0.05-0.04-0.09-0.08-0.14-0.13c-0.02-0.02-0.04-0.04-0.05-0.06c-0.04-0.04-0.07-0.09-0.1-0.14c0,0-0.0099-0.0099-0.0099-0.02
            c-0.04-0.05-0.07-0.11-0.09-0.16c-0.04-0.09-0.07-0.18-0.09-0.28c-0.03-0.15-0.0601-0.29-0.08-0.44c-0.02-0.09-0.03-0.18-0.04-0.27
            c0-0.01-0.0099-0.02,0-0.04c-0.04-0.25-0.0699-0.5-0.08-0.75c-0.01-0.12-0.02-0.24-0.02-0.37c-0.01-0.14-0.01-0.27-0.01-0.41
            c0-0.24,0.01-0.49,0.03-0.73c0.01-0.33,0.05-0.66,0.1-0.98c0.03-0.24,0.07-0.46,0.12-0.69c0.02-0.14,0.05-0.27,0.09-0.41
            c0.04-0.2,0.09-0.39,0.14-0.58c0.06-0.23,0.1299-0.46,0.21-0.69c0.07-0.25,0.16-0.4901,0.26-0.73c0.09-0.24,0.19-0.48,0.29-0.71
            c0.13-0.31,0.28-0.62,0.43-0.92c0.11-0.23,0.23-0.46,0.36-0.68c0.03-0.07,0.07-0.13,0.11-0.19c0.09-0.16,0.18-0.32,0.28-0.48
            c0.13-0.22,0.26-0.44,0.4-0.64c0.12-0.19,0.25-0.37,0.37-0.55c0.28-0.41,0.57-0.79,0.84-1.14c1.09-1.39,2.04-2.3,2.31-2.54
            c0.02-0.03,0.04-0.04,0.05-0.05s0.02-0.02,0.02-0.02l-2.32-3.64c-1.73,1.33-3.28,2.87-4.61,4.59c-0.79,1-1.5,2.05-2.1201,3.16
            c-0.64,1.12-1.2,2.29-1.67,3.51c-0.5,1.31-0.9,2.66-1.19,4.06c-0.26,1.29-0.43,2.61-0.49,3.96
            C294.6488,358.7995,294.6388,359.2395,294.6388,359.6795z"/>
        <path className="st1 Extra273" d="M301.6087,326.0295l0.45,0.7c4.44-2.62,9.79-4.64,16.21-5.65
            C312.2387,321.4395,306.5788,323.1995,301.6087,326.0295z"/>
        <path className="st0 Extra274" d="M315.3188,359.9695c0,3.38,2.75,6.13,6.13,6.13s6.13-2.75,6.13-6.13s-2.75-6.13-6.13-6.13
            c-0.54,0-1.06,0.07-1.55,0.21l2.12,3.24c-0.95,1.91-3.95,2.68-3.95,2.68l-1.94-3.04
            C315.6187,357.8195,315.3188,358.8595,315.3188,359.9695z"/>
        <path className="st0 Extra275" d="M312.1888,359.6795c0,1.17,0.24,2.29,0.67,3.3c0.09,0.22,0.19,0.43,0.29,0.63c0.1899,0.35,0.39,0.69,0.62,1
            c0.11,0.16,0.23,0.31,0.35,0.46c0.57,0.69,1.24,1.29,2,1.77c0.35,0.22,0.71,0.42,1.09,0.59c1.06,0.48,2.23,0.74,3.47,0.74
            c0.47,0,0.93-0.04,1.38-0.11c0.93-0.15,1.81-0.46,2.62-0.89c0.19-0.1,0.38-0.21,0.56-0.33c0.31-0.2,0.61-0.42,0.89-0.65
            c0.24-0.2,0.46-0.41,0.68-0.63c0.73-0.77,1.32-1.67,1.73-2.66c0.39-0.95,0.6-1.98,0.63-3.05v-0.17c0-0.95-0.16-1.86-0.44-2.71
            c-0.08-0.22-0.16-0.44-0.25-0.65c-0.16-0.38-0.35-0.73-0.56-1.07l-0.01-0.01c-0.38-0.64-0.86-1.22-1.4-1.73
            c-0.45-0.42-0.94-0.8-1.48-1.1201c-0.33-0.2-0.67-0.37-1.03-0.53c-0.36-0.15-0.73-0.28-1.11-0.38c-0.17-0.04-0.34-0.09-0.52-0.12
            c-0.55-0.11-1.11-0.17-1.69-0.17c-0.84,0-1.65,0.12-2.41,0.35l1.63,2.51c0.49-0.14,1.0099-0.21,1.55-0.21
            c3.38,0,6.13,2.75,6.13,6.13s-2.75,6.13-6.13,6.13s-6.13-2.75-6.13-6.13c0-1.11,0.3-2.15,0.81-3.04l-1.82-2.86
            c-0.8,0.91-1.42,1.99-1.77,3.19C312.3087,358.0295,312.1888,358.8395,312.1888,359.6795z"/>
        <path className="st1 Extra276" d="M294.6388,359.6795c0,0.81,0.04,1.61,0.11,2.4c0.12,1.37,0.36,2.72,0.69,4.02c0.32,1.26,0.73,2.48,1.22,3.65
            c0.5,1.2,1.09,2.35,1.75,3.44c0.79,1.29,1.68,2.5,2.67,3.63v0.0099c0.78,0.89,1.63,1.7401,2.53,2.52h0.01
            c1.07,0.94,2.21,1.78,3.43,2.52c1.19,0.75,2.45,1.39,3.76,1.92c1.4,0.58,2.85,1.03,4.36,1.35c1.03,0.23,2.08,0.39,3.15,0.48
            c0.78,0.07,1.56,0.11,2.36,0.11c0.71,0,1.41-0.03,2.11-0.09c1.69-0.1299,3.33-0.43,4.92-0.8799h0.0099c1.48-0.4,2.91-0.95,4.28-1.61
            c0.9-0.43,1.78-0.92,2.62-1.46v-0.01c1.25-0.78,2.43-1.68,3.53-2.67c1.14-1.04,2.1899-2.17,3.1299-3.39h0.01
            c0.97-1.25,1.83-2.6,2.56-4.02c0.52-1.01,0.98-2.06,1.36-3.14c0.47-1.3,0.83-2.64,1.08-4.03c0.27-1.44,0.42-2.94,0.43-4.46
            c0.01-0.1,0.01-0.19,0.01-0.29c0-1.21-0.08-2.4-0.25-3.57c-0.17-1.32-0.45-2.61-0.83-3.86c-0.38-1.3-0.86-2.55-1.44-3.75
            c-0.64-1.35-1.39-2.63-2.25-3.84c-0.73-1.04-1.54-2.02-2.42-2.93v-0.01c-0.91-0.96-1.88-1.84-2.93-2.64
            c-1.14-0.89-2.36-1.68-3.65-2.37h-0.01c-1.2-0.65-2.47-1.2-3.78-1.65c-1.13-0.39-2.29-0.71-3.49-0.93v-0.01
            c-1.55-0.3-3.16-0.47-4.8-0.48h-0.2c-1.12,0-2.23,0.07-3.31,0.21c-1.27,0.16-2.52,0.42-3.73,0.76c-1.48,0.41-2.91,0.96-4.27,1.62
            c-0.28,0.13-0.56,0.27-0.83,0.42l4.55,6.97l1.6801,2.56l1.9,2.92l1.15,1.74l0.45,0.7c0.7599-0.23,1.5699-0.35,2.41-0.35
            c0.58,0,1.14,0.06,1.69,0.17c0.18,0.03,0.35,0.08,0.52,0.12c0.38,0.1,0.75,0.23,1.11,0.38c0.36,0.16,0.7,0.33,1.03,0.53
            c0.54,0.32,1.03,0.7,1.48,1.1201c0.54,0.5099,1.02,1.09,1.4,1.73l0.01,0.01c0.21,0.34,0.4,0.69,0.56,1.07
            c0.09,0.21,0.17,0.43,0.25,0.65c0.28,0.85,0.44,1.76,0.44,2.71v0.17c-0.03,1.07-0.24,2.1-0.63,3.05c-0.41,0.99-1,1.89-1.73,2.66
            c-0.22,0.22-0.44,0.43-0.68,0.63c-0.28,0.23-0.58,0.45-0.89,0.65c-0.18,0.12-0.37,0.23-0.56,0.33c-0.81,0.43-1.69,0.74-2.62,0.89
            c-0.45,0.07-0.91,0.11-1.38,0.11c-1.24,0-2.41-0.26-3.47-0.74c-0.38-0.17-0.7401-0.37-1.09-0.59c-0.76-0.48-1.43-1.08-2-1.77
            c-0.12-0.15-0.24-0.3-0.35-0.46c-0.23-0.31-0.4301-0.65-0.62-1c-0.1-0.2-0.2-0.41-0.29-0.63c-0.43-1.01-0.67-2.13-0.67-3.3
            c0-0.84,0.12-1.65,0.35-2.42c0.35-1.2,0.97-2.28,1.77-3.19l-1.84-2.88c-1.69,1.71-3.19,3.12-4.48,4.2599l-0.01,0.01
            c-0.91,0.79-1.7,1.44-2.38,1.96c-0.26,0.19-0.5,0.36-0.72,0.51l-0.02,0.01l-0.02,0.0099c0,0.01,0,0.01,0,0.01s0,0-0.0099,0.01
            c-0.01,0.01-0.01,0.01-0.02,0.01c-0.05,0.04-0.09,0.07-0.13,0.09c-0.06,0.05-0.12,0.09-0.18,0.12c-0.36,0.24-0.66,0.42-0.9,0.53
            c-0.09,0.05-0.18,0.08-0.25,0.11c-0.06,0.03-0.11,0.05-0.16,0.06c-0.26,0.06-0.53,0.08-0.79,0.06h-0.01c-0.01,0-0.01,0-0.03-0.0099
            c-0.03,0-0.07-0.01-0.11-0.01c-0.06-0.01-0.12-0.02-0.18-0.04c-0.18-0.05-0.34-0.12-0.49-0.21c-0.07-0.04-0.14-0.09-0.2-0.15
            c-0.05-0.04-0.09-0.08-0.14-0.13c-0.02-0.02-0.04-0.04-0.05-0.06c-0.04-0.04-0.07-0.09-0.1-0.14c0,0-0.0099-0.0099-0.0099-0.02
            c-0.04-0.05-0.07-0.11-0.09-0.16c-0.04-0.09-0.07-0.18-0.09-0.28c-0.03-0.15-0.0601-0.29-0.08-0.44c-0.02-0.09-0.03-0.18-0.04-0.27
            c0-0.01-0.0099-0.02,0-0.04c-0.04-0.25-0.0699-0.5-0.08-0.75c-0.01-0.12-0.02-0.24-0.02-0.37c-0.01-0.14-0.01-0.27-0.01-0.41
            c0-0.24,0.01-0.49,0.03-0.73c0.01-0.33,0.05-0.66,0.1-0.98c0.03-0.24,0.07-0.46,0.12-0.69c0.02-0.14,0.05-0.27,0.09-0.41
            c0.04-0.2,0.09-0.39,0.14-0.58c0.06-0.23,0.1299-0.46,0.21-0.69c0.07-0.25,0.16-0.4901,0.26-0.73c0.09-0.24,0.19-0.48,0.29-0.71
            c0.13-0.31,0.28-0.62,0.43-0.92c0.11-0.23,0.23-0.46,0.36-0.68c0.03-0.07,0.07-0.13,0.11-0.19c0.09-0.16,0.18-0.32,0.28-0.48
            c0.13-0.22,0.26-0.44,0.4-0.64c0.12-0.19,0.25-0.37,0.37-0.55c0.28-0.41,0.57-0.79,0.84-1.14c1.09-1.39,2.04-2.3,2.31-2.54
            c0.02-0.03,0.04-0.04,0.05-0.05s0.02-0.02,0.02-0.02l-2.32-3.64c-1.73,1.33-3.28,2.87-4.61,4.59c-0.79,1-1.5,2.05-2.1201,3.16
            c-0.64,1.12-1.2,2.29-1.67,3.51c-0.5,1.31-0.9,2.66-1.19,4.06c-0.26,1.29-0.43,2.61-0.49,3.96
            C294.6488,358.7995,294.6388,359.2395,294.6388,359.6795z"/>
        <path className="st1 Extra277" d="M284.1288,346.9995c2.58-5.61,6.98-12.51,14.28-17.88l-0.47-0.73
            C291.6187,332.9895,286.7387,339.4695,284.1288,346.9995z"/>
        <polygon className="st0 Front_Suspension" points="293.4488,323.1095 294.9588,325.3595 296.6087,327.8095 297.2787,327.3595 300.8888,324.9295 
            301.5287,324.4995 299.8188,321.9595 298.3687,319.7995 297.7887,320.1895 294.2387,322.5795 "/>
        <path className="st1 Extra279" d="M301.6087,326.0295l0.45,0.7c4.44-2.62,9.79-4.64,16.21-5.65
            C312.2387,321.4395,306.5788,323.1995,301.6087,326.0295z"/>
        <path className="st1 Extra280" d="M284.1288,346.9995c2.58-5.61,6.98-12.51,14.28-17.88l-0.47-0.73
            C291.6187,332.9895,286.7387,339.4695,284.1288,346.9995z"/>
        <polygon className="st0 Front_Suspension" points="283.2487,307.2995 285.8387,311.1495 286.6187,310.6295 290.0387,308.3195 290.8788,307.7595 
            288.2887,303.8995 287.4987,304.4295 284.1187,306.7095 "/>
        <line className="st1 Extra282" x1="309.3696" y1="336.2304" x2="322.3676" y2="351.3597"/>
        <line className="st1 Extra283" x1="320.8741" y1="333.6417" x2="316.6682" y2="349.0946"/>
        <line className="st1 Extra284" x1="317.3697" y1="333.8511" x2="314.7647" y2="346.1842"/>
        <line className="st1 Extra285" x1="313.6407" y1="334.6082" x2="325.028" y2="352.3896"/>
        <polyline className="st1 Extra286" points="317.8087,350.8495 317.8187,350.8395 320.0987,348.7195 321.7087,347.2095 325.5887,343.5895 
            326.0687,343.1395 328.9987,340.4095 332.9487,336.7095 "/>
        <polyline className="st1 Extra287" points="325.6787,334.1295 326.0687,343.1395 326.0987,343.8195 326.2287,346.9695 326.3987,350.9895 
            326.4387,351.8995 326.5087,353.5096 "/>
        <line className="st1 Extra288" x1="320.6787" y1="351.1917" x2="336.6088" y2="339.0764"/>
        <polyline className="st1 Extra289" points="339.5387,341.7295 333.6887,349.9695 333.3287,350.4795 333.3287,350.4895 332.2487,352.0095 
            329.4187,355.9995 328.7287,356.9695 "/>
        <line className="st1 Extra290" x1="329.1687" y1="335.0561" x2="328.4776" y2="356.3211"/>
        <line className="st1 Extra291" x1="322.8849" y1="351.4821" x2="344.2106" y2="348.4979"/>
        <line className="st1 Extra292" x1="341.9583" y1="344.6574" x2="329.1687" y2="359.8447"/>
        <line className="st1 Extra293" x1="345.6526" y1="352.251" x2="323.994" y2="351.8636"/>
        <polyline className="st1 Extra294" points="327.9187,355.2495 328.5087,355.5396 329.4187,355.9995 331.5287,357.0495 335.3887,358.9796 
            336.8987,359.7395 340.7287,361.6495 346.2887,364.4295 346.2987,364.4395 "/>
        <line className="st1 Extra295" x1="346.4858" y1="356.1128" x2="328.5378" y2="362.8971"/>
        <line className="st1 Extra296" x1="330.5257" y1="358.2333" x2="345.2132" y2="368.4597"/>
        <line className="st1 Extra297" x1="346.7268" y1="359.9717" x2="326.8076" y2="365.5562"/>
        <line className="st1 Extra298" x1="329.8299" y1="362.4087" x2="338.1475" y2="379.0087"/>
        <line className="st1 Extra299" x1="343.8481" y1="371.6024" x2="324.681" y2="367.1702"/>
        <line className="st1 Extra300" x1="326.1327" y1="366.1871" x2="322.7902" y2="385.6472"/>
        <line className="st1 Extra301" x1="318.3214" y1="385.6261" x2="325.2423" y2="366.8417"/>
        <polyline className="st1 Extra302" points="322.0587,368.0595 324.4487,368.9995 325.5687,369.4395 329.3287,370.9195 332.1787,372.0396 
            335.2587,373.2495 341.2787,375.6195 "/>
        <polyline className="st1 Extra303" points="329.1687,360.1295 329.7487,362.4395 330.2987,364.5795 331.3387,368.7095 331.3987,368.9395 
            332.1787,372.0396 334.6187,381.6795 "/>
        <line className="st1 Extra304" x1="319.105" y1="368.0247" x2="332.0015" y2="383.1498"/>
        <polyline className="st1 Extra305" points="317.2087,367.4295 318.4187,369.4295 318.5887,369.7095 321.3587,374.2695 322.0687,375.4495 
            323.9987,378.6295 327.7087,384.7595 "/>
        <polyline className="st1 Extra306" points="303.6187,379.3495 312.3987,373.4595 312.9887,373.0695 314.4887,372.0695 318.4187,369.4295 
            319.6187,368.6295 318.5887,369.7095 314.7787,373.7295 314.5787,373.9295 311.8887,376.7695 307.0487,381.8695 307.0387,381.8795 
            "/>
        <line className="st1 Extra307" x1="314.1207" y1="365.0684" x2="315.1668" y2="385.147"/>
        <line className="st1 Extra308" x1="310.8092" y1="383.7953" x2="313.7659" y2="364.6073"/>
        <polyline className="st1 Extra309" points="300.8187,357.2995 300.4387,357.1095 295.1687,354.4095 "/>
        <polyline className="st1 Extra310" points="303.5987,358.7195 308.8587,361.4095 309.8187,361.9095 313.1487,363.6095 "/>
        <polyline className="st1 Extra311" points="305.5987,357.4195 305.7787,357.5595 309.6487,360.5195 310.3287,361.0396 312.8587,362.9796 "/>
        <line className="st1 Extra312" x1="300.7987" y1="353.7495" x2="296.3587" y2="350.3495"/>
        <line className="st1 Extra313" x1="302.3887" y1="358.9495" x2="294.7487" y2="362.0795"/>
        <polyline className="st1 Extra314" points="307.9887,355.4495 314.3087,354.0695 305.7787,357.5595 304.8787,357.9295 "/>
        <polyline className="st1 Extra315" points="294.6687,358.3695 294.6787,358.3695 300.4387,357.1095 300.7787,357.0295 "/>
        <polyline className="st1 Extra316" points="301.0787,376.8195 306.0487,368.3495 306.3887,367.7695 307.1687,366.4295 309.8187,361.9095 
            310.3287,361.0395 312.5387,357.2595 309.6487,360.5195 308.8587,361.4095 305.0887,365.6595 304.4687,366.3595 302.1987,368.9195 
            298.4087,373.1895 "/>
        <line className="st1 Extra317" x1="315.8686" y1="366.6761" x2="295.4356" y2="366.1017"/>
        <line className="st1 Extra318" x1="296.653" y1="369.7455" x2="316.1188" y2="366.8417"/>
        <line className="st1 Extra319" x1="304.7012" y1="345.3393" x2="300.1443" y2="343.6756"/>
        <line className="st1 Extra320" x1="303.0851" y1="347.671" x2="298.023" y2="346.8397"/>
        <path className="st1 Extra321" d="M282.7887,310.7795l0.94,1.7c-5.37,11.24,1.36,23.75,1.36,23.75"/>
        <path className="st0 Extra322" d="M266.8091,283.6273l2.03,3.31l2.35,3.84l1.13,1.84l0.3-0.24l1.65-1.12l-2.74-4.31l0.86-1.82
            c0,0-0.21-0.45-0.21-1.15c-0.08,0.02-1.08,0.25-1.47,0c-0.42-0.27-3.38-3.67-3.38-3.67
            C267.1591,282.1172,266.8091,283.6273,266.8091,283.6273z"/>
        <path className="st0 Extra323" d="M265.4791,292.9773c0.33,0.41,0.58,0.7,0.63,0.71c0.2,0,3.54-2.24,5.08-2.91l-2.35-3.84c0,0-0.65,0.09-1.27,0.3
            c-0.31,0.1-0.62,0.2401-0.82,0.41c-0.61,0.51-2.52,2.27-2.78,2.72C263.7991,290.6673,264.7891,292.0973,265.4791,292.9773z"/>
        <path className="st0 Extra324" d="M265.6091,283.9673l1.96,3.27c0.62-0.21,1.27-0.3,1.27-0.3l-2.03-3.31
            C266.4091,283.7773,266.0092,283.8973,265.6091,283.9673z"/>
        <path className="st0 Extra325" d="M267.3281,280.3095c0,0,2.96,3.4,3.38,3.67c0.39,0.25,1.39,0.02,1.47,0v-0.08c-0.14-1.77-0.25-3.9-0.32-6.3
            c-1.27-0.42-2.81-0.81-4.55-1.07C267.4981,277.6695,267.4481,279.0595,267.3281,280.3095z"/>
        <path className="st0 Extra326" d="M275.9987,286.8195c0,0,5.03-3.51,7.88-5.72c0.12-0.09,0.23-0.18,0.33-0.26c0.85-0.67,1.45-1.19,1.56-1.41
            c-0.4301-1.32-4.67-1.61-8.11-0.78C276.8587,283.7695,275.9987,286.8195,275.9987,286.8195z"/>
        <path className="st0 Extra327" d="M260.8788,263.0695c0.07,2.54,0.12,4.99,0.12,4.99h0.23c0.2,0.06,0.39,0.14,0.56,0.25
            C261.7887,268.3095,263.1488,264.2695,260.8788,263.0695z"/>
        <path className="st0 Extra328" d="M259.66,268.06h-4.1c-0.25-0.57-0.6-1.12-1.08-1.6c-0.16-0.17-0.34-0.32-0.53-0.47c0.4-2,2.84-3.5,5.61-3.25
            C259.59,265.04,259.66,268.06,259.66,268.06z"/>
        <path className="st0 Extra329" d="M262.8888,269.4295c1.17,1.93,1.05,5.08,1.05,5.08c1.05-0.25,1.92-0.3,2.33-0.04c0.56,0.39,0.87,1.15,1.03,2.06
            h0.01c1.74,0.26,3.28,0.65,4.55,1.07c2.45,0.8,3.92,1.65,3.92,1.65c-1.2-1.85-2.58-3.39-3.98-4.67c-4.35-3.94-8.91-5.28-8.91-5.16
            C262.8888,269.4295,262.8888,269.4295,262.8888,269.4295z"/>
        <polygon className="st0 Extra330" points="276.8181,299.9795 278.0981,302.2895 280.3781,300.8495 278.9781,298.6395 "/>
        <path className="st0 Right_Side" d="M190.4081,381.3295c0.62,1.61,2.07,4.26,4.81,4.26h15.23c2.04,0,5.19-2.68,5.19-2.68s9.87,0.29,10.68,0
            c0.81-0.3,3.4901-1.07,5.11-1.07s3.74,1.07,6.55,1.07s9.58-3.49,10.64-3.49s5.23,0,5.23,0l8.13-10.47c0,0,0,0,1.53,0
            c1.54,0,2-0.72,2-1.7s0-0.77,0-2.51c0-1.75-1.49-1.96-2.89-1.96c-1.4,0-1.91-0.77-1.91-0.77s3.38-14.08,5.54-19.47
            c1.16-6.75,2.22-12.14,3.9399-16.3v-0.18c-0.04-6.3,2.4301-13.41,2.83-13.71c0.14-0.11,1.22-0.68,2.56-1.38
            c2.42-1.26,5.69-2.94,5.69-2.94l-3.17-5.74l-1.28-2.31l-4.2-7.6l-0.3,0.24l-1.13-1.84c-1.54,0.67-4.8799,2.91-5.08,2.91l0.33,0.56
            l1.48,2.54l3.81,6.54l-2.43,1.45l-8.17,34.42c0,0-0.17,0.35-0.47,0.97v0.01c0.13,2.05-2.25,9.71-2.25,9.71s0,2.25,0,5.78
            c0,1.14-0.73,1.91-1.72,2.43c-0.03,0.02-0.0699,0.03-0.1,0.05c-0.59,4.01-4.06,20.72-21.54,21.06c-15.34,0.29-27.63,0.62-32.44,0.74
            l-0.21,0.0601c-1.39,0.35-3.06,0.66-4.97,0.85C195.7481,381.0195,193.2581,381.1895,190.4081,381.3295z"/>
        <path className="st0 Extra332" d="M202.6087,379.9495c4.81-0.12,17.1-0.45,32.44-0.74c17.48-0.34,20.95-17.05,21.54-21.06
            c-2.03,1.01-5.04,1.01-5.16,1.01c-0.01,0-0.01,0-0.01,0c-2.76,5.66-5.21,10.66-5.91,12.04c-1.83,3.6201-7.02,6.9-16.55,6.9
            s-21.4,0-21.4,0S205.8487,379.1195,202.6087,379.9495z"/>
        <path className="st0 Extra333" d="M266.2487,342.5395c0.19-0.48,0.37-0.89,0.54-1.21c2.07-3.96,3.42-8.88,3.4-15.09
            C268.4687,330.3995,267.4087,335.7895,266.2487,342.5395z"/>
        <path className="st0 Right_Side" d="M270.1888,326.0595v0.18c0.23-0.57,0.48-1.11,0.74-1.63c2.12-4.24,4.66-13.05,4.78-13.45l1.98,2.82l5.1-3.2
            l-1.52-2.75c0,0-3.27,1.68-5.69,2.94c-1.34,0.7-2.42,1.27-2.56,1.38C272.6187,312.6495,270.1488,319.7595,270.1888,326.0595z"/>
        <path className="st0 Extra335" d="M255.7088,272.6695l2.33,3.08l-0.91,0.7l0.91,1.03c1.12-1.11,3.21-2.16,5.03-2.73c0.3-0.09,0.59-0.17,0.87-0.24
            c0,0,0.12-3.15-1.05-5.08c0,0,0,0,0-0.01c-0.3-0.47-0.64-0.85-1.1-1.11c-0.17-0.11-0.36-0.19-0.56-0.25h-5.67
            C256.4787,270.0895,256.1087,272.3595,255.7088,272.6695z"/>
        <path className="st0 Extra336" d="M253.3988,274.5095l1.59,1.97l0.81,1l1.33-1.03l0.91-0.7l-2.33-3.08
            C255.1087,273.1295,253.3988,274.5095,253.3988,274.5095z"/>
        <path className="st0 Extra337" d="M250.0387,275.0895c0.26,1.58,1.23,2.28,2.63,2.39c1.1,0.09,1.9-0.56,2.32-1l-1.59-1.97
            c-0.95,0.39-1.78,0.5699-2.47,0.61c-0.01,0-0.01,0-0.01,0C250.5987,275.1395,250.2987,275.1295,250.0387,275.0895z"/>
        <path className="st0 Extra338" d="M255.71,272.67c-0.6,0.46-2.31,1.84-2.31,1.84c-0.37,0.15-0.73,0.27-1.06,0.36c1.94-1.17,3.44-2.63,3.78-4.3
            C256.17,271.64,255.94,272.49,255.71,272.67z"/>
        <path className="st0 Right_Handle" d="M254.37,270.68c0,0,0,0.01,0,0.02c-0.04,0.19-0.4,1.64-2.28,2.82c-0.6801,0.43-1.75,0.9-2.9301,1.34
            c-0.83-0.33-1.35-0.92-1.67-1.44c1.43-0.4,2.85-0.91,3.98-1.56c0.53-0.35-0.3-3.39-2.05-3.8c0,0-1.08,0.41-2.6,0.82
            c0.05-0.74,0.36-1.49,0.86-2.13c0.11-0.07,0.23-0.13,0.35-0.18c0.03-0.02,0.0699-0.04,0.11-0.05c0.02-0.01,0.03-0.01,0.05-0.02
            c0.06-0.02,0.12-0.03,0.17-0.05c0.09-0.03,0.19-0.08,0.28-0.1c0.02-0.0099,0.03-0.02,0.05-0.02c0.01,0,0.02,0,0.03-0.01
            c0.05,0,0.1-0.01,0.15-0.02c0.15-0.03,0.3-0.06,0.45-0.07h0.05c0.08-0.01,0.16-0.01,0.24-0.01c0.29,0,0.57,0.04,0.86,0.09
            c0.1,0.02,0.2,0.04,0.3,0.07c0.17,0.05,0.33,0.11,0.5,0.18c0,0.01,0.01,0,0.01,0c0.1299,0.05,0.2599,0.11,0.39,0.19c0,0,0,0,0.01,0
            c0.38,0.2,0.75,0.47,1.08,0.78c0.69,0.65,1.24,1.5099,1.51,2.53c0.03,0.12,0.06,0.23,0.07,0.34
            C254.36,270.49,254.37,270.59,254.37,270.68z"/>
        <path className="st0 Extra340" d="M264.8587,297.7195c0,0,0.52,1.19,1.57,1.16c1.05-0.02,1.54-1.03,1.49-2.09l-1.48-2.54l-0.65,1.37
            L264.8587,297.7195z"/>
        <path className="st0 Right_Side" d="M154.2787,376.7395v4.17c0,0,4.47,0.51,8.56,0.86c2.16,0.19,4.21,0.33,5.44,0.33c2.66,0,13.86-0.33,22.13-0.77
            c2.85-0.14,5.34-0.31,7.02-0.47c1.91-0.19,3.58-0.5,4.97-0.85c0.0699-0.02,0.14-0.04,0.21-0.0601c3.24-0.83,4.95-1.85,4.95-1.85
            v-4.91c0,0-4.05-1.05-7.2-0.88c-1,0.06-3.59,0.2-6.92,0.39h-0.01c-7.1,0.42-17.58,1.07-23.49,1.53c-1.15,0.09-2.25,0.2-3.33,0.32
            h-0.05C159.5188,375.3695,154.2787,376.7395,154.2787,376.7395z"/>
        <path className="st0 Right_Side" d="M221.4287,371.2695v1.96c3.52,0.01,6.9,0.02,9.06,0.02c5.86,0,9.25-2.99,12.15-7.95c0,0-2.35,1.53-3.18,1.79
            c-0.46,0.14-1.44,1.12-2.57,1.97c-0.92,0.69-1.94,1.29-2.86,1.29h-11.71C221.8187,370.3695,221.4287,370.7695,221.4287,371.2695z"/>
        <path className="st0 Right_Side" d="M222.3187,370.3495h11.71c0.92,0,1.94-0.6,2.86-1.29h-11.38
            C223.9787,369.0595,222.3187,370.3495,222.3187,370.3495z"/>
        <path className="st0 Right_Side" d="M219.7387,369.3595v3.87h1.69v-1.96c0-0.5,0.39-0.9,0.89-0.92c0,0,1.66-1.29,3.19-1.29h11.38
            c1.13-0.85,2.11-1.83,2.57-1.97c0.83-0.26,3.18-1.79,3.18-1.79c0.19-0.33,0.39-0.68,0.58-1.03c0.07-0.14,0.15-0.28,0.23-0.44
            c-1.6,1.22-3.55,1.91-5.58,2.26c0,0,0,0-0.01,0c-0.94,0.84-2.17,1.35-3.51,1.35c-1.29,0-2.47-0.47-3.39-1.23
            c-0.23-0.03-0.44-0.05-0.66-0.08h-7.34C221.1787,366.1295,219.7387,367.5695,219.7387,369.3595z"/>
        <path className="st0 Extra345" d="M235.0788,296.3595c1.05-0.11,2.74-0.34,4.68-0.8c0,0,0,0,0.01,0c1.17-0.28,2.42-0.64,3.68-1.11
            c4.72-1.75,10.69-3.1899,12.06-3c0.48,0.0601,1.89,0.6,3.52,1.27c3.01,1.22,6.76,2.9,6.76,2.9l0.65-1.37l-0.33-0.56
            c-0.05-0.01-0.3-0.3-0.63-0.71l-3.57-1.26l-8.63-3.05c0,0-15,4.79-16.79,5.3C235.5687,294.2295,235.2088,295.3395,235.0788,296.3595
            z"/>
        <path className="st0 Extra346" d="M239.7688,295.5595c11.17-0.33,19.26-2.84,19.26-2.84c-1.6299-0.67-3.04-1.21-3.52-1.27
            c-1.37-0.1899-7.34,1.25-12.06,3C242.1887,294.9195,240.9387,295.2795,239.7688,295.5595z"/>
        <polygon className="st0 Speedometer/Odometer" points="232.6281,291.2595 233.6081,292.8595 242.4681,287.7295 241.4081,286.1495 "/>
        <polygon className="st0 Speedometer/Odometer" points="242.7487,285.6095 243.8288,286.9395 252.3587,281.9895 251.3288,280.5395 "/>
        <path className="st0 Right_Side" d="M245.9487,322.8595l2.16,0.98l13.08,5.94c2.84-1.72,3.32-5.55,1.82-6.35s-8.33-4.05-8.33-4.05
            s-1.56,1.37-3.12,0.79c-1.57-0.57-4.31-1.96-4.31-1.96L245.9487,322.8595z"/>
        <path className="st0 Right_Side" d="M249.2487,329.7795c0.2,0.46,0.35,0.94,0.45,1.43l9.05,4.88l0.47-0.5c0.1-0.14,0.19-0.28,0.28-0.41
            L249.2487,329.7795z"/>
        <path className="st0 Right_Side" d="M248.1087,327.8695c0.47,0.61,0.85,1.24,1.14,1.91l10.25,5.4c0.23-0.34,0.44-0.65,0.63-0.95L248.1087,327.8695z
            "/>
        <path className="st0 Right_Side" d="M217.2187,332.6495c0,0.42,0.04,0.84,0.11,1.25c0.08,0.44,0.2,0.88,0.36,1.3c0.24,0.65,0.58,1.27,0.99,1.86
            c0.2,0.29,0.42,0.56,0.65,0.83c0.31,0.36,0.64,0.7,1.0099,1.03c0.6201,0.55,1.33,1.07,2.11,1.54c0.35,0.21,0.71,0.41,1.08,0.6
            c0.44,0.22,0.9,0.43,1.38,0.62c0.08,0.04,0.16,0.07,0.2401,0.1c2.45,0.96,5.31,1.51,8.3799,1.51c3.3,0,6.3701-0.64,8.9301-1.73
            c0.0699-0.03,0.14-0.06,0.21-0.08c0.83-0.38,1.61-0.79,2.32-1.25c0.36-0.23,0.7-0.47,1.01-0.71c0.3-0.24,0.59-0.47,0.85-0.72
            c0.3-0.27,0.57-0.55,0.83-0.84c0.28-0.32,0.54-0.65,0.78-1c0.22-0.33,0.42-0.67,0.59-1.01c0.16-0.33,0.3-0.66,0.42-1
            c0.13-0.3799,0.22-0.7599,0.29-1.15c0.06-0.38,0.09-0.76,0.09-1.15c0-0.49-0.05-0.97-0.15-1.44c-0.1-0.49-0.25-0.97-0.45-1.43
            c-0.29-0.67-0.67-1.3-1.14-1.91c-0.32-0.41-0.67-0.8-1.06-1.18c-2.93-2.82-7.89-4.67-13.52-4.67c-5.3,0-10.0099,1.65-12.99,4.2
            c-1.23,1.06-2.17,2.27-2.73,3.58c-0.17,0.41-0.31,0.84-0.41,1.27C217.2787,331.5895,217.2187,332.1095,217.2187,332.6495z
             M224.3988,332.7795c0-4.95,4.0099-8.97,8.96-8.97c4.96,0,8.97,4.02,8.97,8.97s-4.01,8.97-8.97,8.97
            C228.4087,341.7495,224.3988,337.7295,224.3988,332.7795z"/>
        <path className="st0 Right_Side" d="M247.0487,326.6895c0.39,0.38,0.74,0.77,1.06,1.18l12.02,6.36c0.28-0.41,0.51-0.79,0.72-1.14l-13.56-7.05
            L247.0487,326.6895z"/>
        <path className="st0 Right_Side" d="M247.2887,326.0395l13.56,7.05c0.21-0.35,0.38-0.67,0.52-0.97l-13.69-7.11L247.2887,326.0395z"/>
        <path className="st0 Right_Side" d="M247.6787,325.0095l13.69,7.11c0.52-1.14,0.51-1.87-0.18-2.34l-13.08-5.94L247.6787,325.0095z"/>
        <path className="st0 Right_Side" d="M224.3988,332.7795c0,4.95,4.0099,8.97,8.96,8.97c4.96,0,8.97-4.02,8.97-8.97s-4.01-8.97-8.97-8.97
            C228.4087,323.8095,224.3988,327.8295,224.3988,332.7795z M229.8487,332.7795c0-1.94,1.58-3.51,3.51-3.51
            c1.94,0,3.52,1.57,3.52,3.51s-1.58,3.51-3.52,3.51C231.4287,336.2895,229.8487,334.7195,229.8487,332.7795z"/>
        <path className="st0 Right_Side" d="M229.8487,332.7795c0,1.94,1.58,3.51,3.51,3.51c1.94,0,3.52-1.57,3.52-3.51s-1.58-3.51-3.52-3.51
            C231.4287,329.2695,229.8487,330.8395,229.8487,332.7795z"/>
        <path className="st1 Right_Side" d="M247.2487,318.2095c0,0,2.74,1.39,4.31,1.96c1.56,0.58,3.12-0.79,3.12-0.79s6.83,3.25,8.33,4.05
            s1.02,4.63-1.82,6.35c0.69,0.47,0.7,1.2,0.18,2.34c-0.14,0.3-0.31,0.62-0.52,0.97s-0.44,0.73-0.72,1.14
            c-0.19,0.3-0.4,0.61-0.63,0.95c-0.09,0.13-0.18,0.27-0.28,0.41c0,0,1.3,2.54,1.44,4.58c0.3-0.62,0.47-0.97,0.47-0.97l8.17-34.42
            l2.43-1.45l-3.81-6.54c0.05,1.06-0.44,2.07-1.49,2.09c-1.05,0.03-1.57-1.16-1.57-1.16l-0.4,0.92c3.3799,5.86,2.44,11.94,1.79,13.4
            c-0.88,1.98-7.03,6.32-8.76,6.38C256.6087,318.4495,252.6787,318.3295,247.2487,318.2095z"/>
        <path className="st0 Right_Side" d="M251.4287,359.1595c0.12,0,3.13,0,5.16-1.01c0.03-0.02,0.07-0.03,0.1-0.05c0.99-0.52,1.72-1.29,1.72-2.43
            c0-3.53,0-5.78,0-5.78l-1.61-1.77C255.1387,351.5295,253.2088,355.4795,251.4287,359.1595z"/>
        <path className="st0 Right_Side" d="M207.5587,373.1895v4.91c0,0,11.87,0,21.4,0s14.72-3.28,16.55-6.9c0.7-1.3799,3.15-6.3799,5.91-12.04
            c0,0,0,0,0.01,0c1.78-3.68,3.71-7.63,5.37-11.04c1.71-3.54,3.15-6.49,3.86-7.94v-0.01c-0.14-2.04-1.44-4.58-1.44-4.58l-0.47,0.5
            l-0.81,0.87l-0.87,0.93l-0.91,0.97l-0.48,0.51c0,0-0.06,0.12-0.18,0.36c-0.1,0.21-0.25,0.52-0.44,0.91v0.01
            c-0.09,0.18-0.19,0.37-0.29,0.59c-0.01,0.01-0.02,0.03-0.03,0.04c-0.07,0.14-0.14,0.31-0.22,0.47h-0.01
            c-2.39,4.88-7.88,16.04-10.61,21.24c-0.16,0.3-0.3101,0.58-0.45,0.84c-0.08,0.16-0.16,0.3-0.23,0.44c-0.19,0.35-0.39,0.7-0.58,1.03
            c-2.9,4.96-6.29,7.95-12.15,7.95c-2.16,0-5.54-0.01-9.06-0.02h-1.69C213.5788,373.2095,207.5587,373.1895,207.5587,373.1895z"/>
        <path className="st0 Right_Side" d="M249.6987,331.2095c0.1,0.47,0.15,0.95,0.15,1.44l8.09,4.31l0.81-0.87L249.6987,331.2095z"/>
        <path className="st0 Right_Side" d="M249.7587,333.7995l7.31,4.09l0.87-0.93l-8.09-4.31C249.8487,333.0395,249.8187,333.4195,249.7587,333.7995z"/>
        <path className="st0 Right_Side" d="M249.4687,334.9495l6.69,3.91l0.91-0.97l-7.31-4.09C249.6887,334.1895,249.5987,334.5695,249.4687,334.9495z"/>
        <path className="st0 Right_Side" d="M249.0487,335.9495l6.45,3.78c0.12-0.24,0.18-0.36,0.18-0.36l0.48-0.51l-6.69-3.91
            C249.3487,335.2895,249.2088,335.6195,249.0487,335.9495z"/>
        <path className="st0 Right_Side" d="M255.0587,340.6495v-0.01c0.19-0.39,0.34-0.7,0.44-0.91l-6.45-3.78c-0.17,0.34-0.37,0.6801-0.59,1.01
            L255.0587,340.6495z"/>
        <path className="st0 Right_Side" d="M247.6787,337.9595l6.74,3.74c0.11-0.14,0.21-0.28,0.32-0.42c0.01-0.01,0.02-0.03,0.03-0.04
            c0.1-0.22,0.2-0.41,0.29-0.59l-6.6-3.69C248.2187,337.3095,247.9588,337.6395,247.6787,337.9595z"/>
        <line className="st1 Right_Side" x1="243.7746" y1="340.9358" x2="251.2826" y2="345.2108"/>
        <path className="st0 Right_Side" d="M203.2587,328.5695c0.55,1.15,2.47,3.45,2.47,3.45s7.44-4,10.12-5.58c1.35-0.79,2.39-1.72,3.1-2.54
            c0.71-0.81,1.08-1.51,1.11-1.87v-0.01l-1.01-2.2599c-1.88-0.21-4.39,1.3199-5.41,1.47c-1.02,0.15-1.96-0.58-2.34-0.45
            s-7.96,4.55-7.96,4.55S202.7088,327.4195,203.2587,328.5695z"/>
        <line className="st1 Extra369" x1="221.1666" y1="339.6958" x2="215.425" y2="342.6822"/>
        <line className="st1 Extra370" x1="217.9957" y1="336.1" x2="213.0017" y2="338.8403"/>
        <line className="st1 Extra371" x1="218.6762" y1="328.1213" x2="207.4256" y2="334.1696"/>
        <path className="st0 Right_Side" d="M219.0487,319.7595l1.01,2.2599v0.01l0.3,0.67c0,0,5.41-2.43,5.62-3.0699c0.21-0.64-0.68-1.69-0.68-1.69
            c-0.2,0-0.4-0.01-0.6-0.01C223.0287,318.3495,220.1187,319.1395,219.0487,319.7595z"/>
        <path className="st1 Extra373" d="M218.9487,323.8995l1.39,2.03l0.2,0.29c2.98-2.55,7.69-4.2,12.99-4.2c5.63,0,10.59,1.85,13.52,4.67l0.24-0.65
            l0.39-1.03l0.43-1.17l-2.16-0.98l-5.98-2.72l0.89-2.06c-3.81-0.06-7.95-0.11-12.09-0.11c-1.2,0-2.36-0.01-3.47-0.03
            c0,0,0.89,1.05,0.68,1.69s-5.62,3.0699-5.62,3.0699l-0.3-0.67C220.0287,322.3895,219.6587,323.0895,218.9487,323.8995z"/>
        <path className="st0 Right_Side" d="M239.9687,320.1395l5.98,2.72l1.3-4.65c-1.96-0.04-4.11-0.09-6.39-0.13L239.9687,320.1395z"/>
        <path className="st1 Extra375" d="M199.0487,338.1395c0,0,1.76,0.63,3.52,1.46"/>
        <path className="st0 Right_Side" d="M229.0887,362.1695c0,1.62,0.73,3.06,1.8701,4.04c0.92,0.76,2.1,1.23,3.39,1.23c1.34,0,2.57-0.51,3.51-1.35
            c0.01,0,0.01,0,0.01,0c1.07-0.96,1.75-2.36,1.75-3.92c0-2.9-2.36-5.26-5.27-5.26
            C231.4487,356.9095,229.0887,359.2695,229.0887,362.1695z M231.7487,362.1695c0-1.44,1.16-2.6,2.6-2.6s2.61,1.16,2.61,2.6
            c0,1.45-1.17,2.61-2.61,2.61S231.7487,363.6195,231.7487,362.1695z"/>
        <path className="st0 Right_Side" d="M231.7487,362.1695c0,1.45,1.16,2.61,2.6,2.61s2.61-1.16,2.61-2.61c0-1.44-1.17-2.6-2.61-2.6
            S231.7487,360.7295,231.7487,362.1695z"/>
        <path className="st0 Right_Side" d="M224.9087,357.2895v7.76c0,0,2.28,0.69,5.39,1.08c0.22,0.03,0.43,0.05,0.66,0.08
            c-1.14-0.98-1.8701-2.42-1.8701-4.04c0-2.9,2.36-5.26,5.26-5.26c2.91,0,5.27,2.36,5.27,5.26c0,1.56-0.68,2.96-1.75,3.92
            c2.03-0.35,3.98-1.04,5.58-2.26c0.14-0.2599,0.29-0.54,0.45-0.84c0,0,0.06-2.35,0.35-6.4c-1.93-1.48-4.39-2.65-7.13-3.06
            c-1.7-0.25-3.51-0.21-5.37,0.22h-0.01C229.4887,354.2795,227.1787,355.3995,224.9087,357.2895z"/>
        <path className="st0 Right_Side" d="M237.1187,353.5295c2.74,0.41,5.2,1.58,7.13,3.06l0.38-3.21l-6.29-2.09L237.1187,353.5295z"/>
        <path className="st0 Right_Side" d="M223.3787,354.1995l1.53,3.09c2.27-1.89,4.58-3.01,6.83-3.54l-1.82-2.46L223.3787,354.1995z"/>
        <path className="st0 Right_Side" d="M197.2981,355.8695l17.62-0.11l1.16-3.46c-0.05,0.01-0.11,0.01-0.16,0.01c-1.43,0-2.6-0.65-2.6-1.46
            c0-0.06,0.01-0.12,0.01-0.17h-9.17C202.4681,351.9795,200.0381,353.8195,197.2981,355.8695z"/>
        <path className="st1 Extra382" d="M218.5181,350.8495c0,0.78-1.07,1.41-2.44,1.45l-1.16,3.46l4.21-0.03v5.55l-7.79,0.89
            c0.55,0.26,1.09,3.9,1.66,5.27c0.58,1.37,6.74,3.48,6.74,3.48v-1.56c0-1.79,1.44-3.23,3.22-3.23h7.34
            c-3.11-0.39-5.39-1.08-5.39-1.08v-7.76l-1.53-3.09l6.54-2.91l1.82,2.46h0.01c1.86-0.43,3.67-0.47,5.37-0.22l1.22-2.24l6.29,2.09
            l-0.3799,3.21c-0.29,4.05-0.35,6.4-0.35,6.4c2.73-5.2,8.22-16.36,10.61-21.24l-0.09-0.05c-0.24,0.3101-0.49,0.61-0.74,0.91
            c-0.23,0.28-0.47,0.55-0.71,0.82c-0.28,0.31-0.56,0.62-0.84,0.91c-0.29,0.3-0.5699,0.59-0.85,0.87c-0.28,0.28-0.55,0.54-0.82,0.8
            c-0.3,0.28-0.58,0.54-0.86,0.8h-0.01c-0.32,0.29-0.63,0.56-0.92,0.8c-0.36,0.32-0.7,0.59-1,0.82c-0.79,0.63-1.31,0.97-1.39,0.95
            c-0.26-0.09-0.77-1.45-1.19-1.96c-0.43-0.51-3.75-1.87-3.75-1.87l0.17-0.59l0.34-1.22l0.33-1.2l0.28-0.98
            c-2.56,1.09-5.63,1.73-8.93,1.73c-3.07,0-5.93-0.55-8.38-1.51l0.27,1.02l0.3,1.11l0.35,1.3l0.29,1.1l-3.65,1.92v0.51
            c0,0-0.13,0.64-1.37,1.53c-0.3-0.09-0.75-0.5-1.28-1.11c-0.2-0.22-0.4-0.46-0.61-0.72l-0.18-0.2401c0,0-9.33,0.16-10.1-1.71
            c-0.76-1.86,3.48-8.23,3.48-8.23c-0.05-0.08-0.07-0.1201-0.07-0.1201l-1.28,0.94l-0.94-1.19l-0.77-0.98l-0.73-0.93l-0.62-0.78
            c0,0-0.3,0.09-0.81,0.21l-2.56,5.44l-0.42,0.9l-2.51,0.12c-0.7599,2.46-1.93,5.33-3.66,7.74c4.01-2.93,7.03-5.15,7.03-5.15
            l1.31,4.34c0,0-0.9301,0.72-2.46,1.88h9.17c0.16-0.74,1.26-1.3,2.59-1.3C217.3581,349.3795,218.5181,350.0295,218.5181,350.8495z"/>
        <path className="st0 Right_Side" d="M209.1687,346.4895c0.77,1.87,10.1,1.71,10.1,1.71c-0.19-0.23-0.37-0.47-0.56-0.73
            c-0.23-0.31-0.47-0.64-0.71-0.98c-0.19-0.26-0.38-0.53-0.57-0.81c-0.23-0.34-0.47-0.68-0.71-1.03c-0.21-0.33-0.43-0.65-0.64-0.97
            c-0.22-0.34-0.44-0.67-0.65-1c-0.21-0.32-0.41-0.63-0.6-0.93c-0.21-0.31-0.4-0.62-0.58-0.9c-0.24-0.39-0.46-0.74-0.66-1.06
            c-0.23-0.37-0.43-0.69-0.59-0.95c-0.16-0.27-0.28-0.47-0.35-0.58C212.6488,338.2595,208.4087,344.6295,209.1687,346.4895z"/>
        <path className="st0 Right_Side" d="M213.3187,350.8495c0,0.81,1.17,1.46,2.6,1.46c0.05,0,0.11,0,0.16-0.01c1.37-0.04,2.44-0.67,2.44-1.45
            c0-0.82-1.16-1.47-2.6-1.47c-1.33,0-2.43,0.56-2.59,1.3C213.3288,350.7295,213.3187,350.7895,213.3187,350.8495z"/>
        <path className="st0 Right_Side" d="M208.0987,335.0195l0.14,0.18l0.62,0.78l8.54-4.91c0.1-0.4301,0.24-0.86,0.41-1.27L208.0987,335.0195z"/>
        <path className="st0 Right_Side" d="M208.8587,335.9795l0.73,0.93l7.63-4.26c0-0.54,0.06-1.06,0.1801-1.58L208.8587,335.9795z"/>
        <path className="st0 Right_Side" d="M210.3587,337.8895l0.94,1.19l1.28-0.94l5.11-2.94c-0.16-0.42-0.28-0.86-0.36-1.3L210.3587,337.8895z"/>
        <path className="st0 Right_Side" d="M216.7187,344.6495c0.2401,0.35,0.48,0.69,0.71,1.03l7.48-4c-0.48-0.19-0.94-0.4-1.38-0.62L216.7187,344.6495z"
            />
        <path className="st0 Right_Side" d="M216.0788,343.6795c0.21,0.32,0.43,0.64,0.64,0.97l6.81-3.59c-0.37-0.19-0.73-0.39-1.08-0.6L216.0788,343.6795z
            "/>
        <path className="st0 Right_Side" d="M214.2487,340.8495c0.18,0.28,0.37,0.59,0.58,0.9l5.5099-2.83c-0.37-0.33-0.7-0.67-1.0099-1.03
            L214.2487,340.8495z"/>
        <path className="st0 Right_Side" d="M213.5887,339.7895c0.2,0.32,0.42,0.67,0.66,1.06l5.08-2.96c-0.23-0.27-0.45-0.54-0.65-0.83L213.5887,339.7895z
            "/>
        <path className="st0 Right_Side" d="M217.4287,345.6795c0.19,0.28,0.38,0.55,0.57,0.81l7.42-3.69l-0.27-1.02c-0.08-0.03-0.16-0.06-0.2401-0.1
            L217.4287,345.6795z"/>
        <path className="st0 Right_Side" d="M217.9987,346.4895c0.24,0.34,0.48,0.67,0.71,0.98l7.0099-3.56l-0.3-1.11L217.9987,346.4895z"/>
        <path className="st0 Right_Side" d="M218.7088,347.4695c0.19,0.26,0.37,0.5,0.56,0.73l0.18,0.2401l6.62-3.23l-0.35-1.3L218.7088,347.4695z"/>
        <path className="st0 Right_Side" d="M219.4487,348.4395c0.21,0.2599,0.41,0.5,0.61,0.72l6.3-2.85l-0.29-1.1L219.4487,348.4395z"/>
        <path className="st0 Right_Side" d="M220.0587,349.1595c0.53,0.61,0.98,1.02,1.28,1.11c1.2401-0.89,1.3701-1.53,1.3701-1.53v-0.51l3.65-1.92
            L220.0587,349.1595z"/>
        <path className="st0 Right_Side" d="M241.3387,345.5495c0,0,3.32,1.36,3.75,1.87c0.42,0.51,0.9301,1.87,1.19,1.96c0.08,0.02,0.6-0.32,1.39-0.95
            l-6.16-3.47L241.3387,345.5495z"/>
        <path className="st0 Right_Side" d="M241.5087,344.9595l6.16,3.47c0.3-0.23,0.64-0.5,1-0.82l-6.82-3.87L241.5087,344.9595z"/>
        <path className="st0 Right_Side" d="M241.8487,343.7395l6.82,3.87c0.29-0.24,0.6-0.51,0.92-0.8l-7.41-4.27L241.8487,343.7395z"/>
        <path className="st0 Right_Side" d="M242.1787,342.5395l7.41,4.27h0.01c0.28-0.26,0.56-0.52,0.86-0.8l-7.79-4.53c-0.07,0.02-0.14,0.05-0.21,0.08
            L242.1787,342.5395z"/>
        <path className="st0 Right_Side" d="M246.8487,338.7995l6.83,3.81c0.25-0.3,0.5-0.6,0.74-0.91l-6.74-3.74
            C247.4187,338.2495,247.1488,338.5295,246.8487,338.7995z"/>
        <path className="st0 Right_Side" d="M244.9887,340.2295l7.14,4.11c0.28-0.29,0.56-0.6,0.84-0.91l-6.97-3.91
            C245.6887,339.7595,245.3487,339.9995,244.9887,340.2295z"/>
        <path className="st0 Right_Side" d="M245.9987,339.5195l6.97,3.91c0.2401-0.27,0.48-0.54,0.71-0.82l-6.83-3.81
            C246.5887,339.0495,246.2987,339.2795,245.9987,339.5195z"/>
        <path className="st0 Right_Side" d="M189.4187,367.7895l4.01,4.91h0.01c3.33-0.1899,5.92-0.33,6.92-0.39c3.15-0.17,7.2,0.88,7.2,0.88
            s6.02,0.02,12.18,0.04v-2.31c0,0-6.16-2.11-6.74-3.48c-0.57-1.37-1.11-5.01-1.66-5.27c-0.54-0.25-7.46-3.38-7.46-3.38
            s-9.67,0.86-12.07,2.52L189.4187,367.7895z M192.7387,366.0995c0-2.27,1.84-4.12,4.11-4.12h7.12c1.13,0,2.17,0.46,2.91,1.21
            c0.75,0.75,1.21,1.77,1.21,2.91c0,2.27-1.85,4.12-4.12,4.12h-7.12c-1.13,0-2.16-0.46-2.91-1.21
            C193.1987,368.2595,192.7387,367.2395,192.7387,366.0995z"/>
        <path className="st0 Right_Side" d="M166.6081,374.5495c1.08-0.12,2.18-0.23,3.33-0.32c5.91-0.46,16.39-1.11,23.4901-1.53l-4.01-4.91l2.39-6.48
            c2.4-1.66,12.0699-2.52,12.0699-2.52s6.92,3.13,7.46,3.38l7.79-0.89v-5.55l-4.21,0.03l-17.62,0.11c-0.01,0-0.01,0-0.01,0
            c-7.4,5.53-17.01,12.5-20.16,13.85c-2.15,0.92-5.69,1.42-9.17,1.69C167.5281,372.4795,166.9581,373.7895,166.6081,374.5495z"/>
        <path className="st1 Extra407" d="M110.4891,375.3773c0.39,0.66,1.17,1.91,2.19,3.14l2.9-3.29c-1.5,0.04-2.91,0.09-4.18,0.12
            C111.0892,375.3573,110.7792,375.3672,110.4891,375.3773z"/>
        <path className="st0 Extra408" d="M121.688,375.0495c0.19,0.22,3.3,3.77,10.59,3.77c7.53,0,11.4-4.47,11.4-4.47c-0.22,0-0.45,0.01-0.68,0.02
            c-5.63,0.19-13.76,0.44-21.07,0.67C121.8481,375.0395,121.7681,375.0395,121.688,375.0495z"/>
        <path className="st1 Extra409" d="M146.7481,376.5895l2,4.19c0.84-0.78,1.59-1.57,2.24-2.33L146.7481,376.5895z"/>
        <path className="st1 Extra410" d="M144.1581,378.2595l1.6,4.91c1.1-0.76,2.1-1.57,2.9901-2.39l-2-4.19l4.24,1.86c2.58-2.99,3.76-5.59,3.76-5.59
            c-0.21,0.07-0.42,0.14-0.63,0.21c-1.4301,0.5-3.03,0.94-4.22,1.03c-0.2,0.01-0.59,0.03-1.15,0.06
            C148.7481,374.1595,147.0881,376.2095,144.1581,378.2595z"/>
        <path className="st1 Extra411" d="M141.9881,379.6195l3.77,3.55l-1.6-4.91C143.4981,378.7195,142.7781,379.1795,141.9881,379.6195z"/>
        <path className="st1 Extra412" d="M135.438,382.0395l3.21,4.42v-5.31C137.6381,381.5195,136.5781,381.8295,135.438,382.0395z"/>
        <path className="st1 Extra413" d="M138.6481,381.1495l3.14,4.2c1.46-0.62,2.78-1.37,3.97-2.18l-3.77-3.55
            C140.9781,380.1895,139.8581,380.7195,138.6481,381.1495z"/>
        <path className="st1 Extra414" d="M138.6481,381.1495v5.31c1.11-0.3,2.16-0.67,3.14-1.11L138.6481,381.1495z"/>
        <line className="st1 Extra415" x1="131.4272" y1="382.3553" x2="129.5155" y2="387.1669"/>
        <path className="st1 Extra416" d="M134.0181,387.2295c1.65-0.11,3.19-0.38,4.63-0.77l-3.21-4.42h-0.0099L134.0181,387.2295z"/>
        <path className="st1 Extra417" d="M123.1081,380.3295v5.45c1,0.36,2.06,0.66,3.2,0.9l-0.19-5.16
            C125.0181,381.1895,124.0081,380.7795,123.1081,380.3295z"/>
        <path className="st1 Extra418" d="M119.0481,383.9095c1.21,0.7,2.56,1.34,4.06,1.87v-5.45L119.0481,383.9095z"/>
        <path className="st1 Extra419" d="M115.5781,381.4595c0.99,0.84,2.14,1.68,3.47,2.45l4.06-3.58c-1.14-0.56-2.13-1.19-2.97-1.81L115.5781,381.4595
            z"/>
        <path className="st1 Extra420" d="M112.6791,378.5173c0.75,0.9,1.71,1.92,2.9,2.94l4.56-2.94c-2.24-1.65-3.4-3.22-3.48-3.32
            c-0.37,0.01-0.73,0.02-1.08,0.03L112.6791,378.5173z"/>
        <path className="st0 Extra421" d="M123.7181,367.0895c0.05,0.04,0.08,0.09,0.12,0.14l2.86-0.06h0.01l21.14-0.39l3.36-0.07l5.29-0.09l0.29-0.01
            c0,0,14.43-0.3,15.96-0.51c1.53-0.21,3.49-0.9,6.04-2.47c0.52-0.32,1.93-1.31,3.85-2.6801l-1.04-0.0099l-12.06-0.14l-12.46-0.14
            l-5.12-0.06l-3.48-0.04l-7.64-0.09l-2.1-0.02l-14.4-0.16l-0.24,2.65v0.0099L123.7181,367.0895z M127.688,363.9295
            c0-1.23,1-2.23,2.2401-2.23c1.23,0,2.23,1,2.23,2.23s-1,2.23-2.23,2.23C128.688,366.1595,127.688,365.1595,127.688,363.9295z"/>
        <path className="st0 Extra422" d="M121.8481,361.4095c0,2.13,0.7,4.09,1.87,5.68l0.38-4.14v-0.0099c-0.11-0.5-0.16-1-0.16-1.53
            c0-4.11,3.33-7.43,7.4301-7.43c3.78,0,6.91,2.81,7.37,6.47l2.1,0.02c-0.03-0.31-0.08-0.62-0.14-0.92c-0.12-0.61-0.3-1.21-0.54-1.77
            c-1.42-3.46-4.82-5.89-8.79-5.89C126.1181,351.8895,121.8481,356.1495,121.8481,361.4095z"/>
        <path className="st0 Extra423" d="M123.938,361.4095c0,0.53,0.05,1.03,0.16,1.53l0.24-2.65l14.4,0.16c-0.46-3.66-3.59-6.47-7.37-6.47
            C127.2681,353.9795,123.938,357.2995,123.938,361.4095z"/>
        <path className="st0 Extra424" d="M115.5781,362.0295c0,1.87,0.31,3.66,0.89,5.33l7.24-0.13l0.01-0.14c-1.17-1.59-1.87-3.55-1.87-5.68
            c0-5.26,4.27-9.52,9.52-9.52c3.97,0,7.37,2.4301,8.79,5.89c0.16-1.05,1.06-1.85,2.15-1.85c1.2,0,2.17,0.97,2.17,2.17
            c0,1.19-0.97,2.17-2.17,2.17c-0.64,0-1.21-0.28-1.61-0.72c0.06,0.3,0.11,0.61,0.14,0.92l7.64,0.09c-0.11-1.29-0.38-2.54-0.77-3.73
            l-1.03,1.27c0,0-3.19-1.83-4.02-4.19l1.85-2.68c-3.02-3.48-7.48-5.68-12.44-5.68
            C122.9581,345.5495,115.5781,352.9295,115.5781,362.0295z"/>
        <line className="st1 Extra425" x1="156.6267" y1="356.4567" x2="151.198" y2="355.7382"/>
        <path className="st2 Extra426" d="M110.1381,324.3095c1.38,0.31,3,1.07,4.82,2.28l7.41-0.39c7.53-4.14,17.61-0.91,17.61-0.91l13.32-0.69
            C129.4981,310.5095,110.1381,324.3095,110.1381,324.3095z"/>
        <path className="st2 Extra427" d="M94.3381,344.9095l6.15-1.39h0.01c3.28-6.5,7.25-9.46,7.25-9.46c-1.7-0.53-2.21-4.47-1.79-7.16
            C97.8481,333.9695,94.3381,344.9095,94.3381,344.9095z"/>
        <path className="st0 Extra428" d="M103.5881,320.9295v3.47c0.74-0.61,1.48-1.19,2.23-1.73L103.5881,320.9295z"/>
        <path className="st0 Carrier" d="M97.4181,315.3595v9.79c0,1.69,3.52,1.72,3.52,1.72c0.86-0.89,1.75-1.71,2.65-2.46v-3.48l2.23,1.74
            c0.6-0.44,1.21-0.85,1.81-1.24l-4.03-2.88v-5.43C103.5981,313.1195,97.4181,311.9095,97.4181,315.3595z"/>
        <polyline className="st1 Extra430" points="103.5887,320.9295 101.3587,319.1895 101.3587,312.9195 "/>
        <path className="st2 Extra431" d="M162.3981,338.7995c4.77,6.53,6.49,13.09,6.59,13.49l-0.03-0.55c0.16-6.98-1.84-12.01-4.47-15.51
            L162.3981,338.7995z"/>
        <path className="st2 Extra432" d="M152.3081,329.4695c1.65,1.03,3.53,2.4,4.78,3.5l1.39-2.01c-1.9-1.04-3.59-1.5-4.57-1.5
            C153.6081,329.4595,153.0581,329.4695,152.3081,329.4695z"/>
        <path className="st1 Extra433" d="M150.5581,353.3395l4.7599-1.34c-0.38-0.98-0.84-1.93-1.35-2.85l-0.28,0.34l-1.1899,1.46l-1.1,1.36
            L150.5581,353.3395z"/>
        <line className="st1 Extra434" x1="135.5639" y1="336.4743" x2="133.3421" y2="341.3317"/>
        <path className="st1 Extra435" d="M122.5981,338.0995l3.52,4.14c1.04-0.33,2.12-0.57,3.23-0.72l-2.57-4.72
            C125.3281,337.0995,123.938,337.5395,122.5981,338.0995z"/>
        <line className="st1 Extra436" x1="116.4272" y1="341.9404" x2="118.2892" y2="346.8681"/>
        <path className="st1 Extra437" d="M110.1781,349.1195l5.27,1.14h0.01c0.6-0.92,1.28-1.79,2.03-2.59l-4.55-2.54
            C111.8881,346.3595,110.9681,347.6995,110.1781,349.1195z"/>
        <line className="st1 Extra438" x1="112.9364" y1="355.3447" x2="108.9894" y2="351.573"/>
        <line className="st1 Extra439" x1="112.346" y1="358.441" x2="108.7038" y2="356.4567"/>
        <path className="st1 Extra440" d="M107.2873,364.716c0.003,0.0218,4.9871-2.2543,4.8614-2.2543"/>
        <path className="st1 Extra441" d="M107.9681,367.9695c0.14,0.52,0.3,1.03,0.48,1.53l4.49-1.23L107.9681,367.9695z"/>
        <path className="st0 Extra442" d="M127.688,363.9295c0,1.23,1,2.23,2.2401,2.23c1.23,0,2.23-1,2.23-2.23s-1-2.23-2.23-2.23
            C128.688,361.6995,127.688,362.6995,127.688,363.9295z"/>
        <path className="st0 Rear_Suspension" d="M140.1581,357.7795c0.24,0.56,0.42,1.16,0.54,1.77c0.4,0.44,0.97,0.72,1.61,0.72c1.2,0,2.17-0.98,2.17-2.17
            c0-1.2-0.97-2.17-2.17-2.17C141.2181,355.9295,140.3181,356.7295,140.1581,357.7795z"/>
        <line className="st1 Extra444" x1="171.3687" y1="356.7896" x2="169.2487" y2="355.9295"/>
        <path className="st0 Extra445" d="M156.1181,354.3395c0.14,0.49,0.27,1,0.38,1.51c0,0,0.64,0.09,1.4399,0.08l0.4-2.59
            C158.1981,353.3395,156.7481,353.3895,156.1181,354.3395z"/>
        <path className="st0 Extra446" d="M157.7281,357.2595l3.63,3.24l3.95-2.98l-6.63-6.32l-0.33,2.14c0,0,0,0-0.01,0l-0.4,2.59L157.7281,357.2595z"/>
        <path className="st0 Extra447" d="M116.4281,330.1595c0,0,28.23-0.58,35.87-0.69h0.01c0.75,0,1.3-0.01,1.6-0.01c0.98,0,2.67,0.46,4.57,1.5
            c1.25,0.69,2.6,1.64,3.89,2.88h6.91c-1.05-2.42-1.79-4.14-2.32-5.82c-4.23-0.6-7.38-2.02-10.14-3.6l-3.5099,0.18h-0.01l-13.32,0.69
            l-17.61,0.91l-7.41,0.39C114.9581,326.5895,116.8781,329.0795,116.4281,330.1595z M160.6181,330.9595c0-1.4,1.13-2.53,2.53-2.53
            s2.54,1.13,2.54,2.53s-1.14,2.54-2.54,2.54C161.7481,333.4995,160.6181,332.3595,160.6181,330.9595z"/>
        <path className="st0 Rear_Fender" d="M162.3681,333.8395c0.73,0.7,1.45,1.49,2.12,2.39c2.63,3.5,4.63,8.53,4.47,15.51l4.98,0.57
            c0,0,0.32-6.76-2.17-12.7c-0.98-2.33-1.8-4.2-2.48-5.77L162.3681,333.8395L162.3681,333.8395z"/>
        <path className="st1 Extra449" d="M126.7781,336.7995l2.57,4.72c0.29-0.05,0.59-0.08,0.89-0.1l1.08-5.17
            C129.7681,336.2895,128.2481,336.4695,126.7781,336.7995z"/>
        <path className="st1 Extra450" d="M138.4681,337.0595l0.54,5.52c1.15,0.42,2.25,0.95,3.28,1.58l0.15-5.68
            C141.1681,337.8995,139.8481,337.4195,138.4681,337.0595z"/>
        <path className="st1 Extra451" d="M142.2881,344.1595l3.89-3.57c-1.18-0.81-2.43-1.52-3.7401-2.11L142.2881,344.1595z"/>
        <path className="st1 Extra452" d="M142.2881,344.1595c0.82,0.49,1.6,1.04,2.34,1.64l4.52-2.82c-0.93-0.88-1.91-1.67-2.96-2.39h-0.0099
            L142.2881,344.1595z"/>
        <path className="st1 Extra453" d="M144.6281,345.7995c0.78,0.64,1.51,1.33,2.19,2.08l0.37-0.54l1.14-1.66l1.15-1.66v-0.0099l0.3-0.4301
            c-0.21-0.21-0.42-0.41-0.63-0.6L144.6281,345.7995z"/>
        <path className="st0 Right_Side" d="M173.938,352.3095l-4.98-0.57l0.03,0.55c0.01,0.01,0.01,0.02,0.01,0.02l0.0699,1.15v0.01l2.3,0.12l6.3,0.33
            l2.28,0.12c0.69-1.9,2.52-3.25,4.65-3.25c2.73,0,4.95,2.21,4.95,4.95c0,0.08,0,0.15-0.01,0.23c2.95-2.14,6.07-4.41,8.74-6.36
            c1.73-2.41,2.9-5.28,3.66-7.74l-4.06,0.2c-0.1,0.8799-0.35,1.5099-0.83,1.56c-1.71,0.17-10.86,1.66-14.09,2.04
            c-3.23,0.39-4.77,0.69-5.36-0.55c-0.6-1.23-2.77-15.87-0.89-16c1.87-0.13,18.95-3.25,19.4-1.81c0.45,1.45,1.42,8.68,1.42,8.68
            c1.3,0.63,3.58,0.54,5.7,0.24c1.69-0.23,3.28-0.59,4.2-0.82c0.5099-0.12,0.81-0.21,0.81-0.21l-0.14-0.18l-0.67-0.85l-0.71-0.89
            l-0.99-1.26c0,0-1.92-2.3-2.47-3.45c-0.55-1.15,0.08-3.2401,0.08-3.2401h-2.55c3.66-5.19,13.45-8.21,13.45-8.21
            c-4.61-0.8-7.08-2.24-6.73-4.7c0.01-0.07,0.03-0.14,0.05-0.21c-0.7,1.49-3,6.13-5.45,8.11c-8.98,7.5-26.68,8.89-35.15,7.7
            c0.53,1.68,1.27,3.4,2.32,5.82h0.01c0.68,1.57,1.5,3.44,2.48,5.77C174.2581,345.5495,173.938,352.3095,173.938,352.3095z
             M182.0481,347.5895c0.89-2.08,4.81-1.15,4.56-0.78c-0.26,0.37-3.73,2.68-3.73,2.68S181.4981,348.7995,182.0481,347.5895z
             M174.7381,335.1695h-3.99l-0.42-4.37l3.93-0.73L174.7381,335.1695z"/>
        <path className="st0 Right_Side" d="M201.938,341.8695l2.51-0.12l0.42-0.9c-0.64-0.43-1.47-0.87-2.3-1.25
            C202.3981,340.2895,202.188,341.0595,201.938,341.8695z"/>
        <path className="st0 Right_Side" d="M202.5681,339.5995c0.83,0.38,1.66,0.82,2.3,1.25l2.56-5.44c-0.92,0.23-2.51,0.59-4.2,0.82
            C203.2281,336.2295,203.0581,337.5995,202.5681,339.5995z"/>
        <path className="st0 Right_Side" d="M205.7287,332.0195l0.99,1.26l13.62-7.35l-1.39-2.03c-0.71,0.82-1.75,1.75-3.1,2.54
            C213.1687,328.0195,205.7287,332.0195,205.7287,332.0195z"/>
        <path className="st0 Right_Side" d="M200.7887,325.3295h2.55c0,0,7.58-4.42,7.96-4.55s1.32,0.6,2.34,0.45c1.02-0.15,3.53-1.68,5.41-1.47
            c1.07-0.6201,3.98-1.41,5.65-1.83c-4.16-0.09-7.69-0.33-10.46-0.81C214.2387,317.1195,204.4487,320.1395,200.7887,325.3295z"/>
        <polygon className="st0 Right_Side" points="171.3681,353.5895 171.3681,356.7895 179.8181,360.2195 177.6681,353.9195 "/>
        <path className="st0 Right_Side" d="M182.8787,349.4895c0,0,3.47-2.31,3.73-2.68c0.25-0.37-3.67-1.3-4.56,0.78
            C181.4987,348.7995,182.8787,349.4895,182.8787,349.4895z"/>
        <path className="st0 Right_Side" d="M179.6481,355.7395c0,2.27,1.53,4.18,3.62,4.76c1.81-1.3,3.99-2.87,6.27-4.52v-0.01
            c0.01-0.08,0.01-0.15,0.01-0.23c0-2.74-2.22-4.95-4.95-4.95c-2.13,0-3.96,1.35-4.65,3.25
            C179.7481,354.5695,179.6481,355.1395,179.6481,355.7395z"/>
        <path className="st0 Right_Side" d="M177.6681,353.9195l2.15,6.3l1.78,0.72l1.04,0.0099c0.2-0.15,0.41-0.3,0.63-0.45
            c-2.09-0.58-3.62-2.49-3.62-4.76c0-0.6,0.1-1.17,0.3-1.7L177.6681,353.9195z"/>
        <path className="st0 Silencer/Exhaust" d="M156.7881,366.6095l0.89,5.11c0,0,5.07,0.1,10.28-0.31c3.48-0.27,7.02-0.77,9.17-1.69
            c3.15-1.35,12.76-8.32,20.16-13.85c0,0,0,0,0.01,0c2.74-2.05,5.17-3.89,6.86-5.19c1.53-1.16,2.46-1.88,2.46-1.88l-1.31-4.34
            c0,0-3.02,2.22-7.03,5.15c-2.67,1.95-5.79,4.22-8.74,6.36v0.01c-2.28,1.65-4.46,3.22-6.27,4.52c-0.22,0.15-0.43,0.3-0.63,0.45
            c-1.92,1.3701-3.33,2.36-3.85,2.6801c-2.55,1.5699-4.51,2.2599-6.04,2.47C171.2181,366.3095,156.7881,366.6095,156.7881,366.6095z"
            />
        <path className="st0 Right_Side" d="M177.5987,345.1195c0.59,1.24,2.13,0.94,5.36,0.55c3.23-0.38,12.3799-1.87,14.09-2.04
            c0.48-0.05,0.73-0.6801,0.83-1.56c0.28-2.23-0.35-6.08-0.35-6.08s-0.97-7.23-1.42-8.68c-0.45-1.44-17.53,1.68-19.4,1.81
            C174.8288,329.2495,176.9987,343.8895,177.5987,345.1195z"/>
        <path className="st0 Right_Side" d="M170.3288,330.7995l0.42,4.37h3.99l-0.48-5.1L170.3288,330.7995z M171.2287,332.8495c0-0.73,0.58-1.31,1.3-1.31
            c0.73,0,1.31,0.58,1.31,1.31c0,0.72-0.58,1.3-1.31,1.3C171.8087,334.1495,171.2287,333.5695,171.2287,332.8495z"/>
        <path className="st0 Right_Side" d="M171.2287,332.8495c0,0.72,0.58,1.3,1.3,1.3c0.73,0,1.31-0.58,1.31-1.3c0-0.73-0.58-1.31-1.31-1.31
            C171.8087,331.5395,171.2287,332.1195,171.2287,332.8495z"/>
        <path className="st0 Rear_Suspension" d="M160.6181,330.9595c0,1.4,1.13,2.54,2.53,2.54s2.54-1.14,2.54-2.54s-1.14-2.53-2.54-2.53
            C161.7481,328.4295,160.6181,329.5595,160.6181,330.9595z"/>
        <path className="st0 Speedometer/Odometer" d="M232.6281,293.4295l1.34,3.03c0,0,0.41-0.02,1.11-0.1c0.13-1.02,0.49-2.13,1.41-2.39
            c1.79-0.51,16.79-5.3,16.79-5.3l8.63,3.05l-8.57-10.29l-0.97,0.56h-0.01l-8.53,4.95l-1.36,0.79l-8.86,5.13L232.6281,293.4295z"/>
        <path className="st0 Silencer/Exhaust" d="M103.6791,370.8173l1.07,4.73c0,0,2.24-0.07,5.74-0.17c0.29-0.01,0.6-0.02,0.91-0.03
            c1.27-0.03,2.68-0.08,4.18-0.12c0.35-0.01,0.71-0.02,1.08-0.03c0.38-0.02,0.76-0.03,1.15-0.04c1.25-0.04,2.55-0.08,3.88-0.11
            c0.08-0.01,0.16-0.01,0.24-0.01c7.31-0.23,15.44-0.48,21.07-0.67c0.23-0.0099,0.46-0.02,0.68-0.02c1.34-0.05,2.52-0.1,3.49-0.13
            c0.62-0.02,1.15-0.04,1.58-0.06c0.56-0.03,0.95-0.05,1.15-0.06c1.19-0.09,2.79-0.53,4.22-1.03c0.21-0.07,0.42-0.14,0.63-0.21
            c1.64-0.58,2.93-1.14,2.93-1.14l-0.89-5.11l-0.29,0.0099l-5.29,0.09l-3.36,0.07l-21.1399,0.39h-0.01l-2.86,0.06h-0.13l-7.2401,0.13
            l-0.28,0.0099l-2.81,0.78l-0.44,0.12l-4.49,1.23L103.6791,370.8173z"/>
        <path className="st0 Right_Handle" d="M242.0087,274.5095c1.19-0.16,3.34-0.4901,5.48-1.09c1.43-0.4,2.85-0.91,3.98-1.56c0.53-0.35-0.3-3.39-2.05-3.8
            c0,0-1.08,0.41-2.6,0.82c-1.52,0.4-3.46,0.81-5.19,0.81C240.1287,269.6895,240.0287,274.7695,242.0087,274.5095z"/>
        <path className="st0 Extra471" d="M300.6687,355.4595c0,0.14,0,0.27,0.01,0.41c0,0.13,0.01,0.25,0.02,0.37c0.01,0.25,0.04,0.5,0.08,0.75
            c-0.0099,0.02,0,0.03,0,0.04c0.01,0.09,0.02,0.18,0.04,0.27c0.02,0.15,0.05,0.29,0.08,0.44c0.02,0.1,0.05,0.19,0.09,0.28
            c0.02,0.05,0.05,0.11,0.09,0.16c0,0.01,0.0099,0.02,0.0099,0.02c0.03,0.05,0.0601,0.1,0.1,0.14c0.01,0.02,0.03,0.04,0.05,0.06
            c0.05,0.05,0.09,0.09,0.14,0.13c0.06,0.06,0.13,0.11,0.2,0.15c0.15,0.09,0.31,0.16,0.49,0.21c0.06,0.02,0.12,0.03,0.18,0.04
            c0.04,0,0.08,0.01,0.11,0.01c0.02,0.0099,0.02,0.0099,0.03,0.0099h0.01c0.2599,0.02,0.53,0,0.79-0.06
            c0.05-0.0099,0.1-0.03,0.16-0.06c0.07-0.03,0.16-0.06,0.25-0.11c0.24-0.11,0.54-0.29,0.9-0.53c0.06-0.03,0.12-0.07,0.18-0.12
            c0.04-0.02,0.08-0.05,0.13-0.09c0.01,0,0.01,0,0.02-0.01c0.0099-0.01,0.0099-0.01,0.0099-0.01s0,0,0-0.01l0.02-0.0099l0.02-0.01
            c0.22-0.15,0.46-0.32,0.72-0.51c0.68-0.52,1.47-1.17,2.38-1.96l0.01-0.01c1.29-1.14,2.79-2.55,4.48-4.2599l-3.3-5.19l-2.09-3.27
            c0,0-0.01,0.01-0.02,0.02s-0.03,0.02-0.05,0.05c-0.27,0.24-1.22,1.15-2.31,2.54c-0.27,0.35-0.56,0.73-0.84,1.14
            c-0.12,0.18-0.25,0.36-0.37,0.55c-0.14,0.2-0.27,0.42-0.4,0.64c-0.1,0.16-0.1899,0.32-0.28,0.48c-0.04,0.06-0.08,0.12-0.11,0.19
            c-0.13,0.22-0.25,0.45-0.36,0.68c-0.15,0.3-0.3,0.61-0.43,0.92c-0.1,0.23-0.2,0.47-0.29,0.71c-0.1,0.24-0.19,0.48-0.26,0.73
            c-0.08,0.23-0.15,0.46-0.21,0.69c-0.05,0.19-0.1,0.3799-0.14,0.58c-0.04,0.14-0.07,0.27-0.09,0.41c-0.05,0.23-0.09,0.45-0.12,0.69
            c-0.05,0.32-0.09,0.65-0.1,0.98C300.6787,354.9695,300.6687,355.2195,300.6687,355.4595z"/>
        <g id="_x32_9">
            <path className="st0 Right_Rear_Indicator" d="M107.7481,334.0595c0.6299,0.19,1.48,0.11,2.39-0.15c-1.83-4.48-0.85-9.75-0.85-9.75
                c-0.83-0.1-1.56-0.01-2.18,0.26c-0.56,0.24-0.96,1.23-1.15,2.48C105.5381,329.5895,106.0481,333.5295,107.7481,334.0595z"/>
            <path className="st0 Right_Rear_Indicator" d="M110.1381,333.9095c2.67-0.74,5.95-2.93,6.29-3.75c0.45-1.08-1.47-3.57-1.47-3.57
                c-1.82-1.21-3.44-1.97-4.82-2.28c-0.29-0.08-0.58-0.13-0.85-0.15C109.2881,324.1595,108.3081,329.4295,110.1381,333.9095z"/>
        </g>
        <g id="_x32_7">
            <path className="st0 Right_Front_Indicator" d="M257.2387,279.0695c0.21,1.94,3.07,4.98,6.83,5.04c0.63-8.48-1-9.36-1-9.36c-1.82,0.57-3.91,1.62-5.03,2.73
                C257.4987,278.0095,257.1787,278.5595,257.2387,279.0695z"/>
            <path className="st0 Right_Front_Indicator" d="M264.0692,284.1073c0.5,0.0099,1.01-0.03,1.54-0.14c0.4-0.07,0.8-0.19,1.2-0.34c0,0,0.35-1.51,0.52-3.32
                c0.12-1.25,0.17-2.64-0.02-3.78h-0.01c-0.16-0.91-0.47-1.67-1.03-2.06c-0.41-0.26-1.28-0.21-2.33,0.04
                c-0.28,0.07-0.57,0.15-0.87,0.24C263.0692,274.7473,264.6992,275.6273,264.0692,284.1073z"/>
        </g>
        <g id="_x32_3_1_">
            <path className="st0 Rear_Suspension" d="M148.3281,345.6795c0,0,1.08,3.13,5.36,3.81l0.28-0.34v-0.01l1.27-1.55c-4.04-0.65-5.76-3.57-5.76-3.57
                L148.3281,345.6795z"/>
            <path className="st0 Rear_Suspension" d="M147.188,347.3395c0,0,2.3,3.14,5.3101,3.61l1.1899-1.46c-4.28-0.68-5.36-3.81-5.36-3.81L147.188,347.3395z"/>
            <path className="st0 Rear_Suspension" d="M146.188,348.7995c0,0,1.4301,3.45,5.21,3.51l1.1-1.36c-3.01-0.47-5.3101-3.61-5.3101-3.61l-0.37,0.54
                L146.188,348.7995z"/>
            <path className="st0 Rear_Suspension" d="M142.6581,353.9095c0.83,2.36,4.02,4.19,4.02,4.19l1.03-1.27l2.7-3.31l0.15-0.18l0.84-1.03
                c-3.78-0.06-5.21-3.51-5.21-3.51l-1.68,2.43L142.6581,353.9095z"/>
            <path className="st0 Rear_Suspension" d="M149.4781,344.0095v0.0099c0,0,1.72,2.92,5.76,3.57h0.01v-0.01l1.28-1.5699
                c-4.96-1.23-5.67-3.9901-5.67-3.9901l-1.08,1.56L149.4781,344.0095z"/>
            <path className="st0 Rear_Suspension" d="M150.8581,342.0195c0,0,0.71,2.76,5.67,3.9901l1.2-1.48c-5.06-1.43-5.77-4.11-5.77-4.11L150.8581,342.0195z"/>
            <path className="st0 Rear_Suspension" d="M151.9581,340.4195c0,0,0.71,2.68,5.77,4.11l1.39-1.7c0,0-4.28-0.7-6-4.1L151.9581,340.4195z"/>
            <path className="st0 Rear_Suspension" d="M153.1181,338.7295c1.72,3.4,6,4.1,6,4.1l1.33-1.64c-4.83-1.23-6.17-4.13-6.17-4.13L153.1181,338.7295z"/>
            <path className="st0 Rear_Suspension" d="M154.2781,337.0595c0,0,1.34,2.9,6.17,4.13l1.12-1.37c0,0-4.38-1.52-6.09-4.5L154.2781,337.0595z"/>
            <path className="st0 Rear_Suspension" d="M155.4781,335.3095v0.01c1.71,2.98,6.09,4.5,6.09,4.5l0.4-0.4901c-1.83-2.61-5.7-5.17-5.7-5.17
                L155.4781,335.3095z"/>
            <path className="st0 Rear_Suspension" d="M156.2681,334.1595c0,0,3.87,2.56,5.7,5.17l0.43-0.53l2.09-2.57c-0.67-0.9-1.39-1.69-2.12-2.39
                c-1.29-1.24-2.64-2.19-3.89-2.88l-1.39,2.01L156.2681,334.1595z"/>
        </g>
        <g id="_x32_2_2_">
            <path className="st0 Front_Suspension" d="M297.2787,327.3595l0.66,1.03l0.47,0.73l4.59,7.21l1.76,2.76l2.32,3.64l2.09,3.27l3.3,5.19l1.84,2.88
                l1.82,2.86l1.94,3.04c0,0,3-0.77,3.95-2.68l-2.12-3.24l-1.63-2.51l-0.45-0.7l-1.15-1.74l-1.9-2.92l-1.6801-2.56l-4.55-6.97
                l-1.79-2.74l-4.69-7.18l-0.45-0.7l-0.72-1.1L297.2787,327.3595z"/>
            <polygon className="st0 Front_Suspension" points="271.5281,286.9495 274.2681,291.2595 278.9781,298.6395 280.3781,300.8495 284.1181,306.7095 
                287.4981,304.4295 283.8781,298.8795 275.9981,286.8195 272.6781,284.5195 272.3881,285.1295 	"/>
            <polygon className="st0 Front_Suspension" points="286.6187,310.6295 294.2387,322.5795 297.7887,320.1895 290.0387,308.3195 	"/>
        </g>
        <g id="_x32_1_1_">
            <path className="st0 Rear_Wheel_Rim" d="M116.6581,375.1995c0.08,0.1,1.24,1.67,3.48,3.32c0.84,0.62,1.83,1.25,2.97,1.81c0.9,0.45,1.91,0.86,3.01,1.19
                c1.71,0.51,3.65,0.84,5.8199,0.84c1.22,0,2.38-0.12,3.4901-0.32h0.0099c1.14-0.21,2.2-0.52,3.21-0.89
                c1.21-0.43,2.33-0.96,3.34-1.53c0.79-0.44,1.51-0.9,2.17-1.36c2.93-2.05,4.59-4.1,4.59-4.1c-0.4301,0.02-0.96,0.04-1.58,0.06
                c-0.97,0.03-2.15,0.08-3.49,0.13c0,0-3.87,4.47-11.4,4.47c-7.29,0-10.4-3.55-10.59-3.77c-1.33,0.03-2.6299,0.07-3.8799,0.11
                C117.4181,375.1695,117.0381,375.1795,116.6581,375.1995z"/>
            <path className="st0 Rear_Wheel_Rim" d="M112.1481,361.2495c0,2.43,0.43,4.75,1.23,6.9l2.81-0.78l0.28-0.01c-0.58-1.67-0.89-3.46-0.89-5.33
                c0-9.1,7.38-16.48,16.49-16.48c4.96,0,9.42,2.2,12.44,5.68l1.68-2.43l0.63-0.92c-0.68-0.75-1.41-1.44-2.19-2.08
                c-0.74-0.6-1.52-1.15-2.34-1.64c-1.03-0.63-2.13-1.16-3.28-1.58c-2.16-0.81-4.5-1.25-6.94-1.25c-0.62,0-1.23,0.03-1.83,0.09
                c-0.3,0.02-0.6,0.05-0.89,0.1c-1.11,0.15-2.19,0.39-3.23,0.72c-1.9,0.59-3.67,1.46-5.28,2.56c-0.91,0.61-1.76,1.3-2.55,2.07
                c-0.27,0.26-0.54,0.53-0.79,0.8h-0.01c-0.75,0.8-1.43,1.67-2.03,2.59h-0.01c-1.59,2.41-2.68,5.1899-3.1,8.18
                c-0.03,0.19-0.05,0.38-0.07,0.57C112.188,359.7495,112.1481,360.4995,112.1481,361.2495z"/>
            <path className="st0 Rear_Wheel_Rim" d="M147.7081,356.8295c0.39,1.19,0.66,2.44,0.77,3.73l3.48,0.04c-0.08-2.5-0.62-4.89-1.55-7.08L147.7081,356.8295
                z"/>
        </g>
        <path className="st0 Front_Wheel_Rim" d="M291.3888,359.6795c0,16.18,13.11,29.3,29.29,29.3s29.3-13.12,29.3-29.3
            s-13.12-29.29-29.3-29.29c-5.04,0-9.79,1.28-13.93,3.52l1.79,2.74c0.27-0.15,0.55-0.29,0.83-0.42c1.36-0.66,2.79-1.21,4.27-1.62
            c1.21-0.34,2.46-0.6,3.73-0.76c1.08-0.14,2.19-0.21,3.31-0.21h0.2c1.64,0.01,3.25,0.1801,4.8,0.48v0.01
            c1.2,0.22,2.36,0.54,3.49,0.93c1.31,0.45,2.58,1,3.78,1.65h0.01c1.29,0.69,2.5099,1.48,3.65,2.37c1.05,0.8,2.02,1.6801,2.93,2.64
            v0.01c0.88,0.91,1.69,1.89,2.42,2.93c0.86,1.21,1.61,2.49,2.25,3.84c0.58,1.2,1.06,2.45,1.44,3.75c0.3799,1.25,0.66,2.54,0.83,3.86
            c0.17,1.17,0.25,2.36,0.25,3.57c0,0.1,0,0.19-0.01,0.29c-0.0099,1.52-0.16,3.02-0.43,4.46c-0.25,1.39-0.61,2.73-1.08,4.03
            c-0.38,1.08-0.84,2.13-1.36,3.14c-0.73,1.42-1.59,2.77-2.56,4.02h-0.01c-0.94,1.22-1.99,2.35-3.1299,3.39
            c-1.1,0.99-2.28,1.89-3.53,2.67v0.01c-0.84,0.54-1.72,1.03-2.62,1.46c-1.37,0.66-2.8,1.21-4.28,1.61h-0.0099
            c-1.59,0.45-3.23,0.75-4.92,0.8799c-0.7,0.06-1.4,0.09-2.11,0.09c-0.8,0-1.58-0.04-2.36-0.11c-1.07-0.09-2.12-0.25-3.15-0.48
            c-1.51-0.3199-2.96-0.77-4.36-1.35c-1.31-0.53-2.57-1.17-3.76-1.92c-1.22-0.74-2.36-1.58-3.43-2.52h-0.01
            c-0.9-0.78-1.75-1.63-2.53-2.52v-0.0099c-0.9901-1.13-1.88-2.34-2.67-3.63c-0.66-1.09-1.25-2.2401-1.75-3.44
            c-0.49-1.17-0.9-2.39-1.22-3.65c-0.33-1.3-0.57-2.65-0.69-4.02c-0.07-0.79-0.11-1.59-0.11-2.4c0-0.44,0.01-0.88,0.04-1.31
            c0.06-1.35,0.23-2.67,0.49-3.96c0.29-1.4,0.69-2.75,1.19-4.06c0.47-1.22,1.03-2.39,1.67-3.51c0.6201-1.11,1.33-2.16,2.1201-3.16
            c1.33-1.72,2.8799-3.26,4.61-4.59l-1.76-2.76C295.9388,341.6695,291.3888,350.1395,291.3888,359.6795z"/>
        <g id="_x31_9_1_">
            <path className="st0 Rear_Tyre" d="M153.9666,349.1373v0.01c0.5099,0.92,0.97,1.87,1.35,2.85c0.31,0.76,0.57,1.54,0.8,2.34
                c0.63-0.95,2.08-1,2.22-1c0.01,0,0.01,0,0.01,0l0.33-2.14l6.63,6.32l0.6,0.58l3.27-2.91l-0.11-1.72v-0.01l-0.07-1.15
                c0,0,0-0.0099-0.01-0.02c-0.1-0.4-1.82-6.96-6.59-13.49l-0.43,0.53l-0.4,0.49l-1.12,1.37l-1.33,1.64l-1.39,1.7l-1.2,1.48
                l-1.28,1.57v0.01h-0.01L153.9666,349.1373z"/>
            <path className="st0 Rear_Tyre" d="M156.4966,355.8473c0.05,0.2,0.09,0.41,0.13,0.61c0.26,1.36,0.42,2.77,0.45,4.2l12.46,0.14l-0.1899-3.03
                l-3.07,2.73l-1.5-1.39l1.13-1.01l-0.6-0.58l-3.95,2.98l-3.63-3.24l0.21-1.33
                C157.1366,355.9373,156.4966,355.8473,156.4966,355.8473z"/>
            <path className="st0 Rear_Tyre" d="M96.1892,362.7773c0,20.08,18.81,34.38,36.34,34.38c17.54,0,26.49-9.59,30.31-15.39
                c-4.09-0.35-8.56-0.86-8.56-0.86v-4.17c0,0,5.24-1.37,12.28-2.1899h0.05c0.35-0.76,0.92-2.07,1.35-3.14
                c-5.21,0.41-10.28,0.31-10.28,0.31s-1.29,0.56-2.93,1.14c0,0-1.18,2.6-3.76,5.59c-0.65,0.76-1.4,1.55-2.24,2.33
                c-0.89,0.82-1.89,1.63-2.99,2.39c-1.19,0.81-2.51,1.56-3.97,2.18c-0.98,0.44-2.03,0.81-3.14,1.11c-1.44,0.39-2.98,0.66-4.63,0.77
                c-0.57,0.04-1.15,0.06-1.74,0.06c-0.96,0-1.88-0.04-2.76-0.12c-1.13-0.11-2.2-0.27-3.21-0.4901c-1.14-0.24-2.2-0.54-3.2-0.9
                c-1.5-0.53-2.85-1.17-4.06-1.87c-1.33-0.77-2.48-1.61-3.47-2.45c-1.19-1.02-2.15-2.04-2.9-2.94c-1.02-1.23-1.8-2.48-2.19-3.14
                c-3.5,0.1-5.74,0.17-5.74,0.17l-1.07-4.73l4.77-1.32c-0.18-0.5-0.34-1.01-0.48-1.53c-0.3-1.05-0.53-2.14-0.68-3.25
                c-0.16-1.13-0.24-2.29-0.24-3.47c0-0.21,0-0.43,0.01-0.64c0.08-3.2,0.76-6.2401,1.93-9.03v-0.01c0.35-0.84,0.75-1.66,1.19-2.45
                c0.79-1.42,1.71-2.7599,2.76-3.99c1.92-2.28,4.24-4.21,6.85-5.68c0.9-0.51,1.83-0.97,2.8-1.35h0.0099c1.34-0.56,2.73-1,4.1801-1.3
                c1.47-0.33,2.99-0.51,4.54-0.55c0.25-0.02,0.5-0.02,0.75-0.02c1.19,0,2.35,0.08,3.49,0.25h0.01c0.99,0.13,1.95,0.33,2.9,0.58
                c1.38,0.36,2.7,0.84,3.97,1.42c1.31,0.59,2.56,1.3,3.74,2.11h0.01c1.05,0.72,2.03,1.51,2.96,2.39c0.21,0.19,0.42,0.39,0.63,0.6
                l1.08-1.56l1.1-1.6l1.16-1.69l1.16-1.67l1.2-1.74v-0.01l0.79-1.15l0.82-1.19c-1.25-1.1-3.13-2.47-4.78-3.5h-0.01
                c-7.64,0.11-35.87,0.69-35.87,0.69c-0.34,0.82-3.62,3.01-6.29,3.75c-0.91,0.26-1.76,0.34-2.39,0.15c0,0-3.97,2.96-7.25,9.46h-0.01
                C98.1691,348.1073,96.1892,354.4573,96.1892,362.7773z"/>
            <path className="st0 Rear_Tyre" d="M122.3681,326.1995l17.61-0.91C139.9781,325.2895,129.8981,322.0595,122.3681,326.1995z"/>
        </g>
        <path className="st0 Front_Tyre" d="M281.9987,359.6795c0,21.37,17.32,38.68,38.68,38.68c21.37,0,38.68-17.31,38.68-38.68
            c0-21.36-17.31-38.68-38.68-38.68c-0.8,0-1.59,0.02-2.38,0.07c-0.01,0-0.02,0-0.03,0.0099c-6.42,1.01-11.77,3.03-16.21,5.65
            l4.69,7.18c4.14-2.24,8.89-3.52,13.93-3.52c16.18,0,29.3,13.11,29.3,29.29s-13.12,29.3-29.3,29.3s-29.29-13.12-29.29-29.3
            c0-9.54,4.55-18.01,11.61-23.35l-4.59-7.21c-7.3,5.37-11.7,12.27-14.28,17.88
            C282.7487,350.9695,281.9987,355.2395,281.9987,359.6795z"/>
        <path className="st0 Fuel_Tank" d="M214.2387,317.1195c2.77,0.48,6.3,0.72,10.46,0.81c0.2,0,0.4,0.01,0.6,0.01
            c1.11,0.02,2.27,0.03,3.47,0.03c4.14,0,8.28,0.05,12.09,0.11c2.28,0.04,4.43,0.09,6.39,0.13c5.43,0.12,9.36,0.24,10.24,0.21
            c1.73-0.06,7.88-4.4,8.76-6.38c0.65-1.46,1.59-7.54-1.79-13.4c-1.2-2.0699-2.94-4.12-5.4301-5.92c0,0-8.09,2.51-19.26,2.84
            c-0.01,0-0.01,0-0.01,0c-1.94,0.46-3.63,0.69-4.68,0.8c-0.7,0.08-1.11,0.1-1.11,0.1s-22.3,9.98-25.96,14.93
            c-0.09,0.1201-0.17,0.23-0.24,0.35c0,0-0.01,0.02-0.03,0.07c-0.03,0.07-0.09,0.19-0.16,0.35c-0.01,0.01-0.02,0.03-0.02,0.05
            c-0.02,0.07-0.04,0.14-0.05,0.21C207.1587,314.8795,209.6287,316.3195,214.2387,317.1195z"/>
        <g id="_x31_6_1_">
            <path className="st0 Headlight" d="M275.9987,286.8195l7.88,12.06v-17.78C281.0287,283.3095,275.9987,286.8195,275.9987,286.8195z"/>
            <path className="st0 Headlight" d="M285.9687,282.3195v14.16c1.26-1.75,2.61-4.36,2.61-7.42
                C288.5788,285.8295,287.1787,283.6295,285.9687,282.3195z"/>
            <path className="st0 Headlight" d="M283.8788,281.0995v17.78c0,0,1.01-0.88,2.09-2.39v-14.17c-0.94-1-1.7599-1.48-1.7599-1.48
                C284.1087,280.9195,283.9987,281.0095,283.8788,281.0995z"/>
        </g>
        <g id="_x31_5_1_">
            <path className="st0 Taillight" d="M88.2181,336.8695l5.56,0.71c1.63-3.75,3.72-6.89,6.05-9.5099h-11.61
                C88.2181,328.0695,87.1281,332.6495,88.2181,336.8695z"/>
            <path className="st1 Extra502" d="M97.4198,328.0687c0,0-3.6986,5.4701-5.2392,9.3039"/>
        </g>
        <path className="st0 Passenger_Seat" d="M166.96,328.02c-4.23-0.6-7.38-2.02-10.14-3.6c-2.76-1.58-5.11-3.32-7.69-4.53
            c-5.15-2.43-18.51-4.81-23.11-4.85c-0.1-0.04-0.2-0.1-0.29-0.17c0,0,0,0-0.01,0c-1.15-0.86-1.4399-3.57-1.4399-3.57
            s3.96-3.37,5.11-3.75c15.36-2.56,30.63-2.9,32.55-2.17L166.96,328.02z"/>
        <path className="st0 Rider_Seat" d="M208.01,311.39c-0.09,0.12-0.17,0.23-0.24,0.35c0,0-0.01,0.02-0.03,0.07c-0.07,0.12-0.13,0.24-0.16,0.35
            c-0.01,0.01-0.02,0.03-0.02,0.05c-0.7,1.49-3,6.13-5.45,8.11c-8.98,7.5-26.68,8.89-35.15,7.7l-5.02-22.64
            c1.91,0.72,4.77,6,13.74,10.25c8.98,4.26,19.96,0.68,25.11-3.53C205.94,307.89,209.6,308.44,208.01,311.39z"/>
        <path className="st0 Rear_Fender" d="M91.4081,344.9095h2.93c0,0,3.51-10.94,11.62-18.01c0.19-1.25,0.59-2.24,1.15-2.48
            c0.62-0.27,1.35-0.36,2.18-0.26c0.27,0.02,0.56,0.07,0.85,0.15c0,0,19.36-13.8,43.16,0.29h0.01l3.5099-0.18
            c-2.7599-1.58-5.11-3.32-7.69-4.53c-5.15-2.43-18.5099-4.81-23.11-4.85c-0.1-0.04-0.2-0.1-0.29-0.17c0,0,0,0-0.01,0
            c-0.41,0.04-9.3,0.93-18.09,6.56c-0.6,0.39-1.21,0.8-1.81,1.24c-0.75,0.54-1.49,1.12-2.23,1.73v0.01c-0.9,0.75-1.79,1.57-2.65,2.46
            c-0.3799,0.39-0.75,0.79-1.11,1.2c-2.33,2.62-4.42,5.7599-6.05,9.5099C92.8081,339.7995,92.0081,342.2395,91.4081,344.9095z"/>
        <g id="_x31_1_1_">
            <path className="st0 Front_Fender" d="M301.5287,324.4995l-0.64,0.43l0.72,1.1c4.97-2.83,10.63-4.59,16.66-4.95
                c0.0099-0.0099,0.02-0.0099,0.03-0.0099c2.31-0.36,4.76-0.59,7.36-0.67c0,0,2.68-1.03,6-3.45c0,0-16.11-4.39-31.84,5.01
                L301.5287,324.4995z"/>
            <path className="st0 Front_Fender" d="M276.6388,359.9695c0,0,2.04-0.57,4.09-2.81c0,0,0.74-4.4,3.4-10.16c2.61-7.53,7.49-14.01,13.81-18.61
                l-0.66-1.03l-0.67,0.45l-1.65-2.45c-1.41,1.12-2.79,2.38-4.15,3.78c-2.25,2.42-4.14,4.79-5.72,7.09
                C277.6687,346.9695,276.8487,356.0195,276.6388,359.9695z"/>
        </g>
        <path className="st0 Right_Foot_Peg" d="M192.7387,366.0995c0,1.14,0.46,2.16,1.2,2.91c0.75,0.75,1.78,1.21,2.91,1.21h7.12
            c2.27,0,4.12-1.85,4.12-4.12c0-1.14-0.46-2.16-1.21-2.91c-0.74-0.75-1.78-1.21-2.91-1.21h-7.12
            C194.5788,361.9795,192.7387,363.8295,192.7387,366.0995z"/>
        <path className="st0 Right_Side_View_Mirror" d="M253.8787,260.3295c0,0.32,1.35,0.71,1.35-0.19c0-0.89,0-2.61,2.2-2.61s2.14,1.69,2.1,2.8
            c-0.0099,0.36,0,1.31,0.03,2.41c0.03,2.3,0.1,5.32,0.1,5.32h1.34c0,0-0.05-2.45-0.12-4.99c-0.05-1.5-0.1-3.04-0.16-4.11
            c-0.16-2.87-3.48-2.61-3.96-3.54c-0.48-0.93-1.57-6.26-1.82-6.57c-0.26-0.32-1-0.39-1.06,0
            C253.8187,249.2295,253.8787,260.0195,253.8787,260.3295z"/>
        <g>
            <path className="st0 Front_Glass" d="M272.1781,283.8995c0.0099,0.03,0.0099,0.05,0.0099,0.08c0,0,0,0-0.0099,0c0,0.7,0.21,1.15,0.21,1.15
                l0.29-0.61l3.32,2.3c0,0,0.86-3.05,1.66-8.17c0.69-4.41,1.32-10.36,1.32-17.21c0-8.83-0.45-18.7401-1.27-27.4
                c0,0.9-0.02,30.1-1.93,45.21c0,0-1.47-0.85-3.92-1.65C271.9281,279.9995,272.0381,282.1295,272.1781,283.8995z"/>
            <path className="st0 Front_Glass" d="M271.7987,274.5795c1.4,1.28,2.78,2.82,3.98,4.67c1.91-15.11,1.9301-44.31,1.9301-45.21c0-0.01,0-0.02,0-0.02
                c-0.56-5.86-1.27-11.15-2.13-15.14C273.7588,210.3595,271.4187,251.4495,271.7987,274.5795z"/>
        </g>
        <rect  x="293.8943" y="301.0445" className="st0 License_Plate" width="21.08" height="5.71"/>
        <g id="_x32_">
            <rect x="133.45" y="110.53" className="st0 License_Plate" width="21.08" height="5.71"/>
            <rect x="364.0032" y="149.12" className="st0 License_Plate" width="21.08" height="5.71"/>
        </g>
        <rect x="61.9237" y="338.5945" className="st0 License_Plate" width="21.08" height="5.71"/>
        <path className="st0 Front_Brake" d="M256.12,270.57c-0.34,1.67-1.84,3.13-3.78,4.3c-0.65,0.39-1.34,0.74-2.06,1.07
            c-2.22,0.98-4.66,1.63-6.53,1.86c-0.49-0.04-0.79-0.45-0.49-1.13c0.81-0.15,3.56-0.93,5.9-1.81c1.1801-0.44,2.25-0.91,2.9301-1.34
            c1.8799-1.18,2.24-2.63,2.28-2.82c0-0.01,0-0.02,0-0.02c0-0.09-0.01-0.19-0.03-0.28c-0.01-0.11-0.04-0.22-0.07-0.34
            c-0.27-1.02-0.82-1.88-1.51-2.53c-0.33-0.3101-0.7-0.58-1.08-0.78c-0.01,0-0.01,0-0.01,0c-0.13-0.08-0.26-0.14-0.39-0.19
            c0,0-0.01,0.01-0.01,0c-0.17-0.07-0.33-0.13-0.5-0.18c-0.48-0.14-0.97-0.19-1.45-0.15c-0.2,0.01-0.4,0.04-0.6,0.09
            c-0.0099,0.01-0.02,0.01-0.03,0.01c-0.02,0-0.03,0.01-0.05,0.02c-0.15,0.04-0.3,0.09-0.45,0.15c-0.02,0.01-0.03,0.01-0.05,0.02
            c-0.04,0.01-0.08,0.03-0.11,0.05c-0.1201,0.05-0.2401,0.11-0.35,0.18c1.15-1.49,3.3-2.38,5.5-1.24c0.28,0.15,0.54,0.32,0.77,0.48
            c0.19,0.15,0.37,0.3,0.53,0.47c0.48,0.48,0.83,1.03,1.08,1.6c0.05,0.1,0.09,0.21,0.13,0.32c0.04,0.1,0.08,0.21,0.11,0.32
            c0.03,0.07,0.05,0.15,0.07,0.22s0.04,0.13,0.05,0.2c0.03,0.12,0.06,0.23,0.08,0.35C256.07,269.85,256.11,270.22,256.12,270.57z"/>
        </svg>
   
    )
}

export default MotorcycleSVG