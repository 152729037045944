import React, { useContext } from 'react'
import style from "./style/CarlevelResult.module.scss"
import { useNavigate, useParams } from 'react-router-dom'
import { AppContext } from './context'
import CarSVG from './CarSVG'
import GuidanceSVG from './GuidanceSVG'
import lang from './language/Carlevelresult.json'
import GoBack from '../img/left-arrow.png'
import Cookies from 'js-cookie'

const RejectedCarLevelResult = () => {

    const { swipe, setSwipe, photowiseDetails, language} = useContext(AppContext)
    const navigate = useNavigate()
    return (
    <div className={style.carlevel}>

        <div className={style.ModuleID} onClick={()=>{
                if(Cookies.get("isMahindra")) return navigate('/vehicle-listing')
                navigate('/pre-inspection')
            }}>
            <img src={GoBack}></img>
            <span className={style.preSpan}>{Cookies.get("isMahindra") ? "Vehicle Listing Page" : lang["Pre-Inspection"][language]}</span>
        </div>

        <div className={style.switchBtnContainer}>
            <div>
                <button style={{backgroundColor: swipe === "carLevelResult" ? "#157AFF" : null }} onClick={() => setSwipe("carLevelResult")} className={style.switchBtn}>{lang["Car level result"][language]}</button>
                <button style={{backgroundColor: swipe === "partlevelResult" ? "#157AFF" : null }} onClick={() => setSwipe("partlevelResult")} className={style.switchBtn}>{lang["Part level result"][language]}</button>
            </div>
        </div>
        <div className={style.carSvgContainer}>
            <div className={style.carSvgFlex}>
                <div style={{opacity: 0.3}} className={style.carImg}>
                    <CarSVG data={[]}/>
                </div>
                {Object.keys(photowiseDetails["regions_covered"]).length !== 0 && 
                <div className={style.guidance}>
                    <GuidanceSVG data={photowiseDetails["regions_covered"]}/>
                </div>}
            </div>
            
            <div style={{opacity: 0.3}} className={style.carSvgFooter}>
                <p className={style.footerTitle}>{lang["Damage Index"][language]}</p>
                <div className={style.footermark}>
                    <div className={style.circle1}></div><p className={style.text}>{lang["Low"][language]}</p>
                    <div className={style.circle2}></div><p className={style.text}>{lang["Medium"][language]}</p>
                    <div className={style.circle3}></div><p className={style.text}>{lang["High"][language]}</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default RejectedCarLevelResult