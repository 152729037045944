import React, {useEffect} from "react";

const TruckSVG = ({data}) => {

    const setColors = () => {
        for(let i = 0; i < data.length; i++){
          if(data[i].damageSeverityScore <= 0.3 ){
            document.querySelectorAll(`.${data[i].partName.split(":").join("").split("/").join(" ").split(" ").join("_")}`).forEach(item => {
              item.classList.remove(item.classList[0])
              item.classList.add("yellow")
            })
          }
          else if(data[i].damageSeverityScore > 0.3 && data[i].damageSeverityScore <=0.7){
            document.querySelectorAll(`.${data[i].partName.split(":").join("").split("/").join(" ").split(" ").join("_")}`).forEach(item => {
              item.classList.remove(item.classList[0])
              item.classList.add("orange")
            })
          }else{
            document.querySelectorAll(`.${data[i].partName.split(":").join("").split("/").join(" ").split(" ").join("_")}`).forEach(item => {
              item.classList.remove(item.classList[0])
              item.classList.add("red")
            })
          }
        }
    }
      
    
    useEffect(()=>{
    setColors()
    },[])

    return(

        <svg 
        version="1.1" 
        id="Layer_1" 
        xmlns="http://www.w3.org/2000/svg" 
        xmlnsXlink="http://www.w3.org/1999/xlink" 
        x="0px" 
        y="0px"
	    viewBox="0 0 1000 1000" 
        style={{enableBackground:"new 0 0 1000 1000"}} 
        xmlSpace="preserve"
        {...{data}}
        >
        <style type="text/css">
            {
                "\n\t.red{fill:#FF0000;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n\t.orange{fill:#FF8A00;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n\t.yellow{fill:#FFB800;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n\t.st0{fill:#FFFFFF;stroke:#231F20;stroke-miterlimit:10;}\n\t.st1{fill:#DBDBDB;stroke:#231F20;stroke-miterlimit:10;}\n\t.st2{fill:#231F20;stroke:#231F20;stroke-miterlimit:10;}\n\t.st3{fill:#F4EA1B;stroke:#231F20;stroke-miterlimit:10;}"
            }
        </style>
        <g id="Front">
	        <g>
                <g>
                    <path className="st0 Extra1" d="M570.4,256.6c5.6-2.5,22.2-8.5,25.6-19.2c3.1-10.1-11.2-22.8-15.8-26.4c0,0-1.4,8.6-2,13.1	c17,8.9,15.9,14-4.4,18.9C573.6,243,570.9,254.7,570.4,256.6z"/>
                    <path className="st1 Right_Side_View_Mirror" d="M601.9,211.7h-16.4c-1.5,0-2.8,1.2-2.8,2.8v39.3c0,1.5,1.2,2.8,2.8,2.8h16.4c1.5,0,2.8-1.2,2.8-2.8v-39.3	C604.7,212.9,603.5,211.7,601.9,211.7z"/>
                </g>
		        <g>
                    <path className="st0 Extra3" d="M372.6,256.5c-5.7-2.4-22.3-8.3-25.8-18.8c-3.2-10,10.9-22.9,15.5-26.6c0,0,1.5,8.6,2.2,13.1	c-16.9,9.1-15.7,14.2,4.6,18.8C369.3,243,372.1,254.7,372.6,256.5z"/>
                    <path className="st1 Left_Side_View_Mirror" d="M340.8,211.8h16.4c1.5,0,2.8,1.2,2.8,2.8v39.3c0,1.5-1.2,2.8-2.8,2.8h-16.4c-1.5,0-2.8-1.2-2.8-2.8v-39.3	C338,213.1,339.3,211.8,340.8,211.8z"/>
                </g>
		        <path className="st0 Front_Bumper" d="M360,175c74.3,0,148.5,0,222.8,0c0-13.7-0.9-38-1.6-38c-8.9,0-214.8,0.1-220,0C360.5,137,359,163.1,360,175z"	/>
		        <g>
				    <circle className="st1 Extra6" cx="373.6" cy="164.7" r="5.3"/>
		        </g>
		        <g>
			        <path className="st0 Bumper_Grill_Top" d="M535.7,175h-67.9l0,0h-60.5l6.8-24.3c1-3.7,4.4-6.3,8.3-6.3h98.4c3.9,0,7.3,2.6,8.3,6.3L535.7,175z"/>
		        </g>
                <path className="st0 Front_Bumper_Cover" d="M579.8,137H468.4H362.7c-0.9,0-1.5-0.5-1.5-1.2c0.2-2.9,0.9-11,3.3-23.1c0.7-3.6,1.6-6.5,2.1-8.3	c0.3-1,1.5-1.7,2.9-1.7h203.4c1.4,0,2.6,0.7,2.9,1.7c0.6,1.7,1.4,4.7,2.1,8.3c2.4,12.1,3.1,20.2,3.3,23.1	C581.4,136.5,580.7,137,579.8,137z"/>
                <circle className="st0 Front_PDC_Sensor" cx="561.7" cy="112.4" r="3.4"/>
                <circle className="st0 Front_PDC_Sensor" cx="387.6" cy="112.4" r="3.4"/>
                <path className="st1 Front_Glass" d="M581.1,211.8c-1.7,20.3-12.4,55.3-16.2,67.3c-0.8,2.5-3.4,4.2-6.4,4.3l-5.1,0.1l-81.6,1.6l-80.6-1.6l-6.9-0.1	c-3-0.1-5.6-1.8-6.4-4.3c-3.8-12-14.3-47-16.3-67.3l11.3-0.2l98.7-1.7l103.6,1.8L581.1,211.8z"/>
                <polygon className="st0 Hood" points="360,175 582.8,175 582.1,193.4 360.6,193.4 		"/>
                <polygon className="st0 Front_Panel" points="582.3,193.6 581.1,211.7 575.2,211.7 471.6,209.9 372.9,211.6 361.7,211.6 360.5,193.4 		"/>
                <g>
                    <path className="st0 Bumper_Grill_Bottom" d="M524.1,124.3h-56.4l0,0h-50.3l5.6-15.1c0.8-2.3,3.7-3.9,6.9-3.9h81.7c3.2,0,6.1,1.6,6.9,3.9L524.1,124.3z"/>
                </g>
                <g>
                    <path className="st0 License_Plate" d="M497.9,130h-52.3c-0.2,0-0.4,0.2-0.4,0.4V142c0,0.2,0.2,0.4,0.4,0.4h52.3c0.2,0,0.4-0.2,0.4-0.4v-11.6	C498.3,130.2,498.1,130,497.9,130z"/>
                </g>
                <polygon className="st0 Emblem" points="476,175 459.5,175 467.9,190.2 467.9,190.2 		"/>
	        </g>
	        <rect x="393.3" y="123.1" className="st0 Tow_Hook_Cover_Front" width="11.6" height="6.4"/>
	        <path className="st0 Right_Fog_Light" d="M578,126.8l-12.9,0.8c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6,0-0.9,0c-2.1-0.1-2.7-2.9-0.8-3.8l0,0	c0.1-0.1,0.3-0.1,0.5-0.2l13.3-3c1.1-0.3,2.2,0.5,2.4,1.6c0.1,0.7,0.3,1.5,0.4,2.3C580.1,125.6,579.2,126.7,578,126.8z"/>
	        <path className="st0 Left_Fog_Light" d="M364.3,127.1l12.9,0.8c0.1,0,0.1,0,0.2,0c0.3,0,0.6,0,0.9,0c2.1-0.1,2.7-2.9,0.8-3.8l0,0	c-0.1-0.1-0.3-0.1-0.5-0.2l-13.3-3c-1.1-0.3-2.2,0.5-2.4,1.6c-0.1,0.7-0.3,1.5-0.4,2.3C362.2,125.9,363.1,127,364.3,127.1z"/>
	        <g>
		        <path className="st0 Left_Headlight" d="M365.7,169.6h10.5c7.9,0,18.5-7.7,21.6-10.1c0.7-0.5,1.1-1.3,1.1-2.1c0.1-1,0.1-3.1,0.1-4.6	c0-0.8-0.3-1.5-0.8-2.1s-1.3-0.9-2.1-0.9c-0.1,0-0.2,0-0.3,0l-21.2,2.3c-0.5,0.1-0.9,0.2-1.3,0.5l-9.2,6.2	c-0.8,0.5-1.3,1.4-1.3,2.4v5.6C362.8,168.3,364.1,169.6,365.7,169.6z"/>
		        <g>
				    <path className="st1 Left_Headlight" d="M369.7,166c-5-6.9,2.4-14.3,9.3-9.3C384,163.6,376.5,171.1,369.7,166z"/>
		        </g>
		        <g>
				    <circle className="st1 Left_Headlight" cx="390" cy="156.9" r="5.2"/>
		        </g>
	        </g>
	        <g>
		        <path className="st0 Right_Headlight" d="M576.2,169.6h-10.5c-7.9,0-18.5-7.7-21.6-10.1c-0.7-0.5-1.1-1.3-1.1-2.1c-0.1-1-0.1-3.1-0.1-4.6	c0-0.8,0.3-1.5,0.8-2.1s1.3-0.9,2.1-0.9c0.1,0,0.2,0,0.3,0l21.2,2.3c0.5,0.1,0.9,0.2,1.3,0.5l9.2,6.2c0.8,0.5,1.3,1.4,1.3,2.4v5.6	C579.1,168.3,577.8,169.6,576.2,169.6z"/>
		        <g>
				    <path className="st1 Right_Headlight" d="M573.9,161.4c0-3.5-2.8-6.3-6.3-6.3s-6.3,2.8-6.3,6.3s2.8,6.3,6.3,6.3S573.9,164.9,573.9,161.4z"/>
		        </g>
		        <g>
				    <circle className="st1 Right_Headlight" cx="551.9" cy="156.9" r="5.2"/>
		        </g>
	        </g>
        </g>
        <g id="Rear">
	        <g>
                <rect x="362.7" y="441.3" className="st0 Trailer" width="209.8" height="238.5"/>
                <polygon className="st0 Right_Barn_Door" points="470.2,450 563.6,450.3 563.6,670.8 470.2,670.5 		"/>
                <path className="st0 Rear_Bumper_Cover" d="M571.1,693.6H464.9l0,0H364.1c-0.9,0-1.4,0.2-1.4,0.5c0.2,1.2,0.9,4.4,3.1,9.3c0.7,1.5,1.5,2.6,2,3.4	c0.3,0.4,1.4,0.7,2.8,0.7h193.9c1.3,0,2.5-0.3,2.8-0.7c0.6-0.7,1.3-1.9,2-3.4c2.3-4.9,3-8.2,3.1-9.3	C572.7,693.9,572,693.6,571.1,693.6z"/>
                <polygon className="st0 Left_Barn_Door" points="371.7,450.3 465,449.8 465,670.3 371.7,670.8 		"/>
                <rect x="362.7" y="679.8" className="st0 Rear_Bumper" width="209.8" height="13.8"/>
		        <g>
                    <path className="st1 Left_Taillight" d="M385.9,689h-17.5c-1.8,0-3.2-1.4-3.2-3.2l0,0c0-1.8,1.4-3.2,3.2-3.2h17.5c1.8,0,3.2,1.4,3.2,3.2l0,0	C389.1,687.6,387.7,689,385.9,689z"/>
                </g>
                <g>
                    <path className="st1 Right_Taillight" d="M566.6,688.7h-17.5c-1.8,0-3.2-1.4-3.2-3.2l0,0c0-1.8,1.4-3.2,3.2-3.2h17.5c1.8,0,3.2,1.4,3.2,3.2l0,0	C569.8,687.3,568.4,688.7,566.6,688.7z"/>
                </g>
                <g>
                    <path className="st1 Rear_Reflector_Top" d="M476.2,449.8h-17.5c-1.8,0-3.2-1.9-3.2-4.2l0,0c0-2.4,1.4-4.2,3.2-4.2h17.5c1.8,0,3.2,1.9,3.2,4.2l0,0	C479.4,447.9,478,449.8,476.2,449.8z"/>
                </g>
                <path className="st1 Right_Rear_Reflector_Bottom" d="M546.9,701.7l8.7,1.2l6.2-1.3c1.1-0.3,1.4-1.7,0.3-2.2c-2.3-1.2-5.4-2.7-7.2-2.7c-1.9-0.1-5.6,1.5-8.3,2.8	C545.5,699.8,545.7,701.5,546.9,701.7z"/>
                <path className="st1 Left_Rear_Reflector_Bottom" d="M373.2,701.7l8.7,1.2l6.2-1.3c1.1-0.3,1.4-1.7,0.3-2.2c-2.3-1.2-5.4-2.7-7.2-2.7c-1.9-0.1-5.6,1.5-8.3,2.8	C371.8,699.8,372,701.5,373.2,701.7z"/>
                <g>
                    <path className="st0 License_Plate" d="M485.9,687.8H449c-0.1,0-0.3-0.1-0.3-0.3v-7.1c0-0.1,0.1-0.3,0.3-0.3h36.9c0.1,0,0.3,0.1,0.3,0.3v7.1	C486.2,687.7,486.1,687.8,485.9,687.8z"/>
                </g>
	        </g>
            <circle className="st0 Rear_PDC_Sensor" cx="397.9" cy="685.8" r="2.4"/>
            <circle className="st0 Rear_PDC_Sensor" cx="537.2" cy="686.1" r="2.6"/>
            <polygon className="st0 Emblem" points="493.5,640.2 477.1,640.2 485.4,624.9 485.4,624.9 	"/>
            <rect x="412.5" y="683.5" className="st0 Tow_Hook_Cover_Rear" width="11.6" height="6.4"/>
        </g>
        <g id="Right_00000138543968530647628870000015464879422646295965_">
            <g>
                <g id="Inner__extra_00000166638617421974834530000017886318033987849387_">
                    <rect x="713.7" y="441.4" className="st0 Fuel_Tank" width="4.3" height="116.5"/>
                    <g>
                        <circle className="st0 Right_Rear_Tyre" cx="737.5" cy="654.6" r="27.7"/>
                        <circle className="st0 Right_Rear_Wheel_Rim" cx="737.5" cy="654.6" r="17.5"/>
                    </g>
                    <g>
                        <path className="st0 Fuel_Tank" d="M731.7,558.3v-71.4c0-0.6-0.5-1.1-1.1-1.1h-22.7c-0.6,0-1.1,0.5-1.1,1.1v71.4c0,0.6,0.5,1.1,1.1,1.1h22.7	C731.3,559.4,731.7,558.9,731.7,558.3z"/>
                        <path className="st0 Fuel_Tank" d="M731.7,488.9v-2c0-0.6-0.5-1-1-1h-22.8c-0.6,0-1,0.5-1,1v2H731.7z"/>
                        <path className="st0 Fuel_Tank" d="M731.7,556.4v2c0,0.6-0.5,1-1,1h-22.8c-0.6,0-1-0.5-1-1v-2H731.7z"/>
                    </g>
                    <rect x="572.7" y="441.3" className="st0 Right_Side" width="127.5" height="266"/>
                    <path className="st0 Right_Running_Board" d="M700.2,441.4h4.4h10c1,0,1.8,0.7,1.8,1.7v16.5c0,0.6-0.3,1-0.8,1.4l-11,7v239.3h-4.4V441.4z"/>
                    <path className="st0 Extra49" d="M711.6,455.4l0.1-6.6c0-0.7-0.6-1.4-1.4-1.4h-4.2c-0.7,0-1.4,0.6-1.4,1.4v7.7c0,0.7,0.6,1.4,1.4,1.4	C706.1,457.9,711.6,456.2,711.6,455.4z"/>
                    <g>
                        <path className="st2 Right_Qtr_Extender" d="M740.8,617c0,0-40.6-4.4-40.6,37.7c0,38.9,35,38,40.4,37.6v-6.1c0,0-34.4,3.1-34.4-31.5	s35.1-31.6,35.1-31.6S741.1,620.5,740.8,617z"/>
                    </g>
		        </g>
                <path className="st0 Right_Side_Roof" d="M607.6,367.1c-2.4,6-2.5,19.7-3.8,27.8c-0.2,1.4-0.7,6.6-0.7,6.8c-0.5,10.4-3.3,20.3-0.9,23.4	c-1.2,2.6-5.6,14.2-5.6,12.7c-0.3-26,2.7-52.9,5.6-65.8c0.3-1.1,0.6-4.6,4.6-9.9c0.3-0.4,0.7-0.1,0.6,1L607.6,367.1z"/>
                <path className="st0 Front_Panel" d="M715.4,326.7l-0.6,9.9c-0.5,0-1.1,0-1.6,0c-5.6-0.2-11.7-0.7-18.5-1.4l0,0c-1.7-0.2-3.3-0.3-5.1-0.5	c-0.4,0-0.9-0.1-1.3-0.1c-1.5-0.2-2.9-0.3-4.2-0.4h-0.3c-4.5-0.3-7.2-0.2-8.9-0.3c-0.1,0-0.2,0-0.3,0c-8.8-0.2-13.3,2.1-17.5,3.3	l0,0c1.5-3.3,2.1-2.9,4.4-9.5C671.4,324.6,701.8,324.2,715.4,326.7z"/>
                <path className="st0 Right_Front_Door" d="M694.7,335.1l-0.1,3c0,0-0.2,8.5-0.3,10.3c-0.7,21.7-2.1,63.2-2.6,77.3v2.6l-54.1-0.1	c0.3-0.1,0.5-0.2,0.8-0.5c0.1-0.1,0.2-0.2,0.3-0.3c1.9-2,3.4-6.9,4.2-8.9l20.1-54c0.3-0.7,2.6-29.1,2.6-29.8	c2.5-0.5,4.7-0.7,9.6-0.9c1.6,0,4.3,0,8.9,0.3C684.1,334.1,693,335,694.7,335.1z"/>
                <g>
                    <circle className="st0 Right_Front_Tyre" cx="737.9" cy="405.2" r="27.6"/>
                    <path className="st0 Right_Front_Wheel_Rim" d="M720.4,405.2c0,9.6,7.8,17.4,17.4,17.4c9.6,0,17.4-7.8,17.4-17.4s-7.8-17.4-17.4-17.4	S720.4,395.6,720.4,405.2z"/>
                </g>
                <path className="st0 Right_Front_Bumper" d="M715.4,326c-0.4,2.8-0.5,9.2-0.6,10.6c0,0,4.3,0.4,11.5,0.8c5.9,0.3,12.1,0.6,12.1,0.6s0-8.8-2.7-11	C734,325.6,717.3,325.8,715.4,326z"/>
                <g>
                    <path className="st0 Right_Front_Door_Handle" d="M662.8,416.5c2.5,0,4.6-2,4.6-4.6c0-2.5-2-4.6-4.6-4.6c-2.5,0-4.6,2-4.6,4.6S660.2,416.5,662.8,416.5z"/>
                    <path className="st0 Right_Front_Door_Handle" d="M664.4,421.3v-17.5c0-0.8-0.7-1.5-1.5-1.5l0,0c-0.8,0-1.5,0.7-1.5,1.5v17.5c0,0.8,0.7,1.5,1.5,1.5l0,0	C663.7,422.8,664.4,422.2,664.4,421.3z"/>
                </g>
                <path className="st1 Right_Front_Window_Glass" d="M665.6,334.7c0,0.1-2.4,29.2-2.7,29.9l-20.1,54c-0.7,1.9-2.2,6.8-4.1,8.8c-0.1,0.1-0.2,0.2-0.3,0.3	c-0.2,0.2-0.5,0.4-0.8,0.5c-0.2,0.1-0.3,0.1-0.5,0.1h-28.4c-0.5,0-1.1-0.1-1.6-0.2c-3.6-0.8-6.2-4.2-5.8-7.9l4.8-45.7	c0.5-3.9,0.9-7.6,3.1-9.7l5.9-5.1c9.8-8,30.5-18.9,40.2-21.8c0.6-0.3,1.2-0.5,2-0.8C657.3,337.1,665.5,334.7,665.6,334.7z"/>
                <path className="st0 Right_Fender" d="M723.2,337.5l-10.1-0.9l-18.5-1.5l-0.2,8.6l-0.2,4.8l-2.6,79.9l8.3,0.1v-0.1c0-2.3,0-4.8-0.1-7.3	c-1.5-4.4-2.4-9.6-2.4-15.8c0-42.1,40.6-37.7,40.6-37.7c0.2,0,0.4-29.5,0.4-29.5L723.2,337.5z M727.6,364.6c-1.4,0.4-8.9,0-16.8,0	c-1.4,0-2.5-0.7-2.5-1.5l0.2-10.5c0.1-1.2,1.8-2.4,4-2.4h15.6c0.9,1,3,3.8,2.8,7.5C730.7,361.2,728.6,363.6,727.6,364.6z"/>
                <path className="st0 Right_Stair" d="M730.9,357.7c-0.2,3.5-2.3,5.9-3.3,6.9c-1.4,0.4-8.9,0-16.8,0c-1.4,0-2.5-0.7-2.5-1.5l0.2-10.5	c0.1-1.2,1.8-2.4,4-2.4h15.6C729,351.2,731.1,354,730.9,357.7z"/>
                <g>
                    <path className="st2 Right_Fender_Extender" d="M737.8,436.8v6.1c-4.5,0.3-30,1-38-21.8c-1.5-4.4-2.4-9.6-2.4-15.8c0-42.1,40.6-37.7,40.6-37.7	c0.2,1.9,0.3,3.6,0.4,4.7c0.1,0.9,0.1,1.4,0.1,1.4s-35.1-3-35.1,31.6C703.4,439.9,737.8,436.8,737.8,436.8z"/>
                </g>
                <path className="st3 Right_Indicator" d="M703.7,341.9v11.5c0,0.5-0.4,0.9-0.9,0.9h-4.5c-0.4,0-0.9-0.5-0.9-0.9v-11.5c0-0.5,0.4-0.9,0.9-0.9h4.5	C703.3,341.1,703.7,341.4,703.7,341.9z"/>
                <path className="st0 Right_Pillar_B" d="M700,441.4c-25.8-0.1-54.4,0.1-54.4,0.1s-7.8-0.2-8.7-0.2c-20-0.4-36.1-1.7-39.4-2.5	c-0.5-0.2-0.8-0.6-0.5-1.2l5.2-12.2l0.7,0.8c0.9,1.2,2.4,1.7,4.1,1.9c0.6,0.1,1.2,0.1,1.8,0.1l28.3,0.1l54.5,0.1l8.3,0.1	L700,441.4z"/>
                <path className="st0 Right_Pillar_A" d="M607.6,367.4c12.5-16.9,49.1-30.2,49.3-30.2l2-3.4l0.5-1.4c-28.8,10.2-42.8,22.2-51.9,30.6l0.1,3.5	L607.6,367.4z"/>
                <path className="st1 Extra66" d="M607.4,362.9c16.6-14,32.2-23.7,52-30.5l2.1-4.7c-26,9.6-41.6,21.6-54.4,34.1L607.4,362.9z"/>
                <polygon className="st2 Right_Front_Door_Moulding" points="685.3,416.6 679.1,414.4 679.1,347 685.3,348.9 		"/>
                <path className="st0 Right_Rear_Bumper" d="M713.8,708.6h-13.9c-1,0-1.9-0.8-1.9-1.9v-12.4c0-1,0.8-1.9,1.9-1.9h5.3l10.5,11.1v3.2	C715.6,707.7,714.8,708.6,713.8,708.6z"/>
	        </g>
	        <circle className="st0 Fuel_Door" cx="718" cy="503.5" r="4.4"/>
        </g>
        <path className="st1 Rear_Glass" d="M538.9,441.3H404.4c-9.3,0-16.8-6.2-16.8-13.9V382c0-7.8,7.5-13.9,16.8-13.9H539c9.3,0,16.8,6.2,16.8,13.9v45.4	C555.7,435.1,548.2,441.3,538.9,441.3z"/>
        <path className="st0 Roof" d="M552.8,283.8l-81.6,1.6l-80.6-1.6c-5,2.9-8.3,8-8.3,13.9v54.1c0,9.1,8,16.5,17.9,16.5h143	c9.9,0,17.9-7.3,18-16.5v-54.1C561.2,291.9,557.8,286.7,552.8,283.8z"/>
        <path className="st0 Spoiler" d="M553.3,359.8c0,2.2-0.9,4.3-2.4,5.8s-3.5,2.4-5.8,2.4H398.4c-4.5,0-8.2-3.7-8.2-8.2c0-2.2,0.9-4.3,2.4-5.8	s3.5-2.4,5.8-2.4h146.7C549.6,351.7,553.3,355.3,553.3,359.8z"/>
        <g id="Left">
            <g id="Inner__extra_00000085224102961850122470000011170629462546652310_">
                <g>
                    <circle className="st0 Left_Rear_Tyre" cx="197.9" cy="654.6" r="27.7"/>
                    <circle className="st0 Left_Rear_Wheel_Rim" cx="197.9" cy="654.6" r="17.5"/>
                </g>
                <rect x="235.2" y="441.3" className="st0 Left_Side" width="127.5" height="266"/>
                <path className="st0 Left_Running_Board" d="M235.2,441.4h-4.4h-10c-1,0-1.8,0.7-1.8,1.7v16.5c0,0.6,0.3,1,0.8,1.4l11,7v239.3h4.4V441.4z"/>
                <path className="st0 Extra77" d="M223.8,455.4l-0.1-6.6c0-0.7,0.6-1.4,1.4-1.4h4.2c0.7,0,1.4,0.6,1.4,1.4v7.7c0,0.7-0.6,1.4-1.4,1.4	C229.3,457.9,223.8,456.2,223.8,455.4z"/>
                <g>
                    <path className="st2 Left_Qtr_Extender" d="M194.6,617c0,0,40.6-4.4,40.6,37.7c0,38.9-35,38-40.4,37.6v-6.1c0,0,34.4,3.1,34.4-31.5	s-35.1-31.6-35.1-31.6S194.3,620.5,194.6,617z"/>
                </g>
            </g>
            <path className="st0 Left_Side_Roof" d="M327.8,367.1c2.4,6,2.5,19.7,3.8,27.8c0.2,1.4,0.7,6.6,0.7,6.8c0.5,10.4,3.3,20.3,0.9,23.4	c1.2,2.6,5.6,14.2,5.6,12.7c0.3-26-2.7-52.9-5.6-65.8c-0.3-1.1-0.6-4.6-4.6-9.9c-0.3-0.4-0.7-0.1-0.6,1L327.8,367.1z"/>
            <path className="st0 Front_Panel" d="M220,326.7l0.6,9.9c0.5,0,1.1,0,1.6,0c5.6-0.2,11.7-0.7,18.5-1.4l0,0c1.7-0.2,3.3-0.3,5.1-0.5	c0.4,0,0.9-0.1,1.3-0.1c1.5-0.2,2.9-0.3,4.2-0.4h0.3c4.5-0.3,7.2-0.2,8.9-0.3c0.1,0,0.2,0,0.3,0c8.8-0.2,13.3,2.1,17.5,3.3l0,0		c-1.5-3.3-2.1-2.9-4.4-9.5C264,324.6,233.6,324.2,220,326.7z"/>
            <path className="st0 Left_Front_Door" d="M240.7,335.1l0.1,3c0,0,0.2,8.5,0.3,10.3c0.7,21.7,2.1,63.2,2.6,77.3v2.6l54.1-0.1c-0.3-0.1-0.5-0.2-0.8-0.5		c-0.1-0.1-0.2-0.2-0.3-0.3c-1.9-2-3.4-6.9-4.2-8.9l-20.1-54c-0.3-0.7-2.6-29.1-2.6-29.8c-2.5-0.5-4.7-0.7-9.6-0.9		c-1.6,0-4.3,0-8.9,0.3C251.3,334.1,242.4,335,240.7,335.1z"/>
            <g>
                <circle className="st0 Left_Front_Tyre" cx="197.5" cy="405.2" r="27.6"/>
                <path className="st0 Left_Front_Wheel_Rim" d="M215,405.2c0,9.6-7.8,17.4-17.4,17.4c-9.6,0-17.4-7.8-17.4-17.4s7.8-17.4,17.4-17.4S215,395.6,215,405.2z"/>
            </g>
            <path className="st0 Left_Front_Bumper" d="M220,326c0.4,2.8,0.5,9.2,0.6,10.6c0,0-4.3,0.4-11.5,0.8c-5.9,0.3-12.1,0.6-12.1,0.6s0-8.8,2.7-11	C201.4,325.6,218.1,325.8,220,326z"/>
            <g>
                <path className="st0 Left_Front_Door_Handle" d="M272.6,416.5c-2.5,0-4.6-2-4.6-4.6c0-2.5,2-4.6,4.6-4.6c2.5,0,4.6,2,4.6,4.6S275.2,416.5,272.6,416.5z"/>
                <path className="st0 Left_Front_Door_Handle" d="M271,421.3v-17.5c0-0.8,0.7-1.5,1.5-1.5l0,0c0.8,0,1.5,0.7,1.5,1.5v17.5c0,0.8-0.7,1.5-1.5,1.5l0,0			C271.7,422.8,271,422.2,271,421.3z"/>
            </g>
            <path className="st1 Left_Front_Window_Glass" d="M269.8,334.7c0,0.1,2.4,29.2,2.7,29.9l20.1,54c0.7,1.9,2.2,6.8,4.1,8.8c0.1,0.1,0.2,0.2,0.3,0.3	c0.2,0.2,0.5,0.4,0.8,0.5c0.2,0.1,0.3,0.1,0.5,0.1h28.4c0.5,0,1.1-0.1,1.6-0.2c3.6-0.8,6.2-4.2,5.8-7.9l-4.8-45.7	c-0.5-3.9-0.9-7.6-3.1-9.7l-5.9-5.1c-9.8-8-30.5-18.9-40.2-21.8c-0.6-0.3-1.2-0.5-2-0.8C278.1,337.1,269.9,334.7,269.8,334.7z"/>
            <path className="st0 Left_Fender" d="M212.2,337.5l10.1-0.9l18.5-1.5l0.2,8.6l0.2,4.8l2.6,79.9l-8.3,0.1v-0.1c0-2.3,0-4.8,0.1-7.3	c1.5-4.4,2.4-9.6,2.4-15.8c0-42.1-40.6-37.7-40.6-37.7c-0.2,0-0.4-29.5-0.4-29.5L212.2,337.5z M207.8,364.6c1.4,0.4,8.9,0,16.8,0	c1.4,0,2.5-0.7,2.5-1.5l-0.2-10.5c-0.1-1.2-1.8-2.4-4-2.4h-15.6c-0.9,1-3,3.8-2.8,7.5C204.7,361.2,206.8,363.6,207.8,364.6z"/>
            <path className="st0 Left_Stair" d="M204.5,357.7c0.2,3.5,2.3,5.9,3.3,6.9c1.4,0.4,8.9,0,16.8,0c1.4,0,2.5-0.7,2.5-1.5l-0.2-10.5	c-0.1-1.2-1.8-2.4-4-2.4h-15.6C206.4,351.2,204.3,354,204.5,357.7z"/>
            <g>
                <path className="st2 Left_Fender_Extender" d="M197.6,436.8v6.1c4.5,0.3,30,1,38-21.8c1.5-4.4,2.4-9.6,2.4-15.8c0-42.1-40.6-37.7-40.6-37.7	c-0.2,1.9-0.3,3.6-0.4,4.7c-0.1,0.9-0.1,1.4-0.1,1.4s35.1-3,35.1,31.6C232,439.9,197.6,436.8,197.6,436.8z"/>
            </g>
            <path className="st3 Left_Indicator" d="M231.7,341.9v11.5c0,0.5,0.4,0.9,0.9,0.9h4.5c0.4,0,0.9-0.5,0.9-0.9v-11.5c0-0.5-0.4-0.9-0.9-0.9h-4.5	C232.1,341.1,231.7,341.4,231.7,341.9z"/>
            <path className="st0 Left_Pillar_B" d="M235.4,441.4c25.8-0.1,54.4,0.1,54.4,0.1s7.8-0.2,8.7-0.2c20-0.4,36.1-1.7,39.4-2.5c0.5-0.2,0.8-0.6,0.5-1.2	l-5.2-12.2l-0.7,0.8c-0.9,1.2-2.4,1.7-4.1,1.9c-0.6,0.1-1.2,0.1-1.8,0.1l-28.3,0.1l-54.5,0.1l-8.3,0.1L235.4,441.4z"/>
            <path className="st0 Left_Pillar_A" d="M327.8,367.4c-12.5-16.9-49.1-30.2-49.3-30.2l-2-3.4l-0.5-1.4c28.8,10.2,42.8,22.2,51.9,30.6l-0.1,3.5	L327.8,367.4z"/>
            <path className="st1 Extra94" d="M328,362.9c-16.6-14-32.2-23.7-52-30.5l-2.1-4.7c26,9.6,41.6,21.6,54.4,34.1L328,362.9z"/>
            <polygon className="st2 Left_Front_Door_Moulding" points="250.1,416.6 256.3,414.4 256.3,347 250.1,348.9 	"/>
            <path className="st0 Left_Rear_Bumper" d="M221.6,708.6h13.9c1,0,1.9-0.8,1.9-1.9v-12.4c0-1-0.8-1.9-1.9-1.9h-5.3l-10.5,11.1v3.2	C219.8,707.7,220.6,708.6,221.6,708.6z"/>
        </g>
        </svg>
    )
}

export default TruckSVG