import React, { useEffect } from "react"

const BusSVG = ({data}) => {

    const setColors = () => {
        for(let i = 0; i < data.length; i++){
          if(data[i].damageSeverityScore <= 0.3 ){
            document.querySelectorAll(`.${data[i].partName.split(":").join("").split("/").join(" ").split(" ").join("_")}`).forEach(item => {
              item.classList.remove(item.classList[0])
              item.classList.add("yellow")
            })
          }
          else if(data[i].damageSeverityScore > 0.3 && data[i].damageSeverityScore <=0.7){
            document.querySelectorAll(`.${data[i].partName.split(":").join("").split("/").join(" ").split(" ").join("_")}`).forEach(item => {
              item.classList.remove(item.classList[0])
              item.classList.add("orange")
            })
          }else{
            document.querySelectorAll(`.${data[i].partName.split(":").join("").split("/").join(" ").split(" ").join("_")}`).forEach(item => {
              item.classList.remove(item.classList[0])
              item.classList.add("red")
            })
          }
        }
    }
      
    
    useEffect(()=>{
    setColors()
    },[])

    return(
        <svg version="1.1" id="Main" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" style={{enableBackground:"new 0 0 1000 1000",}} xmlSpace="preserve" {...{data}}>

            <style type="text/css">
                {"\n\t.red{fill:#FF0000;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n\t.orange{fill:#FF8A00;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n\t.yellow{fill:#FFB800;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n\t.st0{fill:#FFFFFF;stroke:#231F20;stroke-miterlimit:10;}\n\t.st1{fill:#E9EAEB;stroke:#231F20;stroke-miterlimit:10;}\n\t.st2{fill:#F3EB27;stroke:#231F20;stroke-miterlimit:10;}\n\t.st3{fill:#231F20;stroke:#231F20;stroke-miterlimit:10;}\n\t.st4{fill:#FFFFFF;stroke:#221F1F;stroke-miterlimit:10;}\n\t.st5{stroke:#231F20;stroke-miterlimit:10;}"}
            </style>

            <g id="Front">
                <g>
                    <polygon className="st0 Front_Bumper_Cover" points="546.8,39.3 397.8,39.3 387.3,46.9 558.1,46.9 			"/>
                    <g>
                        <path className="st0 Left_Side_View_Mirror" d="M383.5,234.7c-2.9-1.8-5.9-3.9-9-6.2c-3.2-2.4-5.3-7.8-5.3-13.8v-13.3h3.3c0,0-0.5,8.3,0.4,15.1    c0.5,3.8,2.6,8.2,4.6,9.8c2.7,2.1,6.2,4.3,10.8,6.5v4.9C386.7,236.7,385.1,235.8,383.5,234.7"/>
                        <path className="st0 Right_Side_View_Mirror" d="M557.3,237.6c4.4-2.5,9-5.4,13.7-9c3.2-2.4,5.3-7.8,5.3-13.8v-13.4H573c0,0,0.5,8.3-0.4,15.1    c-0.5,3.8-2.6,8.2-4.6,9.8c-2.7,2.1-6.2,4.3-10.8,6.5L557.3,237.6L557.3,237.6z"/>
                    </g>
                    <path className="st1 Front_Glass" d="M379.2,237.9c0-0.1-0.1-0.1-0.2-0.2c-0.5-0.6-0.8-1.2-0.8-1.9c-0.1-29.1-0.2-58.3-0.4-87.5    c0-1.2,0.8-2.3,2.2-2.8c10.6-3.9,23.4-7.8,38.3-10.7c25.3-5,47.5-5.6,64.1-4.8c16.3,0.5,36.7,2.5,59.2,8.3    c11.3,2.9,19.8,5.9,23.5,7.3c1.4,0.5,2.2,1.6,2.2,2.8v87c0,1.2-0.6,2.5-1.6,3.2c0,0-1.4,1.1-4,1.3c-4.6,0.4-158.8-0.7-179.6-0.9    C381.1,239.1,379.9,238.7,379.2,237.9z"/>
                    <path className="st0 Front_Panel" d="M377.7,125.1l5.8-0.2l32.4-19.4h113.2l32.6,17.8l5.1,0.3l0.8,24.4c-49.1-23.7-135.6-25-189.7-0.2L377.7,125.1    z"/>
                    <g>
                        <path className="st0 Left_Side_View_Mirror" d="M379.4,205.4c-0.1,0.2-0.2,0.5-0.4,0.6c-0.2,0.1-0.5,0.2-0.8,0.2h-3h-3l0,0l0,0l0,0l0,0h-3h-3    c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.2-0.4-0.4-0.4-0.6c-0.2-0.8-0.4-2.1-0.7-4.1c-0.2-2-0.4-4.7-0.4-8.2v-7.6v-10.7c0,0,0-0.1,0-0.2    c0,0,0-0.1,0-0.2c0.1-0.4,4.2-3.1,8.3-3.1s8.1,2.7,8.3,3.1c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.2v10.7v7.6c0,3.5-0.2,6.2-0.4,8.2    C379.9,203.3,379.6,204.6,379.4,205.4z"/>
                        <path className="st0 Right_Side_View_Mirror" d="M566.2,205.4c0.1,0.2,0.2,0.5,0.4,0.6s0.5,0.2,0.8,0.2h3h3l0,0l0,0l0,0l0,0h3h3c0.3,0,0.6-0.1,0.8-0.2    c0.2-0.2,0.4-0.4,0.4-0.6c0.2-0.8,0.4-2.1,0.7-4.1s0.4-4.7,0.4-8.2v-7.6v-10.7c0,0,0-0.1,0-0.2c0,0,0-0.1,0-0.2    c-0.1-0.4-4.2-3.1-8.3-3.1s-8.1,2.7-8.3,3.1c0,0,0,0.1,0,0.2s0,0.1,0,0.2v10.7v7.6c0,3.5,0.2,6.2,0.4,8.2S566,204.6,566.2,205.4z    "/>
                    </g>
                    <path className="st0 Front_Bumper" d="M567.6,93.9V82.2c0-1,0-2.1,0-3.1c0-2.3-0.1-4.6-0.1-6.8c-1.7-6.8-3.4-13.7-5.2-20.5    c-0.2-0.6-0.7-2.2-2.3-3.4c-0.8-0.6-1.5-0.9-2.1-1.1c-1.1-0.2-2.4-0.3-3.7-0.3c-1.8-0.1-3.5,0-4.9,0.2    c-21.9-0.2-43.7-0.3-65.6-0.3c-31.6,0-63.2,0-94.8,0.3c-0.4,0-3.2,0.2-4.9,2.4c-1.1,1.5-4.8,17.6-6,22.3c-0.2,2.1-0.2,4.5-0.2,6.9    c0,1.2,0,2.3,0.1,3.4v11.7c0,1.7,1.3,3,2.8,3h5.4l36.9-17h99.4l36.9,17h5.4C566.4,96.9,567.6,95.6,567.6,93.9z"/>
                    <path className="st0 Bumper_Grill_Bottom" d="M504,47h-62.8l4.8,11.3c0.8,1.8,2.5,2.9,4.4,2.9h44.4c1.9,0,3.6-1.1,4.4-2.9L504,47z"/>
                    <path className="st0 Hood" d="M562,123.5l-32.9-18h-13.6l-6.4-14.4c-1-2.3-3.3-3.7-5.9-3.7H444c-2.6,0-4.9,1.5-5.9,3.7l-6.3,14.4h-15.9    l-32.4,19.4c0,0-2.2,0.1-4.8,0.2c-0.3,0-1-0.2-1-0.8c-0.3-11.3,0.1-22.7,0-30.9c0,1.7,1.3,3,2.8,3h5.4l36.9-17h99.4l36.9,17h5.4    c1.7,0,2.9-1.3,2.9-3v30.2L562,123.5z"/>
                    <path className="st0 Bumper_Grill_Top" d="M432,105.5h83.7l-6.4-14.4c-1-2.3-3.3-3.7-5.9-3.7h-59.2    c-2.6,0-4.9,1.5-5.9,3.7L432,105.5z"/>
                    <g>
                        <g>
                            <path className="st0 Right_Headlight" d="M562.5,87.6H552c-7.9,0-18.5-7.7-21.6-10.1c-0.7-0.5-1.1-1.3-1.1-2.1c-0.1-1-0.1-3.1-0.1-4.6    c0-0.8,0.3-1.5,0.8-2.1c0.5-0.5,1.3-0.9,2.1-0.9c0.1,0,0.2,0,0.3,0l21.2,2.3c0.5,0.1,0.9,0.2,1.3,0.5l9.2,6.2    c0.8,0.5,1.3,1.4,1.3,2.4v5.6C565.4,86.3,564.1,87.6,562.5,87.6z"/>
                            <path className="st0 Right_Headlight" d="M560.2,79.4c0-3.5-2.8-6.3-6.3-6.3s-6.3,2.8-6.3,6.3s2.8,6.3,6.3,6.3S560.2,82.9,560.2,79.4z"/>
                            <circle className="st0 Right_Headlight" cx="538.2" cy="74.9" r="5.2"/>
                        </g>
                        <g>
                            <path className="st0 Left_Headlight" d="M382.3,87.6h10.5c7.9,0,18.5-7.7,21.6-10.1c0.7-0.5,1.1-1.3,1.1-2.1c0.1-1,0.1-3.1,0.1-4.6    c0-0.8-0.3-1.5-0.8-2.1s-1.3-0.9-2.1-0.9c-0.1,0-0.2,0-0.3,0l-21.2,2.3c-0.5,0.1-0.9,0.2-1.3,0.5l-9.2,6.2    c-0.8,0.5-1.3,1.4-1.3,2.4v5.6C379.4,86.3,380.7,87.6,382.3,87.6z"/>
                            <path className="st0 Left_Headlight" d="M384.6,79.4c0-3.5,2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3s-2.8,6.3-6.3,6.3S384.6,82.9,384.6,79.4z"/>
                            <circle className="st0 Left_Headlight" cx="406.6" cy="74.9" r="5.2"/>
                        </g>
                    </g>
                    <g>
                        <path className="st0 Right_Fog_Light" d="M541.2,57.4l8.6,1.6l6.3-0.9c1.2-0.2,1.5-1.7,0.5-2.2c-2.3-1.3-5.3-3-7.1-3.1c-1.8-0.2-5.7,1.2-8.5,2.3    C539.8,55.5,540,57.1,541.2,57.4z"/>
                        <path className="st0 Left_Fog_Light" d="M404.7,57.4L396,59l-6.3-1c-1.2-0.2-1.5-1.7-0.4-2.2c2.3-1.3,5.3-2.9,7.1-3.1c1.8-0.2,5.7,1.2,8.5,2.4    C406,55.5,405.9,57.2,404.7,57.4z"/>
                    </g>
                    <polygon className="st0 Emblem" points="480.7,63.2 464.2,63.2 472.6,78.6 472.6,78.6 		"/>
                    <rect x="454" y="50.6" className="st0 License_Plate" width="37.3" height="7.1"/>
                </g>
                <circle className="Front_PDC_Sensor" cx="421.3" cy="52.7" r="3.3"/>
                <circle className="Front_PDC_Sensor" cx="524.5" cy="52.8" r="3.3"/>
                <rect x="424.6" y="61.2" className="st0 Tow_Hook_Cover_Front" width="11.4" height="6.6"/>
            </g>

            <g id="Rear">
                <path className="st0 Rear_Bumper" d="M570.2,905.7c0,1.2-0.1,2.6-0.3,4.1c-0.1,0.6-0.2,1.2-0.4,1.8l-4.5,19.1c-0.7,3-3.3,5.1-6.3,5.1H394.5    c-3,0-5.6-2.1-6.3-5.1l-4.5-19.1c-0.2-2-0.5-3.9-0.7-5.9C445.4,905.7,507.8,905.7,570.2,905.7z"/>
                <path className="st0 Tail_Gate" d="M563.8,905.7H389c-3.6,0-6.5-2.6-6.5-5.7v-97c0-3.2,3-5.7,6.5-5.7h174.8c3.6,0,6.5,2.6,6.5,5.7v97    C570.3,903.1,567.4,905.7,563.8,905.7z"/>
                <path className="st1 Left_Taillight" d="M402.7,844v57h-20.2v-40.6c2.1-7.4,10.7-12.4,16-14.8C401,844.6,402.7,844,402.7,844z"/>
                <path className="st1 Right_Taillight" d="M550.1,843.4v57.7h20.1v-36c-2.1-9.8-10.7-16.3-16-19.5C551.8,844.1,550.1,843.4,550.1,843.4z"/>
                <path className="st1 Rear_Glass" d="M567.7,797.2c1.7,0,3-1.3,3-2.8V740c0-4.6-4.1-8.3-9.3-8.3h-169c-5.2,0-9.3,3.8-9.3,8.3v54.4    c0,1.6,1.4,2.8,3,2.8H567.7z"/>
                <path className="st0 Rear_Bumper_Cover" d="M558.2,936.2H395.5l12.4-12.1c1.8-1.9,6.6-3.1,11.6-3.1h115.1c5,0,9.5,1.2,11.6,3.1L558.2,936.2z"/>
                <path className="st0 Tail_Gate" d="M481.2,896.4v-3.6c0-0.7-0.7-1.3-1.5-1.3h-12.8c-0.8,0-1.5,0.6-1.5,1.3v3.6H481.2z"/>
                <path className="st1 Right_Rear_Reflector_Bottom" d="M563.2,915.3l-8.7,1l-6.2-1.3c-1.1-0.3-1.4-1.7-0.3-2.2c2.4-1.2,5.5-2.6,7.3-2.6c1.9-0.1,5.6,1.5,8.3,2.9    C564.7,913.5,564.4,915.2,563.2,915.3z"/>
                <path className="st1 Left_Rear_Reflector_Bottom" d="M394.9,915.3l8.7,1.2l6.2-1.3c1.1-0.3,1.4-1.7,0.3-2.2c-2.3-1.2-5.4-2.7-7.2-2.7c-1.9-0.1-5.6,1.5-8.3,2.8    C393.5,913.4,393.7,915.1,394.9,915.3z"/>
                <rect x="455.9" y="908.9" className="st0 License_Plate" width="34.8" height="9.2"/>
                <path className="st0 Spoiler" d="M382.9,737.4c0-1.8,0.2-6.2,1.5-8.7c3.8-7.9,13.4-9.2,14.4-9.4h155.3c1,0.1,10.5,1.3,14.4,9.1    c1.3,2.7,1.3,5.6,1.3,7.5c-3-3.3-5-4.2-7.6-4.2c-56.7,0-171,0-171,0S386.1,731.7,382.9,737.4z"/>
                <rect x="438.9" y="912.2" className="st0 Tow_Hook_Cover_Rear" width="10" height="5.2"/>
                <path className="st1 Rear_Reflector_Top" d="M462.5,725.4h33.6c0.3,0,0.6-0.5,0.6-1.2v-3.6c0-0.6-0.3-1.2-0.6-1.2h-33.6c-0.3,0-0.6,0.5-0.6,1.2v3.6    C462,724.9,462.2,725.4,462.5,725.4z"/>
                <polygon className="st0 Emblem" points="480.2,861.4 463.3,861.4 471.9,845.3 471.9,845.3 	"/>
                <circle className="Rear_PDC_Sensor" cx="428.8" cy="914.2" r="2.9"/>
                <circle className="Rear_PDC_Sensor" cx="518.7" cy="914.5" r="2.9"/>
            </g>

            <g id="Right">
                <g>
                    <polygon className="st0 Right_Running_Board" points="717.8,376.8 719.9,376.8 719.9,439.9 722.3,442 722.3,506.2 719.5,508.7 719.5,557 717.1,557 			"/>
                    <path className="st0 Right_Front_Bumper" d="M667,242.2l-0.2-3.2c1.8-3.3,3.7-6.6,5.5-9.9c22.8-0.8,40.8,0.1,45.5,5.5c0,0-0.1,1.9-0.1,29    C711.7,246.1,699.3,241.1,667,242.2z"/>
                    <path className="st0 Right_Pillar_H" d="M573.3,713.3l47,4.7l6.3-0.1l65.2,0.1l-0.8-10.6l-8.3-0.3l-10.3-0.4l-38-0.3l-59.3,0.9    c-0.1,0-0.2,0.1-0.1,0.2C574.9,709.1,573.3,713.3,573.3,713.3z"/>
                    <g id="Wheel">
                        <ellipse className="st0 Right_Rear_Tyre" cx="705.6" cy="590.5" rx="23.6" ry="22.8"/>
                        <ellipse className="st0 Right_Rear_Wheel_Rim" cx="705.6" cy="590.4" rx="15.3" ry="14.8"/>
                        <ellipse className="st0 Right_Front_Tyre" cx="705.6" cy="335.2" rx="23.6" ry="22.8"/>
                        <ellipse className="st0 Right_Front_Wheel_Rim" cx="705.6" cy="335.1" rx="15.3" ry="14.8"/>
                    </g>
                    <path className="st0 Right_Qtr_Panel" d="M668.3,636.1c0.1-26.9,0.3-54.2,0.4-81.1c-1.4,0,48.4,0.2,48.4,0.2c-0.1,4.1,0.1,5.7,0,8    c-7.6,0.4-23.6,2.2-29.7,3.3c-0.2,0-0.3,0.1-0.4,0.1c0,0-0.2,0.1-0.3,0.1c-0.3,0.1-13.8,10-12.5,26c0.1,0.9,0.3,3.4,1.3,6.5    c0.8,2.4,0.7,3.7,5,9.4c1.1,1.4,4.3,4.6,6.3,6c0,0,0.9,0.1,1.4,0.2c6.5,1.5,28,3.1,28.1,3.2c0.4,4.7,0,17.7-0.1,17.7    C701.7,635.8,670.2,636.1,668.3,636.1z"/>
                    <path className="st0 Right_Rear_Bumper" d="M691.8,717.6l-0.5-5.9l-0.2-4L711,708c0.9,0,2.8,1,2.6,1.7c-0.6,2.4-1.8,6.7-3.1,7.4        c-1.7,1-9.6,1.9-17.2,1.4C692.6,718.5,691.9,718.2,691.8,717.6z"/>
                    <path className="st0 Hood" d="M581.6,255.2c20.7-11.5,41.2-13.7,63.6-15.4c5-0.4,12.4-0.8,21.5-0.8c1.8-3.3,3.7-6.6,5.5-9.9    c-4.9,0.1-10.2,0.2-15.8,0.4c-16.2,0.7-34.5,2.2-50.1,5.4c-0.4,0.1-0.8,0.2-1.1,0.2c-10.9,2.4-18.7,5.7-24.2,9.2    c-4.1,2.6-7,5.3-9,7.8c0.1,3.1,0.2,6.2,0.4,9.3C574.2,260,577.4,257.7,581.6,255.2z"/>
                    <path className="st1 Front_Glass" d="M583.5,252.1c26.1-16,71.9-14.9,71.9-14.9l1.9-7.7c-16.2,0.7-34.4,2-50,5.2c-0.9,0.1-0.9,0.2-1.2,0.2    c-11.6,2.1-27.3,9.3-31.7,14.6l0.5,7.3L583.5,252.1z"/>
                    <path className="st0 Right_Fender" d="M717.8,308.1c-2.9,0-30.4,2.9-31.2,3.7c-24.4,22.1-6.6,43.1,0.5,47.7c2.1,1.4,30.7,3.4,30.7,3.6    c0,8.3,0.1,16.1,0,28.3l-48.5,0.4c0,0-0.5-88.7-0.5-100.8l48.8-0.3C717.3,302.5,717.8,308.1,717.8,308.1z"/>
                    <g>
                        <path className="st2 Right_Indicator" d="M700.9,304.9v-9.6c0-0.6-0.6-1.2-1.4-1.2h-3.3c-0.8,0-1.4,0.6-1.4,1.2v9.6c0,0.6,0.6,1.2,1.4,1.2h3.3    C700.3,306.1,700.9,305.5,700.9,304.9z"/>
                        <path className="st0 Extra49" d="M679.8,229H693"/>
                    </g>
                    <path className="st0 Right_Pillar_A" d="M666.8,239.2c0-0.4-7.8-0.2-11.7,0c-6.7,0.2-12.3,0.8-16.4,1.1c-3.5,0.3-6.5,0.5-10.5,1    c-4.7,0.6-8.2,1.2-11.4,1.8c-3.8,0.7-8.7,1.7-14.4,3.1c-3.5,1-7.5,2.4-11.7,4.3c-3.7,1.6-7.3,3.2-11.7,6.1    c-2.9,1.9-5.2,3.8-6.7,5.1c0.2,2,0.3,4.1,0.5,6.1c0.1-0.1,0,1,0.2,1c1.2,0.3,2.5,1.6,2.5,1.6s3.5-5.3,15.4-11.4    c5.3-2.3,10.9-5.7,33.8-10.1c2.5-0.5,8.1-1.7,9.7,4.4c0.7-3,0.5-9.5,1.1-9.6l2.7-0.3c4.6-0.3,9.1-0.5,13.7-0.8    c0,0,2.7-0.1,2.7-0.1c0.1,0,12.3-0.2,12.3-0.5C666.9,241.2,666.8,239.9,666.8,239.2z"/>
                    <path className="st0 Right_Front_Door" d="M717.7,265.2v25.7h-83.9c0-1.6,0-5.1,0-6l0.9-27.4c-0.1-2.7,0.3-13.7,0.5-13.8c13.3-1.3,24-1.2,31.7-1.6        C713.6,239.4,717,258.6,717.7,265.2z"/>
                    <path className="st0 Extra52" d="M633.8,284.9l-58.3,0.3c0-0.1,0-0.1,0-0.2L633.8,284.9z"/>
                    <path className="st1 Right_Front_Window_Glass" d="M634.7,257.5l-0.9,27.4l-58.3,0.1c0.1-3,0.1-6.2,0-9l0-5.8c0,0,3.5-5.1,15.4-11.2    c5.3-2.3,10.9-5.7,33.8-10.1C628,248.3,634.2,246.8,634.7,257.5z"/>
                    <path className="st1 Extra54" d="M575.5,285L575.5,285l0-9C575.6,278.7,575.6,282,575.5,285z"/>
                    <g id="Windows">
                        <polygon className="st1 Right_Rear_Window_Glass" points="575.3,633.2 575.4,559.8 634.1,559.6 633.9,633.2"/>
                        <polygon className="st1 Right_Front_Window_Glass" points="575.8,440.4 575.3,364.4 633.9,364.4 634.4,440.4 				"/>
                        <rect x="575.5" y="444.8" className="st1 Right_Front_Window_Glass" width="58.6" height="62.3"/>
                        <polygon className="st1 Right_Front_Window_Glass" points="575.5,360.3 575.2,290.3 633.8,290.3 634.1,360.3 				"/>
                        <rect className="st1 Right_Rear_Window_Glass" x="575.2" y="638.1" width="58.6" height="68.7"/>
                        <polygon className="st0 Right_Pillar_G" points="633.9,632.9 633.9,637.8 575.4,638.4 575.4,633.5 				"/>
                        <polygon className="st0 Right_Pillar_F" points="634.1,555.1 634.1,559.6 575.4,559.8 575.4,554.9 				"/>
                        <polygon className="st0 Right_Pillar_E" points="634,506.3 634,510.8 575.6,511 575.6,506.6 				"/>
                        <path className="st0 Right_Pillar_B" d="M633.8,284.9v5.3l-58.4,0.1c0-1.5,0-3.2,0.1-5.1L633.8,284.9z"/>
                        <path className="st0 Extra64" d="M575.5,285.2c0,1.9-0.1,3.6-0.1,5.1h-0.1L575.5,285.2L575.5,285.2z"/>
                        <polygon className="st0 Right_Pillar_C" points="633.8,360 633.8,364.6 575.4,364.7 575.4,360.3 				"/>
                        <polygon className="st0 Right_Pillar_D" points="634.1,440.1 634.1,444.6 575.7,444.8 575.7,440.4 				"/>
                    </g>
                    <path className="st0 Right_Front_Door_Handle" d="M673.8,278.1h-2.4c-0.5,0-0.9,0.3-0.9,0.8v6.7c0,0.4,0.4,0.8,0.9,0.8h2.4V278.1z"/>
                    <rect x="575.3" y="511" className="st0 Right_Rear_Door" width="141.8" height="43.9"/>
                    <polygon className="st1 Right_Rear_Window_Glass" points="581.2,548.8 581.2,518 633.9,518 633.9,548.8 			"/>
                    <path className="st0 Fuel_Door" d="M692.3,380.6c-0.1,0.1-0.2,0-1.3,0c-0.8,0-0.9,0-1-0.1c-0.6-0.4-0.2-0.7-0.6-1.1c-0.4-0.4-1.1-0.5-1.5-0.2    c-0.6,0.3-0.8,1.4-0.8,1.4l0,0c0,0-1.4,0.1-2.5,0c-0.2,0-0.7-0.1-1.1-0.5c-0.3-0.3-0.5-0.7-0.5-1.1v-5.4c0-0.9,0.7-1.5,1.5-1.5h7    c0.9,0,1.5,0.7,1.5,1.5v5.4C693,379.5,692.9,380.3,692.3,380.6z"/>
                    <path className="st0 Right_Rear_Door_Handle" d="M657.4,534.9H655c-0.5,0-0.9,0.3-0.9,0.8v6.7c0,0.4,0.4,0.8,0.9,0.8h2.4V534.9z"/>
                </g>
                <path className="st3 Right_Fender_Extender" d="M689.1,309.6c-27,5.5-20.1,48.3-2.3,50.1l11.4,1.5c-27.7-4.3-34.2-45.9,0.8-51.3L689.1,309.6z"/>
                <path className="st3 Right_Qtr_Extender" d="M688.8,564.9c-27,5.5-20.1,48.3-2.3,50.1l11.5,1c-27.7-4.3-34.3-45.8,0.7-51.3L688.8,564.9z"/>
                <path className="st0 Right_Side_Roof" d="M567.4,262.4v448c0.2,0.2,1.5,1.1,1.5,1.1c1.2,0.7,3.6,1.6,3.6,1.6c0.2-0.3,1.3-0.8,1.4-0.9    c0.8-0.7,1.1-3.7,1.1-5c0-1.7,0.1-3.7,0-5.8l0.4-63.2c0,0,0-34.4,0-41.9c-0.1-18.5,0.3-106.9,0.3-121.7c0-4.1-0.1-33.4-0.1-33.4    s0-38.3-0.1-74.2c-0.1-35.8-0.2-70-0.2-71.7c0-1.5,0.1-10.4,0.1-11.8c0-0.3,0.2-11.1-0.2-13c0.1,0.3-0.2-1.1-2.2-1.5l-0.6-7.9    L572,253l-0.1-0.9c-0.3-0.2-1.5,1.9-1.9,2.7c-0.9,1.6-1.3,2.3-1.7,3.4C568.1,259.2,567.7,260.6,567.4,262.4z"/>
                <path className="st4 Right_Side" d="M668.3,636.1l0.4-81.1c-11.6-0.3-23.1,0.5-34.6,0.1v151.7h38l1.6,0.1c13.6,0.6,22.7,1.2,37.5,1.1    c2.5-4.5,3.1-10.6,4-16.6c2.8-18.4,0.1-37.3,0.1-55.9L668.3,636.1z"/>
                <path className="st4 Right_Side" d="M669.6,391.7l-0.5-100.8h-35.3l0.2,219.9c0,0,23.6,0.2,35.2,0.1l0,0l47.9,0.1l0.7-119.6L669.6,391.7z"/>
                <path className="st0 Right_Stair" d="M723.1,290.7h-1.8c-0.8,0-1.5-0.7-1.5-1.5v-23.6c0-0.8,0.7-1.5,1.5-1.5h1.8c0.8,0,1.5,0.7,1.5,1.5v23.6    C724.6,290,724,290.7,723.1,290.7z"/>
                <rect x="700.4" y="416.3" className="st5 Right_Front_Door_Moulding" width="11.8" height="80.6"/>
                <rect x="695.9" y="643.3" className="st5 Right_Rear_Door_Moulding" width="11.8" height="57.2"/>
            </g>

            <g id="Roof">
                <path className="st0 Roof" d="M378.9,683.1V306.7c0-4.4,0.2-8.7,0.7-12.9l4.5-42c0.7-6.6,3.3-11.2,6.4-11.2h165c3,0,5.7,4.6,6.4,11.2l4.5,42    c0.5,4.2,0.7,8.6,0.7,12.9v376.4c0,19.7-7.2,35.6-16,35.6H395C386.1,718.7,378.9,702.7,378.9,683.1z"/>
                <rect x="415.1" y="296.7" className="st1 Sun_Roof" width="115.7" height="62.6"/>
            </g>

            <g id="Right_00000147206180869467544940000008261839002117886855_">
                <g>
                    <polygon className="st0 Left_Running_Board" points="228.7,377.5 226.6,377.5 226.6,440.6 224.2,442.7 224.2,506.9 227,509.4 227,557.7 229.4,557.7 			    "/>
                    <path className="st0 Left_Front_Bumper" d="M279.5,242.9l0.2-3.2    c-1.8-3.3-3.7-6.6-5.5-9.9c-22.8-0.8-40.8,0.1-45.5,5.5c0,0,0.1,1.9,0.1,29C234.8,246.8,247.2,241.8,279.5,242.9z"/>
                    <path className="st0 Left_Pillar_H" d="M373.2,714l-47,4.7l-6.3-0.1    l-65.2,0.1l0.8-10.6l8.3-0.3l10.3-0.4l38-0.3l59.3,0.9c0.1,0,0.2,0.1,0.1,0.2C371.6,709.8,373.2,714,373.2,714z"/>
                    <g id="Wheel_00000157995268759871086950000011163482437993545125_">
                        <ellipse className="st0 Left_Rear_Tyre" cx="240.9" cy="591.2" rx="23.6" ry="22.8"/>
                        <ellipse className="st0 Left_Rear_Wheel_Rim" cx="240.9" cy="591.1" rx="15.3" ry="14.8"/>
                        <ellipse className="st0 Left_Front_Tyre" cx="240.9" cy="335.9" rx="23.6" ry="22.8"/>
                        <ellipse className="st0 Left_Front_Wheel_Rim" cx="240.9" cy="335.8" rx="15.3" ry="14.8"/>
                    </g>
                    <path className="st0 Left_Qtr_Panel" d="M278.2,636.8c-0.1-26.9-0.3-54.2-0.4-81.1c1.4,0-48.4,0.2-48.4,0.2c0.1,4.1-0.1,5.7,0,8    c7.6,0.4,23.6,2.2,29.7,3.3c0.2,0,0.3,0.1,0.4,0.1c0,0,0.2,0.1,0.3,0.1c0.3,0.1,13.8,10,12.5,26c-0.1,0.9-0.3,3.4-1.3,6.5    c-0.8,2.4-0.7,3.7-5,9.4c-1.1,1.4-4.3,4.6-6.3,6c0,0-0.9,0.1-1.4,0.2c-6.5,1.5-28,3.1-28.1,3.2c-0.4,4.7,0,17.7,0.1,17.7    C244.8,636.5,276.3,636.8,278.2,636.8z"/>
                    <path className="st0 Left_Rear_Bumper" d="M254.7,718.3l0.5-5.9l0.2-4l-19.9,0.3c-0.9,0-2.8,1-2.6,1.7c0.6,2.4,1.8,6.7,3.1,7.4c1.7,1,9.6,1.9,17.2,1.4    C253.9,719.2,254.6,718.9,254.7,718.3z"/>
                    <path className="st0 Hood" d="M364.9,255.9c-20.7-11.5-41.2-13.7-63.6-15.4c-5-0.4-12.4-0.8-21.5-0.8c-1.8-3.3-3.7-6.6-5.5-9.9    c4.9,0.1,10.2,0.2,15.8,0.4c16.2,0.7,34.5,2.2,50.1,5.4c0.4,0.1,0.8,0.2,1.1,0.2c10.9,2.4,18.7,5.7,24.2,9.2c4.1,2.6,7,5.3,9,7.8    c-0.1,3.1-0.2,6.2-0.4,9.3C372.3,260.7,369.1,258.4,364.9,255.9z"/>
                    <path className="st1 Front_Glass" d="M363,252.8c-26.1-16-71.9-14.9-71.9-14.9l-1.9-7.7c16.2,0.7,34.4,2,50,5.2c0.9,0.1,0.9,0.2,1.2,0.2    c11.6,2.1,27.3,9.3,31.7,14.6l-0.5,7.3L363,252.8z"/>
                    <path className="st0 Left_Fender" d="M228.7,308.8c2.9,0,30.4,2.9,31.2,3.7c24.4,22.1,6.6,43.1-0.5,47.7c-2.1,1.4-30.7,3.4-30.7,3.6    c0,8.3-0.1,16.1,0,28.3l48.5,0.4c0,0,0.5-88.7,0.5-100.8l-48.8-0.3C229.2,303.2,228.7,308.8,228.7,308.8z"/>
                    <g>
                        <path className="st2 Left_Indicator" d="M245.6,305.6V296c0-0.6,0.6-1.2,1.4-1.2h3.3c0.8,0,1.4,0.6,1.4,1.2v9.6c0,0.6-0.6,1.2-1.4,1.2H247    C246.2,306.8,245.6,306.2,245.6,305.6z"/>
                        <path className="st0 Extra91" d="M266.7,229.7h-13.2"/>
                    </g>
                    <path className="st0 Left_Pillar_A" d="M279.7,239.9c0-0.4,7.8-0.2,11.7,0c6.7,0.2,12.3,0.8,16.4,1.1c3.5,0.3,6.5,0.5,10.5,1    c4.7,0.6,8.2,1.2,11.4,1.8c3.8,0.7,8.7,1.7,14.4,3.1c3.5,1,7.5,2.4,11.7,4.3c3.7,1.6,7.3,3.2,11.7,6.1c2.9,1.9,5.2,3.8,6.7,5.1    c-0.2,2-0.3,4.1-0.5,6.1c-0.1-0.1,0,1-0.2,1c-1.2,0.3-2.5,1.6-2.5,1.6s-3.5-5.3-15.4-11.4c-5.3-2.3-10.9-5.7-33.8-10.1    c-2.5-0.5-8.1-1.7-9.7,4.4c-0.7-3-0.5-9.5-1.1-9.6l-2.7-0.3c-4.6-0.3-9.1-0.5-13.7-0.8c0,0-2.7-0.1-2.7-0.1    c-0.1,0-12.3-0.2-12.3-0.5C279.6,241.9,279.7,240.6,279.7,239.9z"/>
                    <path className="st0 Left_Front_Door" d="M228.8,265.9v25.7h83.9c0-1.6,0-5.1,0-6l-0.9-27.4c0.1-2.7-0.3-13.7-0.5-13.8c-13.3-1.3-24-1.2-31.7-1.6    C232.9,240.1,229.5,259.3,228.8,265.9z"/>
                    <path className="st0 Extra94" d="M312.7,285.6l58.3,0.3c0-0.1,0-0.1,0-0.2L312.7,285.6z"/>
                    <path className="st1 Left_Front_Window_Glass" d="M311.8,258.2l0.9,27.4l58.3,0.1c-0.1-3-0.1-6.2,0-9l0-5.8c0,0-3.5-5.1-15.4-11.2    c-5.3-2.3-10.9-5.7-33.8-10.1C318.5,249,312.3,247.5,311.8,258.2z"/>
                    <path className="st1 Extra96" d="M371,285.7L371,285.7l0-9C370.9,279.4,370.9,282.7,371,285.7z"/>
                    <g id="Windows_00000096048752248229601250000013365890548967833730_">
                        <polygon className="st1 Left_Rear_Window_Glass" points="371.2,633.9 371.1,560.5 312.4,560.3 312.6,633.9 				"/>
                        <polygon className="st1 Left_Front_Window_Glass" points="370.7,441.1 371.2,365.1 312.6,365.1 312.1,441.1 				"/>
                        <rect x="312.4" y="445.5" className="st1 Left_Front_Window_Glass" width="58.6" height="62.3"/>
                        <polygon className="st1 Left_Front_Window_Glass" points="371,361 371.3,291 312.7,291 312.4,361 				"/>
                        <rect x="312.7" y="638.8" className="st1 Left_Rear_Window_Glass" width="58.6" height="68.7"/>
                        <polygon className="st0 Left_Pillar_G" points="312.6,633.6 312.6,638.5 371.1,639.1 371.1,634.2 				"/>
                        <polygon className="st0 Left_Pillar_F" points="312.4,555.8 312.4,560.3 371.1,560.5 371.1,555.6 				"/>
                        <polygon className="st0 Left_Pillar_E" points="312.5,507 312.5,511.5 370.9,511.7 370.9,507.3 				"/>
                        <path className="st0 Left_Pillar_B" d="M312.7,285.6v5.3l58.4,0.1c0-1.5,0-3.2-0.1-5.1L312.7,285.6z"/>
                        <path className="st0 Extra106" d="M371,285.9c0,1.9,0.1,3.6,0.1,5.1h0.1L371,285.9L371,285.9z"/>
                        <polygon className="st0 Left_Pillar_C" points="312.7,360.7 312.7,365.3 371.1,365.4 371.1,361 				"/>
                        <polygon className="st0 Left_Pillar_D" points="312.4,440.8 312.4,445.3 370.8,445.5 370.8,441.1 				"/>
                    </g>
                    <path className="st0 Left_Front_Door_Handle" d="M272.7,278.8h2.4c0.5,0,0.9,0.3,0.9,0.8v6.7c0,0.4-0.4,0.8-0.9,0.8h-2.4V278.8z"/>
                    <rect x="229.4" y="511.7" className="st0 Left_Rear_Door" width="141.8" height="43.9"/>
                    <polygon className="st1 Left_Rear_Window_Glass" points="365.3,549.5 365.3,518.7 312.6,518.7 312.6,549.5 			"/>
                    <path className="st0 Left_Rear_Door_Handle" d="M289.1,535.6h2.4c0.5,0,0.9,0.3,0.9,0.8v6.7c0,0.4-0.4,0.8-0.9,0.8h-2.4V535.6z"/>
                </g>
                <path className="st3 Left_Fender_Extender" d="M257.4,310.3c27,5.5,20.1,48.3,2.3,50.1l-11.4,1.5c27.7-4.3,34.2-45.9-0.8-51.3L257.4,310.3z"/>
                <path className="st3 Left_Qtr_Extender" d="M257.7,565.6c27,5.5,20.1,48.3,2.3,50.1l-11.5,1c27.7-4.3,34.3-45.8-0.7-51.3L257.7,565.6z"/>
                <path className="st0 Left_Side_Roof" d="M379.1,263.1v448c-0.2,0.2-1.5,1.1-1.5,1.1c-1.2,0.7-3.6,1.6-3.6,1.6c-0.2-0.3-1.3-0.8-1.4-0.9    c-0.8-0.7-1.1-3.7-1.1-5c0-1.7-0.1-3.7,0-5.8l-0.4-63.2c0,0,0-34.4,0-41.9c0.1-18.5-0.3-106.9-0.3-121.7c0-4.1,0.1-33.4,0.1-33.4    s0-38.3,0.1-74.2c0.1-35.8,0.2-70,0.2-71.7c0-1.5-0.1-10.4-0.1-11.8c0-0.3-0.2-11.1,0.2-13c-0.1,0.3,0.2-1.1,2.2-1.5l0.6-7.9    l0.4-8.1l0.1-0.9c0.3-0.2,1.5,1.9,1.9,2.7c0.9,1.6,1.3,2.3,1.7,3.4C378.4,259.9,378.8,261.3,379.1,263.1z"/>
                <path className="st4 Left_Side" d="M278.2,636.8l-0.4-81.1c11.6-0.3,23.1,0.5,34.6,0.1v151.7h-38l-1.6,0.1c-13.6,0.6-22.7,1.2-37.5,1.1    c-2.5-4.5-3.1-10.6-4-16.6c-2.8-18.4-0.1-37.3-0.1-55.9L278.2,636.8z"/>
                <path className="st4 Left_Side" d="M276.9,392.4l0.5-100.8h35.3l-0.2,219.9c0,0-23.6,0.2-35.2,0.1l0,0l-47.9,0.1l-0.7-119.6L276.9,392.4z"/>
                <path className="st0 Left_Stair" d="M223.4,291.4h1.8c0.8,0,1.5-0.7,1.5-1.5v-23.6c0-0.8-0.7-1.5-1.5-1.5h-1.8c-0.8,0-1.5,0.7-1.5,1.5v23.6    C221.9,290.7,222.6,291.4,223.4,291.4z"/>
                <rect x="234.4" y="417" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 480.5067 914.5775)" className="st5 Left_Front_Door_Moulding" width="11.8" height="80.6"/>
                <rect x="238.9" y="644" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 489.5214 1345.1881)" className="st5 Left_Rear_Door_Moulding" width="11.8" height="57.2"/>
            </g>
        </svg>
    )
}

export default BusSVG;