import React, { useContext, useEffect, useRef } from 'react'
import style from './style/ClaimPartLevelResult.module.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { AppContext } from './context'
import lang from './language/ClaimPartLevelResult.json'
import GoBack from '../img/left-arrow.png'


let imageCounter = 0

const ClaimPartLevelResult = () => {

    const { swipe, setSwipe, photowiseDetails, selectedImage, setSelectedImage, language} = useContext(AppContext)

    const navigate = useNavigate()
    const imageRef = useRef()
    const { id } = useParams()

    const setImageCounter = (direction) => {
        if(direction === "right"){
            if(imageCounter >= photowiseDetails["relevant_image_section"].length - 1){
                imageCounter = 0
                setSelectedImage(photowiseDetails["relevant_image_section"][imageCounter])
                scrollSmoothHandler(-12000)
                return
            }
            imageCounter ++
            setSelectedImage(photowiseDetails["relevant_image_section"][imageCounter])
            scrollSmoothHandler(103)
        }else{
            if(imageCounter <= 0){
                imageCounter = photowiseDetails["relevant_image_section"].length - 1
                setSelectedImage(photowiseDetails["relevant_image_section"][imageCounter])
                scrollSmoothHandler(12000)
                return
            }
            imageCounter --
            setSelectedImage(photowiseDetails["relevant_image_section"][imageCounter])
            scrollSmoothHandler(-103)
        }
    }

    const setImageLocation = () => {
        let angle = parseInt(selectedImage["detectedAngle"])
        if(angle < 0) angle = angle + 360
        if ((angle >= 337.5 && 360 > angle) || (angle >= 0 && 22.5 >= angle)) return lang["Front Side"][language]
        else if (angle > 22.5 && 66.5 >= angle) return lang["Front Left Side"][language]
        else if (angle > 66.5 && 112.5 >= angle) return lang["Left Side"][language]
        else if (angle > 112.5 && 157.5 >= angle) return lang["Rear Left Side"][language]
        else if (angle >= 157.5 && 202.5 >= angle) return lang["Rear Side"][language]
        else if (angle > 202.5 && 247.5 >= angle) return lang["Rear Right Side"][language]
        else if (angle >= 247.5 && 292.5 >= angle) return lang["Right Side"][language]
        else if (angle >= 292.5 && 337.5 >= angle) return lang["Front Right Side"][language]
        else return selectedImage["detectedAngle"]
    }

    const scrollSmoothHandler = (value) => {
        imageRef.current.scrollLeft += value
    }

    useEffect(()=>{
        imageCounter = 0
        setSelectedImage(photowiseDetails["relevant_image_section"][imageCounter])
    },[setSelectedImage, photowiseDetails])

    return (
    <div className={style.carlevel}>
        <div className={style.ModuleID} onClick={()=>navigate('/claim')}>
            <img src={GoBack}></img>
            <span className={style.preSpan}>{lang["Claim"][language]}</span>
        </div>

        <div className={style.switchBtnContainer}>
            <div>
                <button style={{backgroundColor: swipe === "carLevelResult" ? "#157AFF" : null }} onClick={() => setSwipe("carLevelResult")} className={style.switchBtn}>{lang["Car level result"][language]}</button>
                <button style={{backgroundColor: swipe === "partlevelResult" ? "#157AFF" : null }} onClick={() => setSwipe("partlevelResult")} className={style.switchBtn}>{lang["Part level result"][language]}</button>
            </div>
        </div>
        <div className={style.resultSvg}>
            <div className={style.profileUpdate}>
                <div className={style.profileUpdateContent}>
                    <p className={style.profile}>{setImageLocation()}</p>
                </div>
            </div>
            <div className={style.carSvg}>
                <div className={style.carSvgContainer}>
                    <div className={style.carImg}>
                        <img className={style.img} src={selectedImage.imageUrl} alt="maincar" />
                    </div>
                </div>
            </div>
            <div className={style.footer}>
                <div className={style.degreeUpdater}>
                    <p className={style.degree}>
                        {`Q-Score: ${selectedImage["qualityScore"]}, Angle: ${selectedImage["detectedAngle"]}(degrees)`}
                    </p>
                </div>
            </div>
            <div className={style.imageReelContainer}>
                <i className="fa-solid fa-angle-left" onClick={()=>setImageCounter("left")}></i>
                <div className={style.imageReel} ref={imageRef}>
                    {photowiseDetails["relevant_image_section"].map((item,index)=>{
                        return <div key={index} onClick={()=>{
                            setSelectedImage(photowiseDetails["relevant_image_section"][index])
                            imageCounter = index
                            }}>
                            <img src={item.imageUrl} style={{border: item.imageUrl === selectedImage.imageUrl ? "3px solid #157AFF" : null}} alt="img"/>
                        </div>
                    })}
                </div>
                <i className="fa-solid fa-angle-right" onClick={()=>setImageCounter("right")}></i>
            </div>
        </div>
    </div>
  )
}

export default ClaimPartLevelResult