import Cookies from 'js-cookie'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { makeAuthRequest } from './context/utils'
import config from '../config/config.json'

const GTclose = () => {
    const {urlLink} = config

    const { id } = useParams()
    const navigate = useNavigate()

    const sendGTStatus = async() => {
        makeAuthRequest(`https://results-backend-eu.inspektlabs.com/set_gt_pdf_download_status`, {inspection_id: id, client_id: Cookies.get('client'), client_type: Cookies.get('clientType')})
    }

    const backToClaim = () => {

        window.top.location.href = window.location.origin + `/claim/${id}`

    }

    useEffect(()=>{
        sendGTStatus()
    },[])

    return (
        <button style={{color: "#09728e", background: "#fff", position: "absolute", top: "8px", right: "188px", fontFamily: "Open Sans,sans-serif", fontSize: "14px", fontWeight: 400, height: "34", border: 0, borderRadius: "3px", cursor: "pointer"}} onClick = {()=> backToClaim()}>Close GT Estimate</button>
    )
}

export default GTclose