import React from 'react'
import { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import RejectedCarLevelResult from './RejectedCarLevelResult'
import RejectedCarLevelDashboard from './RejectedCarLevelDashboard'
import { AppContext } from './context'
import { checkCookies, makeAuthRequest, removeCookies } from './context/utils'
import Navbar from './Navbar'
import loadingImg from '../img/loading.gif'
import RejectedPartLevelDashboard from './RejectedPartLevelDashboard'
import RejectedPartLevelResult from './RejectedPartLevelResult'
import styles from './style/InspectionResult.module.scss'
import Cookies from 'js-cookie'
import config from '../config/config.json'


const RejectedResult = () => {

  const { swipe, setPhotowiseDetails, photowiseLoading, setPhotowiseLoading, setLanguage, setLanguageList, setModule, setConfigData } = useContext(AppContext)
  const {urlLink} = config
  const { id } = useParams()
  const navigate = useNavigate()

  const getConfig = async() => {

    const res = await makeAuthRequest(`${urlLink}getConfig`, { client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType') })
    const data = await res.json()
    if(res.ok){
      setConfigData(data)
      setPhotowiseLoading(false)
    }
    
  }

  const getInspectionDetails = async() => {

    const res = Cookies.get("isMahindra") ? await makeAuthRequest(`${urlLink}photowise-details-rejected`, { client_id: Cookies.get('client'), inspection_id: id, access_token: Cookies.get("token"), tl_id: Cookies.get("MahindraTlId"), client_type: Cookies.get("MahindraClientType"), tl_name: Cookies.get("MahindraTlName"), agent_id : Cookies.get("MahindraUserId"), agent_name: Cookies.get("MahindraUserName"), agent_tl_view: "", agent_tl_id: ""}) : await makeAuthRequest(`${urlLink}photowise-details-rejected`, { client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType'), agent_tl_view: Cookies.get("tlView"), agent_tl_id: Cookies.get("tlId") })
    const data = await res.json()

    setPhotowiseDetails(data)
    if(data["batch_status"] === "Available"){
      navigate(`/pre-inspection/${id}`)
    }
    if(data["batch_status"] === "Processing"){
      navigate('/pre-inspection')
    }
    getConfig()
    
  }

  useEffect(()=>{
    setPhotowiseLoading(true)
    if(!checkCookies()){
      removeCookies()
      navigate('/login')
    }
    else{
      if(!id) return navigate('/pre-inspection')
      else {
        setLanguage(Cookies.get('current-language'))
        setModule(JSON.parse(Cookies.get('modules')))
        setLanguageList(JSON.parse(Cookies.get('all-languages')))
        getInspectionDetails()
      } 

    } 
  }, [navigate, setPhotowiseLoading, id, setLanguage, setLanguageList, setModule])
  
  return (
    <div className={styles.container}>
        <Navbar/>
        { photowiseLoading ? 
        <div className={styles.loading} >
          <img src={loadingImg} alt='loading...'/>
        </div>:
        <div className={styles.inspectionResultSwitch}>
        { swipe === "carLevelResult" && <RejectedCarLevelResult/>}
        { swipe === "carLevelResult" && <RejectedCarLevelDashboard/>}
        { swipe === "partlevelResult" && <RejectedPartLevelResult/>}
        { swipe === "partlevelResult" && <RejectedPartLevelDashboard />}
        </div>}
    </div>
  )
}

export default RejectedResult